import React, { useEffect } from "react";
import { Button } from "../Button";
import { Field } from "../Field";
import { useForm, Controller } from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {DateTime} from "luxon";

function ReportPaymentModal({ loading, data, getTypePayment, typePayments, financeReportPayment,getResponsible,responsibles }) {

  const dispatch = useDispatch()

  useEffect(() => {
    getTypePayment(data.permission === "pay" ? 2 : 1);
    dispatch({type:'GET_BANK_ACCOUNT'});

    if(data?.user?.id) {
      getResponsible(data?.user?.id)
    }
  }, []);

  const bankAccounts = useSelector(state => state.companyBankAccount.items);
  const bankAccountsOptions = bankAccounts?.map(value => ({label:value.bank?.bank+" (Agência: "+value.agency+" e Conta: "+value.account+")",value:value.id}))

  const responsibleOptions = responsibles?.map(value => ({label:value.responsible?.name,value:value.id}))
  const { control, handleSubmit, formState: { errors } } = useForm({
    defaultValues:{
      type_model: data ? data.typeModel : null,
      type:data.permission === "pay" ? 2 : 1
    }
  });

  const onSubmit = (request) => {

    financeReportPayment(data.id, request, data.permission);
  };

  const options = [];

  typePayments.forEach(element => {
    options.push({ label: element.description, value: element.id });
  });

  const today = DateTime.local().startOf('day');

  const isFutureDate = (date) => {
    const selectedDate = DateTime.fromJSDate(date).startOf('day');
    return selectedDate > today;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-md-6">
          <label className="fw-bold text-muted" style={{ marginTop: 10 }}>Data de {data.permission === "pay" ? "pagamento" : "recebimento"}*:</label>
          <Controller
            name="payment_date"
            control={control}
            rules={{ required: true }}

            render={({ field: { onChange, value } }) => <Field  disabledDay={isFutureDate} type="date" value={value} onChange={(e) => {
              onChange(e);
            }} />}
          />
          {errors.payment_date && <p style={{ color: "red", marginTop: 10 }}>Necessário preencher a data
            de {data.permission === "pay" ? "pagamento" : "recebimento"}.</p>}
        </div>
        {
          data.permission !== "pay" ?
            <div className="col-md-6">
              <label className="fw-bold text-muted" style={{ marginTop: 10 }}>{data.permission === "pay" ? "Tipo de conta" : "Forma de pagamento"}*:</label>
              <Controller
                name="type_payment"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => <Field type="select" value={value} options={options}
                                                                           onChange={onChange} />}
              />
              {errors.type_payment && <p style={{ color: "red", marginTop: 10 }}>Necessário selecionar
                a {data.permission === "pay" ? "tipo de conta" : "forma de pagamento"}.</p>}
            </div>
            :
            <></>
        }
        <div className="col-md-6">
          <label className="fw-bold text-muted" style={{ marginTop: 10 }}>Conta bancária:</label>
          <Controller
              name="bank_account_id"
              control={control}
              render={({ field: { onChange, value } }) =>
                  <Field
                      type="select"
                      options={bankAccountsOptions}
                      value={value}
                      onChange={onChange}
                  />
              }
          />
        </div>
        {
          data.permission !== "pay" ?
              <div className="col-md-6">
                <label className="fw-bold text-muted" style={{ marginTop: 10 }}>Recebeu de:</label>
                <Controller
                    name="responsible_id"
                    control={control}
                    render={({ field: { onChange, value } }) =>
                        <Field
                            type="select"
                            options={responsibleOptions}
                            value={value}
                            onChange={onChange}
                        />
                    }
                />
              </div>
              :
              <></>
        }
      </div>
      <div className="row" style={{ textAlign: "right", marginTop: 20 }}>
        <div className="col-md-9">

        </div>
        <div className="col-md-3">
          <Button loading={loading} text="Salvar" type="submit" />
        </div>
      </div>
    </form>
  );
}

export default ReportPaymentModal;
