import { useForm,Controller } from "react-hook-form";
import { Field } from "../Field";
import React from "react";
import { Button } from "../Button";

function BloquearHorarioModal({
    getAutocompleteProfessional,
    professionalsAutocomplete,
    professionalsLoadingAutocomplete,
    update,
    data,
    loading,
    auth,
    store
}) {    

    const { control, handleSubmit, formState: { errors }} = useForm({
        defaultValues:{
           company:auth.company.id,
           patient: 0,
           professional: data && data.professional ? data.professional.id : null,
           treatment: 0,
           type_schedule: 0,
           type_address: 0,
           room:0,
           address: 0,
           observation: ''         
        }
    });

    const onSubmit = (request)=>{        
     
        if(data && data.id){
            update(request,data.id)
        }else{
            store(request)
        }        
    }
      
    const handleInputChangeProfessional = (value) => {
        getAutocompleteProfessional(1,1,10,value);
    };

    let arrayProfessionals = [];
    if(professionalsAutocomplete && professionalsAutocomplete.length > 0){
        professionalsAutocomplete.forEach(value =>{
            arrayProfessionals.push({value:value.id,label:value.name})
        });
    }   

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-lg-6">
                    <label className="fw-bold text-muted" style={{marginTop:10}}>Profissional:</label>
                    <Controller
                        name="professional"
                        control={control}
                        rules={{ required: true }}
                        render={({ field : { onChange, value }}) => <Field type="autocomplete" loading={professionalsLoadingAutocomplete}  onInputChange={handleInputChangeProfessional}  value={value} options={arrayProfessionals} onChange={onChange} />}
                    />
                    {errors.professional && <p style={{color:'red',marginTop:10}}>Necessário selecionar o profissional.</p>}
                </div>                
                <div className="col-lg-6">
                    <label className="fw-bold text-muted" style={{marginTop:10}}>Data de início:</label>
                    <Controller
                        name="start_date"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => <Field type="datetime"  value={value} onChange={onChange} />}
                    />
                    {errors.start_date && <p style={{color:'red',marginTop:10}}>Necessário selecionar a data de início.</p>}
                </div>
                <div className="col-lg-6">
                    <label className="fw-bold text-muted" style={{marginTop:10}}>Data de termino:</label>
                    <Controller
                        name="end_date"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => <Field type="datetime"  value={value} onChange={onChange} />}
                    />
                    {errors.end_date && <p style={{color:'red',marginTop:10}}>Necessário selecionar a data de termino.</p>}
                </div>
            </div>            
            <div className="row" style={{textAlign:'right',marginTop:10}}>
                <div className="col-md-9">

                </div>
                <div className="col-md-3">
                    <Button loading={loading} text="Salvar" type="submit" />
                </div>   
            </div>
        </form>
    );
}

export default BloquearHorarioModal