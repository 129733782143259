import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import UserProfession from "../../components/professionals/UserProfession";
import {openModal} from "../../store/actions/uiActions";
import {destroyUserProfession, getUserProfession} from "../../store/actions/userProfessionActions";

function UserProfessionController({openModal,getUserProfession,user,loading,items,destroyUserProfession}) {

    useEffect(() => {
        getUserProfession(user)
    }, [getUserProfession]);

    return (
        <UserProfession destroyUserProfession={destroyUserProfession} user={user} openModal={openModal} loading={loading} items={items} />
    );
}

const mapStateToProps = state => ({
    loading:state.userProfession.loading,
    items:state.userProfession.items,
});

const mapDispatchToProps = dispatch => ({
    openModal:(modal,payload) => dispatch(openModal(modal,payload)),
    getUserProfession:(user) => dispatch(getUserProfession(user)),
    destroyUserProfession:(data) => dispatch(destroyUserProfession(data))
});

export default connect(mapStateToProps,mapDispatchToProps)(UserProfessionController);