import React from 'react';
import Chart from "react-apexcharts";
import ContentLoader from "react-content-loader";

function ChatStyled({data,loading}) {

    if(loading){
        return <div style={{paddingTop:20,paddingLeft:20,height:200}}>
            <ContentLoader viewBox="0 0 380 70">
                <rect x="10" y="0" rx="4" ry="4" width="150" height="20" />
                <rect x="10" y="30" rx="4" ry="4" width="80" height="20" />
            </ContentLoader>
        </div>
    }

    return (
        <Chart
            options={data.options}
            series={data.series}
            type="area"
            height="200"
            key="2"
        />
    );
}

export default ChatStyled;