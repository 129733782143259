import { call, delay, put, takeEvery, select} from 'redux-saga/effects';
import api from '../../api';
import { push } from 'connected-react-router';
import { authFail, authStart, authSuccess, setAdmin, setCompany, setPermission } from '../actions/authActions';
import {  isSupported } from "firebase/messaging";
import { closeModal } from '../actions/uiActions';
import { messenge } from './utils';

export const getAuthToken = (state) => state.auth.token;

function* auth(action){
    try{
        yield put(authStart());

        const response = yield api.post('/login',action)

        if(response.status === 200){
            yield put(authSuccess(response.data))
            yield put(setAdmin(response.data.admin))
            yield put(setCompany(response.data.company))
            yield put(push('/'));
            yield getPermission();
        }else{
            yield messenge(response.data,'warning');
            yield put(authFail());
        }
    } catch (e) {
        yield messenge(e.response.data,'warning');
        yield put(authFail());
    }
}

function* initiateNotification() {

    if (!("Notification" in window)) {
        console.error("Este navegador não suporta notificações!");
        return;
    }

    if (!(yield isSupported()) || Notification.permission === 'denied') {
        console.error("Navegador não suporta notificações ou permissão foi recusada!");
        return;
    }

    //let token;
    try {
        /*token = yield getToken(messaging, {
            vapidKey: 'BKKasFmxA9OBVrGvoQ5B22mfJlLnfBO74fxeLpLPO1K1ythhYMiL6BD-Npnhp886suiomy6Ehovduatv0au-DM0'

        });*/


    } catch (e) {
        console.error(e);
        return;
    }

    try {

    } catch (e) {
        console.error("Erro na comunicação com o NotificationCenter", e);
    }
}

function* alterPassword(action){
    try{
        yield put(authStart());
        const response = yield api.post('/user/'+action.id+'/password',action,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        })

        if(response.status === 200){
            yield put(authFail());
            yield put(messenge(response.data,'success'))
            yield put(closeModal())
        }
    } catch (e) {
        yield put(authFail());
        yield messenge(e.response.data,'warning')
    }
}

function* logout(){
    yield localStorage.clear();
}

function* getPermission(){
    try{

        const response = yield  api.get('/user/permissions',{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield put(setPermission(response.data))
        }
    } catch (e) {
        console.error(e);
    }
}

export function* authSaga() {
    yield takeEvery('AUTH', auth);
    yield takeEvery('AUTH_LOGOUT', logout);
    yield takeEvery('GET_NOTIFICATION', initiateNotification);
    yield takeEvery('AUTH_ALTER_PASSWORD', alterPassword);
    yield takeEvery('GET_PERMISSION', getPermission);

    let token = yield select(getAuthToken);


    while (token) {
        yield call(getPermission);
        yield delay(3600000);
    }
}
