import { useForm,Controller } from "react-hook-form";
import { Field } from "../Field";
import React from "react";
import { Button } from "../Button";

function ProfileModal({data,update,loading,store}) {

    const {control, handleSubmit, formState: { errors } } = useForm({
        defaultValues:{
            name:data && data.name
        }
    });

    const onSubmit = (request)=>{      
        if(data && data.id){
            update(data.id,request);
        }else{
            store(request)
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">                
                <div className="col-lg-4">
                    <label className="fw-bold text-muted" style={{marginTop:10}}>Descrição:</label>
                    <Controller
                        name="name"
                        control={control}        
                        rules={{ required: true }}                
                        render={({ field : { onChange, onBlur, value }}) => <Field type="text" value={value}   onChange={onChange}  />}
                    />
                    {errors.type && <p style={{color:'red',marginTop:10}}>Necessário preencher o tipo.</p>}
                </div>
            </div>
            <div className="row" style={{textAlign:'right',marginTop:20}}>
                <div className="col-md-10">
                   
                </div>
                <div className="col-md-2">
                    <Button loading={loading} text="Salvar" type="submit" />
                </div>   
            </div>
        </form>
    );
}

export default ProfileModal