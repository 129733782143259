import React from 'react';
import {connect} from 'react-redux';
import {closeModalTertiary, openModalTertiary} from "../store/actions/uiActions";
import ModalController from "./ModalController";

function TertiaryController({modal,modalOpen,modalPayload,closeModal,openModal,calendarRef,isMobile}) {
    return (
        <ModalController zIndex={1000} modal={modal} modalOpen={modalOpen} modalPayload={modalPayload} closeModal={closeModal} openModal={openModal} calendarRef={calendarRef} isMobile={isMobile} />
    );
}

const mapStateToProps = state => ({
    modal: state.ui.modalTertiary,
    modalOpen: state.ui.modalOpenTertiary,
    modalPayload: state.ui.modalPayloadTertiary,
})

const mapDispatchToProps = dispatch => ({
    closeModal: () => dispatch(closeModalTertiary()),
    openModal: () => dispatch(openModalTertiary()),
})

export default connect(mapStateToProps, mapDispatchToProps)(TertiaryController)
