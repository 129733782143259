import React, {useMemo} from "react";
import {Controller, useForm} from "react-hook-form";
import {Field} from "../Field";
import {Link} from "react-router-dom";
import {Avatar} from "../Avatar";
import ContentLoader from "react-content-loader";
import isMobile from "is-mobile";
import Img5 from "../../assets/img/5.png";

function Filter({loading,handleSubmit,onSubmit,control,turn,dayTheWeek,treatment,openAny,isMobile})
{
    const arrayTreatment = []
    if (treatment && treatment.length > 0) {
        const removeDuplicatesBySpecializationId = (array) => {
            const seen = new Set();
            return array.filter(item => {
                const id = item.specialization.id;
                return seen.has(id) ? false : seen.add(id);
            });
        };

        removeDuplicatesBySpecializationId(treatment)?.filter(value => value.status === 1).forEach(element => {
            arrayTreatment.push({
                label: ((element && element.specialization.profession) ? element.specialization.profession.description : '') + " " + (element.specialization.description ? element.specialization.description : ''),
                value: element.id
            })
        });

        arrayTreatment.unshift({value:null,label:'Todos'})
    }

    const Form = useMemo(()=>{

        return (
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className='col-md-3' style={{marginTop: 10}}>
                        <label className="fw-bold text-muted">Tratamento:</label>
                        <Controller
                            name="specialization_id"
                            control={control}
                            render={({field: {onChange, value}}) =>
                                <Field type="select" options={arrayTreatment
                                } value={value} onChange={onChange}/>
                            }
                        />
                    </div>
                    <div className='col-md-3' style={{marginTop: 10}}>
                        <label className="fw-bold text-muted">Dia da semana</label>
                        <Controller
                            name="day_the_week"
                            control={control}
                            render={({field: {onChange, value}}) =>
                                <Field type="select" options={dayTheWeek} value={value} onChange={onChange}/>
                            }
                        />
                    </div>
                    <div className='col-md-3' style={{marginTop: 10}}>
                        <label className="fw-bold text-muted">Turno</label>
                        <Controller
                            name="turn"
                            control={control}
                            render={({field: {onChange, value}}) =>
                                <Field type="select" options={turn} value={value} onChange={onChange}/>
                            }
                        />
                    </div>
                    <div className="col-md-1" style={{marginTop: 30}}>
                        <button className={'btn btn-success btn-sm btn-block'+(loading? ' disabled':"")} style={{width: '100%'}}>
                            <div style={{width: 20, margin: 'auto'}}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path
                                        d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352c79.5 0 144-64.5 144-144s-64.5-144-144-144S64 128.5 64 208s64.5 144 144 144z"
                                        fill="#fff"/>
                                </svg>
                            </div>
                        </button>
                    </div>
                    <div className="col-md-5"></div>
                </div>
            </form>
        )
    },[loading]);


    if(isMobile){
        return (
            <div style={{display: 'flex', marginBottom: 10, marginTop: 20}}>
                <h1 style={{flex: 1}}>Fila de espera</h1>
                <div>
                    {
                        isMobile && <div style={{cursor:'pointer',marginRight:10}} onClick={() => openAny(Form,"Filtro", "lg")}>
                            <span className="svg-icon svg-icon-black ">
                                 <svg width="20" height="24" viewBox="0 0 20 20" fill="#000"
                                      xmlns="http://www.w3.org/2000/svg">
                                     <rect x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                                           transform="rotate(45 17.0365 15.1223)" fill="#000"/>
                                     <path
                                         d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                         fill="#000"/>
                                 </svg>
                            </span>
                        </div>
                    }
                </div>
            </div>
        )
    }

    return(
        <div className="card" style={{marginBottom: 20}}>
            <div className="card-header" style={{padding: "5px 20px", minHeight: 30}}>
                <div className="card-title">
                    <div>
                        <div style={{flex: 1}}>Filtro</div>
                    </div>
                </div>
            </div>
            <div className="card-body" style={{paddingTop: 10}}>
                {Form}
            </div>
        </div>
    )
}

const ListSkeleton = () => (
    <ContentLoader
        speed={2}
        width={400}
        height={520}
        viewBox="0 0 400 520"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="0" y="0" rx="5" ry="5" width="100%" height="20" />
        <rect x="0" y="40" rx="5" ry="5" width="80%" height="20" />
        <rect x="0" y="80" rx="5" ry="5" width="90%" height="20" />
        <rect x="0" y="120" rx="5" ry="5" width="70%" height="20" />
        <rect x="0" y="160" rx="5" ry="5" width="100%" height="20" />
        <rect x="0" y="200" rx="5" ry="5" width="85%" height="20" />
        <rect x="0" y="240" rx="5" ry="5" width="95%" height="20" />
        <rect x="0" y="280" rx="5" ry="5" width="100%" height="20" />
        <rect x="0" y="320" rx="5" ry="5" width="80%" height="20" />
        <rect x="0" y="360" rx="5" ry="5" width="90%" height="20" />
        <rect x="0" y="400" rx="5" ry="5" width="70%" height="20" />
        <rect x="0" y="440" rx="5" ry="5" width="100%" height="20" />
        <rect x="0" y="480" rx="5" ry="5" width="85%" height="20" />
    </ContentLoader>
);

function Queue({listQueueWait,loading, list, isMobile,treatment,openAny,closeAny}) {

    const onSubmit = (request) => {
        closeAny()
        listQueueWait(request)
    }

    const dayTheWeek = [
        { label: 'Todos', value: null },
        { label: 'Domingo', value: 0 },
        { label: 'Segunda-feira', value: 1 },
        { label: 'Terça-feira', value: 2 },
        { label: 'Quarta-feira', value: 3 },
        { label: 'Quinta-feira', value: 4 },
        { label: 'Sexta-feira', value: 5 },
        { label: 'Sábado', value: 6 }
    ]

    const turn = [
        {
            label: 'Todos',
            value: null
        },
        {
            label: 'Manhã',
            value: 1
        },
        {
            label: 'Tarde',
            value: 2
        },
        {
            label: 'Noite',
            value: 3
        }
    ]

    const {control, handleSubmit,getValues} = useForm();
    console.log(list)
    return (
        <div style={{marginTop: 10}}>
            {!isMobile ? <div style={{display: 'flex', marginBottom: 10, marginTop: 20}}>
                <h1 style={{flex: 1}}>Fila de espera</h1>
            </div>:<></>}
            <Filter  openAny={openAny} isMobile={isMobile} loading={loading} onSubmit={onSubmit} treatment={treatment} control={control} handleSubmit={handleSubmit} turn={turn} getValues={getValues} dayTheWeek={dayTheWeek} />
            {
                loading ?
                    <ListSkeleton />
                    :
                    list?.length > 0 ?
                        list?.map((value, index) => {
                            console.log(value)
                            return (
                                <Link to={"/patient/"+value.patient?.id} className="card  mb-1 mb-xl-2" style={{cursor: 'pointer'}} key={index} >
                                    <div className="card-body" style={{padding: 10}}>
                                        <div style={{flexDirection: 'row', display: 'flex',alignItems: isMobile ? "normal": "center"}}>
                                            <div style={{flexDirection: 'column', display: 'flex'}}>
                                                <Avatar row={value.patient}/>
                                                {
                                                    value.patient?.status === 1 ?
                                                        <div className="badge badge-success"
                                                             style={{
                                                                marginTop: 5,
                                                                width: 50,
                                                                fontSize: 9
                                                            }}
                                                        >
                                                            Ativo
                                                        </div> :
                                                        <div
                                                            className="badge badge-danger"
                                                            style={{
                                                                marginTop: 5,
                                                                width: 50,
                                                                fontSize: 9
                                                            }}
                                                        >
                                                            Inativo
                                                        </div>
                                                }
                                            </div>
                                            <div style={{marginLeft: 10, display:"flex", flexDirection:isMobile ? "column" : "row", alignItems: isMobile ? "normal": "center"}}>
                                                <div style={{justifyContent:"center",marginRight:20}}>
                                                    <h6>{value.patient?.name}</h6>
                                                    {value.patient?.email && <div><b>Email</b>: {value.patient?.email}</div>}
                                                    {value.patient?.cellphone && <div><b>Celular</b>: {"+" + value.patient?.ddi + " " + value.patient?.cellphone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')}</div>}
                                                </div>
                                                <div className="notice d-flex rounded border border-dashed bg-light-info border-info" style={{padding:10,marginTop:isMobile?10:0}} >
                                                    <span className={"svg-icon svg-icon-2tx me-4 svg-icon-info"}>
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect>
                                                            <rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="currentColor"></rect>
                                                            <rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"></rect>
                                                        </svg>
                                                    </span>
                                                    <div className="d-flex flex-stack flex-grow-1 ">
                                                        <div className="fw-semibold" >
                                                            <div ><b>Tratamento</b>: { value && value.company_specialization?.specialization && value.company_specialization?.specialization?.profession && value.company_specialization?.specialization?.profession?.description } { value && value && value.company_specialization?.specialization && value.company_specialization?.specialization?.description }</div>
                                                            { value.day_the_week ? <div style={{marginTop:1}}><b>Dia da semana</b>: { value.day_the_week ? dayTheWeek.filter(v => v.value == value.day_the_week)[0].label : ''}</div> : ''}
                                                            {value.to_turn ? <div style={{marginTop:1}}><b>Turno</b>: {value.to_turn ? turn.filter(v => v.value == value.to_turn)[0].label : ''}</div> : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            )
                        })
                        :
                        <div className="d-flex flex-column flex-center" style={{paddingTop: 20}}>
                            <img src={Img5} style={{maxWidth: 330}} alt={""}/>
                            <div className="text-dark mb-4" style={{fontSize: 18, textAlign: "center"}}>
                                Não encontramos nenhum registro de tratamento na fila de espera.
                            </div>
                        </div>
            }
        </div>
    );
}

export default Queue;