import { debounce, put, takeEvery,select} from 'redux-saga/effects';
import toast from "react-hot-toast";
import api from '../../api';
import { alterFields, groupsAction, groupsFail,  groupsStart, groupsSuccess } from '../actions/groupsActions';
import {anamneseAlter, evolutionAlter, patientAlterTherapeutic, therapeuticAlter} from "../actions/patientsActions";
import {alterPendenciesTherapeutic, setItem} from "../actions/calendarActions";

export const getAuthToken = (state) => state.auth.token;
export const getAuthCompany = (state) => state.auth.company.id;


function* getGroups(action){

    try{
        yield put(groupsSuccess([]))
        yield put(groupsStart());

        const response = yield  api.get("/groups/company/"+ (yield select(getAuthCompany))+"/type/"+action.typeSchedule+"/treatment/"+action.treatment+"/call/"+action.call+"/patient/"+action.patient,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield put(groupsSuccess(response.data))
        }else{
            yield put(groupsFail())
        }


    } catch (e) {
        console.error(e);
        yield put(groupsFail())
    }
}

function* getPDFGroups(action){

    try{
        let response = api.post("/call/list/report",action.data,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        toast.promise(response,
          {
              loading: 'Gerando o relatório',
              success: (success) => {
                  return "Relatório gerado com sucesso."
              },
              error: (error) => {
                  return error.response.data.message
              }
          }
        );

        response = yield response;

        if(response.status === 200){
            window.open(response.data.url, '_top')
        }
    } catch (e) {
        console.error(e);
    }
}

function* copy(action){
    try{

        const toastId = toast.loading('Utilizando o texto do atendimento anterior como referência.');

        let response = yield api.get("/groups/copy/patient/"+action.patient+"/field/"+action.field+"/typeSchedule/"+action.typeSchedule+"/group/"+action.group+"/call/"+action.call+"/treatment/"+action.treatment, {
            headers: {
                Authorization: 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        toast.dismiss(toastId)

        if(response.status === 200){
            if(response.data.text){
                toast.success('Foi obtida uma cópia do texto do atendimento anterior.')

                yield save({connection:action.connection,table:action.table,id_link:action.id_link,value_link:action.call,id:action.field,value:response.data.text});
                yield put(alterFields(action.group,action.field,response.data.text))
            }else{
                if(!response.data.text){
                    toast.error('Não existe nenhum texto anterior para ser usado como referência');
                }
            }
        }
    } catch (e) {
        console.error(e)
    }
}

function* save(action){
    try{

        yield put(groupsAction(action.table,true));

        const response = yield  api.post("/groups",action,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield put(groupsAction(action.table,false));
            yield put(alterFields(response.data.group.id_group,response.data.group.id,response.data.group.value))

            switch (action.typeSchedule){
                case 1:
                case 4:
                case 2:
                    yield put(evolutionAlter(action));
                    break;
                case 3:
                    yield put(anamneseAlter(action))
                    break;
                case 5:
                    yield put(therapeuticAlter(action));
                    if(response.data.atendimento.id) {
                        yield put(setItem(response.data.atendimento, response.data.atendimento.uuid))
                        yield put(alterPendenciesTherapeutic(response.data.atendimento));
                        yield put(patientAlterTherapeutic(response.data.atendimento));
                    }
                    break;
                default:
                    break;
            }
        }else{
            yield put(groupsAction(action.table,false));
        }
    } catch (e) {
        yield put(groupsAction(action.table,false));
        console.error(e);
    }
}



export function* groupsSaga() {
    yield takeEvery('GET_GROUPS', getGroups);
    yield debounce(1000,'SAVE_GROUPS', save);
    yield takeEvery('GET_PDF_GROUPS',getPDFGroups);
    yield takeEvery('COPY_GROUPS',copy)
}
