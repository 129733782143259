import { connect } from 'react-redux';
import {auth} from "../../store/actions/authActions";
import wayfly from '../../assets/img/wayfly.png';
import bg from '../../assets/img/auth-bg.png';
import grafico from '../../assets/img/grafico.png';
import Spinner from "react-activity/dist/Spinner";
import React from "react";

function Login({auth,loading}) {

    const onSubmit = (ev) =>{
        ev.preventDefault();
        auth(ev.target.email.value,ev.target.password.value);
    }

    return (
        <div className="d-flex flex-column flex-root" id="kt_app_root">
            <div className="d-flex flex-column flex-lg-row flex-column-fluid">
                <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
                    <div className="d-flex flex-center flex-column flex-lg-row-fluid">
                        <div className="w-lg-500px p-10">
                            <form onSubmit={onSubmit}>
                                <div className="text-center mb-11">
                                    <h1 className="text-dark fw-bolder mb-3">
                                        Bem-vindo de volta!
                                    </h1>
                                    <div className="text-gray-500 fw-semibold fs-6">
                                        Faça seu login agora. Há muitas novidades esperando por você.
                                    </div>
                                </div>
                                <div className="fv-row mb-8">
                                    <input className="form-control" name="email" type="email" placeholder="Email" />
                                </div>
                                <div className="fv-row mb-3">
                                    <input className="form-control" name="password" type="password" placeholder="Senha" />
                                </div>
                                {/*<div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
                                    <div></div>
                                    <a href="/metronic8/demo50/../demo50/authentication/layouts/corporate/reset-password.html"
                                       className="link-primary">
                                        Esqueceu sua senha?
                                    </a>
                                </div>*/}
                                <div className="d-grid mb-20" style={{marginTop:20}}>
                                    <button  type='submit'  className="btn btn-lg btn-primary w-100 text-center">{ loading === true ?  <div style={{display:'flex',flexDirection:'row'}}><Spinner /> <div style={{marginLeft:20}}>Entrando...</div></div>  :  "Entrar"  }</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2" style={{backgroundImage: `url(${bg})`}} >
                    <div className="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100">
                        <a href="/" className="mb-0 mb-lg-12">
                            <img alt="logo" src={wayfly} className="h-30px h-lg-45px" />
                        </a>
                        <img className="d-none d-lg-block mx-auto w-300px w-md-50 w-xl-550px mb-10 mb-lg-20" src={grafico} alt=""/>
                        <h1 className="d-none d-lg-block text-white fs-2qx fw-bolder text-center mb-7">
                            Rápido, Eficiente e Produtivo
                        </h1>
                        <div className="d-none d-lg-block text-white fs-base text-center">
                            Muito mais que um prontuário eletrônico! O Wayfly é um sistema de gestão que nasce para revolucionar<br/> a administração do seu consultório ou clínica tanto em aspectos administrativos quanto clínicos.
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

const mapStateToprops = state => {
    return {
        loading: state.auth.loading
    };
}

const mapDispatchToprops = dispatch => ({
    auth:(email,password) => dispatch(auth(email,password)),
})

export default connect(mapStateToprops,mapDispatchToprops)(Login)
