import {updateObject} from '../../utils';

const initialState = {
    items:[],
    loading:false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'DIAGNOSTIC_SUCCESS':
            return updateObject(state, {items: action.data});
        case 'DIAGNOSTIC_LOADING':
            return updateObject(state, {loading: action.status});
        default:
            return state;
    }
}

export default reducer;
  