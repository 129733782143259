import ContentLoader from "react-content-loader";
import React from "react";

const PieChartLoader = (props) => {
    return (
        <ContentLoader
            speed={2}
            width={310}
            height={259}
            viewBox="0 0 400 300"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
        >
            {/* Circle for the pie chart */}
            <circle cx="200" cy="100" r="80" />

            {/* Legends */}
            <rect x="80" y="220" rx="4" ry="4" width="240" height="20" />
            <rect x="80" y="250" rx="4" ry="4" width="240" height="20" />
        </ContentLoader>
    );
};


export default PieChartLoader;