import React, {useEffect} from "react";
import {connect} from "react-redux";
import {openModal} from "../../store/actions/uiActions";
import BankAccounts from "../../components/finance/BankAccounts";
import {getBank, getBankAccount,updateBankAccount} from "../../store/actions/companyBankAccountActions";

function BankAccountsController({openModal,getBankAccount,items,loading,updateBankAccount,auth,getBank}) {

    useEffect(() =>{
        getBankAccount();
        getBank()
    },[getBankAccount]);

    return(
        <BankAccounts openModal={openModal} items={items} loading={loading} updateBankAccount={updateBankAccount} auth={auth} />
    )
}

const mapStateToProps = state => ({
    items: state.companyBankAccount.items,
    loading: state.companyBankAccount.loading,
    auth: state.auth
})

const mapDispatchToProps = dispatch => ({
    openModal: (modal, data) => dispatch(openModal(modal, data)),
    getBankAccount: () => dispatch(getBankAccount()),
    updateBankAccount: (data, id) => dispatch(updateBankAccount(data, id)),
    getBank:() => dispatch(getBank())
})

export default connect(mapStateToProps, mapDispatchToProps)(BankAccountsController)
