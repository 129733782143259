import React, {useEffect} from "react";
import {Controller, useForm} from "react-hook-form";
import {Field} from "../Field";
import {Button} from "../Button";

function OperatorsModal({data, updateOperator, storeOperator,operators,getOperators,operatorItems}) {

    const optionsOperators = operators.filter(value => !data.id ? !operatorItems.map(op => op.operator.id).includes(value.id) : {} ).map((value) => ({label:value?.corporate_reason+(value?.fantasy_name ? " ("+value?.fantasy_name+")" : ""),value:value.id}))

    const onSubmit = request => {
        if(data && data.id){
            updateOperator(request,data.id);
        }else{
            storeOperator(request)
        }
    }

    useEffect(() => {
        getOperators();
    }, []);

    const { control, handleSubmit,  formState: { errors } } = useForm({
        defaultValues:{
            operator:data.operator
        }
    });

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className={"col-lg-4"} style={{marginTop: 10}} >
                    <label className="fw-bold text-muted">Operadora:</label>
                    <Controller
                        name="operator"
                        control={control}
                        rules={{ required: true }}
                        render={({ field : { onChange,  value }}) => <Field type="select" options={optionsOperators} value={value} onChange={onChange} />}
                    />
                    {errors.operator && <p style={{color:'red',marginTop:10}}>Necessário selecionar a operadora.</p>}
                </div>
            </div>
            <div className="row" style={{textAlign: 'right', marginTop: 10}}>
                <div className="col-md-9">

                </div>
                <div className="col-md-3">
                    {
                        <Button  text="Salvar" type="submit"/>
                    }
                </div>
            </div>
        </form>
    );
}

export default OperatorsModal;
