import { put, takeEvery, select } from 'redux-saga/effects';
import api from '../../api';
import { treatmentAllSuccess, treatmentLoading, treatmentLoadingAction, treatmentSuccess } from '../actions/treatmentActions';
import { closeModal } from '../actions/uiActions';
import { messenge } from './utils';
export const getAuthToken = (state) => state.auth.token;
export const getAuthCompany = (state) => state.auth.company.id;


function* get(){
    try{
        yield put(treatmentLoading(true))
        const response = yield  api.get('/treatment',{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
           yield put(treatmentSuccess(response.data));
           yield put(treatmentLoading(false))
        }
    } catch (e) {
        console.error(e);
        yield put(treatmentLoading(false))
    }
}

function* all(){
    try{        
        const response = yield  api.get('/treatment/all',{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield put(treatmentAllSuccess(response.data));
        }
    } catch (e) {
        console.error(e);       
    }
}

function* store(action){
    try{        
        yield put(treatmentLoadingAction(true))  
        const response = yield  api.post('/treatment',action.data,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield put(closeModal())
            yield messenge(response.data,'success');
            yield put(treatmentLoadingAction(false))  
            yield get()
        }
    } catch (e) {
        console.error(e);  
        yield messenge(e.response.data,'warning');
        yield put(treatmentLoadingAction(false))       
    }
}

function* update(action){
    try{        
        yield put(treatmentLoadingAction(true))
        const response = yield  api.put('/treatment/'+action.id,action.data,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield messenge(response.data,'success');
            yield put(treatmentLoadingAction(false));
            yield get()
        }
    } catch (e) {
        console.error(e);  
        yield messenge(e.response.data,'warning');
        yield put(treatmentLoadingAction(false))     
    }
}

function* status(action){
    try{        
        const response = yield  api.post('/treatment/status/'+action.id,{status:action.status},{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield messenge(response.data,'success');
            yield get()
        }
    } catch (e) {
        yield messenge(e.response.data,'warning');
        console.error(e);       
    }
}

export function* treatmentSaga() {
    yield takeEvery('GET_TREATMENT', get);
    yield takeEvery('GET_TREATMENT_ALL', all);
    yield takeEvery('STORE_TREATMENT', store);
    yield takeEvery('UPDATE_TREATMENT', update);
    yield takeEvery('STATUS_TREATMENT', status);    
}