
export const auth = (email,password) => ({type: 'AUTH',email,password});
export const authSuccess = (data) => ({type: 'AUTH_SUCCESS',data});
export const alterAuthSuccess = (data) => ({type: 'ALTER_AUTH',data});
export const authStart = () => ({type: 'AUTH_START'});
export const sendSMS = (cellPhone) => ({type: 'AUTH_SEND_SMS',cellPhone});
export const authFail = () => ({type: 'AUTH_FAIL'});
export const verifyCancel = () => ({type: 'AUTH_VERIFY_CANCEL'});
export const setCompany = (data) => ({type: 'AUTH_SET_COMPANY',data});
export const setAdmin = (data) => ({type: 'AUTH_SET_ADMIN',data});
export const logout = () => ({type:'AUTH_LOGOUT'});
export const getNotification = () => ({type: 'GET_NOTIFICATION'});
export const alterPassword = (id,password,password_current,password_confirmation) => ({type: 'AUTH_ALTER_PASSWORD',id,password,password_current,password_confirmation});
export const setPermission = (data) => ({type: 'AUTH_PERMISSION',data});