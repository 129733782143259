import React, {useEffect} from "react";
import { useForm, Controller } from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import ContentLoader from "react-content-loader";
import { Field } from "../Field";
import { Button } from "../Button";

function MarkOffCallsModal({modalPayload, handlerUpdateStatus}) {
    const loadingCreate = useSelector(state => state.calendar.loadingCreate);

    const data = useSelector(state => state.calendar.item)[modalPayload.uuid];
    const status = useSelector(state => state.companies.status);
    const dispatch = useDispatch();

    let array = [];
    const { control, handleSubmit,  formState: { errors, isSubmitting } } = useForm({
        defaultValues:{
            id: data.id,
            typeAction:modalPayload.type
        }
    });


    useEffect(() =>{

        dispatch({
            type: 'GET_STATUS'
        });
    },[]);

    status.filter((element) => element.lack === 1).forEach((data) => {
        array.push({'label':data.description,'value':data.id})
    });

    const onSubmit = async request => {
        if(isSubmitting) return;
        request['id'] = data.id
        handlerUpdateStatus(request)
        await new Promise(resolve => setTimeout(resolve, 10000));
    }

    if(loadingCreate){
        return (
            <div>
                <ContentLoader viewBox="0 0 380 20">
                    <rect x="0" y="0" rx="4" ry="4" width="300" height="40"/>
                </ContentLoader>
                <ContentLoader viewBox="0 0 380 30">
                    <rect x="0" y="10" rx="4" ry="4" width="300" height="40"/>
                </ContentLoader>
            </div>
        )
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className={"col-lg-6"}>
                    <label className="fw-bold text-muted">Motivo:</label>
                    <Controller
                        name="status"
                        control={control}
                        rules={{ required: true }}
                        render={({ field : { onChange, value }}) => <Field type="select" value={value} onChange={onChange} options={array}  />}
                    />
                    {errors.status && <p style={{color:'red',marginTop:10}}>Necessário selecionar o motivo.</p>}
                </div>
                <div className={"col-lg-12"} style={{marginTop:15}}>
                <label className="fw-bold text-muted">Motivo:</label>
                    <Controller
                        name="obs_desmarcar"
                        control={control}
                        rules={{ required: true }}
                        render={({ field : { onChange, value }}) => <Field type="textArea" value={value} onChange={onChange} options={array}  />}
                    />
                    {errors.obs_desmarcar && <p style={{color:'red',marginTop:10}}>Necessário preencher a observação.</p>}
                </div>
            </div>
            <div className="row" style={{textAlign:'right',marginTop:20}}>
                <div className="col-md-9"></div>
                <div className="col-md-3">
                    <Button text="Salvar" disabled={isSubmitting} type="submit" />
                </div>
            </div>
        </form>
    );
}

export default MarkOffCallsModal;