import React, {useEffect, useState} from "react";
import {DateTime} from "luxon";
import Day from "./Day";
import AllDay from "./AllDay";

function NewCalendar({allDayClick, datesSet, events, duration, startTime, endTime, loading, eventClick, slotClick, openModal,  expedients, allDay, notDayWeek, isMobile, openAny }) {
    const [days, setDays] = useState([]);
    const [luxonDate, setLuxonDate] = useState(DateTime.now());
    const [dateSelected, setDateSelected] = useState(DateTime.now());
    const [page, setPage] = useState(1);

    function getWeekFromDate() {
        let weekDates = [];

        if (page === 1) {
            const lastDayOfWeek = luxonDate.endOf('week');
            for (let i = 0; i < 7; i++) {
                const weekDate = lastDayOfWeek.minus({days: 6 - i});
                weekDates.push(weekDate);
            }
        } else if (page === 2) {
            weekDates.push(luxonDate);
        }

        return weekDates;
    }

    useEffect(() => {
        const weekFromDate = getWeekFromDate();

        setDays(weekFromDate);

        datesSet({
            start: DateTime.fromSQL(weekFromDate[0].toFormat('yyyy-MM-dd') + " 00:00:00"),
            end: DateTime.fromSQL(weekFromDate[weekFromDate.length - 1].toFormat('yyyy-MM-dd') + " 23:59:59")
        });
    }, [luxonDate, page]);

    const now = DateTime.now();

    const next = () => {
        setLuxonDate(state => state.plus(page === 1 ? {weeks: 1} : {days: 1}))
    }

    const previous = () => {
        setLuxonDate(state => state.minus(page === 1 ? {weeks: 1} : {days: 1}))
    }

    const today = () => {
        setLuxonDate(DateTime.now());
        setDateSelected(DateTime.now());
    }

    if (isMobile) {
        return (
            <>
                <div style={{backgroundColor: '#fff', padding: "0px 20px 0px 20px"}}>
                    <div style={{flexDirection: 'row', display: 'flex', width: '100%', padding: "2px 0px 5px 0px"}}>
                        <button
                            className="btn-link btn btn-sm "
                            style={{height: 30}}
                            onClick={() => {
                                previous()
                            }}
                        >
                        <span className="svg-icon svg-icon-dark svg-icon-2hx">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M11.2657 11.4343L15.45 7.25C15.8642 6.83579 15.8642 6.16421 15.45 5.75C15.0358 5.33579 14.3642 5.33579 13.95 5.75L8.40712 11.2929C8.01659 11.6834 8.01659 12.3166 8.40712 12.7071L13.95 18.25C14.3642 18.6642 15.0358 18.6642 15.45 18.25C15.8642 17.8358 15.8642 17.1642 15.45 16.75L11.2657 12.5657C10.9533 12.2533 10.9533 11.7467 11.2657 11.4343Z"
                                    fill="#000"/>
                            </svg>
                        </span>
                        </button>
                        {
                            days.length > 0 && <h4
                                style={{
                                    textAlign: 'center',
                                    textTransform: 'capitalize',
                                    flex: 1,
                                    marginTop: 15
                                }}
                            >
                                {days.length > 0 && days[0].setLocale("pt-BR").toFormat('MMMM')}
                            </h4>
                        }
                        <button
                            className="btn-link btn btn-sm"
                            style={{height: 30}}
                            onClick={() => {
                                next()
                            }}
                        >
                            <span className="svg-icon svg-icon-dark svg-icon-2hx">
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z"
                                        fill="#000"
                                    />
                                </svg>
                            </span>
                        </button>
                    </div>
                    <div style={{flexDirection: 'row', display: 'flex', width: '100%', padding: "20px 0px 5px 0px"}}>
                        {
                            days.map((value, index) => {

                                return (
                                    <div style={{flex: 1}}>
                                        <div
                                            onClick={() => setDateSelected(value)}
                                            style={{
                                                textAlign: 'center',
                                                textTransform: 'capitalize',
                                                borderRadius: 10,
                                                color: dateSelected.toFormat('yyyy-MM-dd') === value.toFormat('yyyy-MM-dd') ? '#fff' : '#000',
                                                backgroundColor: dateSelected.toFormat('yyyy-MM-dd') === value.toFormat('yyyy-MM-dd') ? '#a3acf7' : value.toFormat('yyyy-MM-dd') === now.toFormat('yyyy-MM-dd') ? '#fffadf' : '#fff'
                                            }}
                                            key={index}
                                        >
                                            { value.setLocale("pt-BR").toLocaleString({weekday: 'short'})}
                                            <br/>
                                            { value.toLocaleString({day: '2-digit'}) }
                                        </div>
                                        <div style={{flexDirection: 'row', display: 'flex', justifyContent: 'center'}}>
                                            {
                                                events.filter((ev) => DateTime.fromSQL(ev.start_date).toFormat('yyyy-MM-dd') === value.toFormat('yyyy-MM-dd')).slice(0, 5).map((value) => {

                                                    return (
                                                        <div style={{
                                                            backgroundColor: value.status.color ?? 'transparent',
                                                            width: 5,
                                                            height: 5,
                                                            borderRadius: 50,
                                                            gap: 2,
                                                            margin: "5px 1px"
                                                        }}></div>
                                                    )
                                                })
                                            }

                                        </div>
                                        <div style={{
                                            textAlign: 'center',
                                            color: '#c1c1c1',
                                            marginTop: -5,
                                            marginBottom: 10
                                        }}>
                                            {
                                                events.filter((ev) => DateTime.fromSQL(ev.start_date).toFormat('yyyy-MM-dd') === value.toFormat('yyyy-MM-dd')).length > 5 ? "+" : ""
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div style={{flexDirection: 'row', display: 'flex', width: '100%', padding: "0px 0px 10px 0px"}}>
                        <div style={{flex: 1}}></div>
                        <div>
                            {loading &&
                                <div className="loader" style={{marginTop: 10, marginRight: 10}}></div>}
                        </div>
                        <div>
                            <button
                                className="btn-light btn btn-sm"
                                style={{marginLeft: 10, backgroundColor: '#F9F9F9', height: 40}}
                                onClick={() => {
                                    openAny()
                                }}
                            >
                                <span className="svg-icon" style={{marginTop: -50}}>
                                    <svg width="20" height="20" viewBox="0 0 20 23" fill="#000"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <rect x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                                              transform="rotate(45 17.0365 15.1223)" fill="#000"/>
                                        <path
                                            d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                            fill="#000"/>
                                    </svg>
                                </span>
                            </button>
                        </div>
                        <div>
                            <button
                                className="btn-light btn btn-sm"
                                style={{marginLeft: 10, backgroundColor: '#F9F9F9', height: 40}}
                                onClick={() => {
                                    openModal('legendas')
                                }}
                            >
                                Legendas
                            </button>
                        </div>
                    </div>
                </div>
                <div style={{padding: "0px 0px"}}>
                    {
                        days.filter((value) => dateSelected.toFormat('yyyy-MM-dd') === value.toFormat('yyyy-MM-dd')).map((value, index) => {
                            return (
                                <>
                                    {
                                        allDay.length > 0 &&
                                        <div
                                            style={{backgroundColor: value.toFormat('yyyy-MM-dd') === now.toFormat('yyyy-MM-dd') ? '#fffadf' : '#fff'}}
                                        >
                                            <AllDay
                                                isMobile={isMobile}
                                                day={value}
                                                events={allDay.filter(day => day.start === value.toFormat('yyyy-MM-dd'))}
                                                eventClick={allDayClick}
                                            />
                                        </div>
                                    }
                                    <Day
                                        isMobile={isMobile}
                                        expedients={expedients}
                                        slotClick={slotClick}
                                        eventClick={eventClick}
                                        day={value}
                                        startTime={startTime}
                                        endTime={endTime}
                                        duration={duration}
                                        events={events.filter((ev) => DateTime.fromSQL(ev.start_date).toFormat('yyyy-MM-dd') === value.toFormat('yyyy-MM-dd'))}
                                    />
                                </>
                            )
                        })
                    }
                </div>
                {dateSelected.toFormat('yyyy-MM-dd') !== DateTime.now().toFormat('yyyy-MM-dd') ? <button
                    className="btn-light btn btn-sm shadow"
                    style={{marginLeft: 10, backgroundColor: '#F9F9F9', height: 40,position:'fixed',zIndex:9999,bottom:50,left:20,borderColor:'#000'}}
                    onClick={() => {
                        today()
                    }}
                >
                    Hoje
                </button> : <></>}
            </>
        )
    }

    return (
        <div className="card">
            <div className="card-body" style={{padding:"5px 20px"}}>
                <div className="row">
                    <div
                        className="col-md-4 fc-button-group"
                        style={{
                            marginBottom: 0,
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            alignContent: 'space-around'
                        }}
                    >
                        <button
                            className="btn-light btn btn-sm "
                            style={{
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                                backgroundColor: '#F9F9F9',
                                height: 40
                            }}
                            onClick={() => {
                                previous()
                            }}
                        >
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M11.2657 11.4343L15.45 7.25C15.8642 6.83579 15.8642 6.16421 15.45 5.75C15.0358 5.33579 14.3642 5.33579 13.95 5.75L8.40712 11.2929C8.01659 11.6834 8.01659 12.3166 8.40712 12.7071L13.95 18.25C14.3642 18.6642 15.0358 18.6642 15.45 18.25C15.8642 17.8358 15.8642 17.1642 15.45 16.75L11.2657 12.5657C10.9533 12.2533 10.9533 11.7467 11.2657 11.4343Z"
                                    fill="#7E8299"
                                />
                            </svg>
                        </button>
                        <button
                            className="btn-light btn btn-sm"
                            style={{
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                                backgroundColor: '#F9F9F9',
                                height: 40
                            }}
                            onClick={() => {
                                next()
                            }}
                        >
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z"
                                    fill="#7E8299"
                                />
                            </svg>
                        </button>
                        <button
                            className="btn-light btn btn-sm"
                            style={{
                                marginLeft:10,
                                backgroundColor: '#F9F9F9',
                                height: 40
                            }}
                            onClick={() => {
                                openAny()
                            }}
                        >
                            <span className="svg-icon" style={{marginTop: -50}}>
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 23"
                                    fill="#000"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect
                                        x="17.0365"
                                        y="15.1223"
                                        width="8.15546"
                                        height="2"
                                        rx="1"
                                        transform="rotate(45 17.0365 15.1223)" fill="#000"
                                    />
                                    <path
                                        d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                        fill="#000"
                                    />
                                </svg>
                            </span>
                            Filtro
                        </button>
                        <button
                            className="btn-light btn btn-sm"
                            style={{marginLeft: 10, backgroundColor: '#F9F9F9', height: 40}}
                            onClick={() => {
                                today()
                            }}
                        >
                            Hoje
                        </button>
                        <div>
                            {loading && <div className="loader" style={{marginTop: 8, marginLeft: 10}}></div>}
                        </div>
                    </div>
                    <div className="col-md-4" style={{textAlign: 'center', paddingTop: 25}}>
                        {page === 1 ? <h5>{days && days.length > 0 && days[0].toFormat('dd/MM/yyyy')} - {days && days.length > 0 && days[days.length - 1].toFormat('dd/MM/yyyy')}</h5> : <></>}
                    </div>
                    <div
                        className="col-md-4 buttonRightCalendar"
                        style={{
                            display: 'inline-flex',
                            alignContent: 'space-between',
                            justifyContent: 'flex-end',
                            flexWrap: 'wrap',
                            flexDirection: 'row',
                            marginTop: 15,
                            marginBottom: 10
                        }}
                    >

                        <button
                            className="btn-light btn btn-sm"
                            style={{marginRight: 10, backgroundColor: '#F9F9F9', height: 40}}
                            onClick={() => {
                                openModal('legendas')
                            }}
                        >
                            Legendas
                        </button>
                        <button
                            className="btn-light btn btn-sm"
                            style={{
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                                backgroundColor: '#F9F9F9',
                                height: 40
                            }}
                            onClick={() => {
                                setPage(1)
                            }}
                        >
                            Semana
                        </button>
                        <button
                            className="btn-light btn  btn-sm"
                            style={{
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                                backgroundColor: '#F9F9F9',
                                height: 40
                            }}
                            onClick={() => {
                                setPage(2)
                            }}
                        >
                            Dia
                        </button>
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: 'row',
                        borderColor: '#f9f9f9',
                        borderRadius: 10,
                        borderWidth: 1,
                        borderStyle: "solid",
                        flexWrap: 'wrap'
                    }}
                >
                    {
                        days.filter((value) => !Object.values(notDayWeek).includes(value.weekday + '')).map((value, index) => {
                            return (
                                <div
                                    style={{flexGrow: 1, width: 160}}
                                    className="list-group list-group-flush"
                                    key={index}
                                >
                                    <a
                                        href="#"
                                        className="list-group-item list-group-item-action disabled"
                                        style={{
                                            textAlign: 'center',
                                            backgroundColor: '#f9f9f9',
                                            borderStyle: 'solid',
                                            borderWidth: 1,
                                            borderColor: '#f9f9f9',
                                            borderTopLeftRadius: index === 0 ? 10 : 0,
                                            borderTopRightRadius: days.length - 1 === index ? 10 : 0,
                                            color: '#7E8299',
                                            fontSize: 12
                                        }}
                                    >
                                        <b style={{textTransform: 'capitalize'}}>{value.toLocaleString({weekday: 'long'})}</b><br/>
                                        <b>{value.toFormat('dd/MM/yyyy')}</b>
                                    </a>
                                    {
                                        allDay.length > 0 &&
                                        <div
                                            style={{backgroundColor: value.toFormat('yyyy-MM-dd') === now.toFormat('yyyy-MM-dd') ? '#fffadf' : '#fff'}}>
                                            <AllDay
                                                            day={value}
                                                            events={allDay.filter(day => day.start === value.toFormat('yyyy-MM-dd'))}
                                                            eventClick={allDayClick}
                                                        />
                                                    </div>
                                                }
                                                <div
                                                    style={{backgroundColor: value.toFormat('yyyy-MM-dd') === now.toFormat('yyyy-MM-dd') ? '#fffadf' : '#fff'}}
                                                >
                                                    <Day
                                                        expedients={expedients}
                                                        slotClick={slotClick}
                                                        eventClick={eventClick}
                                                        day={value}
                                                        startTime={startTime}
                                                        endTime={endTime}
                                                        duration={duration}
                                                        events={events.filter((ev) => DateTime.fromSQL(ev.start_date).toFormat('yyyy-MM-dd') === value.toFormat('yyyy-MM-dd'))}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </div>

    );
}

export default NewCalendar;