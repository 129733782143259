import {updateObject} from '../../utils';

const initialState = {
    itens:[],
    loading:false,
    total:0,
    item:{},
    autocomplete:[],
    loadingRequest:false,
    loadingInfinity:[]
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'PROFESSIONALS_SUCCESS':
            return updateObject(state, {loading:false,itens:action.itens,total:action.count});
        case 'PROFESSIONALS_LOADING':
            return updateObject(state, {loading:action.status,itens:state.itens});        
        case 'PROFESSIONALS_LOADING_AUTOCOMPLETE':
            return updateObject(state, {loadingAutocomplete:action.status});
        case 'PROFESSIONALS_SUCCESS_AUTOCOMPLETE':
            return updateObject(state, {loadingAutocomplete:false,autocomplete:action.data});
        case 'PROFESSIONALS_SET':
            return updateObject(state, {loading:false,item:action.data});
        case 'INFINITYLOADING_PROFESSIONALS_SET':
            return updateObject(state, {loadingInfinity:action.data});
        case 'PROFESSIONALS_FAIL_REQUEST':
            return updateObject(state, {loadingRequest:false});
        case 'PROFESSIONALS_START_REQUEST':
            return updateObject(state, {loadingRequest:true});
        case 'PROFESSIONAL_ALTER':{
            let newObj = {...state.item};
            Object.getOwnPropertyNames(action.data).sort().forEach(function(val, idx, array) {
                newObj[val] = action.data[val]
            });
             
            return updateObject(state, {item:newObj});
        }
        case 'PROFESSIONAL_CLEAR':
            return initialState;
        default:
            return state;        
    }

    
}

export default reducer;
  