import { put, takeEvery, select} from 'redux-saga/effects';
import api from '../../api';
import toast from "react-hot-toast";
import {closeModal} from "../actions/uiActions";
import {queueWaitListSuccess, queueWaitLoading, queueWaitSuccess} from "../actions/queueWaitActions";

export const getAuthToken = (state) => state.auth.token;
export const getPatient = (state) => state.patients.item;

function* get(action){
    try{
        yield put(queueWaitLoading(true))
        const response = yield  api.get('/user/'+action.patient+"/queueWait",{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield put(queueWaitSuccess(response.data));
        }
    } catch (e) {
        console.error(e);
    }finally {
        yield put(queueWaitLoading(false));
    }
}

function* list(action){
    try{

        yield put(queueWaitListSuccess([]));
        yield put(queueWaitLoading(true))
        const response = yield  api.post('/userQueueWait/search', action.data,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield put(queueWaitListSuccess(response.data));
        }
    } catch (e) {
        console.error(e);
    }finally {
        yield put(queueWaitLoading(false));
    }
}


function* update(action){

    let response = api.put('/userQueueWait/'+action.id, action.data, {
        headers: {
            'Authorization': 'Bearer ' + (yield select(getAuthToken)),
        }
    });

    toast.promise(response,
        {
            loading: 'Atualizando o tratamento na fila...',
            success: (success) => {
                return 'Convênio atualizado com sucesso.'
            },
            error: (error) => {
                return 'Não foi possível atualizar o tratamento na fila.'
            }
        }
    );

    response = yield response;
    if (response.status === 200) {
        yield get({patient:action.data.user});
    }
}

function* store(action){
    let patient = yield select(getPatient);

    let response = api.post('/userQueueWait', action.data, {
        headers: {
            'Authorization': 'Bearer ' + (yield select(getAuthToken)),
        }
    });

    toast.promise(response,
        {
            loading: 'Cadastrando o tratamento na fila...',
            success: (success) => {
                return 'Convênio cadastrado com sucesso.'
            },
            error: (error) => {
                return 'Não foi possível cadastrar o tratamento na fila.'
            }
        }
    );

    response = yield response;
    if (response.status === 201) {
        yield put(closeModal());
        yield get({patient:patient.id});
    }
}


function* destroy(action){
    let patient = yield select(getPatient);

    let response = api.delete('/userQueueWait/'+action.id, {
        headers: {
            'Authorization': 'Bearer ' + (yield select(getAuthToken)),
        }
    });

    toast.promise(response,
        {
            loading: 'Deletando o tratamento na fila...',
            success: (success) => {
                return 'Convênio deletado com sucesso.'
            },
            error: (error) => {
                return 'Não foi possível deletar o tratamento da fila.'
            }
        }
    );

    response = yield response;
    if (response.status === 200) {
        yield put(closeModal());
        yield get({patient:patient.id});
    }
}

export function* QueueWaitSaga() {
    yield takeEvery('GET_QUEUE_WAIT', get);
    yield takeEvery('GET_QUEUE_LIST_WAIT', list);
    yield takeEvery('UPDATE_QUEUE_WAIT', update);
    yield takeEvery('STORE_QUEUE_WAIT', store);
    yield takeEvery('DESTROY_QUEUE_WAIT', destroy);
}