import {updateObject} from '../../utils';

const initialState = {
    loading:true,
    itens:[],
    itensFinance:[],
    loadingRequest:false,
    loadingDestroy:[],
    typeDocument:[],
    openModal:false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'OPEN_MODAL_DOCUMENT':
            return updateObject(state, {openModal: action.status });
        case 'DOCUMENTS_START':
            return updateObject(state, {loading: true});
        case 'DOCUMENTS_SUCCESS':
            return updateObject(state, {loading: false,itens:action.data});
        case 'DOCUMENTS_FINANCE_SUCCESS':
            return updateObject(state, {loading: false,itensFinance:action.data});
        case 'TYPE_DOCUMENTS_SUCCESS':
            return updateObject(state, {typeDocument:action.data});
        case 'DOCUMENTS_FAIL':
            return updateObject(state, {loading: false,itens:[]});
        case 'DOCUMENTS_LOADING_REQUEST':
            return updateObject(state,{loadingRequest:action.status});
        case 'DOCUMENTS_CLEAR':
            return initialState;
        case 'DOCUMENTS_INSERT':{
            let newDocument = [...state.itens];
            console.log(action.data)
            newDocument.push(action.data);
            return updateObject(state, {itens:newDocument,loadingRequest: false});
        }

        case 'DOCUMENTS_INSERT_FINANCE':{
            let newDocument = [...state.itensFinance];
            newDocument.push(action.data);
            return updateObject(state, {itensFinance:newDocument,loadingRequest: false});
        }

        case 'DOCUMENTS_REMOVE':{

            let index = state.itens.findIndex(function(item) {
                return item.id === action.id;
            });

            let itensRemove = [...state.itens];

            itensRemove.splice(index, 1);
            return updateObject(state, {itens: itensRemove});
        }

        case 'DOCUMENTS_REMOVE_FINANCE':{

            let index = state.itensFinance.findIndex(function(item) {
                return item.id === action.id;
            });

            let itensRemove = [...state.itensFinance];

            itensRemove.splice(index, 1);
            return updateObject(state, {itensFinance: itensRemove});
        }


        case 'DOCUMENTS_UPDATE':{

            let index = state.itens.findIndex(function(item) {
                return item.id === action.id;
            });

            let itens = [...state.itens];

            itens[index] = action.data;
            return updateObject(state, {itens: itens});
        }

        case 'DOCUMENTS_UPDATE_FINANCE':{

            let index = state.itensFinance.findIndex(function(item) {
                return item.id === action.id;
            });

            let itens = [...state.itensFinance];

            itens[index] = action.data;
            return updateObject(state, {itensFinance: itens});
        }


        case 'DOCUMENTS_LOADING_DESTROY':{
            let arrayDestroy = [...state.loadingDestroy];

            arrayDestroy[action.id] = action.status;

            return updateObject(state, {loadingDestroy: arrayDestroy});
        }
        default:
            return state;
    }
}

export default reducer;
