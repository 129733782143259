import {updateObject} from '../../utils';

const initialState = {
    templates:[],
    templatesLoading:false,
    tokens:[],
    actionLoading:false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'TEMPLATE_SUCCESS':
            return updateObject(state, {templates: action.data});
        case 'TEMPLATE_LOADING':
            return updateObject(state, {templatesLoading: action.status});
        case 'TOKEN_SUCCESS':
            return updateObject(state, {tokens: action.data});
        case 'ACTION_LOADING':
            return updateObject(state, {actionLoading: action.status});
        default:
            return state;
    }
}

export default reducer;
