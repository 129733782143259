import {  put, takeEvery,select} from 'redux-saga/effects';
import api from '../../api';
import { professionFail, professionStart, professionSuccess } from '../actions/professonActions';

export const getAuthToken = (state) => state.auth.token;
export const getAuthID = (state) => state.auth.id;
export const getAuthCompany = (state) => state.auth.company.id

function* get(action){

    try{
        yield put(professionStart());
       
        const response = yield  api.get("/profession",{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){          
            yield put(professionSuccess(response.data))
        }else{
            yield put(professionFail())   
        }
    } catch (e) {
        console.error(e);
        yield put(professionFail())   
    }
}


export function* professionSaga() {
    yield takeEvery('GET_PROFESSION', get);
}