export const patientloading = (status) => ({type: 'PATIENTS_LOADING',status});
export const patientClear = () => ({type: 'PATIENTS_CLEAR'});
export const patientSuccess = (itens,count) => ({type: 'PATIENTS_SUCCESS',itens,count});
export const patientloadingAutocomplete = (status) => ({type: 'PATIENTS_LOADING_AUTOCOMPLETE',status});
export const patientSuccessAutocomplete = (data) => ({type: 'PATIENTS_SUCCESS_AUTOCOMPLETE',data});

export const getAutocompletePatient = (typeUser,page,perPage,name) => ({type: 'AUTOCOMPLETE_PATIENT',typeUser,page,perPage,name});
export const setPatient  = (data) => ({type: 'PATIENT_SET',data});
export const getPatient = (id) => ({type: 'GET_PATIENT',id});
export const patientFailRequest = () => ({type: 'PATIENTS_FAIL_REQUEST'});
export const uploadPhotoPatient = (data,id) => ({type: 'UPLOAD_FOTO_PATIENT',data,id});
export const deletePhotoPatient = (id) => ({type: 'DELETE_FOTO_PATIENT',id});
export const alterPatient = (data) => ({type: 'PATIENT_ALTER',data});
export const getPatientPrivate = (id) => ({type: 'GET_PATIENT_PRIVATE',id});
export const patientPrivateSuccess = (data) => ({type: 'SET_PATIENT_PRIVATE',data});
export const patientPrivateLoading = (status) =>  ({type: 'PATIENT_PRIVATE_STATUS',status});
export const getUserPrivateStatus = () => ({type:'GET_USER_PRIVATE_STATUS'});
export const patientPrivateStatusSuccess = (data) => ({type: 'PATIENT_PRIVATE_STATUS_SUCCESS',data});
export const destroyPatientPrivate = (data) => ({type: 'DESTROY_PATIENT_PRIVATE',data});
export const updatePatientPrivate = (data,id) => ({type: 'UPDATE_PATIENT_PRIVATE',data,id});
export const patientPrivateLoadingAction = (status) =>  ({type: 'PATIENT_PRIVATE_STATUS_ACTION',status});
export const getPatientTasks = (id) => ({type: 'GET_PATIENT_TASKS',id});
export const patientTasksSuccess = (data) => ({type: 'SET_PATIENT_TASKS',data});
export const patientTasksLoading = (status) =>  ({type: 'PATIENT_TASKS_STATUS',status});
export const destroyPatientTasks = (data) => ({type: 'DESTROY_PATIENT_TASKS',data});
export const updatePatient  = (data,id,typeModel) => ({type: 'UPDATE_PATIENT',data,id,typeModel});
export const destroyPatient  = (id,patient,typeModel) => ({type: 'DESTROY_PATIENT',id,patient,typeModel});
export const storePatient  = (data,typeModel) => ({type: 'STORE_PATIENT',data,typeModel});
export const addressStart = () => ({type: 'ADDRESS_START'});
export const addressSuccess = (itens) => ({type: 'ADDRESS_SUCCESS',itens});
export const addressFail = () => ({type: 'ADDRESS_FAIL'});
export const getAddress = (id) => ({type: 'GET_ADDRESS',id});
export const destroyAddress = (id) => ({type: 'DESTROY_ADDRESS',id});
export const destroyAddressStart = () => ({type: 'ADDRESS_DESTROY_START'});
export const destroyAddressFail = () => ({type: 'ADDRESS_DESTROY_FAIL'});
export const storeAddress = (data) => ({type: 'STORE_ADDRESS',data});
export const insertAddress = (data) => ({type:'ADDRESS_INSERT',data});
export const removeAddress = (id) => ({type:'ADDRESS_REMOVE',id});
export const alterAddress = (id,data) => ({type:'ADDRESS_ALTER',id,data});
export const updateAddress = (id,data) => ({type:'UPDATE_ADDRESS',id,data});
export const addressStartRequest = () => ({type: 'ADDRESS_START_REQUEST'});
export const addressFailRequest = () => ({type: 'ADDRESS_FAIL_REQUEST'});
export const getForCep = (cep,setValue) => ({type: 'GET_FOR_CEP',cep,setValue});
export const searchLoading = (status) => ({type:'ADDRESS_LOADING_SEARCH',status})
export const getAvaliation = (id,page,perPage) => ({type:'GET_AVALIATION',id,page,perPage});
export const avaliationSuccess = (data,total) => ({type:'AVALIATION_SUCCESS',data,total});
export const avaliationLoading = (status) => ({type:'AVALIATION_LOADING',status});
export const countAvaliation = (count) => ({type:'AVALIATION_COUNT',count});
export const getEvolution = (data) => ({type:'GET_EVOLUTION',data});
export const evolutionSuccess = (data,total) => ({type:'EVOLUTION_SUCCESS',data,total});
export const evolutionLoading = (status) => ({type:'EVOLUTION_LOADING',status});
export const countEvolution = (count) => ({type:'EVOLUTION_COUNT',count});
export const evolutionAlter = (data) => ({type:'EVOLUTION_ALTER',data});
export const getReturn = (id,page,perPage) => ({type:'GET_RETURN',id,page,perPage});
export const returnSuccess = (data,total) => ({type:'RETURN_SUCCESS',data,total});
export const returnLoading = (status) => ({type:'RETURN_LOADING',status});
export const countReturn = (count) => ({type:'RETURN_COUNT',count});
export const getAnamnese = (data) => ({type:'GET_ANAMNESE',data});
export const getTherapeutic = (data) => ({type:'GET_THERAPEUTIC',data});
export const anamneseLoading = (status) => ({type:'ANAMNESE_LOADING',status});
export const therapeuticSuccess = (data) => ({type:'THERAPEUTIC_SUCCESS',data});
export const anamneseSuccess = (data) => ({type:'ANAMNESE_SUCCESS',data});
export const therapeuticLoading = (status) => ({type:'THERAPEUTIC_LOADING',status});

export const countAnamnese = (count) => ({type:'ANAMNESE_COUNT',count});
export const anamneseAlter = (data) => ({type:'ANAMNESE_ALTER',data})

export const getResponsible = (id) => ({type:'GET_RESPONSIBLE',id});
export const responsibleSuccess = (data) => ({type:'RESPONSIBLE_SUCCESS',data});
export const responsibleLoading = (status) => ({type:'RESPONSIBLE_LOADING',status});

export const getTypeResponsible = () => ({type:'GET_TYPE_RESPONSIBLE'});
export const setTypeResponsible = (data) => ({type:'SET_TYPE_RESPONSIBLE',data});

export const getTypeAddress = () => ({type:'GET_TYPE_ADDRESS'});
export const setTypeAddress = (data) => ({type:'SET_TYPE_ADDRESS',data});

export const setCallArrayEvolution  = (data) => ({type: 'PATIENT_ALTER_ITEM_ARRAY_EVOLUTION',data});

export const setCallArrayAnamnese = (data) => ({type: 'PATIENT_ALTER_ITEM_ARRAY_ANAMNESE',data});




export const patientAlterEvolution  = (data) => ({type: 'PATIENT_ALTER_EVOLUTION',data});
export const patientAlterAnamnese  = (data) => ({type: 'PATIENT_ALTER_ANAMNESE',data});
export const patientAlterTherapeutic  = (data) => ({type: 'PATIENT_ALTER_THERAPEUTIC',data});

export const patientStoreEvolution  = (data) => ({type: 'PATIENT_STORE_EVOLUTION',data});
export const patientStoreAnamnese  = (data) => ({type: 'PATIENT_STORE_ANAMNESE',data});
export const patientStoreTherapeutic  = (data) => ({type: 'PATIENT_STORE_THERAPEUTIC',data});

export const patientDestroyEvolution = (data) => ({type: 'PATIENT_DESTROY_EVOLUTION', data})
export const patientDestroyAnamnese = (data) => ({type: 'PATIENT_DESTROY_ANAMNESE', data})
export const patientDestroyTherapeutic = (data) => ({type: 'PATIENT_DESTROY_THERAPEUTIC', data})

export const therapeuticAlter = (data) => ({type:'THERAPEUTIC_ALTER',data})
