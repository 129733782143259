import React from "react";
import {Controller, useForm} from "react-hook-form";
import {Field} from "../Field";
import {Button} from "../Button";
import {toast} from "react-toastify";

function DocumentsModal({typeDocument,upload,typeModel,id,loading,data,updateDocuments}) {

    const {control, handleSubmit, formState: { errors },setValue } = useForm({
        defaultValues:{
            type_model:typeModel,
            id:id,
            type_document:data ? data.type_document.id : null,
            description:data ? data.description : null,
            idDocument: data ? data.id : null
        }
    });

    const changeHandler = (event) => {
        setValue('file',event.target.files[0]);
    };

    const onSubmit = (request)=>{

        if(data && data.id){
            updateDocuments(request);
        }else{
            if(request.file.size > 8000000){
                toast.warning("O arquivo não pode ser maior que 8MB");
                return;
            }
            upload(request);
        }

    };

    let typeDocumentOptions = []
    if(typeDocument && typeDocument.length > 0){
        typeDocument.forEach((value) =>{
            typeDocumentOptions.push({label:value.description,value:value.id})
        })
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-md-12">
                    <label style={{marginTop: 10}}>Descrição:</label>
                    <Controller
                        name="description"
                        control={control}
                        rules={{required: true}}
                        render={({field: {onChange, value}}) => <Field type="text" value={value} onChange={onChange}/>}
                    />
                    {errors.description &&
                        <p style={{color: 'red', marginTop: 10}}>Necessário preencher a descrição.</p>}
                </div>

                {
                    data && data.id > 0 ?
                        <></>
                        :
                        <div className="col-md-8">
                            <label style={{marginTop: 10}}>Arquivo:</label>
                            <Controller
                                name="file"
                                control={control}
                                rules={{required: true}}
                                render={({field: {onChange, value}}) =>
                                    <input
                                        type="file"
                                        name="file"
                                        onChange={changeHandler}
                                        className='form-control'
                                        style={{height: 38}}/>
                                }
                            />
                            {errors.type_document && <p style={{color: 'red', marginTop: 10}}>Necessário escolher o arquivo.</p>}
                        </div>
                }

                <div className="col-lg-4">
                    <label style={{marginTop: 10}}>Tipo de documento</label>
                    <Controller
                        name="type_document"
                        control={control}
                        rules={{required: true}}
                        render={({field: {onChange, value}}) => <Field type="select" value={value}
                                                                       options={typeDocumentOptions}
                                                                       onChange={onChange}/>}
                    />
                    {errors.type_document &&
                        <p style={{color: 'red', marginTop: 10}}>Necessário preencher o tipo de documento.</p>}
                </div>

            </div>
            <div className="row" style={{textAlign: 'right', marginTop: 20}}>
                <div className="col-md-9"></div>
                <div className="col-md-3">
                    <Button loading={loading} text="Salvar" type="submit"/>
                </div>
            </div>
        </form>
    );
}

export default DocumentsModal;
