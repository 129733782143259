import React, {useState, useEffect} from "react";
import {connect, useSelector} from "react-redux";
import {DateTime, Duration, Interval} from "luxon";
import ContentLoader from "react-content-loader";
import {Field} from "../../../components/Field";
import {getProfessionals} from "../../../store/actions/professionalsActions";
import {calcularMoney, formatDateStartAndEnd2} from "../../../utils";
import {getCalendarForPay} from "../../../store/actions/calendarActions";
import Img15 from "../../../assets/img/15.png";
import blank from "../../../assets/img/blank.png";
import Excel from "../../../assets/img/file-excel-regular.svg";
import Pdf from "../../../assets/img/file-pdf-regular.svg";
import api from "../../../api";
import toast from "react-hot-toast";
import {BadgeTypeScheduler} from "../../../components/BadgeTypeScheduler";
import {Link} from "react-router-dom";
import {closeAny, openAny} from "../../../store/actions/uiActions";
import {Controller, useForm} from "react-hook-form";

const FinalizadoList = ({pat}) => {

    const [open, setOpen] = useState(false)

    return (
        <>
            <div className="separator separator-dashed"></div>
            <div className="py-0">
                <div className="py-3 d-flex flex-stack flex-wrap">
                    <div className="d-flex align-items-center collapsible collapsed rotate"
                         style={{width: '100%', cursor: 'pointer'}} onClick={() => setOpen(!open)}>
                        <img src={pat[0].patient.img ? pat[0].patient.img : blank} className="w-40px h-40px me-3"
                             alt={pat[0].patient.name} style={{borderRadius: 10}}/>
                        <div className="me-3" style={{width: '100%'}}>
                            <div className="d-flex ">
                                <div className="text-gray-800 fw-bold" style={{flex: 1}}>
                                    <div
                                        style={{textAlign: 'left'}}
                                        className={"btn btn-link"}
                                    >
                                        {pat[0].patient.name}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="me-3">
                            <span className="svg-icon svg-icon-3 rotate-90">
                                {
                                    open ?
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                                                fill="currentColor"/>
                                        </svg>
                                        :
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z"
                                                fill="currentColor"
                                            ></path>
                                        </svg>
                                }

                            </span>
                        </div>
                    </div>
                    <div className="me-3" style={{width: '100%', marginTop: 10}}>
                        <div className="d-flex ">
                            {pat.length > 0 && <div
                                className="badge badge-light-primary">{pat.length === 1 ? "1 Atendimento" : pat.length + " Atendimentos"} </div>}
                        </div>
                    </div>
                </div>
                <div className={"collapse " + (open ? " show" : "")}
                     style={{paddingRight: 20, background: '#f8f8f8', padding: 20, borderRadius: 5}}>
                    {
                        pat.map((value, index) => {
                            return (
                                <div key={index} style={{marginTop: 5, marginBottom: 5}}>
                                    {index > 0 && <div className="separator separator-dashed"
                                                       style={{backgroundColor: "#000"}}></div>}
                                    <div className="d-flex ">
                                        <div className="d-flex align-items-center collapsible  collapsed">
                                            <div className="me-3">
                                                <div className="text-gray-800 ">
                                                    <div className="my-2">
                                                        <div>Data:</div>
                                                        <div
                                                            className="text-muted"
                                                        >
                                                            {formatDateStartAndEnd2(DateTime.fromSQL(value.start), DateTime.fromSQL(value.end))}
                                                        </div>
                                                    </div>
                                                    <div style={{display: 'flex'}} className="my-2">
                                                        <div>Tipo de atendimento:</div>
                                                        <BadgeTypeScheduler data={value.type_schedule}/>
                                                    </div>
                                                    <div style={{display: 'flex'}} className="my-2">
                                                        <div style={{marginRight: 5}}>Tratamento:</div>
                                                        {value.treatment?.specialization?.profession.description + " " + (value.treatment?.specialization?.description ?? "")}
                                                    </div>


                                                    {value.replacement ? <div style={{display: 'flex'}}>
                                                        <div>Reposição:</div>
                                                        <div className="badge badge-light-warning ms-2">Sim</div>
                                                    </div> : <></>}
                                                    <div style={{display: 'flex'}} className="my-2">
                                                        <div>Status:</div>
                                                        <div
                                                            className="badge badge-primary ms-2"
                                                            style={{backgroundColor: value.status.color}}
                                                        >
                                                            {value.status.description}
                                                        </div>
                                                    </div>
                                                    {
                                                        [5, 6, 7, 8, 13].includes(value.status.id) && value.obs_desmarcar ?
                                                            <div style={{display: 'flex'}} className="my-2">
                                                                <div>Motivo desmarcação:</div>
                                                                <div
                                                                    className="text-muted ms-2"> {value.obs_desmarcar ? value.obs_desmarcar : ""}</div>
                                                            </div>
                                                            :
                                                            <></>
                                                    }

                                                    {value.rescheduled ? <>
                                                        <div style={{display: 'flex'}}>
                                                            <div>Remarcado:</div>
                                                            <div className="badge badge-primary ms-2"
                                                                 style={{backgroundColor: '#ffc107'}}>Sim
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="text-muted">{value.call_new}</div>
                                                        </div>

                                                    </> : <></>}
                                                </div>

                                                {
                                                    value.service && value.service.id > 0 ?
                                                        <div className="text-gray-800 ">
                                                            <div style={{display: 'flex'}} className="my-2">
                                                                <div>Serviço:</div>
                                                                <div
                                                                    className="text-muted ms-2"
                                                                >
                                                                    {value.service.name}
                                                                </div>
                                                            </div>
                                                            <div style={{display: 'flex'}} className="my-2">
                                                                <div>Comissão:</div>
                                                                <div
                                                                    className="text-muted ms-2">{value.service.type_commission === 1 ? calcularMoney(value.service.commisission_fixed).toLocaleString('pt-br', {
                                                                    style: 'currency',
                                                                    currency: 'BRL'
                                                                }) : calcularMoney((value.service.total * value.service.commisission_percentage) / 100).toLocaleString('pt-br', {
                                                                    style: 'currency',
                                                                    currency: 'BRL'
                                                                }) + " (" + value.service.commisission_percentage + "%)"}</div>
                                                            </div>
                                                        </div>
                                                        : <></>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                    <Link
                        to={"/patient/" + pat[0].patient.id}
                        className="badge badge-primary"
                        style={{color: '#fff', marginTop: 30, marginBottom: 0}}
                    >
                        Acesse a ficha do paciente
                    </Link>
                </div>
            </div>
        </>
    );
};

const Filter = ({
                    setProfessional,
                    professional,
                    setDateStart,
                    dateStart,
                    setDateEnd,
                    dateEnd,
                    professionals,
                    setSearch,
                    getCalendarForPay,
                    auth,
                    closeAny,
                    setSearchTerm
                }) => {

    const {control, handleSubmit} = useForm({
        defaultValues: {
            professional: professional,
            dateStart: dateStart,
            dateEnd: dateEnd,
        }
    });

    const professionalsArray = professionals?.map(value => ({label: value.name, value: value.id}));
    professionalsArray?.unshift({value: 0, label: "Selecione..."});

    const onSubmit = (data) => {
        closeAny()
        setSearchTerm('')
        setSearch(true);
        getCalendarForPay({
            professional: [data.professional],
            patient: [],
            status: 0,
            dateStart: data.dateStart + " 00:00:00",
            dateEnd: data.dateEnd + " 23:59:00",
            noLock: true
        });

        if (data.professional) {
            setProfessional(data.professional)
        }
        if (data.dateStart) {
            setDateStart(data.dateStart)
        }

        if (data.dateEnd) {
            setDateEnd(data.dateEnd)
        }
    };


    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                {
                    (auth?.admin === true || auth?.subadmin === true) &&
                    <div className="col-md-2" style={{marginTop: 10}}>
                        <label className="fw-bold text-muted">Profissional:</label>
                        <Controller
                            name="professional"
                            control={control}
                            render={({field: {onChange, value}}) =>
                                <Field type="select" value={value} options={professionalsArray} onChange={onChange}/>
                            }
                        />
                    </div>
                }
                <div className="col-md-4" style={{marginTop: 10}}>
                    <div className="row">
                        <div className="col-md-6">
                            <label className="fw-bold text-muted">Filtro por período:</label>
                            <Controller
                                name="dateStart"
                                control={control}
                                render={({field: {onChange, value}}) =>
                                    <Field type="date" value={value} onChange={onChange}/>
                                }
                            />
                        </div>
                        <div className="col-md-6">
                            <label></label>
                            <Controller
                                name="dateEnd"
                                control={control}
                                render={({field: {onChange, value}}) =>
                                    <Field type="date" value={value} onChange={onChange}/>
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-1" style={{marginTop: 30}}>
                    <button className="btn btn-success btn-sm btn-block" style={{width: "100%"}}>
                        <div style={{width: 20, margin: "auto"}}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path
                                    d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352c79.5 0 144-64.5 144-144s-64.5-144-144-144S64 128.5 64 208s64.5 144 144 144z"
                                    fill="#fff"
                                />
                            </svg>
                        </div>
                    </button>
                </div>
            </div>
        </form>
    )
}

function PaymentsController({
                                professionals,
                                getProfessionals,
                                getCalendarForPay,
                                items,
                                loading,
                                openAny,
                                isMobile,
                                closeAny,
                                auth
                            }) {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [professional, setProfessional] = useState((auth?.admin === true || auth?.subadmin === true) ? 0 : auth?.id);
    const [search, setSearch] = useState(false);
    const [dateStart, setDateStart] = useState(DateTime.local().startOf("month").toFormat("yyyy-MM-dd"));
    const [dateEnd, setDateEnd] = useState(DateTime.local().endOf("month").toFormat("yyyy-MM-dd"));

    useEffect(() => {
        getProfessionals(1, 1, 100);

        if (!(auth?.admin || auth?.subadmin)) {
            setSearch(true);
            getCalendarForPay({
                professional: [professional],
                patient: [],
                status: 0,
                dateStart: `${dateStart} 00:00:00`,
                dateEnd: `${dateEnd} 23:59:00`,
                noLock: true,
                type_schedule: [1,2,3,4],
            });
        }
    }, []);

    const handleSearch = (event) => {
        const searchTerm = event

        // Filtrar os resultados com base no termo de pesquisa
        const results = items.filter(
            item => (item.patient.name && item.patient.name?.includes(searchTerm))
        );
        setSearchResults(results);
        setSearchTerm(event);
    };

    useEffect(() => {
        setSearchResults(items)
    }, [items]);


    const calculatorFixed = (value) => {
        let result = 0;
        for (let i = 0; i < value.length; i++) {
            result += parseInt(value[0].service.commisission_fixed);
        }
        return result;
    }

    const calculatorPercentage = (value) => {
        let result = 0;
        for (let i = 0; i < value.length; i++) {
            result += (value[0].service.total * value[0].service.commisission_percentage) / 100;
        }
        return result;
    }

    const calculator = (value) => {
        if (value[0].service.type_commission === 1) {
            return calculatorFixed(value);
        } else {
            return calculatorPercentage(value);
        }
    }

    let atendimentosFinalizados = [];
    let atendimentosFinalizadosReposicao = [];
    let atendimentosProfissionalDesmarcou = [];
    let atendimentosProfissionalDesmarcouRemarcado = [];
    let atendimentosPacienteDesmarcou = [];
    let atendimentosPacienteDesmarcouCom = [];
    let atendimentosPacienteDesmarcouComRemarcado = [];
    let atendimentosPacienteDesmarcouSem = [];
    let verificarMotivo = [];
    let atendimentosPendencias = [];
    let atendimentosPendenciasPassado = [];
    let atendimentosPendenciasFuturo = [];
    let horas = 0;
    let groupsCalls = [];
    let services = [];
    let servicesTotal = 0;
    let serviceView = []
    let agendado = [];
    let aguardadoAtendimento = [];
    let emAtendimento = [];
    let resultByTypeSchedule = [];
    let resultByTreatment = [];
    let patientsOptions = new Set();

    let array = (searchResults || []).filter(value => ![5,6,7].includes(value.type_schedule.id)).filter((value) => ![13, 14, 15].includes(value.status.id)) || [];

    function diffMinutes(start, end) {
        let date1 = DateTime.fromSQL(start);
        let date2 = DateTime.fromSQL(end);
        let diff = Interval.fromDateTimes(date1, date2);
        return diff.length("minutes");
    }

    function groupByType(data) {
        const result = {};

        data.forEach((item) => {
            const typeSchedule = item.type_schedule.description;
            const minutes = diffMinutes(item.start, item.end);

            if (!result[typeSchedule]) {
                result[typeSchedule] = 0;
            }

            result[typeSchedule] += minutes;
        });

        return result;
    }

    function groupByTreatment(data) {
        const result = {};

        data.forEach((item) => {

            const typeSchedule = item.treatment?.specialization?.profession.description + " " + (item.treatment?.specialization?.description ?? "");
            const minutes = diffMinutes(item.start, item.end);

            if (!result[typeSchedule]) {
                result[typeSchedule] = 0;
            }

            result[typeSchedule] += minutes;
        });

        return result;
    }

    if (array && array.length > 0) {

        items.forEach(val => patientsOptions.add(val.patient.name));

        atendimentosFinalizados = array.filter((at) => at.status.id === 9);

        atendimentosFinalizadosReposicao = atendimentosFinalizados.filter((at) => at.replacement === 1);

        atendimentosProfissionalDesmarcou = array.filter((at) => at.status.id === 8);
        atendimentosProfissionalDesmarcouRemarcado = atendimentosProfissionalDesmarcou.filter((at) => at.rescheduled === 1);
        atendimentosPacienteDesmarcou = array.filter((at) => at.status.id === 5 || at.status.id === 6 || at.status.id === 7);

        verificarMotivo = atendimentosPacienteDesmarcou.filter((at) => at.status.id === 5);
        atendimentosPacienteDesmarcouCom = atendimentosPacienteDesmarcou.filter((at) => at.status.id === 6);
        atendimentosPacienteDesmarcouComRemarcado = atendimentosPacienteDesmarcou.filter((at) => at.rescheduled === 1);
        atendimentosPacienteDesmarcouSem = atendimentosPacienteDesmarcou.filter((at) => at.status.id === 7);

        atendimentosPendencias = array.filter((at) => at.status.id !== 9 && at.status.id !== 8 && at.status.id !== 7 && at.status.id !== 6 && at.status.id !== 5);

        atendimentosPendenciasPassado = atendimentosPendencias.filter((at) => {
            let date2 = DateTime.fromSQL(at.start);
            let date = DateTime.now();
            return date2 <= date;
        });

        agendado = atendimentosPendenciasPassado.filter((at) => at.status.id === 1 || at.status.id === 2)
        aguardadoAtendimento = atendimentosPendenciasPassado.filter((at) => at.status.id === 3);
        emAtendimento = atendimentosPendenciasPassado.filter((at) => at.status.id === 4);

        atendimentosPendenciasFuturo = atendimentosPendencias.filter((at) => {
            let date2 = DateTime.fromSQL(at.start);
            let date = DateTime.now();
            return date2 > date;
        });

        resultByTypeSchedule = groupByType(atendimentosFinalizados);
        resultByTreatment = groupByTreatment(atendimentosFinalizados);

        const withService = atendimentosFinalizados.filter((at) => at.service && at.service.id > 0);

        services = withService.reduce((res, at) => {
            if (typeof res[at.service.id] === "undefined") res[at.service.id] = [];
            res[at.service.id].push(at);
            return res;
        }, {});


        atendimentosFinalizados.forEach((at) => {
            let date1 = DateTime.fromSQL(at.start);
            let date2 = DateTime.fromSQL(at.end);
            let diff = Interval.fromDateTimes(date1, date2);

            horas += diff.length("minutes");
        });

        groupsCalls = array.reduce((res, at) => {
            if (typeof res[at.patient.id] === "undefined") res[at.patient.id] = [];
            res[at.patient.id].push(at);
            return res;
        }, {});

        Object.values(services).forEach(value => {
            serviceView.push({
                'title': value.length + "x " + value[0].service.name,
                'commisission': value[0].service.type_commission === 1 ? calcularMoney(value[0].service.commisission_fixed).toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL'
                }) : calcularMoney((value[0].service.total * value[0].service.commisission_percentage) / 100).toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL'
                }) + " (" + value[0].service.commisission_percentage + "%)",
                'total': calcularMoney(calculator(value)).toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})
            })
            servicesTotal += calculator(value);
        })
    }

    let token = useSelector(state => state.auth.token);

    const downloadPreview = (data) => {
        let response = api.post('/call/list/report/payment/preview', data, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        });

        toast.promise(response, {
            loading: 'Gerando relatório de pagamento.',
            success: 'Relatório de pagamento gerado..'
        }).then((response) => {
            if (response.status === 200) {
                window.open(response.data.url, '_blank')
            }
        })
    }

    const download = (data) => {
        let response = api.post('/call/list/report/excel/payment', data, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        });

        toast.promise(response, {
            loading: 'Gerando excel do relatório de pagamento.',
            success: 'Relatório de pagamento gerado.'
        }).then((response) => {
            if (response.status === 200) {
                window.open(response.data.url, '_blank')
            }
        });
    }

    const duracao = Duration.fromObject({minutes: horas});

    const horasx = Math.floor(duracao.as('hours'));
    const minutosx = Math.floor(duracao.as('minutes')) % 60;
    const segundosx = duracao.seconds;

    let descriptionMinutos = `${horasx} horas, ${minutosx} minutos, ${segundosx} segundos`

    return (
        <>
            <div>
                <div style={{marginTop: 20, flexDirection: 'row', display: 'flex'}}>
                    <h3 style={{flex: 1}}>Relatório para pagamento</h3>
                    {
                        search === true && Object.values(groupsCalls).length > 0 ?
                            <button
                                className="btn btn-link btn-sm"
                                style={{marginTop: -5, marginRight: 10}}
                                onClick={() =>
                                    download({
                                        professional: [professional],
                                        patient: [],
                                        status: [],
                                        dateStart: dateStart + " 00:00:00",
                                        dateEnd: dateEnd + " 23:59:00",
                                        noLock: true
                                    })
                                }
                            >
                                <img src={Excel} width="10" style={{color: '#fff'}} alt={"Icone do excel"}/>
                            </button>
                            :
                            <></>
                    }
                    {
                        isMobile && <div>
                            <span
                                className="svg-icon svg-icon-black "
                                onClick={() => openAny(<Filter
                                    setProfessional={setProfessional}
                                    professional={professional}
                                    setDateStart={setDateStart}
                                    dateStart={dateStart}
                                    setDateEnd={setDateEnd}
                                    dateEnd={dateEnd}
                                    getCalendarForPay={getCalendarForPay}
                                    setSearch={setSearch}
                                    professionals={professionals}
                                    closeAny={closeAny}
                                    auth={auth}
                                    setSearchTerm={setSearchTerm}
                                />, 'Filtro', 'lg')}
                            >
                                <svg width="20" height="24" viewBox="0 0 20 20" fill="#000"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <rect x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                                          transform="rotate(45 17.0365 15.1223)" fill="#000"/>
                                    <path
                                        d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                        fill="#000"
                                    />
                                </svg>
                            </span>
                        </div>
                    }
                </div>
                {!isMobile && <Filter
                    setProfessional={setProfessional}
                    professional={professional}
                    setDateStart={setDateStart}
                    dateStart={dateStart}
                    setDateEnd={setDateEnd}
                    dateEnd={dateEnd}
                    getCalendarForPay={getCalendarForPay}
                    setSearch={setSearch}
                    professionals={professionals}
                    closeAny={closeAny}
                    auth={auth}
                    setSearchTerm={setSearchTerm}
                />}
            </div>

            {
                search === false ?
                    <div style={{textAlign: "center"}}>
                        <br/><br/>
                        <br/><br/>
                        <h1>Escolha o profissional e período no filtro para gerar relatório de pagamentos.</h1>
                        <br/><br/>
                        <br/><br/>
                        <img src={Img15} style={{width: 300}} alt="img"/>
                    </div>
                    :
                    <>

                        <div className="row">
                            <div className="col-md-3">
                                <div className="card my-2">
                                    <div className="card-body" style={{height: 200}}>
                                        <div className="d-flex flex-column">
                                            <span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">
                                                {
                                                    loading === true ?
                                                        <ContentLoader viewBox="0 0 380 50">
                                                            <rect x="0" y="0" rx="4" ry="4" width="60" height="50"/>
                                                        </ContentLoader>
                                                        :
                                                        atendimentosFinalizados.length
                                                }
                                            </span>
                                            <div className="m-0">
                                                <span className="fw-semibold fs-6 " style={{color: "#0bdba1"}}>
                                                  Atendimentos finalizados
                                                </span>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center py-2">
                                            <span className="bullet bg-primary me-3"></span>
                                            {
                                                loading === true ?
                                                    <ContentLoader viewBox="0 0 380 30">
                                                        <rect x="0" y="0" rx="4" ry="4" width="230" height="30"/>
                                                    </ContentLoader>
                                                    :
                                                    atendimentosFinalizadosReposicao.length > 1 ? atendimentosFinalizadosReposicao.length + " reposições" : atendimentosFinalizadosReposicao.length + " reposição"
                                            }
                                        </div>
                                        <div className="d-flex align-items-center py-2">
                                            <span className="bullet bg-primary me-3"></span>
                                            {
                                                loading === true ?
                                                    <ContentLoader viewBox="0 0 380 30">
                                                        <rect x="0" y="0" rx="4" ry="4" width="230" height="30"/>
                                                    </ContentLoader>
                                                    :
                                                    descriptionMinutos
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="card  my-2">
                                    <div className="card-body" style={{height: 200}}>
                                        <div className="d-flex flex-column">
                                            <span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">
                                                {
                                                    loading === true ?
                                                        <ContentLoader viewBox="0 0 380 50">
                                                            <rect x="0" y="0" rx="4" ry="4" width="60" height="50"/>
                                                        </ContentLoader>
                                                        :
                                                        atendimentosProfissionalDesmarcou.length
                                                }
                                            </span>
                                            <div className="m-0">
                                                <span className="fw-semibold fs-6 " style={{color: "#bf0ddb"}}>
                                                    Profissional desmarcou
                                                </span>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center py-2">
                                            <span className="bullet bg-primary me-3"></span>
                                            {
                                                loading === true ?
                                                    <ContentLoader viewBox="0 0 380 30">
                                                        <rect x="0" y="0" rx="4" ry="4" width="230" height="30"/>
                                                    </ContentLoader>
                                                    :
                                                    atendimentosProfissionalDesmarcouRemarcado.length + " Remarcado"
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="card  my-2">
                                    <div className="card-body" style={{height: 200}}>
                                        <div className="d-flex flex-column">
                                            <span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">
                                                {
                                                    loading === true ?
                                                        <ContentLoader viewBox="0 0 380 50">
                                                            <rect x="0" y="0" rx="4" ry="4" width="60" height="50"/>
                                                        </ContentLoader>
                                                        :
                                                        atendimentosPacienteDesmarcou.length
                                                }
                                            </span>
                                            <div className="m-0">
                                                <span className="fw-semibold fs-6 " style={{color: "#db0f24"}}>
                                                  Paciente desmarcou
                                                </span>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center py-1">
                                            <span className="bullet bg-primary me-3"></span>
                                            {
                                                loading === true ?
                                                    <ContentLoader viewBox="0 0 380 30">
                                                        <rect x="0" y="0" rx="4" ry="4" width="230" height="30"/>
                                                    </ContentLoader>
                                                    :
                                                    verificarMotivo.length + " Verificar motivo"
                                            }
                                        </div>
                                        <div className="d-flex align-items-center py-1">
                                            <span className="bullet bg-primary me-3"></span>
                                            {
                                                loading === true ?
                                                    <ContentLoader viewBox="0 0 380 30">
                                                        <rect x="0" y="0" rx="4" ry="4" width="230" height="30"/>
                                                    </ContentLoader>
                                                    :
                                                    atendimentosPacienteDesmarcouCom.length + " com direito à reposição"
                                            }
                                        </div>
                                        <div className="d-flex align-items-center py-1"
                                             style={{marginLeft: 20, fontSize: 11}}>
                                            <span className="bullet bg-primary me-3" style={{width: 4}}></span>
                                            {
                                                loading === true ?
                                                    <ContentLoader viewBox="0 0 380 30">
                                                        <rect x="0" y="0" rx="4" ry="4" width="230" height="30"/>
                                                    </ContentLoader>
                                                    :
                                                    atendimentosPacienteDesmarcouComRemarcado.length + " Remarcado"
                                            }
                                        </div>
                                        <div className="d-flex align-items-center py-1">
                                            <span className="bullet bg-primary me-3"></span>
                                            {
                                                loading === true ?
                                                    <ContentLoader viewBox="0 0 380 30">
                                                        <rect x="0" y="0" rx="4" ry="4" width="230" height="30"/>
                                                    </ContentLoader>
                                                    :
                                                    atendimentosPacienteDesmarcouSem.length + " sem direito à reposição"
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="card  my-2">
                                    <div className="card-body" style={{height: 200}}>
                                        <div className="d-flex flex-column">
                                            <span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">
                                                {
                                                    loading === true ?
                                                        <ContentLoader viewBox="0 0 380 50">
                                                            <rect x="0" y="0" rx="4" ry="4" width="60" height="50"/>
                                                        </ContentLoader>
                                                        :
                                                        atendimentosPendencias.length
                                                }
                                            </span>
                                            <div className="m-0">
                                                <span className="fw-semibold fs-6 " style={{color: "#000"}}>
                                                  Pendências
                                                </span>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center py-1">
                                            <span className="bullet bg-primary me-3"></span>
                                            {
                                                loading === true ?
                                                    <ContentLoader viewBox="0 0 380 30">
                                                        <rect x="0" y="0" rx="4" ry="4" width="230" height="30"/>
                                                    </ContentLoader>
                                                    :
                                                    atendimentosPendenciasFuturo.length + " pendências futuras"
                                            }
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <span className="bullet bg-primary me-3"></span>
                                            {
                                                loading === true ?
                                                    <ContentLoader viewBox="0 0 380 30">
                                                        <rect x="0" y="0" rx="4" ry="4" width="230" height="30"/>
                                                    </ContentLoader>
                                                    :
                                                    atendimentosPendenciasPassado.length + " pendências passadas"
                                            }
                                        </div>
                                        <div
                                            className="d-flex align-items-center py-1"
                                            style={{marginLeft: 20, fontSize: 11}}
                                        >
                                            <span className="bullet bg-primary me-3" style={{width: 4}}></span>
                                            {
                                                loading === true ?
                                                    <ContentLoader viewBox="0 0 380 30">
                                                        <rect x="0" y="0" rx="4" ry="4" width="230" height="30"/>
                                                    </ContentLoader>
                                                    :
                                                    agendado.length + " Agendado / Confirmado"
                                            }
                                        </div>
                                        <div
                                            className="d-flex align-items-center py-1"
                                            style={{marginLeft: 20, fontSize: 11}}
                                        >
                                            <span className="bullet bg-primary me-3" style={{width: 4}}></span>
                                            {
                                                loading === true ?
                                                    <ContentLoader viewBox="0 0 380 30">
                                                        <rect x="0" y="0" rx="4" ry="4" width="230" height="30"/>
                                                    </ContentLoader>
                                                    :
                                                    aguardadoAtendimento.length + " Aguardando atendimento"
                                            }
                                        </div>
                                        <div
                                            className="d-flex align-items-center py-1"
                                            style={{marginLeft: 20, fontSize: 11}}
                                        >
                                            <span className="bullet bg-primary me-3" style={{width: 4}}></span>
                                            {
                                                loading === true ?
                                                    <ContentLoader viewBox="0 0 380 30">
                                                        <rect x="0" y="0" rx="4" ry="4" width="230" height="30"/>
                                                    </ContentLoader>
                                                    :
                                                    emAtendimento.length + " Em atendimento"
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            loading === false && <>

                                <div className="row">
                                    {Object.keys(resultByTypeSchedule).length > 0 &&
                                        <div className="col-md-6" style={{marginBottom: 10, marginTop: 10}}>
                                            <div className="card flex-row-fluid">
                                                <div className="card-header" style={{minHeight: 10, marginTop: 5}}>
                                                    <div className="card-title" style={{fontSize: 15}}>
                                                        Horas por tipo de agendamento
                                                    </div>
                                                    <div className="badge badge-primary" style={{
                                                        marginTop: 5,
                                                        height: 21,
                                                        background: '#0bdba1'
                                                    }}> Atendimentos finalizados
                                                    </div>
                                                </div>
                                                <div className="card-body" style={{paddingLeft: 30, paddingRight: 10}}>

                                                    {
                                                        Object.keys(resultByTypeSchedule).map((value) => {
                                                            let duracao = Duration.fromObject({minutes: resultByTypeSchedule[value]});
                                                            let horasx = Math.floor(duracao.as('hours'));
                                                            let minutosx = Math.floor(duracao.as('minutes') % 60);
                                                            let segundosx = Math.floor(duracao.as('seconds') % 60);

                                                            let descriptionMinutos = `${horasx} horas, ${minutosx} minutos, ${segundosx} segundos`
                                                            return (
                                                                <div className="d-flex align-items-center py-2">
                                                                    <span className="bullet bg-primary me-3"></span>
                                                                    <b>{value}</b>: {descriptionMinutos}
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>}
                                    {Object.keys(resultByTreatment).length > 0 &&
                                        <div className="col-md-6" style={{marginBottom: 10, marginTop: 10}}>
                                            <div className="card flex-row-fluid">
                                                <div className="card-header" style={{minHeight: 10, marginTop: 5}}>
                                                    <div className="card-title" style={{fontSize: 15}}>
                                                        Horas por tratamento
                                                    </div>
                                                    <div className="badge badge-primary" style={{
                                                        marginTop: 5,
                                                        height: 21,
                                                        background: '#0bdba1'
                                                    }}> Atendimentos finalizados
                                                    </div>
                                                </div>
                                                <div className="card-body" style={{paddingLeft: 30, paddingRight: 10}}>
                                                    {
                                                        Object.keys(resultByTreatment).map((value) => {
                                                            let duracao = Duration.fromObject({minutes: resultByTreatment[value]});

                                                            let horasx = Math.floor(duracao.as('hours'));
                                                            let minutosx = Math.floor(duracao.as('minutes')) % 60;
                                                            let segundosx = duracao.seconds;

                                                            let descriptionMinutos = `${horasx} horas, ${minutosx} minutos, ${segundosx} segundos`
                                                            return (
                                                                <div className="d-flex align-items-center py-2">
                                                                    <span className="bullet bg-primary me-3"></span>
                                                                    <b>{value}</b>: {descriptionMinutos}
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>}
                                    <div className="row" style={{marginTop: 20}}>
                                        <div className="col-md-3">
                                            <label className="fw-bold text-muted">
                                                Pesquisar:
                                                <span
                                                    style={{
                                                        marginTop: 10,
                                                        flex: 1,
                                                        flexGrow: "inherit",
                                                        cursor: 'pointer',
                                                        marginLeft: 10
                                                    }}
                                                    className="svg-icon svg-icon-muted svg-icon-1hx"
                                                    title="Utilize o campo de pesquisa pelo nome do paciente para filtrar os pacientes disponíveis durante o período e com o profissional informado. Os dados no topo do relatório serão refinados de acordo com o nome do paciente inserido, proporcionando uma visualização mais específica e personalizada das informações."
                                                    onClick={() => openAny(<div style={{textAlign: 'justify'}}>Utilize o
                                                        campo de
                                                        pesquisa pelo nome do paciente para filtrar os pacientes disponíveis
                                                        durante
                                                        o período e com o profissional informado. Os dados no topo do
                                                        relatório
                                                        serão refinados de acordo com o nome do paciente inserido,
                                                        proporcionando
                                                        uma visualização mais específica e personalizada das
                                                        informações.</div>, "Informação")}
                                                >
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10"
                                                          fill="currentColor"/>
                                                    <path
                                                        d="M11.276 13.654C11.276 13.2713 11.3367 12.9447 11.458 12.674C11.5887 12.394 11.738 12.1653 11.906 11.988C12.0833 11.8107 12.3167 11.61 12.606 11.386C12.942 11.1247 13.1893 10.896 13.348 10.7C13.5067 10.4947 13.586 10.2427 13.586 9.944C13.586 9.636 13.4833 9.356 13.278 9.104C13.082 8.84267 12.69 8.712 12.102 8.712C11.486 8.712 11.066 8.866 10.842 9.174C10.6273 9.482 10.52 9.82267 10.52 10.196L10.534 10.574H8.826C8.78867 10.3967 8.77 10.2333 8.77 10.084C8.77 9.552 8.90067 9.07133 9.162 8.642C9.42333 8.20333 9.81067 7.858 10.324 7.606C10.8467 7.354 11.4813 7.228 12.228 7.228C13.1987 7.228 13.9687 7.44733 14.538 7.886C15.1073 8.31533 15.392 8.92667 15.392 9.72C15.392 10.168 15.322 10.5507 15.182 10.868C15.042 11.1853 14.874 11.442 14.678 11.638C14.482 11.834 14.2253 12.0533 13.908 12.296C13.544 12.576 13.2733 12.8233 13.096 13.038C12.928 13.2527 12.844 13.528 12.844 13.864V14.326H11.276V13.654ZM11.192 15.222H12.928V17H11.192V15.222Z"
                                                        fill="currentColor"/>
                                                </svg>
                                            </span>
                                            </label>
                                            <Field  value={searchTerm} onChange={handleSearch} type={"select"} options={Array.from(patientsOptions).map(value => ({label:value,value:value}))} />

                                            <br/>
                                        </div>
                                        <div className="col-md-6">

                                        </div>
                                        <div className="col-md-3">
                                            {/* <button className="btn btn-link btn-sm" style={{float: 'right'}} onClick={() => console.log('aa')}><img src={Excel} width="16" style={{color: '#fff'}} alt={"Icone do excel"}/></button> */}
                                        </div>
                                    </div>
                                    {
                                        Object.values(groupsCalls).length > 0 &&
                                        <div className="col-md-12">
                                            <div className="card card-flush py-4 flex-row-fluid">
                                                <div className="card-header" style={{minHeight: 10}}>
                                                    <div className="card-title" style={{fontSize: 15, color: "#0bdba1"}}>
                                                        Atendimentos
                                                    </div>
                                                </div>
                                                <div className="card-body" style={{paddingLeft: 10, paddingRight: 10}}>
                                                    {
                                                        Object.values(groupsCalls).map((pat, index) => {
                                                            return (
                                                                <FinalizadoList pat={pat} key={index} index={index}/>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                    }
                                    {
                                        Object.values(services).length > 0 ?

                                            <div className="col-md-12">
                                                <div className="card">
                                                    <div className="card-header" style={{minHeight: 10}}>
                                                        <div className="card-title" style={{fontSize: 15}}>
                                                            Prévia para pagamento
                                                        </div>
                                                        <div className="card-toolbar">

                                                            <button className="btn btn-danger btn-sm"
                                                                    style={{float: 'right'}}
                                                                    onClick={() => downloadPreview({
                                                                        dateEnd: DateTime.fromSQL(dateEnd).toFormat("dd/MM/yyyy"),
                                                                        dateStart: DateTime.fromSQL(dateStart).toFormat("dd/MM/yyyy"),
                                                                        calculator: serviceView,
                                                                        total: calcularMoney(servicesTotal).toLocaleString('pt-br', {
                                                                            style: 'currency',
                                                                            currency: 'BRL'
                                                                        }),
                                                                        professional: professionals.length > 0 ? professionals.filter((pro) => pro.id === professional)[0]['name'] : ''
                                                                    })}><img alt={"Icone de PDF"} src={Pdf} width="16"
                                                                             style={{color: '#fff'}}/>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                        <table className="table">
                                                            <tr>
                                                                <th style={{textAlign: 'right'}}>Serviço</th>
                                                                <th> Comissão</th>
                                                                <th style={{textAlign: 'left'}}> Total</th>
                                                            </tr>
                                                            {
                                                                serviceView.map((value) => {
                                                                    return (
                                                                        <tr>
                                                                            <td style={{textAlign: 'right'}}>{value.title}</td>
                                                                            <td> {value.commisission} </td>
                                                                            <td style={{textAlign: 'left'}}> {value.total} </td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            }
                                                            <tr style={{borderTop: '1px dashed #000'}}>
                                                                <td style={{textAlign: 'right'}}>Total</td>
                                                                <td style={{textAlign: 'right'}}></td>
                                                                <td style={{textAlign: 'left'}}>{calcularMoney(servicesTotal).toLocaleString('pt-br', {
                                                                    style: 'currency',
                                                                    currency: 'BRL'
                                                                })}</td>
                                                            </tr>
                                                        </table>
                                                        <div
                                                            className="notice d-flex bg-light-warning rounded border-warning border border-dashed  p-6 my-7"
                                                        >
                                                            <span
                                                                className="svg-icon svg-icon-2tx svg-icon-warning me-4">
                                                                <svg width="24" height="24" viewBox="0 0 24 24"
                                                                     fill="none"
                                                                     xmlns="http://www.w3.org/2000/svg">
                                                                <rect opacity="0.3" x="2" y="2" width="20" height="20"
                                                                      rx="10"
                                                                      fill="currentColor"></rect>
                                                                <rect x="11" y="14" width="7" height="2" rx="1"
                                                                      transform="rotate(-90 11 14)"
                                                                      fill="currentColor"></rect>
                                                                <rect x="11" y="17" width="2" height="2" rx="1"
                                                                      transform="rotate(-90 11 17)"
                                                                      fill="currentColor"></rect>
                                                              </svg>
                                                            </span>
                                                            <div className="d-flex flex-stack flex-grow-1 ">
                                                                <div className=" fw-semibold">
                                                                    <h4 className="text-gray-900 fw-bold">Atenção ao
                                                                        pagamento</h4>
                                                                    <div className="fs-6 text-gray-700 ">Esta prévia de
                                                                        pagamento é baseada nos
                                                                        atendimentos finalizados com serviços associados. Se
                                                                        houver divergências nas
                                                                        informações, <br/>recomendamos corrigir os
                                                                        atendimentos
                                                                        ou serviços antes de
                                                                        efetuar o pagamento, a fim de evitar problemas
                                                                        futuros
                                                                        de pagamentos incorretos ou
                                                                        imprecisos.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            :
                                            <></>
                                    }
                                </div>
                            </>
                        }
                    </>
            }
        </>
    );
}

const mapStateToProps = state => ({
    professionals: state.professionals.itens,
    calls: state.calendar.dashboard.callsDay,
    items: state.calendar.callForPay,
    loading: state.calendar.callForPayLoading,
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    getCalendarForPay: (data) => dispatch(getCalendarForPay(data)),
    getProfessionals: (typeUser, page, perPage) => dispatch(getProfessionals(typeUser, page, perPage)),
    openAny: (children, title, size) => dispatch(openAny(children, title, size)),
    closeAny: () => dispatch(closeAny())
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsController);
