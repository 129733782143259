import {  put, takeEvery,select} from 'redux-saga/effects';
import api from '../../api';
import { toast } from 'react-toastify';
import { closeModal } from '../actions/uiActions';
import { getOrientation, orientationFail, orientationLoadingRequest, orientationStart, orientationSuccess } from '../actions/orientationActions';

export const getAuthToken = (state) => state.auth.token;
export const getAuthID = (state) => state.auth.id;
export const getAuthCompany = (state) => state.auth.company.id

function* get(action){

    try{
        yield put(orientationStart());
       
        const response = yield  api.get("/user/"+action.patient+"/orientation",{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });


        if(response.status === 200){          
            yield put(orientationSuccess(response.data))
        }else{
            yield put(orientationFail())   
        }
    } catch (e) {
        console.error(e);
        yield put(orientationFail())   
    }
}

function* store(action){
    yield put(orientationLoadingRequest(true));
   
    try{

        let data = new FormData();
        data.append('patient', action.data.patient);
        data.append('description', action.data.description);
        data.append('type', action.data.type);
        data.append('file', action.data.file);

        const response = yield  api.post("/orientation",data,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
                'Content-Type': 'multipart/form-data'
            }
        });

        if(response.status === 200){
            yield put(getOrientation(action.data.patient));  
            toast.success(response.data.message);
            yield put(closeModal())
        }

        yield put(orientationLoadingRequest(false));
   
    } catch (e) {
        console.error(e);    
        yield put(orientationLoadingRequest(false));       
    }
}

function* update(action){
    yield put(orientationLoadingRequest(true));
   
    try{       
        const response = yield  api.put("/orientation/"+action.id,action.data,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });
       
        if(response.status === 200){     
            yield put(getOrientation(action.data.patient));       
            toast.success(response.data.message);
        }else{
            
        }
        yield put(orientationLoadingRequest(false));
   
    } catch (e) {
        console.error(e);  
        yield put(orientationLoadingRequest(false));
         
    }
}


function* destroy(action){
    yield put(orientationLoadingRequest(true));
   
    try{       
        const response = yield  api.delete("/orientation/"+action.id,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });
      
        if(response.status === 200){ 
            yield put(getOrientation(action.patient));           
            toast.success(response.data.message);
        }else{
            
        }
        yield put(orientationLoadingRequest(false));
   
    } catch (e) {
        console.error(e);   
        yield put(orientationLoadingRequest(false));
        
    }
}

export function* orientationSaga() {
    yield takeEvery('GET_ORIENTATION', get);
    yield takeEvery('ORIENTATION_STORE', store);
    yield takeEvery('ORIENTATION_UPDATE', update);
    yield takeEvery('ORIENTATION_DESTROY', destroy);
}