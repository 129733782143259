import React, {useEffect} from "react";
import {Controller, useForm} from "react-hook-form";
import {Field} from "../Field";
import {Button} from "../Button";

function HealthInsuranceModal({data, updateHealthInsurance, storeHealthInsurance, operators, getOperator}) {

    const optionsOperators = operators.filter(value => value.status === 1).map((value) => ({label:value.operator?.corporate_reason+(value.operator?.fantasy_name ? " ("+value.operator?.fantasy_name+")" : ""),value:value.id}))

    useEffect(() => {
        getOperator();
    }, []);

    const onSubmit = request => {
        if(data && data.id){
            updateHealthInsurance(request,data.id);
        }else{
            storeHealthInsurance(request)
        }
    }

    const { control, handleSubmit,  formState: { errors } } = useForm({
        defaultValues:{...data}
    });

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className={"col-lg-3"} style={{marginTop: 10}} >
                    <label className="fw-bold text-muted">Operadora*:</label>
                    <Controller
                        name="company_health_insurance_id"
                        control={control}
                        rules={{ required: true }}
                        render={({ field : { onChange,  value }}) => <Field options={optionsOperators} type="select" value={value} onChange={onChange} />}
                    />
                    {errors.company_health_insurance_id && <p style={{color:'red',marginTop:10}}>Necessário selecionar a operadora.</p>}
                </div>

                <div className={"col-lg-3"} style={{marginTop: 10}} >
                    <label className="fw-bold text-muted">N° carteirinha*:</label>
                    <Controller
                        name="card_number"
                        control={control}
                        rules={{ required: true }}
                        render={({ field : { onChange,  value }}) => <Field type="text" value={value} onChange={onChange} />}
                    />
                    {errors.card_number && <p style={{color:'red',marginTop:10}}>Necessário preencher o número da carteirinha.</p>}
                </div>
                <div className={"col-lg-3"} style={{marginTop: 10}} >
                    <label className="fw-bold text-muted">Validade:</label>
                    <Controller
                        name="validity"
                        control={control}
                        render={({ field : { onChange,  value }}) => <Field type="date" value={value} onChange={onChange} />}
                    />
                </div>
                <div className={"col-lg-3"} style={{marginTop: 10}} >
                    <label className="fw-bold text-muted">Acomodação:</label>
                    <Controller
                        name="accommodation"
                        control={control}
                        render={({ field : { onChange,  value }}) => <Field type="text" value={value} onChange={onChange} />}
                    />
                </div>
            </div>
            <div className="row" style={{textAlign: 'right', marginTop: 10}}>
                <div className="col-md-9">

                </div>
                <div className="col-md-3">
                    {
                        <Button  text="Salvar" type="submit"/>
                    }
                </div>
            </div>
        </form>
    );
}

export default HealthInsuranceModal;
