import React, {useState, useEffect} from "react";
import {Link, useHistory, useLocation} from "react-router-dom";
import logo2 from "../../assets/img/usemed.png";
import {checkPermission} from "../../utils";
import {useSelector} from "react-redux";
import Profile from "./Profile";

export default function Header({headerRight,isOpen, setOpen, dispatch, auth, professionalCalendar, calendarRef, isMobile, shadow, headerNavigation}) {
    const [novo, setNovo] = useState(false);
    const [notification, setNotification] = useState(false);

    const patient = useSelector(state => state.patients.item)

    useEffect(() => {
        function click(ev) {

            if (ev.srcElement.closest("#novo") == null) {
                setNovo(false);
            }

            if (ev.srcElement.closest("#notification") == null) {
                setNotification(false);
            }
        }

        if (novo || notification) {
            window.addEventListener("click", click, false);
            return () => {
                window.removeEventListener("click", click, false);
            };
        }
    }, [novo, notification]);

    let location = useLocation();
    let history = useHistory();
    let url = location.pathname.split("/");

    const navigation = [
        {
            description: "Paciente",
            seletor: () => {
                dispatch({type: "UI_OPEN_MODAL", modal: "patient"});
                setNovo(false);
            },
            permission: "patients.store"
        },
        {
            description: "Atendimento",
            seletor: () => {
                dispatch({
                    type: "UI_OPEN_MODAL",
                    modal: "atendimento",
                    payload: url[1] === "patient" ? {
                        patient: {
                            id: patient.id,
                            name: patient.name,
                        },
                        calendarRef: calendarRef
                    } : url[1] === "calendar" ? {
                        professional: {
                            id: professionalCalendar.id,
                            name: professionalCalendar.name,
                        },
                        calendarRef: calendarRef,
                    } : {calendarRef: calendarRef}
                });
                setNovo(false);
            },
            permission: "calls.store",
        },
        {
            description: "Bloqueio de horário",
            seletor: () => {
                dispatch({type: "UI_OPEN_MODAL", modal: "bloquearHorario"});
                setNovo(false);
            },
            permission: "timeblock.store",
        },
        {
            description: "Perfil",
            seletor: () => {
                dispatch({type: 'UI_OPEN_MODAL', modal: 'profile'});
                setNovo(false)
            },
            permission: 'roles.store'
        },
        // {
        //      description: "Usuário",
        //      seletor: () => {
        //          dispatch({type: 'UI_OPEN_MODAL', modal: 'user'});
        //          setNovo(false)
        //      },
        //      permission: 'professionals.store'
        //  }
    ];

    const navigationWayfly = [
        {
            description: "Empresa",
            seletor: () => {
                dispatch({type: "UI_OPEN_MODAL", modal: "companyModal"});
                setNovo(false);
            },
            permission: "patients.store",

        }
    ];

    let style = {}

    if (isMobile) {
        style = {
            zIndex: 88888,
            position: "fixed",
            backgroundColor: '#fff',
            width: '100%',
            height: 60
        }
    }

    const back = {
        'patient': true,
        'user' : true
    }

    const menu = [...navigation,...headerNavigation];
    const menuWayfly = [...navigationWayfly,...headerNavigation];

    return (
        <div className={"header "+(shadow && isMobile ? "" : "")} style={style} data-kt-sticky-header="on">
            <div className="container-fluid d-flex align-items-center ">

                <div className="d-flex m-2" >
                    {
                        isMobile ?
                            back[url[1]] ?
                                <button
                                    style={{marginRight: 20,marginLeft:0}}
                                    onClick={() => {
                                        history.goBack();
                                    }}
                                    className="btn btn-sm btn-icon btn-active-color-primary"

                                >
                                    <span className="svg-icon svg-icon-muted svg-icon-2hx">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.2657 11.4343L15.45 7.25C15.8642 6.83579 15.8642 6.16421 15.45 5.75C15.0358 5.33579 14.3642 5.33579 13.95 5.75L8.40712 11.2929C8.01659 11.6834 8.01659 12.3166 8.40712 12.7071L13.95 18.25C14.3642 18.6642 15.0358 18.6642 15.45 18.25C15.8642 17.8358 15.8642 17.1642 15.45 16.75L11.2657 12.5657C10.9533 12.2533 10.9533 11.7467 11.2657 11.4343Z" fill="#000"/>
                                        </svg>
                                    </span>
                                </button>
                                :
                                <div className="btn btn-icon btn-active-icon-primary" style={{marginLeft:-11}} onClick={() => setOpen(!isOpen)}>
                                    <span className="svg-icon svg-icon-muted svg-icon-2x">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="black" />
                                            <path  d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z" fill="#000" />
                                        </svg>
                                    </span>
                                </div>
                            :
                            <></>
                    }
                </div>
                { (!headerRight && isMobile) &&<div className="d-flex">
                    <div>
                        <Link to="/">
                            <img alt="Logo" src={auth.company?.logo ? auth.company?.logo : logo2} className="h-30px"/>
                        </Link>
                    </div>
                </div>}
                <div className="d-flex flex-grow-1 justify-content-end gap-5">
                    <div className="d-flex flex ">
                        <div className="dropdown">
                            {
                                !checkPermission(auth.permissions, "patients.store") && !checkPermission(auth.permissions, "calls.store") && !checkPermission(auth.permissions, "timeblock.store") && !checkPermission(auth.permissions, "professionals.store") && !checkPermission(auth.permissions, "roles.store") ?
                                    <></>
                                    :
                                    <button
                                        className={"btn btn-link btn-sm"}
                                        onClick={() => setNovo(!novo)}
                                    >
                                        <span className="svg-icon svg-icon-muted svg-icon-2hx" style={{height:"1.5rem",width:"1.5rem",display:"block",marginBottom:5}}>
                                            <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 30 30"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M21 13H3C2.4 13 2 12.6 2 12C2 11.4 2.4 11 3 11H21C21.6 11 22 11.4 22 12C22 12.6 21.6 13 21 13Z" fill="#000"/>
                                                <path d="M12 22C11.4 22 11 21.6 11 21V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V21C13 21.6 12.6 22 12 22Z" fill="#000"/>
                                            </svg>
                                        </span>
                                    </button>
                            }
                            {headerRight}
                            <div className={"dropdown-menu menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px " + (novo ? "show" : "")} style={{top: 50, right: 0}} id="novo">
                                {
                                    (auth.company?.id === 169 ? menuWayfly : menu).map((value, index) => {
                                        return ( (!value.permission || checkPermission(auth.permissions, value.permission)) && <a className="dropdown-item" key={index} onClick={() => { value.seletor();}} style={{cursor: "pointer"}}>{value.description}</a>);
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    {!headerRight ? <Profile  auth={auth} dispatch={dispatch}/> : <></>}
                </div>
            </div>
        </div>
    );
}
