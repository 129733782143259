import {  put, takeEvery,select} from 'redux-saga/effects';
import api from '../../api';
import { toast } from 'react-toastify';
import { getPlan, planFail, planLoadingRequest, planStart, planSuccess } from '../actions/planActions';
import { closeModal } from '../actions/uiActions';

export const getAuthToken = (state) => state.auth.token;
export const getAuthID = (state) => state.auth.id;
export const getAuthCompany = (state) => state.auth.company.id

function* get(action){

    try{
        yield put(planStart());
       
        const response = yield  api.get("/patient/"+action.patient+"/therapeuticplan",{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });


        if(response.status === 200){          
            yield put(planSuccess(response.data))
        }else{
            yield put(planFail())   
        }
    } catch (e) {
        console.error(e);
        yield put(planFail())   
    }
}

function* store(action){
    yield put(planLoadingRequest(true));
   
    try{       
        const response = yield  api.post("/therapeuticplan",action.data,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){        
            yield put(getPlan(action.data.patient));  
            toast.success(response.data.message);
            yield put(closeModal())
        }

        yield put(planLoadingRequest(false));
   
    } catch (e) {
        console.error(e);    
        yield put(planLoadingRequest(false));       
    }
}

function* update(action){
    yield put(planLoadingRequest(true));
   
    try{       
        const response = yield  api.put("/therapeuticplan/"+action.id,action.data,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });
       
        if(response.status === 200){     
            yield put(getPlan(action.data.patient));       
            toast.success(response.data.message);
        }else{
            
        }
        yield put(planLoadingRequest(false));
   
    } catch (e) {
        console.error(e);  
        yield put(planLoadingRequest(false));
         
    }
}


function* destroy(action){
    yield put(planLoadingRequest());
   
    try{       
        const response = yield  api.delete("/therapeuticplan/"+action.id,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });
      
        if(response.status === 200){ 
            yield put(getPlan(action.patient));           
            toast.success(response.data.message);
        }else{
            
        }
        yield put(planLoadingRequest(false));
   
    } catch (e) {
        console.error(e);   
        yield put(planLoadingRequest(false));
        
    }
}

export function* planSaga() {
    yield takeEvery('GET_PLAN', get);
    yield takeEvery('PLAN_STORE', store);
    yield takeEvery('PLAN_UPDATE', update);
    yield takeEvery('PLAN_DESTROY', destroy);
}