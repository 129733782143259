export const professionalloading = (status) => ({type: 'PROFESSIONALS_LOADING',status});
export const professionalSuccess = (itens,count) => ({type: 'PROFESSIONALS_SUCCESS',itens,count});
export const professionalloadingAutocomplete = (status) => ({type: 'PROFESSIONALS_LOADING_AUTOCOMPLETE',status});
export const professionalSuccessAutocomplete = (data) => ({type: 'PROFESSIONALS_SUCCESS_AUTOCOMPLETE',data});
export const professionalClear = () => ({type: 'PROFESSIONAL_CLEAR'});
export const getAutocompleteProfessional = (typeUser,page,perPage,name) => ({type: 'AUTOCOMPLETE_PROFESSIONAL',typeUser,page,perPage,name});
export const setProfessional  = (data) => ({type: 'PROFESSIONALS_SET',data});
export const getProfessional = (id) => ({type: 'GET_PROFESSIONAL',id});
export const professionalStartRequest = () => ({type: 'PROFESSIONALS_START_REQUEST'});
export const professionalFailRequest = () => ({type: 'PROFESSIONALS_FAIL_REQUEST'});
export const getProfessionals = (typeUser,page,perPage) => ({type: 'GET_PROFESSIONALS',typeUser,page,perPage});
export const storeProfessional  = (data,typeModel) => ({type: 'STORE_PROFESSIONAL',data,typeModel});
export const updateProfessional  = (data,id,typeModel) => ({type: 'UPDATE_PROFESSIONAL',data,id,typeModel});
export const uploadPhotoProfessional = (data,id) => ({type: 'UPLOAD_FOTO_PROFESSIONAL',data,id});
export const deletePhotoProfessional = (id) => ({type: 'DELETE_FOTO_PROFESSIONAL',id});
export const alterProfessional = (data) => ({type: 'PROFESSIONAL_ALTER',data});
export const getInfinityLoadingProfessionals = (typeUser,page,perPage,name) => ({type: 'INFINITYLOADING_PROFESSIONALS',typeUser,page,perPage,name});
export const setInfinityLoadingProfessional  = (data) => ({type: 'INFINITYLOADING_PROFESSIONALS_SET',data});
