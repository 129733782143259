import ContentLoader from "react-content-loader";
import React from "react";

const ListChartLoader = (props) => {
    return (
        <ContentLoader
            speed={2}
            width={350}
            height={259}
            viewBox="0 0 400 300"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
        >
            <rect x="0" y="0" rx="5" ry="5" width="100%" height="20" />
            <rect x="0" y="40" rx="5" ry="5" width="80%" height="20" />
            <rect x="0" y="80" rx="5" ry="5" width="90%" height="20" />
            <rect x="0" y="120" rx="5" ry="5" width="70%" height="20" />
            <rect x="0" y="160" rx="5" ry="5" width="100%" height="20" />
            <rect x="0" y="200" rx="5" ry="5" width="85%" height="20" />
            <rect x="0" y="240" rx="5" ry="5" width="95%" height="20" />
            <rect x="0" y="280" rx="5" ry="5" width="100%" height="20" />

        </ContentLoader>
    );
};


export default ListChartLoader;