import { put, takeEvery, select} from 'redux-saga/effects';
import api from '../../api';
import { push } from 'connected-react-router';
import { messenge } from './utils';
import { getPermission, startLoading, templateLoading, templateSuccess, tokenSuccess } from '../actions/accountActions';
import { authSuccess, setAdmin, setCompany } from '../actions/authActions';

export const getAuthToken = (state) => state.auth.token;

function* getTemplate(){
    try{      
        const response = yield  api.get('/templateemail',{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){    
           yield put(templateSuccess(response.data))
        }
    } catch (e) {
        console.error(e);
    }
}

function* sendEmail(action){
    try{   
        yield put(templateLoading(true))   
        const response = yield  api.post('/templateemail',action.data,{});

        if(response.status === 200){    
            yield messenge(response.data,'success');        
            yield put(templateLoading(false));
        }
    } catch (e) {
        yield put(templateLoading(false))   
        console.error(e);
        yield messenge(e.response.data,'warning');     
    }
}

function* getToken(action){
    try{   
        yield put(templateLoading(true))   
        const response = yield  api.get('/templateemail/token/'+action.token,{});

        if(response.status === 200){    
            
            yield put(tokenSuccess(response.data));
            yield put(templateLoading(false));

        }
    } catch (e) {
        yield put(templateLoading(false))   
        console.error(e);
        yield messenge(e.response.data,'warning');     
    }
}

function* start(action){
    try{   
        yield put(startLoading(true))   
        const response = yield  api.post('/company/start',action.data,{});

        if(response.status === 200){    
            yield put(authSuccess(response.data))
            yield put(setAdmin(response.data.admin))
            yield put(setCompany(response.data.company));
            yield put(push('/'));
            yield put(getPermission());
            yield put(startLoading(false))   
        }
    } catch (e) {
        yield put(startLoading(false))   
        console.error(e);
        yield messenge(e.response.data,'warning');     
    }
}

export function* AccountSaga() {
    yield takeEvery('GET_TEMPLATE', getTemplate);
    yield takeEvery('SEND_EMAIL', sendEmail);
    yield takeEvery('GET_TOKEN', getToken);
    yield takeEvery("START",start);
}