import  React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

function ReportsFinance() {
    return (
        <div className='card'>
            <div className='card-header'>
                <div className='card-title'>
                    Relatórios financeiros
                </div>
            </div>
            <div className='card-body'>
                <div className="list-group">
                    <Link to="/finance/reports/payments" className="list-group-item list-group-item-action">
                        Relatório de pagamento
                    </Link>
                    {/*<a href="#" className="list-group-item d-flex justify-content-between align-items-center disabled">
                        Relatório de inadimplência
                        <span className="badge badge-info badge-pill">Em breve</span>
                    </a>
                    <a href="#" className="list-group-item d-flex justify-content-between align-items-center disabled">
                        Relatório adimplente
                        <span className="badge badge-info badge-pill">Em breve</span>
                    </a>*/}
                </div>
            </div>
        </div>
    );
}

const mapStateToprops = state => ({

});

const mapDispatchToprops = dispatch => ({

});

export default connect(mapStateToprops,mapDispatchToprops)(ReportsFinance);
