import {getName, getNamePrimary} from "../../utils";
import React from "react";

/*
const Event10 = ({eventInfo, sigla, background}) => {

    return (
        <div
            title={eventInfo.event.extendedProps.patient.name}
            style={{
                textAlign: "left",
                borderLeftColor: eventInfo.event.extendedProps.status.color,
                borderLeftWidth: 3,
                borderLeftStyle: "solid",
                backgroundColor: background,
                width: "100%",
                color: "#000",
                height: 10,
                cursor: "pointer",
                paddingRight: 10
            }}
        >
            <div style={{flexDirection: "column", display: "flex", height: 10}}>
                <span
                    style={{
                        marginLeft: 3,
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        flex: 1,
                        marginTop:-8,
                        fontSize:7
                    }}
                >
                    {eventInfo.event.start.toLocaleTimeString('pt-BR', {hour: '2-digit', minute: '2-digit'})} [{sigla}] {eventInfo.event.extendedProps.patient && getName(eventInfo.event.extendedProps.patient.name)}
                </span>
            </div>
        </div>
    )
}
*/

const Event30 = ({eventInfo, sigla, background}) => {

    return (
        <div
            title={eventInfo.event.extendedProps.patient.name}
            style={{
                textAlign: "left",
                borderLeftColor: eventInfo.event.extendedProps.status.color,
                borderLeftWidth: 5,
                borderLeftStyle: "solid",
                backgroundColor: background,
                width: "100%",
                color: "#000",
                height: "100%",
                cursor: "pointer",
                paddingRight: 10,
                minHeight: 30
            }}
        >
            <div style={{flexDirection: "column", display: "flex", height: 40}}>
                <span
                    style={{
                        marginLeft: 5,
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        flex: 1,
                        marginTop:-4
                    }}
                >
                    {eventInfo.event.start.toLocaleTimeString('pt-BR', {hour: '2-digit',minute: '2-digit'})} [{sigla}] {eventInfo.event.extendedProps.patient && getName(eventInfo.event.extendedProps.patient.name)}
                </span>
                <span style={{
                    marginLeft: 5,
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    marginTop: -16,
                    flex: 1,
                    fontSize:9
                }}>
                    [{eventInfo.event.extendedProps.professional && getNamePrimary(eventInfo.event.extendedProps.professional.name)}] {eventInfo.event.extendedProps.treatment.specialization && eventInfo.event.extendedProps.treatment.specialization.profession && eventInfo.event.extendedProps.treatment.specialization.profession.description} {eventInfo.event.extendedProps.treatment.specialization && eventInfo.event.extendedProps.treatment.specialization.description}
                </span>
            </div>
        </div>
    )
}

const Event40 = ({eventInfo, sigla, background}) => {

    return (
        <div
            title={eventInfo.event.extendedProps.patient.name}
            style={{
                textAlign: "left",
                borderLeftColor: eventInfo.event.extendedProps.status.color,
                borderLeftWidth: 5,
                borderLeftStyle: "solid",
                backgroundColor: background,
                width: "100%",
                color: "#000",
                height: "100%",
                cursor: "pointer",
                paddingRight: 10,
                minHeight: 37
            }}
        >
            <div style={{flexDirection: "column", display: "flex", height: 40}}>
                <span
                    style={{
                        marginLeft: 5,
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        flex: 1,
                        marginTop:-1
                    }}
                >
                    {eventInfo.event.start.toLocaleTimeString('pt-BR', {hour: '2-digit',minute: '2-digit'})} [{sigla}] {eventInfo.event.extendedProps.patient && getName(eventInfo.event.extendedProps.patient.name)}
                </span>
                <span style={{
                    marginLeft: 5,
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    marginTop: -10,
                    flex: 1,
                    fontSize:9
                }}>
                    [{eventInfo.event.extendedProps.professional && getNamePrimary(eventInfo.event.extendedProps.professional.name)}] {eventInfo.event.extendedProps.treatment.specialization && eventInfo.event.extendedProps.treatment.specialization.profession && eventInfo.event.extendedProps.treatment.specialization.profession.description} {eventInfo.event.extendedProps.treatment.specialization && eventInfo.event.extendedProps.treatment.specialization.description}
                </span>
            </div>
        </div>
    )
}

const Event = ({eventInfo, sigla, background}) => {


    switch (parseInt(eventInfo.event.extendedProps.treatment.time)){
        case 30:
            return  <Event30 eventInfo={eventInfo} sigla={sigla} background={background} />;
        case 40:
            return  <Event40 eventInfo={eventInfo} sigla={sigla} background={background} />;
        default:
            return (
                <div
                    title={eventInfo.event.extendedProps.patient.name}
                    style={{
                        textAlign: "left",
                        borderLeftColor: eventInfo.event.extendedProps.status.color,
                        borderLeftWidth: 5,
                        borderLeftStyle: "solid",
                        backgroundColor: background,
                        width: "100%",
                        color: "#000",
                        height: "100%",
                        cursor: "pointer",
                        paddingRight: 10,
                        minHeight: 45
                    }}
                >
                    <div style={{flexDirection: "column", display: "flex", height: 40}}>
                    <span
                        style={{
                            marginLeft: 5,
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            flex: 1,
                        }}
                    >
                        {eventInfo.event.start.toLocaleTimeString('pt-BR', {hour: '2-digit',minute: '2-digit'})} [{sigla}] {eventInfo.event.extendedProps.patient && getName(eventInfo.event.extendedProps.patient.name)}
                    </span>
                        <span style={{
                            marginLeft: 5,
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            marginTop: -5,
                            flex: 1
                        }}>
                        [{eventInfo.event.extendedProps.professional && getNamePrimary(eventInfo.event.extendedProps.professional.name)}] {eventInfo.event.extendedProps.treatment.specialization && eventInfo.event.extendedProps.treatment.specialization.profession && eventInfo.event.extendedProps.treatment.specialization.profession.description} {eventInfo.event.extendedProps.treatment.specialization && eventInfo.event.extendedProps.treatment.specialization.description}
                    </span>
                    </div>
                </div>
            );
    }


}

const Birthday = ({eventInfo}) => {
    return (
        <div
            title={"Aniversariante "+ eventInfo.event.title}
            style={{
                textAlign: "left",
                width: "100%",
                color: "#000",
                height: 30,
                cursor: "pointer",
                paddingRight: 10,
                background:"#50CD89",
                borderRadius:5,
                display:'flex',
                alignItems:'center'
            }}
        >
            <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21 9V11C21 11.6 20.6 12 20 12H14V8H20C20.6 8 21 8.4 21 9ZM10 8H4C3.4 8 3 8.4 3 9V11C3 11.6 3.4 12 4 12H10V8Z" fill="#fff"/>
                <path d="M15 2C13.3 2 12 3.3 12 5V8H15C16.7 8 18 6.7 18 5C18 3.3 16.7 2 15 2Z" fill="#fff"/>
                <path opacity="0.3" d="M9 2C10.7 2 12 3.3 12 5V8H9C7.3 8 6 6.7 6 5C6 3.3 7.3 2 9 2ZM4 12V21C4 21.6 4.4 22 5 22H10V12H4ZM20 12V21C20 21.6 19.6 22 19 22H14V12H20Z" fill="#fff"/>
            </svg>
            <span
                style={{
                    marginLeft: 5,
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    flex: 1,

                    color:"#fff"
                }}
            >
                {eventInfo.event.title}
            </span>
        </div>
    )
}

const eventContent = (eventInfo) => {
    const data = () => {
        if (eventInfo.event.extendedProps.replacement === 1) {
            return <Event eventInfo={eventInfo} sigla="R" background="#ffeca7"/>
        }

        switch (eventInfo.event.extendedProps.type_schedule.id) {
            case 1:
                return <Event eventInfo={eventInfo} sigla="AV" background="#a9fca9"/>
            case 2:
                return <Event eventInfo={eventInfo} sigla="E" background="#c6eaff"/>
            case 3:
                return <Event eventInfo={eventInfo} sigla="A" background="#ededed"/>
            case 4:
                return <Event eventInfo={eventInfo} sigla="D" background="#e2c7bc"/>
            case 0:
                return <Birthday eventInfo={eventInfo} />
            default:
                return <></>
        }
    }

    return (
        <>
            {
                eventInfo.event.extendedProps.status.id === 10 ?
                    <div style={{
                        textAlign: "center",
                        backgroundColor: eventInfo.event.extendedProps.status.color,
                        width: "100%",
                        color: "#FFF",
                        height: "100%",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden"
                    }}>
                        Horário bloqueado <br/>{getName(eventInfo.event.extendedProps.professional.name)}
                    </div>

                    :
                    data()
            }
        </>
    );
};

export default eventContent;

