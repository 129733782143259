import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import Documents from '../../components/document/Documents'
import {
    destroyDocuments,
    getDocuments,
    getTypesDocuments,
    openModalDocument,
    updateDocuments,
    upload
} from "../../store/actions/documentsActions";
import {openViewPDF} from "../../store/actions/uiActions";

function DocumentsController({disabled,
    loading,
    typeModel,
    id,
    typeDocument,
    getTypesDocuments,
    openModalDocument,
    openModal,
    upload,
    loadingRequest,
    getDocuments,
    documents,
    auth,
    destroyDocuments,
    title,
    documentsFinance,
    updateDocuments,
    openViewPDF
}) {

    useEffect(() => {
        getTypesDocuments(typeModel);
        getDocuments(typeModel, id);
    }, [id])

    return (
        <Documents
            disabled={disabled}
            updateDocuments={updateDocuments}
            documentsFinance={documentsFinance}
            loading={loading}
            title={title}
            destroyDocuments={destroyDocuments}
            loadingRequest={loadingRequest}
            typeModel={typeModel}
            id={id}
            typeDocument={typeDocument}
            openModalDocument={openModalDocument}
            openModal={openModal}
            upload={upload}
            documents={documents}
            auth={auth}
            openViewPDF={openViewPDF}
        />
    );
}

const mapStateToprops = state => ({
    typeDocument: state.documents.typeDocument,
    openModal: state.documents.openModal,
    loadingRequest: state.documents.loadingRequest,
    documents: state.documents.itens,
    documentsFinance: state.documents.itensFinance,
    auth: state.auth,
    loading: state.documents.loading
});

const mapDispatchToprops = dispatch => ({
    getTypesDocuments: (typeModel) => dispatch(getTypesDocuments(typeModel)),
    openModalDocument: (status) => dispatch(openModalDocument((status))),
    upload: (data) => dispatch(upload(data)),
    getDocuments: (typeModel, id) => dispatch(getDocuments(typeModel, id)),
    destroyDocuments: (id) => dispatch(destroyDocuments(id)),
    updateDocuments: (data) => dispatch(updateDocuments(data)),
    openViewPDF:(viewPDFOpen,payloadViewPDF) => dispatch(openViewPDF(viewPDFOpen,payloadViewPDF))
});

export default connect(mapStateToprops, mapDispatchToprops)(DocumentsController);