import React, {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import Call from "./pendencies/Call";

function PendenciesCallsNotSignedModal({data,modalRef}) {

    const dispatch = useDispatch();
    const openCall = (modal,item,typeModal) => dispatch({type:'OPEN_CALL',modal,item,typeModal});
    const getCountCallsNotSigned = (data) => dispatch({type:'GET_COUNT_CALLS_NOT_SIGNED',data});
    const getCallsNotSigned = (professional) => dispatch({type:'GET_CALLS_NOT_SIGNED',professional});
    const clearCallsNotSigned = () => dispatch({type:'CLEAR_CALLS_NOT_SIGNED'});
    const calendar = useSelector(state => state.calendar.dashboard.callsNotSigned);

    useEffect(() => {

        clearCallsNotSigned()
        loadCalls()

        return () => {
            getCountCallsNotSigned({ professional: data.professional})
            clearCallsNotSigned()
        }
    }, [])

    const loadCalls = useCallback(async () => {
        console.log(calendar?.page,calendar?.lastPage,!calendar?.loading)
        if (calendar?.page <= calendar?.lastPage && !calendar?.loading) {
            getCallsNotSigned(data.professional);
        }
    }, [getCallsNotSigned]);

    const handleScroll = useCallback(() => {
        const { scrollHeight, scrollTop, clientHeight } = modalRef.current;
        if (scrollTop + clientHeight >= scrollHeight-5) {
            loadCalls();
        }
    }, [loadCalls]);

    useEffect(() => {
        modalRef.current.addEventListener('scroll', handleScroll);

        return () => {
            modalRef && modalRef.current && modalRef.current.removeEventListener('scroll', handleScroll);
        };
    },[handleScroll]);

    return (
        <>
            {calendar?.items?.filter(value => !value.signed_document?.id > 0).map((value,index) => <Call openCall={openCall} key={index} value={value} />)}
            <div  style={{textAlign: 'center'}}>{calendar?.loading === true ? "Carregando..." : <></>}</div>
        </>
    );
}

export default PendenciesCallsNotSignedModal;
