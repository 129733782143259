import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import {Link, useParams} from 'react-router-dom';
import {getReports} from "../../store/actions/reportsActions";
import ContentLoader from "react-content-loader";
import Img5 from "../../assets/img/5.png";
import {openModal} from "../../store/actions/uiActions";
import {checkPermission} from "../../utils";

function Report({value,openModal}){

    switch (value.type){
        case 2:
            return(
                <a onClick={() => openModal('reportsView',value)}  className="list-group-item list-group-item-action" style={{cursor:'pointer'}}>
                    {value.description}
                </a>
            );
        default:
            return(
                <Link to={value.url}  className="list-group-item list-group-item-action">
                    {value.description}
                </Link>
            )
    }


}


function ReportsController({itens,getReports,loading,openModal,auth}) {
    const { page } = useParams();

    useEffect(() =>{
        getReports(page)
    },[getReports,page])

    return (
        <div className='card'>
            <div className='card-header'>
                <div className='card-title'>
                    Relatórios
                </div>
            </div>
            <div className='card-body'>
                <div className="list-group">
                    {
                        loading === true ?
                            <ContentLoader
                                speed={2}
                                width={400}
                                height={200}
                                viewBox="0 0 400 150"
                                backgroundColor="#f3f3f3"
                                foregroundColor="#ecebeb"
                            >
                                <rect x="25" y="15" rx="5" ry="5" width="300" height="15" />
                                <rect x="25" y="45" rx="5" ry="5" width="320" height="15" />
                                <rect x="25" y="75" rx="5" ry="5" width="340" height="15" />
                                <rect x="25" y="105" rx="5" ry="5" width="320" height="15" />
                                <rect x="25" y="135" rx="5" ry="5" width="320" height="15" />
                                <rect x="25" y="165" rx="5" ry="5" width="340" height="15" />
                            </ContentLoader>
                            :
                            itens && itens.length > 0 ?
                                itens.map((value,index) => {


                                    if(!checkPermission(auth.permissions,value.permission)){
                                        return <></>
                                    }

                                    return(
                                        <Report openModal={openModal} key={index} value={value} />
                                    )
                                })
                                :
                                <div className="d-flex flex-column flex-center" style={{ paddingTop: 20 }}>
                                    <img src={Img5} style={{ maxWidth: 330 }} alt={""} />
                                    <div className="text-dark mb-4" style={{ fontSize: 18, textAlign: "center" }}>Desculpe-nos, não
                                        encontramos nenhum relatório<br />.
                                    </div>
                                </div>
                    }
                </div>
            </div>
        </div>
    );
}

const mapStateToprops = state => ({
    itens: state.reports.itens ?  state.reports.itens   : [],
    loading: state.reports.loading,
    auth:state.auth
});

const mapDispatchToprops = dispatch => ({
    getReports:(page) => dispatch(getReports(page)),
    openModal: (modal,payload) => dispatch(openModal(modal,payload))
});

export default connect(mapStateToprops,mapDispatchToprops)(ReportsController);
