export const updateObject = (oldObject, newProperties) => ({...oldObject, ...newProperties});

export function formatDayAndMonth(date){
    return date.day.toString().padStart(2, '0')+"/"+date.month.toString().padStart(2, '0')
}

export function formatYear(date){
    return date.year;
}

export function formatHour(date){
    return date.hour.toString().padStart(2, '0')+":"+date.minute.toString().padStart(2, '0');
}

export function newDate(date) {

  return `${date.year}-${date.month.toString().padStart(2, '0')}-${date.day.toString().padStart(2, '0')} ${date.hour.toString().padStart(2, '0')}:${date.minute.toString().padStart(2, '0')}`;
}

export const getValue = (data) =>{

  return{
    ...data,
    "treatment": data.treatment.id,
    "room": data.room?.id ?? 0,
    "professional": data.professional.id,
    "patient": data.patient.id,
    "status": data.status.id,
    "type_schedule": data.type_schedule.id,
    "hour": data.start,
    "typeAction": data.typeAction,
    "service": data.service ? data.service.id : 0
  }
}

export function formatDate(date) {
    return `${date.day.toString().padStart(2, '0')}/${date.month.toString().padStart(2, '0')}/${date.year} às ${date.hour.toString().padStart(2, '0')}:${date.minute.toString().padStart(2, '0')}`;
}

export function formatDateTOYYYYMMDD(date) {
    return `${date.year}-${date.month.toString().padStart(2, '0')}-${date.day.toString().padStart(2, '0')}`;
}

export function formatDateToDDMMYYYY(data) {
    if(data === undefined) return "00/00/0000";
    const date = new Date(data);
    return `${date.getDate().toString().padStart(2, "0")}/${(date.getMonth()+1).toString().padStart(2, "0")}/${date.getFullYear()}`;
}

export function formatDateToYYYYMMDD(data) {
    const date = new Date(data);
    return `${date.getFullYear()}-${(date.getMonth()+1).toString().padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
}


export function formatDate2(date1,date2) {
  return `${date1.day.toString().padStart(2, '0')}/${date1.month.toString().padStart(2, '0')}/${date1.year} às ${date1.hour.toString().padStart(2, '0')}:${date1.minute.toString().padStart(2, '0')} até ${date2.day.toString().padStart(2, '0')}/${date2.month.toString().padStart(2, '0')}/${date2.year} às ${date2.hour.toString().padStart(2, '0')}:${date2.minute.toString().padStart(2, '0')}`;
}

export const ddi = [
    {
        label:'+55 (BR)',
        value:'55',
    },
    {
        label:'+1 (EUA)',
        value:'1',
    }
]

export function formatDate3(date1,date2) {
  return `${date1.day.toString().padStart(2, '0')}/${date1.month.toString().padStart(2, '0')}/${date1.year} às ${date1.hour.toString().padStart(2, '0')}:${date1.minute.toString().padStart(2, '0')} até ${date2.hour.toString().padStart(2, '0')}:${date2.minute.toString().padStart(2, '0')}`;
}

export function getUuid(data){
    return (data.company+""+data.patient.id+""+data.professional.id+""+data.start_date).replaceAll("-","").replaceAll(" ","").replaceAll(":","");

}

export function formatHourStartAndEnd(date1,date2) {
  return `${date1.hour.toString().padStart(2, '0')}:${date1.minute.toString().padStart(2, '0')} até  ${date2.hour.toString().padStart(2, '0')}:${date2.minute.toString().padStart(2, '0')}`;
}

export function formatDateNotHour(date) {
  return `${date.day.toString().padStart(2, '0')}/${date.month.toString().padStart(2, '0')}/${date.year}`;
}

export const humanFileSize = (bytes, si=false, dp=1) => {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }

    const units = si
      ? ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10**dp;

    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return bytes.toFixed(dp) + ' ' + units[u];
}

const month = ["Janeiro","Fevereiro","Março","Abril","Maio","Junho","Julho","Agosto","Setembro","Outubro","Novembro","Dezembro"];

export function formatDateStartAndEnd(start,end) {
    return `${start.setLocale('pt-br').toFormat('EEEE, dd \'de\' MMMM \'de\' yyyy')} às ${start.hour.toString().padStart(2, '0')}:${start.minute.toString().padStart(2, '0')} até ${end.hour.toString().padStart(2, '0')}:${end.minute.toString().padStart(2, '0')}`;
}

export function formatDateStartAndEnd2(start,end) {
    return `${start.setLocale('pt-br').toFormat('EEEE')}, ${start.day.toString().padStart(2, '0')} de ${month[start.month.toString()-1]} de ${start.year} às ${start.hour.toString().padStart(2, '0')}:${start.minute.toString().padStart(2, '0')} até ${end.hour.toString().padStart(2, '0')}:${end.minute.toString().padStart(2, '0')}`;
}

export function getWeekName(date) {
    return `${date.setLocale('pt-br').toFormat('EEEE')}`;
}

export function checkPermission(permissions,permission){
  if(!permissions || permissions.length === 0 ) return false;

  const result = permissions.filter(value => value === permission);
  return result.length >= 1;
}
export const getName = name => {
    if(name){
        let array = name.split(' ');

        if (array.length === 1) {
            return array[0];
        }

        return array[0] + ' ' + array[array.length - 1];
    }

    return ''
};
export const getNamePrimary = name => {

    let array = name.split(' ');

    if (array.length === 1) {
        return array[0];
    }

    return array[0];
};

export const formatNumber = (number) => {
    return (number / 100).toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
};

export const calcularMoney = (value) => {
	return (value/100)
}

export const removeDuplicateSpecializations = (array) => {
    const unique = {};
    return array.filter(item => {
        const specializationId = item.company_specialization.specialization.id;
        if (!unique[specializationId]) {
            unique[specializationId] = true;
            return true;
        }
        return false;
    });
}

export const canSign = () =>{
    return true;
}