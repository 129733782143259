import { put, takeEvery, select} from 'redux-saga/effects';
import api from '../../api';
import { serviceLoading, serviceStore, serviceSuccess, serviceUpdate} from "../actions/serviceActions";
import toast from "react-hot-toast";
import {closeModal} from "../actions/uiActions";

export const getAuthToken = (state) => state.auth.token;

function* getService(){
    try{
        yield put(serviceLoading(true))
        const response = yield  api.get('/service',{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield put(serviceLoading(false));
            yield put(serviceSuccess(response.data));
        }
    } catch (e) {
        yield put(serviceLoading(false));
        console.error(e);
    }
}

function* update(action){

    let response = api.put('/service/'+action.id, action.data, {
        headers: {
            'Authorization': 'Bearer ' + (yield select(getAuthToken)),
        }
    });

    toast.promise(response,
        {
            loading: 'Atualizando o serviço.',
            success: (success) => {
                return success.data.message
            },
            error: (error) => {
                return error.response.data.message
            }
        }
    );

    response = yield response;
    if (response.status === 200) {

        yield put(serviceUpdate(response.data.service,action.id))
    }
}

function* store(action){

    let response = api.post('/service', action.data, {
        headers: {
            'Authorization': 'Bearer ' + (yield select(getAuthToken)),
        }
    });

    toast.promise(response,
        {
            loading: 'Cadastrando o serviço.',
            success: (success) => {
                return success.data.message
            },
            error: (error) => {
                return error.response.data.message
            }
        }
    );

    response = yield response;
    if (response.status === 200) {
        yield put(serviceStore(response.data.service))
        yield put(closeModal())
    }
}

function* status(action){

    let response = api.put('/service/'+action.data.id,action.data, {
        headers: {
            'Authorization': 'Bearer ' + (yield select(getAuthToken)),
        }
    });

    toast.promise(response,
        {
            loading: 'Atualizando o status do serviço.',
            success: (success) => {
                return success.data.message
            },
            error: (error) => {
                return error.response.data.message
            }
        }
    );

    response = yield response;
    if (response.status === 200) {
        yield put(serviceUpdate(action.data,action.data.id))
    }
}

export function* serviceSaga() {
    yield takeEvery('GET_SERVICE', getService);
    yield takeEvery('UPDATE_SERVICE', update);
    yield takeEvery('STORE_SERVICE', store);
    yield takeEvery('STATUS_SERVICE', status);

}
