import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import Queue from "../../components/patients/Queue";
import {listQueueWait} from "../../store/actions/queueWaitActions";
import {getTreatment} from "../../store/actions/treatmentActions";
import {closeAny, openAny} from "../../store/actions/uiActions";

function PatientController({listQueueWait,list,loading,isMobile,getTreatment,treatment,openAny,closeAny}) {

    useEffect(()=>{
        listQueueWait();
        getTreatment()
    },[]);

    return (
        <Queue
            openAny={openAny}
            closeAny={closeAny}
            listQueueWait={listQueueWait}
            loading={loading}
            list={list}
            isMobile={isMobile}
            treatment={treatment}
        />
    );
}

const mapStateToProps = state => ({
    list:state.queueWait.list,
    loading:state.queueWait.loading,
    treatment: state.treatment.itens,
});

const mapDispatchToProps = dispatch => ({
    listQueueWait:(data) => dispatch(listQueueWait(data)),
    getTreatment: () => dispatch(getTreatment()),
    openAny:(children,title,size) => dispatch(openAny(children,title,size)),
    closeAny:() => dispatch(closeAny())
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientController);