import React, {useCallback, useEffect, useState} from "react";
import NewCalendar from "./Layout/NewCalendar";
import {connect, useDispatch, useSelector} from "react-redux";
import {closeAny, openAny, openModal} from "../../store/actions/uiActions";
import {searchCalls, setEndDate, setPatient, setProfessional, setStartDate} from "../../store/actions/calendarActions";
import Search from "./Search";
import api from "../../api";
import {getProfessionals} from "../../store/actions/professionalsActions";
import {getStatus} from "../../store/actions/companiesActions";
import {checkPermission} from "../../utils";
import {useHistory} from "react-router-dom";

function CalendarViewV2({
                            isMobile,
                            layoutRef,
                            myScheduler,
                            openModal,
                            statusArray,
                            user,
                            searchCalls,
                            loading,
                            events,
                            setStartDate,
                            setEndDate,
                            startDate,
                            endDate,
                            professional,
                            setProfessional,
                            setPatient,
                            openAny,
                            closeAny
                        }) {

    const [patientLocal, setPatientLocal] = useState();
    const [professionalLocal, setProfessionalLocal] = useState();
    const [roomLocal, setRoomLocal] = useState();
    const [status, setStatus] = useState(null);
    const [expedients, setExpedients] = useState([]);
    const [birthdays, setBirthdays] = useState([]);

    const token = useSelector(state => state.auth.token);
    const auth = useSelector(state => state.auth);

    const dispatch = useDispatch();
    const history = useHistory();

    const openCall = (modal,item,typeModal) => dispatch({type:'OPEN_CALL',modal,item,typeModal});

    useEffect( () => {
        async function fetchData(){
            await getExpedient(user.id)
        }

        if (!isMobile) {
            layoutRef.current.classList.remove('container-xxl')
            layoutRef.current.classList.add('container-fluid');
        } else {
            layoutRef.current.classList.remove('container-xxl')
        }

        if (myScheduler) {
            fetchData();
        }
    }, [user, isMobile]);

    const startTime = new Date();
    startTime.setHours(auth.company.start_expedient ?? 7, 0, 0);
    const endTime = new Date();
    endTime.setHours(auth.company.end_expedient ?? 22, 0, 0);

    const handleDateChange = useCallback((startStr, endStr) => {

        searchCalls({
            dateStart: startStr,
            dateEnd: endStr,
            professional: myScheduler ? [user.id] : professionalLocal,
            patient: patientLocal,
            status: status,
            room: roomLocal,
        });

        const getBirthday = async (startStr, endStr) => {

            const response = await api.post('/user/birthday', {
                dateStart: startStr,
                dateEnd: endStr
            }, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            });

            let array = [];
            (response.data || []).map((value) => {
                array.push({
                    patient: value.id,
                    title: value.title,
                    start: value.birthday_date,
                    type: value.type,
                    allDay: true,
                    status: {
                        id: 0
                    },
                    type_schedule: {
                        id: 0
                    },
                    birthday: true
                })
            })

            setBirthdays(array);
        }

        getBirthday(startStr, endStr);

        setTimeout(() => {
            setStartDate(startStr);
            setEndDate(endStr);
        }, 1000)


    }, [professional, patientLocal]);

    const getExpedient = async (professional) => {
        const response = await api.get('/user/' + professional + "/expedients?format=true", {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        });

        if (response.status === 200) {
            setExpedients(response.data);
        }
    }

    const onSubmit = useCallback(async (data) => {
        const room = data.room?.map(value => value.value);

        setProfessional(data.professional.length === 0 ? [] : data.professional);
        setProfessionalLocal(data.professional.length === 0 ? [] : data.professional);
        setStatus(data.status === 0 ? [] : [data.status]);
        setPatient(data.patient.length === 0 ? [] : data.patient);
        setPatientLocal(data.patient.length === 0 ? [] : data.patient);
        setRoomLocal(room?.length === 0 ? [] : room);

        try {
            searchCalls({
                dateStart: startDate,
                dateEnd: endDate,
                professional: myScheduler ? [user.id] : data.professional.length === 0 ? [] : data.professional,
                patient: data.patient.length === 0 ? [] : data.patient,
                status: data.status === 0 ? [] : [data.status],
                room: room?.length === 0 ? [] : room,
            })

            if (data.professional.length === 1) {
                await getExpedient(data.professional[0])
            } else {
                setExpedients([]);
            }
            closeAny()
        } catch (err) {

        }
    }, [startDate, endDate]);


    const Filter = <Search  user={user} statusArray={statusArray} onSubmit={onSubmit} myScheduler={myScheduler}/>

    return (
        <NewCalendar
            isMobile={isMobile}
            datesSet={(args) => {
                    handleDateChange(args.start.toFormat('yyyy-MM-dd HH:mm:ss'), args.end.toFormat('yyyy-MM-dd HH:mm:ss'))
            }}
            eventClick={(event) => {
                checkPermission(user.permissions, "calls.show") && openCall("session", {
                    ...event,
                    id: event.id,
                    edit: true,
                    type: 1
                },"secondary");
            }}
            slotClick={(event) => {
                checkPermission(user.permissions, "calls.store") && openModal("atendimento", {
                    start_date: event.slot.toFormat('yyyy-MM-dd HH:mm:ss'),
                    replacement: event.outHours,
                    type: 1
                });
            }}
            openAny={() => openAny(Filter, 'Filtro', 'lg')}
            openModal={openModal}
            expedients={expedients}
            allDay={birthdays}
            allDayClick={(event) => {
                if (event.type === 1) {
                    return;
                }

                if (event.type === 3) {
                    history.push('/patient/' + event.patient);
                    return;
                }

            }}
            events={ myScheduler? events.filter(value => value.professional.id === user.id) : events}
            duration={auth.company.slotDuration ?? 30}
            startTime={startTime}
            endTime={endTime}
            loading={loading}
            notDayWeek={auth.company.not_day_week ? auth.company.not_day_week.split(',') : []}
        />
    );
}

const mapStateToProps = state => ({
    user: state.auth,
    statusArray: state.companies.status,
    events: state.calendar.itens,
    loading: state.calendar.loading,
    startDate: state.calendar.startDate,
    endDate: state.calendar.endDate,
    professional: state.calendar.professional,
    patient: state.calendar.patient
});

const mapDispatchToProps = dispatch => ({
    getProfessionals: (typeUser, page, perPage) => dispatch(getProfessionals(typeUser, page, perPage)),
    openModal: (modal, payload) => dispatch(openModal(modal, payload)),
    getStatus: () => dispatch(getStatus()),
    searchCalls: (data) => dispatch(searchCalls(data)),
    setStartDate: (startDate) => dispatch(setStartDate(startDate)),
    setEndDate: (endDate) => dispatch(setEndDate(endDate)),
    setProfessional: (data) => dispatch(setProfessional(data)),
    setPatient: (data) => dispatch(setPatient(data)),
    openAny: (children, title, size) => dispatch(openAny(children, title, size)),
    closeAny:() => dispatch(closeAny()),
});


export default connect(mapStateToProps, mapDispatchToProps)(React.memo(CalendarViewV2));
