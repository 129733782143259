import React from "react";
import {useSelector} from "react-redux";
import ContentLoader from "react-content-loader";
import {useForm} from "react-hook-form";

function ConfirmDestroyModal({modalPayload,destroyCall,closeModal}) {

    const data = useSelector(state => state.calendar.item)[modalPayload.uuid];

    const loadingCreate = useSelector(state => state.calendar.loadingCreate);
    const { handleSubmit} = useForm({
        defaultValues: data
    });

    const onSubmit = (request, event) => {

        data['type'] = modalPayload.type
        if(event.nativeEvent.submitter.name === 'all'){
            data['all'] = true;
        }

        destroyCall(data)
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)}>

            {
                data.task > 0 ?
                    <div
                        className={"notice d-flex bg-light-danger rounded border-danger border border-dashed  p-6"}
                        style={{marginTop: 0}}
                    >
                        <span
                            className={"svg-icon svg-icon-2tx svg-icon-danger me-4"}
                        >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect>
                                <rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)"
                                      fill="currentColor"></rect>
                                <rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)"
                                      fill="currentColor"></rect>
                            </svg>
                        </span>
                        <div className="d-flex flex-stack flex-grow-1 ">
                            <div className=" fw-semibold">
                                <h4 className="text-gray-900 fw-bold">Atenção</h4>
                                <div className="fs-6 text-gray-700 ">
                                    Este atendimento faz parte de um cadastro recorrente, isto
                                    é, que se repete com alguma frequência previamente determinada. Se você optar por <b>'Excluir
                                    também os próximos'</b>, todos os atendimentos recorrentes desse horário e terapeuta serão
                                    excluídos.
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <></>
            }
            {
                data.task > 0 ?
                    <div  style={{marginTop: 10,display:"flex",flexDirection:"row",gap:10}}>
                        <div style={{flex:1}}>
                            {
                                loadingCreate ?
                                    <ContentLoader backgroundColor="#c7c6c6" viewBox="0 0 380 40">
                                        <rect x="0" y="0" rx="4" ry="4" width="380" height="30"/>
                                    </ContentLoader>
                                    :
                                    <button
                                        className={"btn btn-sm btn-primary btn-block "}
                                        style={{width: '100%'}}
                                        name={"one"}
                                    >
                                        Excluir somente esse
                                    </button>
                            }
                        </div>
                        <div style={{flex:1}}>
                            {
                                loadingCreate ?
                                    <ContentLoader backgroundColor="#c7c6c6" viewBox="0 0 380 40">
                                        <rect x="0" y="0" rx="4" ry="4" width="380" height="30"/>
                                    </ContentLoader>
                                    :
                                    <button
                                        className={"btn btn-sm btn-secondary btn-block "}
                                        style={{width: '100%'}}
                                        name={"all"}
                                    >
                                        Excluir também os próximos
                                    </button>
                            }
                        </div>
                    </div>
                    :
                    <div  style={{marginTop: 10,display:"flex",flexDirection:"row",gap:10}}>
                        <div style={{flex:1}}>
                            <button
                                className={"btn btn-sm btn-danger"}
                                style={{width: '100%'}}
                                name={"one"}
                                type="submit"
                            >
                                Sim
                            </button>
                        </div>
                        <div style={{flex:1}}>
                            <button
                                className={"btn btn-sm btn-primary"}
                                style={{width: '100%'}}
                                onClick={() => closeModal()}
                                type="button"
                            >
                                Não
                            </button>
                        </div>
                    </div>
            }
        </form>
    );
}

export default ConfirmDestroyModal;