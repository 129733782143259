import React, { useEffect } from "react";
import { useForm,Controller } from "react-hook-form";
import { Field } from "../Field";
import { Button } from "../Button";


function ExpedientsModal({loading,data,store,update}) {    

    const {control, handleSubmit, formState: { errors } } = useForm({
        defaultValues:{
            day_week:data ? data.day_week : null,
            hour_open: data ? data.hour_open : null,
            hour_close: data ? data.hour_close : null,
            user: data ? data.user : null
        }
    });

    useEffect(()=>{
   
    },[])   

    const onSubmit = (request) =>{
        if(data && data.id){
            update(data.id,request);
        }else{
     
            store(request);
        }        
    }

    const week = [
        {value:0,label:"Domingo"},
        {value:1,label:"Segunda-feira"},
        {value:2,label:"Terça-feira"},
        {value:3,label:"Quarta-feira"},
        {value:4,label:"Quinta-feira"},
        {value:5,label:"Sexta-feira"},
        {value:6,label:"Sábado"}
    ]

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-lg-4" >
                    <label>Dia</label>
                    <Controller
                        name={"day_week"}
                        control={control}
                        rules={{ required: true }}
                        render={({ field : { onChange, value }}) => <Field type="select" options={week} value={value} onChange={(value) => {onChange(value);} } />}
                    />
                    {errors.day_week && <p style={{color:'red',marginTop:10}}>Necessário preencher o dia da semana.</p>}
                </div>     
                <div className="col-lg-4" >
                    <label>Início</label>
                    <Controller
                        name={"hour_open"}
                        control={control}
                        rules={{ required: true }}
                        render={({ field : { onChange, value }}) => <Field type="time" value={value} onChange={(value) => {onChange(value);} } />}
                    />
                    {errors.hour_open && <p style={{color:'red',marginTop:10}}>Necessário preencher o horário de início.</p>}
                </div>     
                <div className="col-lg-4" >
                    <label>Fim</label>
                    <Controller
                        name={"hour_close"}
                        control={control}
                        rules={{ required: true }}
                        render={({ field : { onChange, value }}) => <Field type="time" value={value} onChange={(value) => {onChange(value);} } />}
                    />
                    {errors.hour_close && <p style={{color:'red',marginTop:10}}>Necessário preencher o horário de fim.</p>}
                </div>           
            </div>
            <div className="row" style={{textAlign:'right',marginTop:20}}>
                <div className="col-md-9"></div>
                <div className="col-md-3">
                    <Button loading={loading} text="Salvar" type="submit" />   
                </div>   
            </div>
        </form>
    );
}

export default ExpedientsModal