import { put, takeEvery, select,debounce } from 'redux-saga/effects';
import api from '../../api';
import toast from 'react-hot-toast';
import { push } from 'connected-react-router';
import {
    addressFail,
    addressFailRequest,
    addressStart,
    addressStartRequest,
    addressSuccess,
    alterAddress,
    destroyAddressFail,
    destroyAddressStart,
    insertAddress,
    patientSuccess,
    removeAddress,
    setPatient,
    patientFailRequest,
    avaliationSuccess,
    evolutionSuccess,
    evolutionLoading,
    avaliationLoading,
    responsibleLoading,
    responsibleSuccess,
    setTypeResponsible,
    setTypeAddress,
    patientloading,
    patientSuccessAutocomplete,
    patientloadingAutocomplete,
    alterPatient,
    patientPrivateSuccess,
    returnSuccess,
    returnLoading,
    anamneseLoading,
    anamneseSuccess,
    patientClear,
    countReturn,
    countAnamnese,
    countEvolution,
    countAvaliation,
    patientPrivateLoading,
    patientPrivateStatusSuccess,
    patientPrivateLoadingAction,
    patientTasksSuccess,
    patientTasksLoading,
    searchLoading, therapeuticLoading, therapeuticSuccess
} from '../actions/patientsActions';
import { closeModal } from '../actions/uiActions';
import { messenge } from './utils';
import {calendarClear, searchCalls} from '../actions/calendarActions';
import {getCalendar} from "./calendarSaga";

export const getAuthToken = (state) => state.auth.token;
export const getPatientSearch = (state) => state.patients.patientSearch;

function* getPatient(action){
    try{
        yield put(patientClear());
        yield put(calendarClear())
        yield put(patientloading(true));

        const response = yield  api.get('/user/'+action.id,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield put(setPatient(response.data)); // selecionando o paciente
            yield getPatientPrivate(action);
        }
    } catch (e) {
        console.error(e);
        yield put(patientloading(false));
    }
}

function* getPatients(action){

    try{
        let patient = yield select(getPatientSearch);

        yield put(patientSuccess([],0));
        yield put(patientloading(true));

        const response = yield  api.post('/user/search/type/'+action.typeUser+"?page="+action.page+"&pageSize="+action.perPage,{name:patient},{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),

            }
        });

        if(response.status === 200){

            yield put(patientSuccess(response.data.data,response.data.total));
        }
    } catch (e) {
        console.error(e)
        yield put(patientloading(false));
    }
}

function* getPatientPrivate(action){
    try{
        yield put(patientPrivateLoading(true));
        const response = yield  api.get('/user/'+action.id+'/private',{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield put(patientPrivateSuccess(response.data));
            yield put(patientPrivateLoading(false));
        }
    } catch (e) {
        console.error(e)
        yield put(patientPrivateLoading(false));
    }
}

function* getPatientPrivateStatus(){

    try{
        const response = yield  api.get('/userprivatestatus',{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield put(patientPrivateStatusSuccess(response.data))
        }
    } catch (e) {
        console.error(e);
    }
}

function* destroyPatientPrivate(action){

    try{
        let calendar = yield select(getCalendar);
        let response = api.delete('/userprivate/'+action.data.id,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        toast.promise(response,{
            loading: 'Deletando...',
            success: (success) => {
                return success.data.message
            },
            error: (error) => {
                return error.response.data.message
            }
        });

        response = yield response;

        if(response.status === 200){
            yield getPatientPrivate({id:action.data.patient})

            if(calendar.startDate && calendar.endDate && [3,5].includes(action.data.status)){
                yield put(searchCalls({patient:calendar.patient,professional:calendar.professional,dateStart:calendar.startDate,dateEnd:calendar.endDate}));
            }

        }
    } catch (e) {
        console.error(e);
    }
}

function* updatePatientPrivate(action){

    try{
        let calendar = yield select(getCalendar);
        let response = api.put('/userprivate/'+action.id,action.data,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        toast.promise(response,{
            loading: 'Atualizando...',
            success: (success) => {
                return success.data.message
            },
            error: (error) => {
                return error.response.data.message
            }
        });

        response = yield response;
        if(response.status === 200){
            yield getPatientPrivate({id:action.data.patient});

            if(calendar.startDate && calendar.endDate && [3,5].includes(action.data.status)){
                yield put(searchCalls({patient:calendar.patient,professional:calendar.professional,dateStart:calendar.startDate,dateEnd:calendar.endDate}));
            }
        }
    } catch (e) {
        console.error(e);
        yield put(patientPrivateLoadingAction(false))
    }
}

function* searchPatient(action){
    yield getPatients(action);
}

function* autocompletePatient(action){

    try{
        yield put(patientloadingAutocomplete(true));
        const response = yield  api.post('/user/search/type/'+action.typeUser+"?page="+action.page+"&pageSize="+action.perPage,{name:action.name},{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            if(response.data.data.length === 0){
                yield messenge({message:'Sem resultados para a pesquisa.'},'warning')
            }
            yield put(patientSuccessAutocomplete(response.data.data));
        }
    } catch (e) {
        yield put(patientloadingAutocomplete(false));
        console.error(e);
    }
}

function* update(action){
    try{

        let response = api.put('/user/'+action.id,action.data,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        toast.promise(response,{
            loading: 'Atualizando...',
            success: (success) => {
                return success.data.message
            },
            error: (error) => {
                return error.response.data.message
            }
        });

        response = yield response;

        if(response.status === 200){

            yield put(patientFailRequest());

            if(action.typeModel === 'responsible'){
                yield getResponsible({id:action.data.patient}); // buscando os responsáveis pelo paciente.
            }else{
                yield put(alterPatient(response.data.user))
            }
        }
    } catch (e) {
        console.error(e)

        yield put(patientFailRequest());
    }
}

function* store(action){
    try{

        let response = api.post('/user',action.data,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        toast.promise(response,{
            loading: 'Cadastrando...',
            success: (success) => {
                return success.data.message
            },
            error: (error) => {
                return error.response.data.message
            }
        });

        response = yield response;
        if(response.status === 200){
            yield put(closeModal());

            if(action.typeModel === 'responsible'){
                yield getResponsible({id:action.data.patient}); // buscando os responsáveis pelo paciente.
            }else{
                yield put(push('/'+action.typeModel+'/'+response.data.data.id));
            }
        }
    } catch (e) {
        console.error(e)
        yield put(patientFailRequest());
    }
}

function* destroy(action){
    try{

        let response = api.delete('/user/'+action.id,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        toast.promise(response,{
            loading: 'Deletando...',
            success: (success) => {
                return success.data.message
            },
            error: (error) => {
                return error.response.data.message
            }
        });

        response = yield response;
        if(response.status === 200){
            if(action.typeModel === 'responsible'){
                yield getResponsible({id:action.patient}); // buscando os responsáveis pelo paciente.
            }

            if(action.typeModel === 'patient'){
                yield put(push('/patients'));
            }
        }
    } catch (e) {
        yield messenge(e.response.data,'warning')
    }
}

function* uploadPhoto(action){
    try{
        let data = new FormData();
        data.append('photo', action.data);

        let response = api.post('/user/'+action.id+"/photo",data,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        toast.promise(response,{
            loading: 'Atualizando a foto.',
            success: (success) => {
                return success.data.message
            },
            error: (error) => {
                return error.response.data.message
            }
        });

        response = yield response;

        if(response.status === 200){
            yield put(alterPatient({img:response.data.url}))
        }
    } catch (e) {
        console.error(e);
        yield messenge(e.response.data,'warning')
    }
}

function* deletePhoto(action){

    try{
        let response = api.delete('/user/'+action.id+"/photo",{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });


        toast.promise(response,{
            loading: 'Deletando a foto.',
            success: (success) => {
                return success.data.message
            },
            error: (error) => {
                return error.response.data.message
            }
        });

        response = yield response;
        if(response.status === 200){
            yield put(alterPatient({img:''}))
        }
    } catch (e) {
        console.error(e)
        yield messenge(e.response.data,'warning');
    }
}

function* getAddress(action){

    try{
        yield put(addressStart());
        const response = yield  api.get('/address/list/'+action.id,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield put(addressSuccess(response.data));
        }else{
            yield put(addressFail());
        }

    } catch (e) {
        console.error(e)
        yield put(addressFail());
    }
}

function* destroyAddress(action){

    try{
        yield put(destroyAddressStart());
        let response = api.delete('/address/'+action.id,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        toast.promise(response,{
            loading: 'Deletando o endereço.',
            success: (success) => {
                return success.data.message
            },
            error: (error) => {
                return error.response.data.message
            }
        });

        response = yield response;
        if(response.status === 200){
            yield put(removeAddress(action.id));
        }else{
            yield put(destroyAddressFail());
        }
    } catch (e) {
        console.error(e);
        yield put(destroyAddressFail());
    }
}

function* storeAddress(action){

    try{
        yield put(addressStartRequest())
        let response = api.post('/address',action.data,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        toast.promise(response,{
            loading: 'Cadastrando o endereço.',
            success: (success) => {
                return success.data.message
            },
            error: (error) => {
                return error.response.data.message
            }
        });

        response = yield response;

        if(response.status === 200){
            yield put(insertAddress(response.data.address));
            yield put(closeModal())
        }else{
          yield put(addressFailRequest())
        }
    } catch (e) {
        console.error(e)
        yield put(addressFailRequest())
    }
}

function* updateAddress(action){
    try{

        let response = api.put('/address/'+action.id,action.data,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        toast.promise(response,{
            loading: 'Atualizando o endereço.',
            success: (success) => {
                return success.data.message
            },
            error: (error) => {
                return error.response.data.message
            }
        });

        response = yield response;

        if(response.status === 200){
            yield put(alterAddress(action.id,action.data));
        }else{
            yield put(addressFailRequest())
        }
    } catch (e) {
        console.error(e)
        yield put(addressFailRequest())
    }
}

function* getForCep(action){
    try{
        let response = api.get('https://viacep.com.br/ws/'+action.cep+"/json",{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        toast.promise(response,{
            loading: 'Buscando o endereço.',
            success: (success) => {
                if(success.data.uf) action.setValue('state',success.data.uf)
                if(success.data.localidade) action.setValue('city',success.data.localidade)
                if(success.data.bairro) action.setValue('neighborhood',success.data.bairro)
                if(success.data.logradouro) action.setValue('street',success.data.logradouro)     ;

                return 'O endereço foi encontrado.'
            },
            error: (error) => {

                return 'O endereço não foi encontrado.'
            }
        });

    } catch (e) {
        console.error(e)
        yield put(searchLoading(false))
    }
}

function* getAvaliation(action){
    try{
        yield put(avaliationLoading(true))
        const response = yield  api.get('/patient/'+action.id+"/calls/typeSchedule/1?page="+action.page+"&pageSize="+action.perPage,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield put(avaliationSuccess(response.data.data,response.data.total))
        }else{
            yield put(avaliationLoading(false))
        }
    } catch (e) {
        console.error(e)
        yield put(avaliationLoading(false))
    }
}

function* getCountAvaliation(action){
    try{
        const response = yield  api.get('/patient/'+action.id+"/calls/typeSchedule/1/count",{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield put(countAvaliation(response.data.count))
        }
    } catch (e) {
        console.error(e);
    }
}

function* getReturn(action){
    try{

        yield put(returnLoading(true))
        const response = yield  api.get('/patient/'+action.id+"/calls/typeSchedule/4?page="+action.page+"&pageSize="+action.perPage,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield put(returnSuccess(response.data.data,response.data.total))
        }else{
            yield put(returnLoading(false))
        }
    } catch (e) {
        console.error(e)
        yield put(returnLoading(false))
    }
}

function* getCountReturn(action){
    try{
        const response = yield  api.get('/patient/'+action.id+"/calls/typeSchedule/4/count",{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield put(countReturn(response.data.count))
        }
    } catch (e) {
        console.error(e);
    }
}

function* getTherapeutic(action){
    try{
        yield put(therapeuticLoading(true));

        const response = yield  api.post('/call/list', action.data, {
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield put(therapeuticSuccess(response.data))
        }
    } catch (e) {
        console.error(e)
    }finally {
        yield put(therapeuticLoading(false));
    }
}

function* getCountAnamnese(action){
    try{
        const response = yield  api.get('/patient/'+action.id+"/calls/typeSchedule/3/count",{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield put(countAnamnese(response.data.count))
        }
    } catch (e) {
        console.error(e);
    }
}

function* getEvolution(action){
    try{

        yield put(evolutionLoading(true))
        const response = yield  api.post('/call/list', action.data, {
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield put(evolutionSuccess(response.data))
        }

        yield put(evolutionLoading(false));
    } catch (e) {
        console.error(e)
        yield put(evolutionLoading(false));
    }
}

function* getAnamnese(action){
    try{

        yield put(anamneseLoading(true))
        const response = yield  api.post('/call/list', action.data, {
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield put(anamneseSuccess(response.data))
        }
    } catch (e) {
        console.error(e)
    }finally {
        yield put(anamneseLoading(false))
    }
}

function* getCountEvolution(action){
    try{
        const response = yield  api.get('/patient/'+action.id+"/calls/typeSchedule/2/count",{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield put(countEvolution(response.data.count))
        }
    } catch (e) {
        console.error(e);
    }
}

function* getResponsible(action){
    try{
        yield put(responsibleSuccess([]))
        yield put(responsibleLoading(true))
        const response = yield  api.get('/user/'+action.id+"/responsible",{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield put(responsibleSuccess(response.data))
        }else{
            yield put(responsibleLoading(false))
        }
    } catch (e) {
        console.error(e)
        yield put(responsibleLoading(false))
    }
}

function* getTypeResponsible(){
    try{
        const response = yield  api.get('/user/typeResponsabile',{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield put(setTypeResponsible(response.data))
        }
    } catch (e) {
        console.error(e)
    }
}

function* getTypeAddress(){
    try{
        const response = yield  api.get('/user/typeAddress',{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield put(setTypeAddress(response.data))
        }
    } catch (e) {
        console.error(e)
    }
}

function* getTasks(action)
{
    try{
        yield put(patientTasksLoading(true))
        const response = yield  api.get('/patient/'+action.id+"/tasks",{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield put(patientTasksSuccess(response.data))
            yield put(patientTasksLoading(false))
        }
    } catch (e) {
        console.error(e);
        yield put(patientTasksLoading(false))
    }
}

function* destroyTasks(action)
{

    try{

        const response = yield  api.delete('/tasks/'+action.data.id,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield getTasks({id:action.data.patient})
        }
    } catch (e) {
        console.error(e);

    }
}

export function* patientsSaga() {
    yield takeEvery('GET_PATIENTS', getPatients);
    yield takeEvery('GET_PATIENT', getPatient);
    yield debounce(1000,'AUTOCOMPLETE_PATIENT', autocompletePatient);
    yield debounce(1000,'SEARCH_PATIENT', searchPatient);

    yield takeEvery('UPLOAD_FOTO_PATIENT',uploadPhoto);
    yield takeEvery('DELETE_FOTO_PATIENT',deletePhoto);

    yield takeEvery('UPDATE_PATIENT', update);
    yield takeEvery('STORE_PATIENT', store);
    yield takeEvery('DESTROY_PATIENT', destroy);

    yield takeEvery('GET_ADDRESS', getAddress);
    yield takeEvery('GET_TYPE_ADDRESS', getTypeAddress);
    yield takeEvery('DESTROY_ADDRESS', destroyAddress);
    yield takeEvery('STORE_ADDRESS', storeAddress);
    yield takeEvery('UPDATE_ADDRESS', updateAddress);
    yield takeEvery('GET_FOR_CEP', getForCep);

    yield takeEvery('GET_RESPONSIBLE', getResponsible);
    yield takeEvery('GET_TYPE_RESPONSIBLE', getTypeResponsible);

    yield takeEvery('GET_AVALIATION',getAvaliation);
    yield takeEvery('GET_COUNT_AVALIATION', getCountAvaliation);

    yield takeEvery('GET_EVOLUTION',getEvolution);
    yield takeEvery('GET_COUNT_EVOLUTION', getCountEvolution);

    yield takeEvery('GET_ANAMNESE', getAnamnese);
    yield takeEvery('GET_THERAPEUTIC',getTherapeutic);
    yield takeEvery('GET_COUNT_ANAMNESE', getCountAnamnese);

    yield takeEvery('GET_RETURN',getReturn);
    yield takeEvery('GET_COUNT_RETURN', getCountReturn);

    yield takeEvery('GET_PATIENT_PRIVATE',getPatientPrivate);
    yield takeEvery('GET_USER_PRIVATE_STATUS', getPatientPrivateStatus);
    yield takeEvery('DESTROY_PATIENT_PRIVATE',destroyPatientPrivate);
    yield takeEvery('UPDATE_PATIENT_PRIVATE',updatePatientPrivate);
    yield takeEvery('GET_PATIENT_TASKS',getTasks);
    yield takeEvery('DESTROY_PATIENT_TASKS',destroyTasks);
}
