import {formatHourStartAndEnd, getName} from "../../../utils";
import {DateTime} from "luxon";
import {BadgeTypeScheduler} from "../../BadgeTypeScheduler";
import React from "react";

const Call = ({value,openCall}) => {

    return(
        <div className="d-flex align-items-center mb-6">
            <span className="bullet bullet-vertical d-flex align-items-center min-h-100px mh-100 me-4 " style={{background:value.status.color,height:110}}></span>
            <div className="flex-grow-1 me-5">
                <div className="text-gray-800 fw-semibold fs-2">
                    {getName(value.patient.name)}
                </div>
                <div className="text-gray-400 fw-semibold fs-7">
                    {DateTime.fromSQL(value.start_date).setLocale('pt-br').toFormat(' dd \'de\' MMMM \'de\' yyyy')} {formatHourStartAndEnd(DateTime.fromSQL(value.start_date), DateTime.fromSQL(value.end_date))}
                </div>
                <div className="text-gray-700 fw-semibold fs-6">
                    {value.treatment?.specialization?.profession?.description} {value.treatment?.specialization && value.treatment?.specialization?.description}
                </div>
                <div className="text-gray-700 fw-semibold fs-6">
                    <div
                        style={{
                            display: "flex",
                            flex: 1,
                            padding:"5px 0",
                            flexWrap: "nowrap",
                            alignItems: "flex-start",
                            flexDirection: "row",
                            gap: 3
                        }}
                    >
                        <BadgeTypeScheduler data={value.type_schedule}/>
                        {value.type_address === 4 ? <div className="badge badge-info">Teleatendimento</div> : <></>}
                        {value.replacement ? <div className="badge badge-light-warning">Reposição</div> : <></>}
                        {value.rescheduled ? <div className="badge badge-primary" style={{backgroundColor: '#ffc107'}}>Remarcado</div> : <></>}
                    </div>
                </div>

                <div className="text-gray-400 fw-semibold fs-7">
                    Profissional
                    <span
                        className="text-primary fw-semibold"
                        style={{marginLeft: 3}}
                    >
                        {getName(value.professional.name)}
                    </span>
                </div>
            </div>
            <a
                className="btn btn-sm btn-light"
                onClick={() => openCall('session',value,"secondary")}
            >
                Visualizar
            </a>
        </div>
    )
}


export default Call;