import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import api from "../../api";
import ContentLoader from "react-content-loader";
import {formatDate} from "../../utils";
import {DateTime} from "luxon";

function History({value, group, field}) {
    const [history, setHistory] = useState([])
    const [loading, setLoading] = useState(false)

    const token = useSelector(state => state.auth.token);

    useEffect(()=>{
        async function getHistory(){
            setLoading(true)
            const response = await api.get('/groups/history/group/'+group+'/link/'+value+'/field/'+field,{
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            });

            if(response.status === 200){

                setHistory(response.data)
            }
            setLoading(false)
        }

        getHistory();
    },[group,value,field]);


    if(loading){
        return (
            <>
                <ContentLoader viewBox="0 0 380 15">
                    <rect x="0" y="0" rx="4" ry="4" width="150" height="10"/>
                </ContentLoader>
                <ContentLoader viewBox="0 0 380 15">
                    <rect x="0" y="0" rx="4" ry="4" width="100" height="10"/>
                </ContentLoader>
                <ContentLoader viewBox="0 0 380 15">
                    <rect x="0" y="0" rx="4" ry="4" width="100" height="10"/>
                </ContentLoader>

                <hr style={{background: '#c1c1c1', marginTop: 30,marginBottom:30}}/>
                <ContentLoader viewBox="0 0 380 15">
                    <rect x="0" y="0" rx="4" ry="4" width="150" height="10"/>
                </ContentLoader>
                <ContentLoader viewBox="0 0 380 15">
                    <rect x="0" y="0" rx="4" ry="4" width="100" height="10"/>
                </ContentLoader>
                <ContentLoader viewBox="0 0 380 15">
                    <rect x="0" y="0" rx="4" ry="4" width="100" height="10"/>
                </ContentLoader>

            </>
        )

    }

    return (
        <div >
            {
                history?.map((value,index) =>
                    <div className="card" key={index} style={{marginBottom:10}}>
                        <div className="card-header" style={{display:'flex',flexDirection:'column',paddingTop:10}}>
                            <h5 className="card-title">
                                {formatDate(DateTime.fromSQL(value.created_at))}
                            </h5>
                            <p>{value.professional.name}</p>
                        </div>
                        <div className="card-body"  style={{textAlign:'justify'}}>
                            {value.value}
                        </div>

                    </div>)
            }
        </div>
    );
}

export default History