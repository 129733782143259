import React from 'react';
import {connect} from 'react-redux';
import {closeModal, openModal} from "../store/actions/uiActions";
import ModalController from "./ModalController";

function PrimaryController({modal,modalOpen,modalPayload,closeModal,openModal,calendarRef,isMobile}) {
    return (
        <ModalController zIndex={3000} modal={modal} modalOpen={modalOpen} modalPayload={modalPayload} closeModal={closeModal} openModal={openModal} calendarRef={calendarRef} isMobile={isMobile} />
    );
}

const mapStateToProps = state => ({
    modal: state.ui.modal,
    modalOpen: state.ui.modalOpen,
    modalPayload: state.ui.modalPayload,
})

const mapDispatchToProps = dispatch => ({
    closeModal: () => dispatch(closeModal()),
    openModal: () => dispatch(openModal()),
})

export default connect(mapStateToProps, mapDispatchToProps)(PrimaryController)
