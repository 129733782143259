import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {connect} from "react-redux";
import { Field } from "../Field";
import { Button } from "../Button";
import {storeDignostic, updateDignostic} from "../../store/actions/diagnosticActions";

function DiagnosticModal({data,store,update,getDiagnostic,diagnostics}) {

    const {control, handleSubmit, formState: { errors } } = useForm({
        defaultValues:{
            patient:data ? data.patient : null,
            diagnostic: data && data.diagnostic ? data.diagnostic.id : null
        }
    });

    useEffect(()=>{
        getDiagnostic()
    },[])   

    const onSubmit = (request) =>{
        if(data && data.id){
            update(request, data.id);
        }else{
            store(request);
        }        
    }
    let arrayDiagnostics = [];
    if(diagnostics && diagnostics.length > 0){
        diagnostics.forEach((value) => arrayDiagnostics.push({value:value.id,label:value.description}))
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-lg-12" >
                    <Controller
                        name="diagnostic"
                        control={control}
                        rules={{ required: true }}
                        render={({ field : { onChange, value }}) => <Field type="select" value={value} options={arrayDiagnostics} onChange={(value) => {onChange(value);} } />}
                    />
                    {errors.diagnostic && <p style={{color:'red',marginTop:10}}>Necessário preencher o diagnóstico.</p>}
                </div>           
            </div>
            <div className="row" style={{textAlign:'right',marginTop:20}}>
                <div className="col-md-9"></div>
                <div className="col-md-3">
                    <Button  text="Salvar" type="submit" />
                </div>   
            </div>
        </form>
    );
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  store:(data) => dispatch(storeDignostic(data)),
  update:(data,id) => dispatch(updateDignostic(data,id))
})

export default connect(mapStateToProps, mapDispatchToProps)(DiagnosticModal)
