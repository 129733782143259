import { useForm,Controller } from "react-hook-form";
import { Field } from "../Field";
import React from "react";
import { useEffect } from "react";
import { Button } from "../Button";
import {ddi} from "../../utils";

function UserModal({loading,store,auth,data,update,type,getProfiles,profiles}) {

    const {control, handleSubmit, formState: { errors } } = useForm({
        defaultValues:{
            name: data ? data.name : null,
            sex: data ? data.sex : null,
            birth_date: data ? data.birth_date : null,
            cellphone: data && data.cellphone ?  data.cellphone  : null,
            email:data ? data.email : null,
            cpf: data ? data.cpf : null,
            rg:data ? data.rg : null,
            profession:data ? data.profession : null,
            type: 1,
            role:data ? data.role : null,
            ddi: data ? data.ddi : '55',
            legal_name: data ? data.legal_name : '',
            cnpj: data ? data.cnpj : '',
        }
    });

    useEffect(()=>{      
        getProfiles();
    },[])

    const onSubmit = (request)=>{
        if(data && data.id){
            update(request,data.id,"user");
        }else{
            request['company'] =  auth.company ? auth.company.id : null;
            store(request,"user")
        }
    }

    let arrayProfiles = [];
    if(profiles && profiles.length > 0){
        profiles.forEach(value =>{
            arrayProfiles.push({value:value.id,label:value.name})
        });
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-lg-6">
                    <label className="fw-bold text-muted" style={{marginTop:10}}>Nome:</label>
                    <Controller
                        name="name"
                        control={control}
                        rules={{ required: true }}
                        render={({ field : { onChange, value }}) => <Field  type="text" value={value}  onChange={onChange}   />}
                    />
                      {errors.name && <p style={{color:'red',marginTop:10}}>Necessário preencher o nome.</p>}
                </div>
                <div className="col-lg-6">
                    <label className="fw-bold text-muted" style={{marginTop:10}}>Email:</label>
                    <Controller
                        name="email"
                        control={control}
                        disabled={data?.myData}
                        rules={{ required: true }}
                        render={({ field : { onChange, value }}) => <Field  disabled={data?.myData} type="email" value={value}  onChange={onChange}   />}
                    />
                    {errors.email && <p style={{color:'red',marginTop:10}}>Necessário preencher o email.</p>}
                </div>
                <div className="col-lg-3">
                    <label className="fw-bold text-muted" style={{marginTop:10}}>Sexo:</label>
                    <Controller
                        name="sex"
                        control={control}
                        render={({ field : { onChange, value }}) => <Field  type="select" options={[{'label':'Masculino',"value":1},{'label':'Feminino','value':2}]} value={value}  onChange={onChange}   />}
                    />
                </div>
                <div className="col-lg-3">
                    <label className="fw-bold text-muted" style={{marginTop:10}}>Data de Nascimento:</label>
                    <Controller
                        name="birth_date"
                        control={control}
                        rules={{ required: type === 'patient' }}
                        render={({ field : { onChange, value }}) => <Field type="dateNative" value={value}  onChange={onChange}   />}
                    />
                    {errors.birth_date && <p style={{color:'red',marginTop:10}}>Necessário preencher a data de nascimento.</p>}
                </div>
                <div className="col-lg-6">
                    <label className="fw-bold text-muted" style={{marginTop:10}}>Celular:</label>
                    <div style={{display:'flex'}}>
                        <div style={{marginRight:5,width:200}}>
                            <Controller
                                name="ddi"
                                control={control}
                                render={({ field : { onChange, value }}) => <Field options={ddi} type="select" value={value}  onChange={onChange}   />}
                            />
                        </div>
                        <Controller
                            name="cellphone"
                            control={control}
                            render={({ field : { onChange, value }}) => <Field  type="cellphone" value={value}  onChange={onChange}   />}
                        />
                    </div>
                </div>
                <div className="col-lg-3">
                    <label className="fw-bold text-muted" style={{marginTop:10}}>CPF:</label>
                    <Controller
                        name="cpf"
                        control={control}
                        render={({ field : { onChange, value }}) => <Field type="cpf" value={value}  onChange={onChange}   />}
                    />
                </div>
                <div className="col-lg-3">
                    <label className="fw-bold text-muted" style={{marginTop:10}}>RG:</label>
                    <Controller
                        name="rg"
                        control={control}
                        render={({ field : { onChange, value }}) => <Field type="text" value={value}  onChange={onChange}   />}
                    />
                </div>

                {
                    data && data.id ?
                        <></>
                    :
                        <div className="col-lg-3">
                            <label className="fw-bold text-muted" style={{marginTop:10}}>Senha:</label>
                            <Controller
                                name="password"
                                control={control}
                                rules={{ required: true }}
                                render={({ field : { onChange }}) => <Field type="password" onChange={onChange}   />}
                            />
                            {errors.email && <p style={{color:'red',marginTop:10}}>Necessário preencher o password.</p>}
                        </div>
                }
                {!data?.myData && <div className="col-lg-3">
                    <label className="fw-bold text-muted" style={{marginTop:10}}>Perfil:</label>
                    <Controller
                        name="role"
                        control={control}
                        render={({ field : { onChange, value }}) => <Field type="select" value={value} options={arrayProfiles} onChange={onChange} />}
                    />
                </div>}
                <div className="col-lg-12">
                    <br/>
                    <br/>
                    <h6>Informações como pessoa jurídica</h6>
                    <hr/>
                </div>
                <div className="col-lg-4">
                    <label className="fw-bold text-muted" style={{marginTop:10}}>CNPJ:</label>
                    <Controller
                        name="cnpj"
                        control={control}
                        render={({ field : { onChange, value }}) => <Field  type="cnpj" options={[{'label':'Masculino',"value":1},{'label':'Feminino','value':2}]} value={value}  onChange={onChange}   />}
                    />
                </div>
                <div className="col-lg-8">
                    <label className="fw-bold text-muted" style={{marginTop:10}}>Razão social:</label>
                    <Controller
                        name="legal_name"
                        control={control}
                        render={({ field : { onChange, value }}) => <Field  type="text" options={[{'label':'Masculino',"value":1},{'label':'Feminino','value':2}]} value={value}  onChange={onChange}   />}
                    />
                </div>
            </div>

            <div className="row" style={{textAlign:'right',marginTop:20}}>
                <div className="col-md-10"></div>
                <div className="col-md-2">
                    <Button loading={loading} text="Salvar" type="submit" />
                </div>
            </div>
        </form>
    );
}

export default UserModal
