import React, { useMemo} from "react";
import {DateTime} from "luxon";
import Lottie from "react-lottie";
import {checkPermission, formatDateNotHour, formatHourStartAndEnd, getName} from "../../utils";
import {Avatar} from "../Avatar";
import Call from "./Call";
import CallLoading from "./CallLoading";

export default function CallsToday({data, loading, openCall, isMobile,openAny,auth}) {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: require("../../assets/lottie/loading_blue.json"),
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    if (data && data.length > 0) {
        data = data.filter(at => at.status.id !== 10);
        data = data.filter(at => ![5,6,7].includes(at.type_schedule.id));
    }

    const Helper = useMemo(() => {
        return (
            <span
                style={{marginTop: 10, flex: 1, flexGrow: "inherit",cursor:'pointer'}}
                className="svg-icon svg-icon-muted svg-icon-1hx"
                title="Aqui o profissional verifica todos os atendimentos baseado no período informado no filtro."
                onClick={() => openAny(<div>Aqui o profissional verifica todos os atendimentos baseado no período informado no filtro.</div>,"Informação")}
            >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"  xmlns="http://www.w3.org/2000/svg">
                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
                    <path d="M11.276 13.654C11.276 13.2713 11.3367 12.9447 11.458 12.674C11.5887 12.394 11.738 12.1653 11.906 11.988C12.0833 11.8107 12.3167 11.61 12.606 11.386C12.942 11.1247 13.1893 10.896 13.348 10.7C13.5067 10.4947 13.586 10.2427 13.586 9.944C13.586 9.636 13.4833 9.356 13.278 9.104C13.082 8.84267 12.69 8.712 12.102 8.712C11.486 8.712 11.066 8.866 10.842 9.174C10.6273 9.482 10.52 9.82267 10.52 10.196L10.534 10.574H8.826C8.78867 10.3967 8.77 10.2333 8.77 10.084C8.77 9.552 8.90067 9.07133 9.162 8.642C9.42333 8.20333 9.81067 7.858 10.324 7.606C10.8467 7.354 11.4813 7.228 12.228 7.228C13.1987 7.228 13.9687 7.44733 14.538 7.886C15.1073 8.31533 15.392 8.92667 15.392 9.72C15.392 10.168 15.322 10.5507 15.182 10.868C15.042 11.1853 14.874 11.442 14.678 11.638C14.482 11.834 14.2253 12.0533 13.908 12.296C13.544 12.576 13.2733 12.8233 13.096 13.038C12.928 13.2527 12.844 13.528 12.844 13.864V14.326H11.276V13.654ZM11.192 15.222H12.928V17H11.192V15.222Z"  fill="currentColor"/>
                </svg>
            </span>
        )
    }, [openAny])

    if (isMobile) {
        return (
            <>
                <div style={{display:"flex",flexDirection:'row'}}>
                    <h4 style={{flex:1}}>Atendimentos: {Helper}</h4>
                    <div
                        className="badge badge-primary"
                        style={{
                            width: 'fit-content',
                            height:19
                        }}
                    >
                        {data?.length}
                    </div>
                </div>
                <div style={{display: "flex", flexDirection: 'row', overflowY: 'auto', paddingBottom: 15}}>
                    {
                        loading ?
                            [1,2,3,4,5].map(() => <CallLoading />)
                            :
                            data?.length > 0 ?
                                data?.map((value, index) => {
                                    return (
                                        <Call auth={auth} key={index} value={value} openCall={openCall}/>
                                    )
                                })
                                :
                               <div style={{textAlign: "center", padding: 30, paddingTop:60,width: '100%'}}>
                                   Não encontramos nenhum atendimento.
                               </div>
                    }
                </div>
            </>
        )
    }

    return (
        <div className="card " style={{height: 400}}>
            <div className="card-header " style={{minHeight:50}}>
                <div className="card-title align-items-start " style={{display: 'flex', flexDirection: 'row',width:'100%'}}>
                    <div style={{marginTop: 5,flex:1}}>
                        <span className="fw-bolder m-0">{(data && data.length > 1) ? data && data.length + " atendimentos " : data && data.length + " atendimento"}</span>
                    </div>
                    {Helper}
                </div>
            </div>
            <div className="card-body pt-5" style={{height: 300, overflow: "auto"}}>
                {
                    loading ?
                        <div style={{textAlign: "center", marginTop: 130}}>
                            <Lottie
                                options={defaultOptions}
                                height={60}
                                width={300}
                            />
                        </div>
                        :
                        data && data.length > 0 ?
                            <div className="table-responsive">
                                <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                    <thead>
                                    <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                                        <th style={{textAlign: "left"}}>Paciente</th>
                                        <th style={{textAlign: "left"}}>Tratamento</th>
                                        <th style={{textAlign: "left"}}>Profissional</th>
                                        <th style={{textAlign: "left"}}>Status</th>
                                        <th style={{textAlign: "left"}}>Data</th>
                                        <th style={{textAlign: "left"}}>Sala</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {

                                        data && data.filter((at) => at.status.id !== 10).map((value, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td style={{textAlign: "left"}}>
                                                        <button onClick={() => checkPermission(auth.permissions, "calls.show") && openCall("session", {
                                                            ...value,
                                                            type: 1,
                                                            id: value.id,
                                                            start: DateTime.fromSQL(value.start, {zone: "America/Sao_Paulo"}).toFormat("yyyy-MM-dd HH:mm:ss"),
                                                            end: DateTime.fromSQL(value.end, {zone: "America/Sao_Paulo"}).toFormat("yyyy-MM-dd HH:mm:ss"),
                                                            edit: true,
                                                        },"secondary")} className="btn btn-link" style={{
                                                            cursor: "pointer",
                                                            textAlign: "left",
                                                            padding: 0,
                                                            display: 'flex',
                                                            flexDirection: 'row'
                                                        }}>
                                                            <Avatar row={value.patient} w={"w-30px"} h={"h-30px"}/>
                                                            <div style={{marginTop: 5, marginLeft: 10}}
                                                                 title={value.patient.name}>{getName(value.patient.name)}</div>
                                                        </button>
                                                    </td>
                                                    <td style={{textAlign: "left"}}>
                                                        {value.treatment.specialization.profession && value.treatment.specialization.profession.description} {value.treatment.specialization.description}
                                                    </td>
                                                    <td style={{textAlign: "left"}}>
                                                        <div style={{display: 'flex', flexDirection: 'row'}}>
                                                            <Avatar row={value.professional} w={"w-30px"} h={"h-30px"}/>
                                                            <div style={{marginTop: 2, marginLeft: 10}}
                                                                 title={value.professional.name}>{getName(value.professional.name)}</div>
                                                        </div>
                                                    </td>
                                                    <td
                                                        style={{
                                                            display: "flex",
                                                            border: 0,
                                                            flex: 1,
                                                            placeContent: "center space-evenly",
                                                            flexWrap: "nowrap",
                                                            alignItems: "flex-start",
                                                            justifyContent: "space-around",
                                                            alignContent: "center",
                                                            flexDirection: "column",
                                                            height: 75,
                                                        }}
                                                    >
                                                        <div
                                                            className="badge badge-light-primary"
                                                            style={{
                                                                marginLeft: 10,
                                                                background: value.status.color,
                                                                color: "#fff",
                                                            }}
                                                        >
                                                            {value.status.description}
                                                        </div>
                                                        {value.replacement ? <div className="badge badge-light-warning"
                                                                                  style={{
                                                                                      marginLeft: 10,
                                                                                      marginTop: 10
                                                                                  }}>Reposição</div> : <></>}
                                                    </td>
                                                    <td style={{textAlign: "left"}}>
                                                        <span>{formatDateNotHour(DateTime.fromSQL(value.start))} {formatHourStartAndEnd(DateTime.fromSQL(value.start), DateTime.fromSQL(value.end))}</span>
                                                    </td>
                                                    <td style={{textAlign: "left"}}>
                                                        {value.room && value.room.description}
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    }
                                    </tbody>
                                </table>
                            </div>
                            :
                            <div style={{textAlign: "center", marginTop: 130}}>Não encontramos nenhum
                                atendimento. </div>
                }
            </div>
        </div>
    );
}
