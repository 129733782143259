import React, { useCallback, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { destroyAddress, getAddress } from "../../../store/actions/patientsActions";
import { openModal } from "../../../store/actions/uiActions";
import { checkPermission } from "../../../utils";
import ContentLoader from "react-content-loader";

function ListAddress({value,destroy,openModal,auth}){

    const [novo,setNovo] = useState();

    useEffect(() => {
      
        function click(ev) {

            if(ev.srcElement.closest('#address') == null){
                setNovo(false);
            }
        }

        if(novo){
            window.addEventListener('click',click,false);  
            return () => {
                window.removeEventListener('click',click,false);
            };
        }        
       
    }, [novo]);

    return(
        <tr key={value.id} style={{cursor:'pointer'}}>
            <td style={{textAlign:'left'}}> {value.street}, {value.number} <br/><small style={{fontSize:10}}>{value.neighborhood} - {value.city} - {value.state} - {value.zipcode}</small></td>
            <td style={{textAlign:'left'}}> {value.type.description}</td>
            <td>
                <button  className="btn btn-sm btn-icon  btn-active-color-primary" style={{float:'right'}} onClick={()=> setNovo(true)}>
                    <span className="svg-icon svg-icon-muted svg-icon-2hx">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="4" fill="currentColor"/>
                            <rect x="11" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor"/>
                            <rect x="15" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor"/>
                            <rect x="7" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor"/>
                        </svg>
                    </span>
                </button>
                <div className={"dropdown-menu "+(novo ? "show" : '')} style={{marginTop:40,right:33}} id="address" >
                    {checkPermission(auth.permissions,'address.update') && <button className="dropdown-item" style={{cursor:'pointer'}} onClick={() =>{ openModal('address',value);setNovo(false)}}>Editar</button>}
                    {checkPermission(auth.permissions,'address.destroy') && <button className="dropdown-item" style={{cursor:'pointer'}} onClick={() =>{ destroy(value.id);setNovo(false)}}>Excluir</button>}
                </div>    
            </td>     
        </tr>
    );
}

function Address({
    address,
    loadingAddress,
    openModal,
    destroyAddress,
    getAddress,
    patient,
    auth
}) {

    useEffect(() => {
        getAddress(patient);
    },[patient,getAddress])

    const destroy = useCallback((id) => {destroyAddress(id)}, [destroyAddress]);
    
    return (
        <div className="card mb-xl-5 mb-5">
            <div className="card-header cursor-pointer">					
                <div className="card-title align-items-start flex-column">
                    <span className="fw-bolder m-0">Endereços</span>           
                </div>
                <div className="card-toolbar">
                    {checkPermission(auth.permissions,'address.store') &&<button onClick={() => openModal('address')} className="btn btn-sm btn-light-primary" >
						Adicionar
                    </button> }
				</div>
            </div>
            <div className="card-body py-3">
                <div className='table-responsive'>
                    <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                        <thead>
                            <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                                <th style={{textAlign:'left'}}>Endereço</th>
                                <th>Tipo</th>
                                <th></th>
                            </tr>
                        </thead>
                        {
                            loadingAddress === true ?
                                <tbody>
                                {
                                    [1,2,3].map((value,index) =>{
                                        return(
                                            <tr key={index}>

                                                <td style={{textAlign:'left'}}>
                                                    <ContentLoader viewBox="0 0 380 35">
                                                        <rect x="0" y="17" rx="4" ry="4" width="200" height="40" />
                                                    </ContentLoader>
                                                </td>

                                                <td style={{textAlign:'left'}}>
                                                    <ContentLoader viewBox="0 0 380 50">
                                                        <rect x="0" y="17" rx="4" ry="4" width="200" height="40" />
                                                    </ContentLoader>
                                                </td>

                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                                :
                                <tbody>
                                    {
                                        address && address.length > 0 ?
                                            address.map((value) =>{
                                                return(
                                                   <ListAddress auth={auth} key={value.id} value={value} destroy={destroy} openModal={openModal} />
                                                )
                                            })
                                        :
                                            (<tr><td colSpan="2"><div style={{textAlign:'center',paddingTop:7}}>Não foi encontrado nenhum endereço.</div></td></tr>)                          
                                    }                        
                                </tbody>
                        }
                    </table>
                </div>
            </div>
        </div>    
    );
}

const mapStateToprops = state => ({
    address:state.patients.address,
    loadingAddress:state.patients.addressLoading,
    addressDestroyLoading:state.patients.addressDestroyLoading,
    auth:state.auth
});

const mapDispatchToprops = dispatch => ({
    openModal:(modal,payload) => dispatch(openModal(modal,payload)),
    destroyAddress:(id) => dispatch(destroyAddress(id)),
    getAddress:(id) => dispatch(getAddress(id))
});

export default connect(mapStateToprops,mapDispatchToprops)(Address);