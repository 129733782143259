import { useForm,Controller } from "react-hook-form";
import { Field } from "../Field";
import React, {  } from "react";
import { Button } from "../Button";

function AlterPasswordModal({loading,alterPassword,auth}) {

    const {control, handleSubmit, formState: { errors } } = useForm({
        defaultValues:{

        }
    });

    const onSubmit = (request)=>{
        alterPassword(auth.id,request.password,request.password_current,request.password_confirmation);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-lg-4">
                    <label className="fw-bold text-muted" style={{marginTop:10}}>Senha atual:</label>
                    <Controller
                        name="password_current"
                        control={control}
                        rules={{ required: true }}
                        render={({ field : { onChange, onBlur, value }}) => <Field type="password" value={value}  onChange={(value) => {onChange(value);} }  />}
                    />
                    {errors.password_current && <p style={{color:'red',marginTop:10}}>Necessário preencher a senha atual.</p>}
                </div>
                <div className="col-lg-4">
                    <label className="fw-bold text-muted" style={{marginTop:10}}>Nova senha:</label>
                    <Controller
                        name="password"
                        control={control}
                        rules={{ required: true }}
                        render={({ field : { onChange, onBlur, value }}) => <Field  type="password" value={value}  onChange={(value) => {onChange(value);} }  />}
                    />
                    {errors.password && <p style={{color:'red',marginTop:10}}>Necessário preencher a nova senha.</p>}
                </div>
                <div className="col-lg-4">
                    <label className="fw-bold text-muted" style={{marginTop:10}}>Confirme nova senha:</label>
                    <Controller
                        name="password_confirmation"
                        control={control}
                        rules={{ required: true }}
                        render={({ field : { onChange, onBlur, value }}) => <Field type="password" value={value}  onChange={(value) => {onChange(value);} }  />}
                    />
                    {errors.password_confirmation && <p style={{color:'red',marginTop:10}}>Necessário preencher a confirmação da nova senha.</p>}
                </div>
            </div>
            <div className="row" style={{textAlign:'right',marginTop:20}}>
                <div className="col-md-10">

                </div>
                <div className="col-md-2">
                    <Button loading={loading} text="Salvar" type="submit" />
                </div>   
            </div>
        </form>
    );
}

export default AlterPasswordModal