import React from 'react';
import {connect} from 'react-redux';
import {closeModalSecondary, openModalSecondary} from "../store/actions/uiActions";
import ModalController from "./ModalController";

function SecondaryController({modal,modalOpen,modalPayload,closeModal,openModal,calendarRef,isMobile}) {
    return (
        <ModalController zIndex={2000} modal={modal} modalOpen={modalOpen} modalPayload={modalPayload} closeModal={closeModal} openModal={openModal} calendarRef={calendarRef} isMobile={isMobile} />
    );
}

const mapStateToProps = state => ({
    modal: state.ui.modalSecondary,
    modalOpen: state.ui.modalOpenSecondary,
    modalPayload: state.ui.modalPayloadSecondary,
})

const mapDispatchToProps = dispatch => ({
    closeModal: () => dispatch(closeModalSecondary()),
    openModal: () => dispatch(openModalSecondary()),
})

export default connect(mapStateToProps, mapDispatchToProps)(SecondaryController)
