import Lottie from "react-lottie";
import {Doughnut} from "react-chartjs-2";
import {Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title} from "chart.js";
import React from "react";

ChartJS.register(ArcElement, Title, Tooltip, Legend, CategoryScale, LinearScale, BarElement);

export default function DoughnutStatus({data, loading, isMobile}) {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: require("../../assets/lottie/loading_blue.json"),
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    let labels = [];
    let aData = [];
    let color = [];

    data = data?.filter(at => ![5,6,7].includes(at.type_schedule.id));

    if (data && data.length > 0) {
        let groupsCalls = data.reduce((res, at) => {
            if (typeof res[at.status.id] === "undefined") res[at.status.id] = [];

            res[at.status.id].push(at);
            return res;
        }, {});

        for (const value of Object.values(groupsCalls)) {

            if (value[0].status.id === 10) continue;
            if (!labels.includes(value[0].status.description)) {
                labels.push(value[0].status.description);
            }
            aData.push(value.length);
            color.push(value[0].status.color);
        }
    }

    const items = {
        labels: labels,
        datasets: [
            {
                label: "# of Votes",
                data: aData,
                backgroundColor: color,
                borderColor: color,
                borderWidth: 0,
            },
        ],
        options: {
            legend: {
                position: 'right',
            }
        }
    };

    let options = {}

    if (isMobile) {
        options = {
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    position: 'right',
                },
            },
        };
    }

    return (
        <div
            className={isMobile ? "" : "card"}
            style={{height: isMobile ? 190 : 380, backgroundColor: '#fff', padding: isMobile ? "10px 10px" : 0}}
        >
            <div
                className={isMobile ? "" : "card-body"}
            >
                {
                    loading ?
                        <div style={{textAlign: "center", marginTop: isMobile ? 0 : 130,paddingTop:isMobile ? 50 : 0}}>
                            <Lottie
                                options={defaultOptions}
                                height={60}
                                width={320}
                            />
                        </div>
                        :
                        items && items.datasets[0].data.length > 0 ?
                            <div><Doughnut options={options} data={items} height={180} style={{height:170}} /></div>
                            :
                            <div style={{textAlign: "center", padding: 40, width: '100%',paddingTop:isMobile ? 70 : 150}}>Não encontramos nenhum
                                atendimento. </div>
                }
            </div>
        </div>
    );
}
