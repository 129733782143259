import {updateObject} from '../../utils';

const initialState = {
    itens: [],
    loading:false,
    loadingAction:false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'PROCEDURE_SUCCESS':
            return updateObject(state, {itens: action.itens,loading:false,loadingAction:false});
        case 'PROCEDURE_START':
            return updateObject(state, {loading: true});
        case 'PROCEDURE_FAIL':
            return updateObject(state, {loading: false,itens:[]});   
        case 'PROCEDURE_LOADING':
            return updateObject(state, {loadingAction: action.status});   
        case 'PROCEDURE_CREATE':{
            const newArray = [...state.itens];
            newArray.push(action.data);
            return updateObject(state, {itens:newArray});
        }
        case 'PROCEDURE_UPDATE':{
            const newArray = [...state.itens];
            const index = state.itens.findIndex((value) => value.id === action.id);

            newArray[index] = action.data

            return updateObject(state, {itens:newArray});
        }
        case 'PROCEDURE_DESTROY':{
            const newArray = [...state.itens];
            const index = state.itens.findIndex((value) => value.id === action.id);

            newArray.splice(index,1)

            return updateObject(state, {itens:newArray});
        }
        default:
            return state;
    }
}

export default reducer;
  