import React, {useEffect, useState, useMemo, useCallback, useRef} from 'react';
import {connect, useSelector} from "react-redux";
import Table from "../../components/Table";
import {Field} from "../../components/Field";
import api from "../../api";
import {Controller, useForm} from "react-hook-form";
import {closeAny, openAny} from "../../store/actions/uiActions";

import {Avatar} from "../../components/Avatar";
import {useHistory} from "react-router-dom";

const Filter = ({closeAny,control,handleSubmit,pageRef,status,loadCompanies,setCompanies,loadingRef,lastPageRef,description,plan}) => {

    const onSubmit = (data) => {
        pageRef.current = 1
        lastPageRef.current = 1
        loadingRef.current = false
        setCompanies([]);

        if(data.description) {
            description.current = data.description;
        }else{
            description.current = ''
        }

        status.current = data.status
        plan.current = data.plan

        closeAny()
        loadCompanies();

    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className='row'>
                <div className='col-md-3' style={{marginTop: 5}}>
                    <label className="fw-bold text-muted">Nome:</label>
                    <Controller
                        name="description"
                        control={control}
                        render={({field: {onChange, value}}) =>
                            <Field type="text" value={value} onChange={onChange}/>
                        }
                    />
                </div>
                <div className='col-md-2' style={{marginTop: 5}}>
                    <label className="fw-bold text-muted">Status:</label>
                    <Controller
                        name="status"
                        control={control}
                        render={({field: {onChange, value}}) =>
                            <Field type="select" options={[{label: 'Todos', value: -1}, {label: 'Ativo', value: 1}, {
                                label: 'Inativo',
                                value: 0
                            }]} value={value} onChange={onChange}/>
                        }
                    />
                </div>
                <div className='col-md-3' style={{marginTop: 5}}>
                    <label className="fw-bold text-muted">Plano:</label>
                    <Controller
                        name="plan"
                        control={control}
                        render={({field: {onChange, value}}) =>
                            <Field type="select" options={[{label: 'Todos', value: -1},{label: 'Teste', value: 1}, {label: 'Básico', value:2}, {
                                label: 'Plus',
                                value: 3
                            }]} value={value} onChange={onChange}/>
                        }
                    />
                </div>

                <div className="col-md-1" style={{marginTop: 25}}>
                    <button className='btn btn-success btn-sm' style={{width: '100%'}}>
                        <div style={{width: 18, margin: 'auto'}}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path
                                    d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352c79.5 0 144-64.5 144-144s-64.5-144-144-144S64 128.5 64 208s64.5 144 144 144z"
                                    fill="#fff"/>
                            </svg>
                        </div>
                    </button>
                </div>
            </div>
        </form>
    )
}

function Companies({isMobile,openAny,closeAny}){
    const pageRef = useRef(1);
    const loadingRef = useRef(false);
    const [companies,setCompanies] = useState([]);
    const token = useSelector(state => state.auth.token);
    const lastPageRef = useRef(1);
    const status = useRef(-1)
    const plan = useRef(-1)
    const description = useRef('');
    const history = useHistory();

    const {control, handleSubmit} = useForm({
        defaultValues: {
            status: -1,
            plan: -1,
            description: ''
        }
    });

    const fetchCompanies = useCallback(async () => {

        const response = await api.get(
            "/company/?page=" + pageRef.current + "&pageSize=10&plan="+plan.current+"&status="+status.current+"&description="+description.current,
             {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json'
                }
            });

        return response.data;
    }, [token]);

    const loadCompanies = useCallback(async () => {

        if (pageRef.current <= lastPageRef.current && !loadingRef.current) {
            loadingRef.current = true;
            const response = await fetchCompanies();
            lastPageRef.current = response.last_page
            setCompanies((prevUsers) => [...prevUsers, ...response.data]);
            pageRef.current = pageRef.current + 1;
            loadingRef.current = false;
        }
    }, [fetchCompanies]);

    const handleScroll = useCallback(() => {
        const scrollHeight = document.documentElement.scrollHeight;
        const scrollTop = window.scrollY;
        const clientHeight = window.innerHeight;

        if (scrollTop + clientHeight >= scrollHeight - 20) {
            loadCompanies();
        }
    }, [loadCompanies]);


    useEffect(() => {
        loadCompanies();
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll, loadCompanies]);

    const open = (value) => {
        history.push('/companies/'+value.id);
    }

    const plans = [
        "",
        "Teste",
        "Básico",
        "Plus"
    ]

    return (
        <div style={{marginTop: 10}}>
            <div style={{display: 'flex', marginBottom: 10, marginTop: 20}}>
                <h1 style={{flex: 1}}>Empresas</h1>
                <div>
                    {
                        isMobile && <div style={{cursor:'pointer',marginRight:10}} onClick={() => openAny(<Filter
                            status={status}
                            plan={plan}
                            description={description}
                            pageRef={pageRef}
                            lastPageRef={lastPageRef}
                            setCompanies={setCompanies}
                            loadingRef={loadingRef}
                            loadCompanies={loadCompanies}
                            control={control}
                            handleSubmit={handleSubmit}
                            closeAny={closeAny}
                        />,"Filtro", "lg")}>
                            <span className="svg-icon svg-icon-black ">
                                 <svg width="20" height="24" viewBox="0 0 20 20" fill="#000"
                                      xmlns="http://www.w3.org/2000/svg">
                                     <rect x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                                           transform="rotate(45 17.0365 15.1223)" fill="#000"/>
                                     <path
                                         d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                         fill="#000"/>
                                 </svg>
                            </span>
                        </div>
                    }
                </div>
            </div>
            {   !isMobile && <div style={{textAlign: 'left'}}>
                <div className="card" style={{marginBottom: 20}}>
                    <div className="card-header" style={{minHeight: 15}}>
                        <div className="card-title">
                            <div>
                                <div style={{flex: 1}}>Filtro</div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body" style={{paddingTop: 10}}>
                        <Filter
                            status={status}
                            plan={plan}
                            description={description}
                            pageRef={pageRef}
                            lastPageRef={lastPageRef}
                            setCompanies={setCompanies}
                            loadingRef={loadingRef}
                            loadCompanies={loadCompanies}
                            control={control}
                            handleSubmit={handleSubmit}
                            closeAny={closeAny}
                        />
                    </div>
                </div>
            </div>}

            {
                companies.map((value, index) => {
                    return (
                        <div className="card  mb-1 mb-xl-2" style={{cursor: 'pointer'}} key={index}
                             onClick={() => open(value)}>
                            <div className="card-body" style={{padding: 10}}>
                                <div style={{flexDirection: 'row', display: 'flex'}}>
                                    <div style={{flexDirection: 'column', display: 'flex'}}>
                                        <Avatar row={{img:value.logo}}/>
                                        {value.status === 1 ? <div className="badge badge-success" style={{
                                            marginTop: 5,
                                            width: 50,
                                            fontSize: 9
                                        }}>Ativo</div> : <div className="badge badge-danger" style={{
                                            marginTop: 5,
                                            width: 50,
                                            fontSize: 9
                                        }}>Inativo</div>
                                        }
                                    </div>
                                    <div style={{marginLeft: 10}}>
                                        <h6>{value.description}</h6>
                                        {value.email && <div><b>Email</b>: {value.email}</div>}
                                        {value.plan && <div><b>Plano</b>: {plans[value.plan]}</div>}

                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
            {loadingRef.current && pageRef.current < lastPageRef.current &&
                <p style={{textAlign: 'center', padding: 10}}>Carregando...</p>}
        </div>
    )
}


const mapStateToProps = state => ({
    auth: state.auth,

});

const mapDispatchToProps = dispatch => ({

    openAny:(children,title,size) => dispatch(openAny(children,title,size)),
    closeAny:() => dispatch(closeAny())
});

export default connect(mapStateToProps, mapDispatchToProps)(Companies);