import  React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import Treatment from '../../components/treatment/Treatment';
import { getTreatment, statusTreatment } from '../../store/actions/treatmentActions';
import { openModal } from '../../store/actions/uiActions';

function TreatmentController({auth, getTreatment,treatments,loading,openModal,statusTreatment}) {

    useEffect(() =>{
        getTreatment()
    },[getTreatment]);

    return (<Treatment auth={auth} itens={treatments} loading={loading} openModal={openModal} statusTreatment={statusTreatment} />);
}

const mapStateToprops = state => ({  
    auth: state.auth,
    treatments:state.treatment.itens,
    loading:state.treatment.loading
}); 

const mapDispatchToprops = dispatch => ({
    getTreatment:() => dispatch(getTreatment()),
    openModal:(modal,payload) => dispatch(openModal(modal,payload)),
    statusTreatment:(status,id) => dispatch(statusTreatment(status,id))
});

export default connect(mapStateToprops,mapDispatchToprops)(TreatmentController);