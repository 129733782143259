import React from "react";
import ContentLoader from "react-content-loader";


const CallLoading = ({}) => {
    return (
        <div
            className="card"
            style={{
                marginRight: 10,
                backgroundColor: '#fff',
                borderRadius: 10,
                minWidth: 300,
                height: 150,
                justifyContent: 'start',
                padding:20
            }}
        >
            <ContentLoader
                speed={2}
                width={400}
                height={160}
                viewBox="0 0 70% 150"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"

            >
                <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
                <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
                <rect x="0" y="56" rx="3" ry="3" width="210" height="6" />
                <rect x="0" y="72" rx="3" ry="3" width="180" height="6" />
                <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
                <circle cx="20" cy="20" r="20" />
            </ContentLoader>
        </div>
    )
}

export default CallLoading;