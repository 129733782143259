import React, { useEffect } from "react";
import isMobile from "is-mobile";

function Confirm({title,closeModal,open,onClick,buttons,typeNotification,textNotification}) {
    const  isMobileV =  isMobile({featureDetect:true,tablet:true});

    function scrollToTop() {

        window.scrollTo({
            top: 0
        });
    }

    useEffect(() =>{

        if(isMobileV){
            scrollToTop();
        }

        const close = (e) => {
            if(e.keyCode === 27){
                closeModal();
            }
        }

        window.addEventListener('keydown', close)

        return () =>{
            window.removeEventListener('keydown', close)

        }
    },[open,closeModal])

    return (
        <>
            {
                isMobileV ?
                    <div className={'card'} style={{
                        position: 'absolute',
                        zIndex: '88888',
                        top: 0,
                        left: 0,
                        backgroundColor:  '#fff',
                        width: '100%',
                        height: '100%',
                        borderRadius: 0,
                        display: open? 'block' : 'none'
                    }}>
                        <div className={'card-header'} style={{minHeight: 50, paddingTop: 5, paddingBottom: 5}}>
                            <h4 className={'card-title'} style={{marginLeft: '-10px'}}>
                                <a
                                    style={{marginRight: 20}}
                                    onClick={() => {
                                    closeModal()
                                }}
                                    className="btn btn-sm btn-icon btn-active-color-primary"
                                >
                                    <span className="svg-icon svg-icon-dark  svg-icon-2hx">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M11.2657 11.4343L15.45 7.25C15.8642 6.83579 15.8642 6.16421 15.45 5.75C15.0358 5.33579 14.3642 5.33579 13.95 5.75L8.40712 11.2929C8.01659 11.6834 8.01659 12.3166 8.40712 12.7071L13.95 18.25C14.3642 18.6642 15.0358 18.6642 15.45 18.25C15.8642 17.8358 15.8642 17.1642 15.45 16.75L11.2657 12.5657C10.9533 12.2533 10.9533 11.7467 11.2657 11.4343Z"
                                                fill="#000"/>
                                        </svg>
                                    </span>
                                </a>
                                {title}
                            </h4>
                        </div>
                        <div className={'card-body'} style={{overflowY: 'auto'}}>
                            {
                                typeNotification ?
                                    <div
                                        className={"notice d-flex bg-light-" + typeNotification + " rounded border-" + typeNotification + " border border-dashed  p-6"}
                                        style={{marginTop: 0}}>
                                        <span
                                            className={"svg-icon svg-icon-2tx svg-icon-" + typeNotification + " me-4"}
                                        >
                                            <svg
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10"  fill="currentColor"></rect>
                                                <rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="currentColor"></rect>
                                                <rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"></rect>
                                            </svg>
                                        </span>
                                        <div className="d-flex flex-stack flex-grow-1 ">
                                            <div className=" fw-semibold">
                                                <h4 className="text-gray-900 fw-bold">Atenção</h4>
                                                <div className="fs-6 text-gray-700 " style={{textAlign:'justify'}}>{textNotification}</div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <></>
                            }
                            <br/>
                            {
                                buttons && buttons.length > 0 ?
                                    <div style={{display:'flex',flexDirection:'row'}}>
                                        {buttons.map((value, index) => {

                                        return (
                                            <button
                                                name={value.target}
                                                key={index}
                                                type={value.type ? value.type : "button"}
                                                className={"btn btn-sm " + (value.class)}
                                                style={{marginRight: 5}}
                                                onClick={() => {
                                                    value.onClick && value.onClick();
                                                    closeModal()
                                                }}
                                            >
                                                {value.name}
                                            </button>
                                        )
                                    })}
                                    </div>
                                    :
                                    <div style={{display:'flex',flexDirection:'row'}}>
                                        <button
                                            type="button"
                                            style={{flex:1}}
                                            className="btn btn-primary btn-sm"
                                            onClick={() => {
                                                onClick && onClick();
                                                closeModal()
                                            }}>Sim
                                        </button>
                                        <button
                                            type="button"

                                            className="btn btn-light btn-sm"
                                            style={{marginLeft: 20,flex:1}}
                                            onClick={() => {
                                                closeModal()
                                            }}
                                        >
                                            Não
                                        </button>
                                    </div>
                            }
                        </div>
                    </div>
                    :
                    <div className={"modal fade show " + (open ? " modal-open" : "")}
                         style={{display: open ? 'block' : 'none'}}>
                        <div className={"modal-dialog modal-dialog-centered "}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h2 className="card-label fw-bolder fs-3 " style={{paddingLeft: 0}}>{title}</h2>
                                    <button onClick={() => {
                                        closeModal()
                                    }} className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal"
                                            type="button">
                                <span className="svg-icon svg-icon-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                         fill="none">
                                        <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                              transform="rotate(-45 6 17.3137)" fill="black"></rect>
                                        <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                              transform="rotate(45 7.41422 6)" fill="black"></rect>
                                    </svg>
                                </span>
                                    </button>
                                </div>
                                <div className="modal-body" style={{textAlign: 'left'}}>
                                    {
                                        typeNotification ?
                                            <div
                                                className={"notice d-flex bg-light-" + typeNotification + " rounded border-" + typeNotification + " border border-dashed  p-6"}
                                                style={{marginTop: 0}}>
                                        <span
                                            className={"svg-icon svg-icon-2tx svg-icon-" + typeNotification + " me-4"}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10"
                                                      fill="currentColor"></rect>
                                                <rect x="11" y="14" width="7" height="2" rx="1"
                                                      transform="rotate(-90 11 14)" fill="currentColor"></rect>
                                                <rect x="11" y="17" width="2" height="2" rx="1"
                                                      transform="rotate(-90 11 17)" fill="currentColor"></rect>
                                            </svg>
                                        </span>
                                                <div className="d-flex flex-stack flex-grow-1 ">
                                                    <div className=" fw-semibold">
                                                        <h4 className="text-gray-900 fw-bold">Atenção</h4>
                                                        <div className="fs-6 text-gray-700 ">{textNotification}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <></>
                                    }
                                    <br/>
                                    {
                                        buttons && buttons.length > 0 ?
                                            buttons.map((value, index) => {
                                                return (
                                                    <button name={value.target} key={index}
                                                            type={value.type ? value.type : "button"}
                                                            className={"btn btn-sm " + (value.class)}
                                                            style={{marginRight: 5}} onClick={() => {
                                                        value.onClick && value.onClick();
                                                        closeModal()
                                                    }}>{value.name}</button>
                                                )
                                            })
                                            :
                                            <div>
                                                <button type="button" className="btn btn-primary btn-sm"
                                                        onClick={() => {
                                                            onClick && onClick();
                                                            closeModal()
                                                        }}>Sim
                                                </button>
                                                <button type="button" className="btn btn-light btn-sm"
                                                        style={{marginLeft: 20}} onClick={() => {
                                                    closeModal()
                                                }}>Não
                                                </button>
                                            </div>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
            }

            {open && <div
                style={{
                    position: 'fixed',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: '#69696970',
                    zIndex: 333
                }}
            >
            </div>}
        </>

    );
}


export default Confirm;
