import {updateObject} from '../../utils';

const initialState = {
    items:[],
    loading: false,
    banks:[]
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'BANK_ACCOUNT_SUCCESS':
            return updateObject(state, {items: action.data});
        case 'BANK_SUCCESS':
            return updateObject(state, {banks: action.data});
        case 'BANK_ACCOUNT_LOADING':
            return updateObject(state, {loading: action.status});
        default:
            return state;
    }
}

export default reducer;
