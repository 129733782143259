import { put, takeEvery, select} from 'redux-saga/effects';
import api from '../../api';
import toast from "react-hot-toast";
import {closeModal} from "../actions/uiActions";
import {healthInsuranceLoading, healthInsuranceSuccess} from "../actions/healthInsuranceActions";

export const getAuthToken = (state) => state.auth.token;
export const getPatient = (state) => state.patients.item;

function* get(action){
    try{
        yield put(healthInsuranceLoading(true))
        const response = yield  api.get('/user/'+action.patient+"/healthInsurance",{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield put(healthInsuranceLoading(false));
            yield put(healthInsuranceSuccess(response.data));
        }
    } catch (e) {
        yield put(healthInsuranceLoading(false));
        console.error(e);
    }
}


function* update(action){

    let response = api.put('/userHealthInsurance/'+action.id, action.data, {
        headers: {
            'Authorization': 'Bearer ' + (yield select(getAuthToken)),
        }
    });

    toast.promise(response,
        {
            loading: 'Atualizando o convênio...',
            success: (success) => {
                return 'Convênio atualizado com sucesso.'
            },
            error: (error) => {
                return 'Não foi possível atualizar o convênio.'
            }
        }
    );

    response = yield response;
    if (response.status === 200) {
        yield get({patient:action.data.user});
    }
}

function* store(action){
    let patient = yield select(getPatient);

    let response = api.post('/userHealthInsurance', action.data, {
        headers: {
            'Authorization': 'Bearer ' + (yield select(getAuthToken)),
        }
    });

    toast.promise(response,
        {
            loading: 'Cadastrando o convênio...',
            success: (success) => {
                return 'Convênio cadastrado com sucesso.'
            },
            error: (error) => {
                return 'Não foi possível cadastrar o convênio.'
            }
        }
    );

    response = yield response;
    if (response.status === 201) {
        yield put(closeModal());
        yield get({patient:patient.id});
    }
}


function* destroy(action){

    let patient = yield select(getPatient);

    let response = api.delete('/userHealthInsurance/'+action.id, {
        headers: {
            'Authorization': 'Bearer ' + (yield select(getAuthToken)),
        }
    });

    toast.promise(response,
        {
            loading: 'Deletando o convênio...',
            success: (success) => {
                return 'Convênio deletado com sucesso.'
            },
            error: (error) => {
                return 'Não foi possível deletar o convênio.'
            }
        }
    );

    response = yield response;
    if (response.status === 200) {
        yield put(closeModal());
        yield get({patient:patient.id});
    }
}


export function* healthInsuranceSaga() {
    yield takeEvery('GET_HEALTH_INSURANCE', get);
    yield takeEvery('UPDATE_HEALTH_INSURANCE', update);
    yield takeEvery('STORE_HEALTH_INSURANCE', store);
    yield takeEvery('DESTROY_HEALTH_INSURANCE', destroy);
}