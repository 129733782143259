import React, {useEffect} from 'react';
import Overview from "./Overview";

function Home({isMobile,layoutRef}) {

    useEffect(() => {
        if (isMobile) {
            layoutRef.current.classList.remove('container-xxl');
        }
    }, [isMobile,layoutRef]);

    return (
        <Overview isMobile={isMobile}/>
    );
}

export default Home
