import {checkPermission} from '../../utils';
import React, {useState, useEffect} from 'react';
import ContentLoader from "react-content-loader";

function TreatmentList({value, openModal, statusTreatment}) {
    const [novo, setNovo] = useState();

    useEffect(() => {

        function click(ev) {
            if (ev.srcElement.closest('#treatmentNovo') == null) {
                setNovo(false);
            }
        }

        if (novo) {
            window.addEventListener('click', click, false);
            return () => {
                window.removeEventListener('click', click, false);
            };
        }

    }, [novo]);

    return (
        <tr style={{cursor: 'pointer'}}>
            <td style={{textAlign: 'left'}}>{value.specialization.profession ? value.specialization.profession.description : ''} {value.specialization ? value.specialization.description : ''}</td>
            <td style={{textAlign: 'left'}}>{value.time} minutos</td>
            <td style={{textAlign: 'left'}}><span
                style={{color: value.status === 1 ? "#4eb14e" : "#e91e63"}}>{value.status === 1 ? "Ativado" : "Desativado"}</span>
            </td>
            <td style={{textAlign: 'right'}}>
                <button className="btn btn-sm btn-icon  btn-active-color-primary" style={{float: 'right'}}
                        onClick={() => setNovo(true)}>
                    <span className="svg-icon svg-icon-muted svg-icon-2hx">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="4" fill="currentColor"/>
                            <rect x="11" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor"/>
                            <rect x="15" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor"/>
                            <rect x="7" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor"/>
                        </svg>
                    </span>
                </button>
                <div className={"dropdown-menu " + (novo ? "show" : '')} style={{marginTop: 40, right: 33}}
                     id="treatmentNovo">
                    <a className="dropdown-item" style={{cursor: 'pointer'}} onClick={() => {
                        openModal('treatment', value);
                        setNovo(false)
                    }}>Editar</a>
                    <a className="dropdown-item" style={{cursor: 'pointer'}} onClick={() => {
                        statusTreatment(value.status === 1 ? 0 : 1, value.id);
                        setNovo(false)
                    }}><span
                        style={{color: value.status === 1 ? "#e91e63" : "#4eb14e"}}> {value.status === 1 ? "Desativar" : "Ativar"} </span></a>
                </div>
            </td>
        </tr>
    )
}

function Treatment({loading, itens, auth, openModal, statusTreatment}) {


    return (
        <div className="card  mb-5 mb-xl-10">
            <div className="card-header cursor-pointer">
                <div className="card-title align-items-start flex-column">
                    <span className="fw-bolder m-0">Tratamentos</span>
                </div>
                <div className="card-toolbar">
                    {checkPermission(auth.permissions, 'documents.store') &&
                        <button onClick={() => openModal('treatment', {})} className="btn btn-sm btn-light-primary">
                            Adicionar
                        </button>}
                </div>
            </div>
            <div className="card-body py-3">
                <div className='table-responsive'>
                    <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                        <thead>
                            <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                                <th style={{textAlign: 'left'}}>Nome</th>
                                <th style={{textAlign: 'left'}}>
                                    Tempo
                                    <span
                                        style={{marginLeft: 10}}
                                        className="svg-icon svg-icon-muted svg-icon-1hx"
                                        title="Esse tempo configura o horário de término do atendimento em minutos, a partir do horário escolhido para início."
                                    >
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10"
                                                              fill="currentColor"/>
                                                        <path
                                                            d="M11.276 13.654C11.276 13.2713 11.3367 12.9447 11.458 12.674C11.5887 12.394 11.738 12.1653 11.906 11.988C12.0833 11.8107 12.3167 11.61 12.606 11.386C12.942 11.1247 13.1893 10.896 13.348 10.7C13.5067 10.4947 13.586 10.2427 13.586 9.944C13.586 9.636 13.4833 9.356 13.278 9.104C13.082 8.84267 12.69 8.712 12.102 8.712C11.486 8.712 11.066 8.866 10.842 9.174C10.6273 9.482 10.52 9.82267 10.52 10.196L10.534 10.574H8.826C8.78867 10.3967 8.77 10.2333 8.77 10.084C8.77 9.552 8.90067 9.07133 9.162 8.642C9.42333 8.20333 9.81067 7.858 10.324 7.606C10.8467 7.354 11.4813 7.228 12.228 7.228C13.1987 7.228 13.9687 7.44733 14.538 7.886C15.1073 8.31533 15.392 8.92667 15.392 9.72C15.392 10.168 15.322 10.5507 15.182 10.868C15.042 11.1853 14.874 11.442 14.678 11.638C14.482 11.834 14.2253 12.0533 13.908 12.296C13.544 12.576 13.2733 12.8233 13.096 13.038C12.928 13.2527 12.844 13.528 12.844 13.864V14.326H11.276V13.654ZM11.192 15.222H12.928V17H11.192V15.222Z"
                                                            fill="currentColor"/>
                                                    </svg>
                                                </span>
                                </th>
                                <th style={{textAlign: 'left'}}>Status</th>
                                <th></th>
                            </tr>
                        </thead>
                        {
                            loading === true ?
                                <tbody>
                                {
                                    [1, 2, 3].map((value, index) => {
                                        return (
                                            <tr key={index}>
                                                <td style={{textAlign: 'left'}}>
                                                    <ContentLoader viewBox="0 0 380 70">
                                                        <rect x="0" y="17" rx="4" ry="4" width="200"
                                                              height="40"/>
                                                    </ContentLoader>
                                                </td>
                                                <td style={{textAlign: 'left'}}>
                                                    <ContentLoader viewBox="0 0 380 35">
                                                        <rect x="0" y="17" rx="4" ry="4" width="200"
                                                              height="40"/>
                                                    </ContentLoader>
                                                </td>
                                                <td style={{textAlign: 'left'}}>
                                                    <ContentLoader viewBox="0 0 380 35">
                                                        <rect x="0" y="17" rx="4" ry="4" width="200"
                                                              height="40"/>
                                                    </ContentLoader>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                                :
                                <tbody>
                                {
                                    itens && itens.length > 0 ?
                                        itens.map((value, index) => {
                                            return (
                                                <TreatmentList openModal={openModal} value={value} key={index}
                                                               statusTreatment={statusTreatment}/>
                                            )
                                        })
                                        :
                                        (<tr>
                                            <td colSpan="6">
                                                <div style={{textAlign: 'center', paddingTop: 30}}>Não foi
                                                    encontrado nenhum tratamento.
                                                </div>
                                            </td>
                                        </tr>)
                                }
                                </tbody>
                        }
                    </table>
                </div>
            </div>
        </div>
    );
}

export default Treatment;
