import React from "react";
import {checkPermission} from "../utils";


export function Menu({setOpen,options,open,id,auth,className,description, style}) {

    return(
        <>
            {
                options && options.length > 0 ?
                    <button
                        className={className ? className : "btn btn-sm btn-icon  btn-active-color-primary"}
                        onClick={() => {
                            setOpen(true);
                        }}
                    >
                        {
                            description ?
                                description
                                :
                                <span className="svg-icon svg-icon-muted svg-icon-2hx">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="4" fill="currentColor" />
                                        <rect x="11" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor" />
                                        <rect x="15" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor" />
                                        <rect x="7" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor" />
                                    </svg>
                                </span>
                        }

                    </button>
                    :
                    <></>
            }
            <div className={"dropdown-menu shadow " + (open ? "show" : "")} style={ style ? style : { top: 55,right:30}} id={id}>
                {
                    options && options.map((value,index) => {
                        if(!checkPermission(auth.permissions, value.permission)) return <></>
                        return(
                            <a
                                key={index}
                                className="dropdown-item"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    value.onClick();
                                    setOpen(false);
                                }}
                            >
                                {value.description}
                            </a>
                        )
                    })
                }
            </div>
        </>
    )
}
