import ContentLoader from "react-content-loader";
import React from "react";
import signed from "../../../assets/img/signed.png";
import {useSelector} from "react-redux";



function CallsNotSigned({openModalTertiary,professional}){

    const countCallsNotSigned = useSelector(state => state.calendar.dashboard.countCallsNotSigned);

    const total = countCallsNotSigned?.items;

    return (
        <div className="card mb-5">
            <div className="card-body  px-0 pb-0" style={{paddingTop: 10}}>
                <div className="mb-12 px-4" style={{height: 125}}>
                    {
                        countCallsNotSigned?.loading === true ?
                            <div className="d-flex align-items-center mb-3">
                                <ContentLoader
                                    speed={2}
                                    width={200}
                                    height={30}
                                    viewBox="0 0 70% 150"
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#ecebeb"
                                >
                                    <rect x="0" y="0" rx="3" ry="3" width="100" height="20"/>
                                </ContentLoader>
                            </div>
                            :
                            <div className="d-flex align-items-center mb-3">
                                <span
                                    className="fs-1hx fw-bold text-gray-800 me-2 lh-1"
                                    style={{fontSize: 20, flex: 1}}
                                >
                                    {total}
                                </span>
                            </div>
                    }
                    <span className="fs-8 fw-semibold text-gray-600">Assinaturas pendentes</span>
                    <div
                        style={{
                            display: "flex",
                            gap: 5,
                            flexDirection: "column",
                            flex: 1,
                            marginTop: 10
                        }}
                    >
                        {
                            countCallsNotSigned?.loading === true ?
                                <div className="d-flex fs-6 fw-semibold align-items-center">
                                    <ContentLoader
                                        speed={2}
                                        width={200}
                                        height={100}
                                        viewBox="0 0 70% 150"
                                        backgroundColor="#f3f3f3"
                                        foregroundColor="#ecebeb"
                                    >
                                        <rect x="0" y="0" rx="3" ry="3" width="130" height="10"/>
                                        <rect x="0" y="25" rx="3" ry="3" width="120" height="10"/>
                                        <rect x="0" y="50" rx="3" ry="3" width="110" height="10"/>
                                        <rect x="0" y="75" rx="3" ry="3" width="130" height="10"/>
                                    </ContentLoader>
                                </div>
                                :
                                countCallsNotSigned?.items > 0 ?
                                    <div  className="d-flex fs-6 fw-semibold align-items-center" >
                                        <div className="bullet w-8px h-6px rounded-2 me-3" ></div>
                                        <div
                                            className="fs-8 fw-semibold text-gray-500 flex-shrink-0">Atendimentos
                                        </div>
                                        <div
                                            className="separator separator-dashed min-w-9px flex-grow-1 mx-2"></div>
                                        <button
                                            onClick={() => openModalTertiary('pendenciesCallsNotSignedModal', {
                                                professional: professional
                                            })}
                                            className="btn btn-sm btn-light"
                                            style={{height: 30, width: 78, fontSize: 10}}
                                        >
                                            {countCallsNotSigned.items}
                                        </button>
                                    </div>
                                    :
                                    <div style={{textAlign: "right",marginRight:-13}}>
                                        <img
                                            src={signed}
                                            width={105}
                                        />
                                    </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CallsNotSigned;