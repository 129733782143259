import { DateTime } from "luxon";
import React,{ useEffect, useState } from "react";
import { checkPermission, formatHour } from "../../utils";
import Lottie from 'react-lottie';
import Confirm from "../Confirm";

function ManagerView({value,destroyPatientTasks,auth}){
    let action = JSON.parse(value.action);
    const [confirm,setConfirm] = useState(false);

    let type = ["","todo dia","toda semana","a cada 15 dias","todo mês","todo ano"]
    let day = ["Domingo","Segunda-feira","Terça-feira","Quarta-feira","Quinta-feira","Sexta-feira","Sábado"];

    return (
        <div className="card shadow" style={{marginBottom:15}} >
            <div className="card-body">
                <div className="row">
                    <div className="col-md-10">
                        <div className="text-primary fw-semibold fs-5">Cadastro recorrente {type[action.type]} para {value.professional.name}</div>
                        <p className="fw-semibold fs-6">{value.treatment && value.treatment.profession && value.treatment.profession.description} {value.treatment.description}</p>
                        <div>
                            {
                                action.type === 2 ?
                                    <>
                                        Dias da semana:
                                        <ul>
                                        {

                                            Object.keys(action.day_week).map((value,index2) =>{
                                                return <li key={index2}>{day[value]}</li>
                                            })
                                        }
                                        </ul>
                                    </>
                                :
                                    <></>
                            }
                        </div>
                        <div className="fw-semibold fs-7">Manter sempre {action.count} cadastrado{action.count > 1 ? "s" : ""}</div>
                        <div className="fw-semibold fs-7">Horário: {formatHour(DateTime.fromSQL(action.data.data))}</div>
                        <Confirm
                            title="Deseja excluir o cadastro? "
                            open={confirm}
                            closeModal={ () => setConfirm(false)}
                            onClick={() =>  destroyPatientTasks(value)}
                        />
                    </div>
                    <div className="col-md-2">
                        {checkPermission(auth.permissions,'task.destroy') && <button className="btn btn-danger btn-sm" style={{marginTop:55,float:'right',width:'100%'}} onClick={() => setConfirm(true) }>Excluir</button>}
                    </div>
                </div>
            </div>
        </div>
    )
}

function ManagerCallsModal({getPatientTasks,data,patientTasks,patientTasksLoading,destroyPatientTasks,auth}) {

    useEffect(()=>{
        getPatientTasks(data.patient);
    },[]);

	const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: require('../../assets/lottie/loading_blue.json'),
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <div>
            {
                patientTasksLoading ?
                    <div style={{textAlign:'center'}}>
                        <Lottie
                            options={defaultOptions}
                            height={200}
                            width={200}
                        />
                    </div>
                :
                    patientTasks && patientTasks.length > 0 ?
                        patientTasks.map((value,index) =>{
                            return (<ManagerView index={index} value={value} destroyPatientTasks={destroyPatientTasks} key={index} auth={auth} />)
                        })
                    :
                        <div style={{textAlign:'center',margin:100}}>Nenhum cadastro recorrente encontrado.</div>
            }
        </div>
    );
}

export default ManagerCallsModal;
