import React from "react";
import {getName} from "../../../utils";
import {Avatar} from "../../../components/Avatar";

function AllDay({ events, eventClick, isMobile}) {

    return (
        <>
            {
                events.map((event,index) => {

                    return (
                        <button
                            key={index}
                            onClick={() => eventClick({...event,allDay:true})}
                            className={`list-group-item list-group-item-action`}
                            style={{padding:3,paddingLeft:8,height:38,flexDirection:'row',display:'flex',background:'#50cd89'}}
                        >
                            <div style={{marginTop:4}}>
                                <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21 9V11C21 11.6 20.6 12 20 12H14V8H20C20.6 8 21 8.4 21 9ZM10 8H4C3.4 8 3 8.4 3 9V11C3 11.6 3.4 12 4 12H10V8Z" fill="#fff"></path><path d="M15 2C13.3 2 12 3.3 12 5V8H15C16.7 8 18 6.7 18 5C18 3.3 16.7 2 15 2Z" fill="#fff"></path><path opacity="0.3" d="M9 2C10.7 2 12 3.3 12 5V8H9C7.3 8 6 6.7 6 5C6 3.3 7.3 2 9 2ZM4 12V21C4 21.6 4.4 22 5 22H10V12H4ZM20 12V21C20 21.6 19.6 22 19 22H14V12H20Z" fill="#fff"></path></svg>
                            </div>
                            {isMobile &&<div style={{marginTop:3,marginLeft:5}}>
                                <Avatar row={event.patient} w={"w-25px"} h={"h-25px"}/>
                            </div>}
                            {
                                event && <div style={{marginLeft:10,width:'100%',whiteSpace:'nowrap',display:'flex',flexDirection:'column'}} title={"Aniversariante "+event.title} >
                                    <div style={{fontSize:11,color:'#fff',marginTop:7}}>{getName(event.title)}</div>
                                </div>
                            }
                        </button>
                    )
                })
            }
        </>
    )
}

export default AllDay;
