import React, { useMemo } from "react";
import Chart from "react-apexcharts";

function PieChartModal({ data }) {
    const chartData = useMemo(() => {
        const series = data.data.map(item => item[data.valueField]);
        const labels = data.data.map(item => item[data.labelField]);
        const colors = data.colorField ? data.data.map(item => item[data.colorField]) : undefined;

        return {
            series: series,
            options: {
                chart: {
                    type: 'pie',
                    width: 400,
                    height: 400,
                },
                labels: labels,
                colors: colors,
                legend: {
                    position: 'right',
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 400,
                            height: 400,
                        },
                        legend: {
                            position: 'bottom',
                            fontSize: '12px',
                            itemMargin: {
                                horizontal: 10,
                                vertical: 5,
                            },
                        },
                    },
                }],
            },
        };
    }, [data]);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50%' }}>
            <div style={{ width: 600 }}>
                <Chart
                    options={chartData?.options}
                    series={chartData?.series}
                    type="pie"
                />
            </div>
        </div>
    );
}

export default PieChartModal;
