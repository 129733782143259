import React from 'react';
import {connect} from 'react-redux';
import Modal from '../components/modal/Modal';
import {closeAny, openModal} from "../store/actions/uiActions";

function AnyController({isMobile,anyOpen,closeAny,children,size,title}) {

    if ((children && !children.$$typeof) || !anyOpen) {
        return null;
    }


    let modalConfig = {icon: null, title:title, size: size,type:null,color:null}

    return (
        <>
            <Modal
                isMobile={isMobile}
                closeModal={closeAny}
                icon={modalConfig.icon}
                title={modalConfig.title}
                type={modalConfig.type}
                size={modalConfig.size}
                color={modalConfig.color}
                zIndex={200000}
            >
                {children}
            </Modal>
        </>
    );
}


const mapStateToProps = state => ({
    anyOpen: state.ui.anyOpen,
    children: state.ui.children,
    title: state.ui.title,
    size: state.ui.size
})

const mapDispatchToProps = dispatch => ({
    closeAny: () => dispatch(closeAny())
})

export default connect(mapStateToProps, mapDispatchToProps)(AnyController)
