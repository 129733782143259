import React, {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import Call from "./pendencies/Call";

function PendenciesTherapeuticModal({data,modalRef}) {

    const dispatch = useDispatch();
    const openCall = (modal,item,typeModal) => dispatch({type:'OPEN_CALL',modal,item,typeModal});
    const getCountPendenciesTherapeutic = (data) => dispatch({type:'GET_COUNT_PLAN_THERAPEUTIC',data});
    const getPendenciesTherapeutic = (professional) => dispatch({type:'GET_PENDENCIES_THERAPEUTIC',professional});
    const clearPendenciesTherapeutic = () => dispatch({type:'CLEAR_PENDENCIES_THERAPEUTIC'});
    const calendar = useSelector(state => state.calendar.dashboard.planTherapeutic);

    useEffect(() => {

        clearPendenciesTherapeutic()
        loadCalls()

        return () => {
            getCountPendenciesTherapeutic({ professional: data.professional})
            clearPendenciesTherapeutic()
        }
    }, [])


    const loadCalls = useCallback(async () => {

        if (calendar.page <= calendar.lastPage && !calendar.loading) {
            getPendenciesTherapeutic(data.professional);
        }
    }, [getPendenciesTherapeutic]);

    const handleScroll = useCallback(() => {
        const { scrollHeight, scrollTop, clientHeight } = modalRef.current;
        if (scrollTop + clientHeight >= scrollHeight-5) {
            loadCalls();
        }
    }, [loadCalls]);

    useEffect(() => {
        modalRef.current.addEventListener('scroll', handleScroll);
        return () => {
            modalRef && modalRef.current && modalRef.current.removeEventListener('scroll', handleScroll);
        };
    },[handleScroll]);

    return (
        <>
            {calendar?.items?.filter(value => value.status.id === 18).map((value,index) => <Call openCall={openCall} key={index} value={value} />)}
            <div  style={{textAlign: 'center'}}>{calendar?.loading === true ? "Carregando..." : <></>}</div>
        </>
    );
}

export default PendenciesTherapeuticModal;
