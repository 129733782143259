import React from "react";
import { useEffect } from "react";
import {checkPermission, formatDate2} from "../../utils"
import { DateTime } from "luxon";
import Lottie from 'react-lottie';
import * as animationData from '../../assets/lottie/loading_blue.json';

function ListaBloquearHorarioModal({
    getTimeBlock,
    data,
    itens,
    destroyTimeBlock,
    loading,
    auth
}) {        

    useEffect(()=>{
        getTimeBlock(data.id);
    },[]);

    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <div>
            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                <thead>
                    <tr className="fw-bolder text-muted">
                        <th style={{textAlign:'left'}}>Nome</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        loading ?
                            <div style={{textAlign:'center'}}>
                                <Lottie 
                                    options={defaultOptions}
                                    height={200}
                                    width={200}                                                        
                                /> 
                            </div>
                        :
                            itens.map((row,index) => {
                                return (
                                    <tr key={index}>
                                        <td style={{textAlign:'left'}}>{formatDate2(DateTime.fromSQL(row.start_date),DateTime.fromSQL(row.end_date))}</td>
                                        <td>{checkPermission(auth.permissions,'timeblock.destroy') && <button className="btn btn-sm btn-danger" onClick={() => destroyTimeBlock(row.id,data.id)}>Excluir</button>}</td>
                                    </tr>
                                )
                            })
                    }
                </tbody>
            </table>
           
        </div>
    );
}

export default ListaBloquearHorarioModal