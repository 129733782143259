import {  put, takeEvery,select} from 'redux-saga/effects';
import api from '../../api';
import {userProfessionLoading, userProfessionSuccess} from "../actions/userProfessionActions";
import toast from "react-hot-toast";
import {closeModal} from "../actions/uiActions";

export const getAuthToken = (state) => state.auth.token;
export const getProfessional = (state) => state.professionals.item;
function* get(action){

    try{
        yield put(userProfessionLoading(true));
        yield put(userProfessionSuccess([]))
        const response = yield  api.get("/user/"+action.user+"/professions",{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield put(userProfessionSuccess(response.data))
        }
    } catch (e) {
        console.error(e);
    }finally {
        yield put(userProfessionLoading(false))
    }
}

function* store(action){

    try{

        let response =   api.post("/userProfession",action.data,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
                'Content-Type': 'multipart/form-data'
            }
        });


        toast.promise(response,
            {
                loading: 'Cadastrando a profissão...',
                success: (success) => {
                    return 'Profissão cadastrado com sucesso.'
                },
                error: (error) => {
                    return 'Não foi possível cadastrar a profissão.'
                }
            }
        );

        response = yield  response

        if(response.status === 201){
            yield put(closeModal())
            yield get({user:action.data.user});
        }
    } catch (e) {
        console.error(e);
    }
}

function* update(action){

    try{
        const professional = yield select(getProfessional)
        console.log(professional)
        let response = api.put("/userProfession/"+action.id,action.data,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        toast.promise(response,
            {
                loading: 'Atualizando a profissão...',
                success: (success) => {
                    return 'Profissão atualizada com sucesso.'
                },
                error: (error) => {
                    return 'Não foi possível atualizar a profissão.'
                }
            }
        );

        response = yield  response
       
        if(response.status === 200){
            yield get({user:professional.id});
        }
   
    } catch (e) {
        console.error(e);
    }
}

function* destroy(action){
    try{

        const patient = yield select(getProfessional)

        let response =   api.delete("/userProfession/"+action.data.id,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        toast.promise(response,
            {
                loading: 'Excluindo a profissão...',
                success: (success) => {
                    return 'Profissão excluída com sucesso.'
                },
                error: (error) => {
                    return 'Não foi possível excluir a profissão.'
                }
            }
        );

        response = yield  response
      
        if(response.status === 200){
            yield get({user:patient.id});
        }
   
    } catch (e) {
        console.error(e);
    }
}

export function* userProfessionSaga() {
    yield takeEvery('GET_USER_PROFESSION', get);
    yield takeEvery('USER_PROFESSION_STORE', store);
    yield takeEvery('USER_PROFESSION_UPDATE', update);
    yield takeEvery('USER_PROFESSION_DESTROY', destroy);
}