import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import logo1 from '../../assets/img/usemed.png'
import { useForm,Controller } from "react-hook-form";
import { Field } from '../../components/Field';
import { Button } from '../../components/Button';
import { getTemplate, sendEmail } from '../../store/actions/accountActions';

function SendInvite({loading,getTemplate,templates,sendEmail}) {

    const { control, handleSubmit, formState: { errors }} = useForm({});

    useEffect(()=>{
        getTemplate()
    },[]);

    let arrayTemplates = [];
    if(templates && templates.length > 0){
        templates.forEach(value =>{
            arrayTemplates.push({value:value.id,label:value.description})
        });
    }

    const onSubmit = (request)=>{
        sendEmail(request);
    }

    return (
        <div className="d-flex flex-column flex-column-fluid" >
            <div className="d-flex flex-row-fluid flex-column flex-column-fluid text-center p-10 py-lg-20" >
                <a className="pt-lg-20 mb-12"> <img alt="logo" src={logo1} className="h-70px" /> </a>
                <h1 className="fw-bold fs-2qx text-gray-800 mb-7">Enviar convite de promoção</h1>
                <div className="fs-3 fw-semibold text-muted mb-10">
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='row justify-content-md-center' style={{textAlign:'left'}}>
                        <div className="col-lg-2">
                            <label className="fw-bold text-muted" style={{marginTop:10}}>Template:</label>
                            <Controller
                                name="template"
                                control={control}
                                rules={{ required: true }}
                                render={({ field : { onChange, value }}) => <Field type="select" options={arrayTemplates} value={value} onChange={onChange} />}
                            />
                            {errors.template && <p style={{color:'red',marginTop:10}}>Necessário selecionar o template.</p>}
                        </div>
                        <div className="col-lg-2">
                            <label className="fw-bold text-muted" style={{marginTop:10}}>Email:</label>
                            <Controller
                                name="email"
                                control={control}
                                rules={{ required: true }}
                                render={({ field : { onChange, value }}) => <Field type="email"  value={value} onChange={onChange} />}
                            />
                            {errors.email && <p style={{color:'red',marginTop:10}}>Necessário preencher o email.</p>}
                        </div>
                    </div>
                    <div className='row justify-content-md-center' style={{marginTop:40}}>
                        <div className="col-lg-3">
                            <Button loading={loading} text="Enviar" type="submit" className="btn-lg btn-block" />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

const mapStateToprops = state => {
    return {
        templates:state.account.templates,
        loading:state.account.templatesLoading
    };
}

const mapDispatchToprops = dispatch => ({
    getTemplate:() =>dispatch(getTemplate()),
    sendEmail:(data) => dispatch(sendEmail(data))
})

export default connect(mapStateToprops,mapDispatchToprops)(SendInvite)
