import { put, takeEvery, select} from 'redux-saga/effects';
import api from '../../api';
import { expedientsFormatSuccess, expedientsLoading, expedientsLoadingAction, expedientsSuccess } from '../actions/expedientsActions';
import { closeModal } from '../actions/uiActions';
import { messenge } from './utils';

export const getAuthToken = (state) => state.auth.token;

function* getExpedients(action){

    try{
        yield put(expedientsLoading(true));
       
        const response = yield  api.get('/user/'+(action.id)+"/expedients",{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield put(expedientsLoading(false));
            yield put(expedientsSuccess(response.data))
        }
    } catch (e) {
        console.error(e);
        yield put(expedientsLoading(false));
    }
}


function* store(action){    
    try{
        yield put(expedientsLoadingAction(true))    
        const response = yield  api.post('/expedients',action.data,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield messenge(response.data,'success');             
            yield put(closeModal());
            yield put(expedientsLoadingAction(false))  
            yield getExpedients({id:action.data.user});
        }
    } catch (e) {
        console.error(e);     
        yield messenge(e.response.data,'warning');
        yield put(expedientsLoadingAction(false))  
    }
}


function* update(action){

    try{    
        yield put(expedientsLoadingAction(true))         
        const response = yield  api.put('/expedients/'+action.id,action.data,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });
       
        if(response.status === 200){           
            yield put(closeModal());
            yield put(expedientsLoadingAction(false)) 
            yield messenge(response.data,'success'); 
            yield getExpedients({id:action.data.user})
        }
    } catch (e) {
        console.error(e);
        yield messenge(e.response.data,'warning');
        yield put(expedientsLoadingAction(false))  
    }
}


function* destroy(action){
    try{    
        yield put(expedientsLoadingAction(true))                
        const response = yield  api.delete('/expedients/'+action.id,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield put(expedientsLoadingAction(false)) 
            yield messenge(response.data,'success');  
            yield getExpedients({id:action.user})
        }
    } catch (e) {
        console.error(e);
        yield messenge(e.response.data,'warning');
        yield put(expedientsLoadingAction(false))         
    }
}


function* getExpedientsFormat(action){

    try{
        yield put(expedientsFormatSuccess([]))
        const response = yield  api.get('/user/'+(action.id)+"/expedients?format=true",{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield put(expedientsFormatSuccess(response.data))
        }
    } catch (e) {      
        console.error(e);          
    }
}



export function* expedientsSaga() {
    yield takeEvery('GET_EXPEDIENTS', getExpedients);
    yield takeEvery('STORE_EXPEDIENTS', store);
    yield takeEvery('UPDATE_EXPEDIENTS', update);
    yield takeEvery('DESTROY_EXPEDIENTS', destroy);
    yield takeEvery('GET_FORMAT_EXPEDIENTS', getExpedientsFormat);
}