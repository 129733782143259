import {calcularMoney, checkPermission} from "../../utils";
import ContentLoader from "react-content-loader";
import React, {useEffect, useState} from "react";


function BankAccountsList({value,openModal,updateBankAccount,auth}){
    const [novo,setNovo] = useState(false);

    useEffect(() => {
        function click(ev) {
            if(ev.srcElement.closest('#service') == null){
                setNovo(false);
            }
        }

        if(novo){
            window.addEventListener('click',click,false);
            return () => {
                window.removeEventListener('click',click,false);
            };
        }

    }, [novo]);

    return(
        <tr>
            <td style={{textAlign:'left'}}>{value.bank?.bank}</td>
            <td style={{textAlign:'left'}}>{value.account}</td>
            <td style={{textAlign:'left'}}>{value.agency}</td>
            <td style={{textAlign:'left'}}>{value.bank?.code}</td>
            <td style={{textAlign:'left'}}>{value.status === 1 ? "Ativo" : "Inativo"}</td>

            <td>
                <button  className="btn btn-sm btn-icon  btn-active-color-primary" style={{float:'right'}} onClick={()=>setNovo(true)}>
                    <span className="svg-icon svg-icon-muted svg-icon-2hx">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="4" fill="currentColor"/>
                            <rect x="11" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor"/>
                            <rect x="15" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor"/>
                            <rect x="7" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor"/>
                        </svg>
                    </span>
                </button>
                <div className={"dropdown-menu "+(novo ? "show" : '')} style={{marginTop:40,right:33}} id="service" >
                    {checkPermission(auth.permissions,'bankAccount.update') ? <a className="dropdown-item" style={{cursor:'pointer'}} onClick={()=>{ openModal('bankAccountsModal',{...value,"bank_id":value.bank?.id}); setNovo(false) }}>Editar</a> : <></>}
                    {checkPermission(auth.permissions,'bankAccount.destroy') ? <a className="dropdown-item" style={{cursor:'pointer'}} onClick={()=>{  updateBankAccount({...value,status:value.status === 1 ? 0 : 1  },value.id); setNovo(false) }}>{value.status === 1 ? "Desativar" : "Ativar"}</a>: <></>}
                </div>
            </td>
        </tr>
    )
}


const BankAccounts = ({items,openModal,loading,auth,updateBankAccount}) => {
    const [tab,setTab] = useState(1);

    return (
        <div className="card  mb-5 mb-xl-10">
            <div className="card-header cursor-pointer">
                <div className="card-title align-items-start flex-column">
                    <span className="fw-bolder m-0">Contas Bancárias</span>
                </div>
                <div className="card-toolbar">
                    {checkPermission(auth.permissions,'bankAccount.store') ?  <button className="btn btn-sm btn-light-info" onClick={() => openModal('bankAccountsModal',{}) }>Adicionar</button> : <></>}
                </div>
            </div>
            <div className="card-body">
                <div className='table-responsive'>
                    <div style={{display: "flex", flexDirection: "row", marginBottom: 20}}>
                        {
                            [{label:"Ativa",value:1},{label:"Inativa",value:0}].map((value, index) => {
                                return (
                                    <div
                                        key={index}
                                        onClick={() => setTab(value.value)}
                                        style={{
                                            flex: "none",
                                            padding: "5px 10px",
                                            background: value.value === tab ? "#8bec8b" : '#f1f1f1',
                                            borderRadius: 10,
                                            marginRight: 10,
                                            color: value.value === tab ? "#fff" : '#000',
                                            cursor: "pointer"
                                        }}
                                    >
                                        {value.label}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                <div className='table-responsive'>
                    <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                        <thead>
                        <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                            <th style={{textAlign: 'left'}}>Banco</th>
                            <th style={{textAlign: 'left'}}>Agência</th>
                            <th style={{textAlign: 'left'}}>Conta</th>
                            <th style={{textAlign: 'left'}}>Código</th>
                            <th style={{textAlign: 'left'}}>Status</th>
                            <th></th>
                        </tr>
                        </thead>
                        {
                            loading === true ?
                                <tbody>
                                {
                                    [1, 2, 3].map((value, index) => {
                                        return (
                                            <tr key={index}>
                                                <td style={{textAlign: 'left'}}>
                                                    <ContentLoader viewBox="0 0 380 70">
                                                        <rect x="0" y="17" rx="4" ry="4" width="200" height="40"/>
                                                    </ContentLoader>
                                                </td>
                                                <td style={{textAlign: 'left'}}>
                                                    <ContentLoader viewBox="0 0 380 35">
                                                        <rect x="0" y="17" rx="4" ry="4" width="200" height="40"/>
                                                    </ContentLoader>
                                                </td>
                                                <td style={{textAlign: 'left'}}>
                                                    <ContentLoader viewBox="0 0 380 35">
                                                        <rect x="0" y="17" rx="4" ry="4" width="200" height="40"/>
                                                    </ContentLoader>
                                                </td>
                                                <td style={{textAlign: 'left'}}>
                                                    <ContentLoader viewBox="0 0 380 50">
                                                        <rect x="0" y="17" rx="4" ry="4" width="200" height="40"/>
                                                    </ContentLoader>
                                                </td>
                                                <td style={{textAlign: 'left'}}>
                                                    <ContentLoader viewBox="0 0 380 50">
                                                        <rect x="0" y="17" rx="4" ry="4" width="200" height="40"/>
                                                    </ContentLoader>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                                :
                                <tbody>
                                {
                                    items && items.filter(value => value.status === tab).length > 0 ?
                                        items.filter(value => value.status === tab).map((value, index) => {
                                            return (
                                                <BankAccountsList
                                                    auth={auth}
                                                    value={value}
                                                    key={index}
                                                    openModal={openModal}
                                                    updateBankAccount={updateBankAccount}
                                                />
                                            )
                                        }) :
                                        <tr>
                                            <td colSpan="6">
                                                <div style={{textAlign: "center", paddingTop: 10}}>Não foi encontrado
                                                    nenhuma conta bancária.
                                                </div>
                                            </td>
                                        </tr>
                                }
                                </tbody>
                        }
                    </table>
                </div>
            </div>
        </div>
    )
}


export default BankAccounts;