
export const getService = () => ({type: 'GET_SERVICE'});
export const serviceSuccess = (data) => ({type: 'SERVICE_SUCCESS',data});
export const serviceLoading = (status) => ({type: 'SERVICE_LOADING',status});
export const updateService = (data,id) => ({type: 'UPDATE_SERVICE',data,id});
export const serviceUpdate = (data,id) => ({type: 'SERVICE_UPDATE',data,id});
export const storeService = (data) => ({type: 'STORE_SERVICE',data});
export const serviceStore = (data) => ({type: 'SERVICE_STORE',data});









