import React, { useEffect,useState } from "react";
import { useForm,Controller } from "react-hook-form";
import { Field } from "../Field";
import { Button } from "../Button";

function UserPrivateModal({loading,data,update,getUserPrivateStatus,userPrivateStatus}) {
    const [statusSelected,setStatusSelected] = useState(0)
    const {control, handleSubmit, formState: { errors } } = useForm({
        defaultValues:{
            status: data && data.status ? data.status.id : null,
            patient:  data && data.patient ?  data.patient : null,
            leader: data && data.leader ? data.leader : 0,
            report_date: data && data.report_date ? data.report_date : null,
        }
    });



    useEffect(()=>{
        getUserPrivateStatus();

        if(data.status.id){
            setStatusSelected(data.status.id)
        }
    }, [])

    const onSubmit = (request) =>{

        update(request,data.id);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-lg-3" >
                    <label>Status:</label>
                    <Controller
                        name={"status"}
                        control={control}
                        rules={{ required: true }}
                        render={({ field : { onChange, value }}) => <Field type="select" options={userPrivateStatus} value={value} onChange={(value) => {onChange(value);setStatusSelected(value)}} />}
                    />
                    {errors.status && <p style={{color:'red',marginTop:10}}>Necessário preencher o status.</p>}
                </div>
                <div className="col-lg-3" >
                    <label>Lider:</label>
                    <Controller
                        name={"leader"}
                        control={control}
                        render={({ field : { onChange, value }}) => <Field type="select" options={[{value:1,label:'Sim'},{value:0,label:'Não'}]} value={value} onChange={onChange} />}
                    />
                </div>
                <div className="col-lg-3" >
                    <label>Envio do relatório:</label>
                    <Controller
                        name={"report_date"}
                        control={control}
                        render={({ field : { onChange, value }}) => <Field type="date" value={value} onChange={onChange} />}
                    />
                </div>
                {(statusSelected === 3 || statusSelected === 5) && <div className="col-lg-12" style={{paddingBottom:20}}>
                    <div className="notice d-flex bg-light-danger rounded border-danger border border-dashed  p-6" style={{marginTop:20}}>
                        <span className="svg-icon svg-icon-2tx svg-icon-danger me-4">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect>
                                  <rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="currentColor"></rect>
                                  <rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"></rect>
                                </svg>
                            </span>
                            <div className="d-flex flex-stack flex-grow-1 ">
                                <div className=" fw-semibold">
                                    <h4 className="text-gray-900 fw-bold">Atenção</h4>
                                    <div className="fs-6 text-gray-700 ">Ao escolher este status, todos os atendimentos futuros serão excluídos .</div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div className="row" style={{textAlign:'right',marginTop:20}}>
                <div className="col-md-9"></div>
                <div className="col-md-3">
                    <Button loading={loading} text="Salvar" type="submit" />
                </div>
            </div>
        </form>
    );
}

export default UserPrivateModal
