import {updateObject} from '../../utils';

const initialState = {
    items:[],
    loading: false,
    operators:[]
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'OPERATOR_SUCCESS':
            return updateObject(state, {items: action.data});
        case 'OPERATOR_LOADING':
            return updateObject(state, {loading: action.status});
        case 'OPERATOR_STORE':{
            const newArray = [...state.items];
            newArray.push(action.data);
            return updateObject(state, {items:newArray});
        }
        case 'SET_OPERATORS':
            return updateObject(state, {operators: action.data});
        default:
            return state;
    }
}

export default reducer;
