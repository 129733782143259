import React, {useEffect} from "react";
import {Controller, useForm} from "react-hook-form";
import {Field} from "../Field";
import {Button} from "../Button";

function QueueWaitModal({data,getTreatment,treatment,storeQueueWait,updateQueueWait}) {

    const arrayTreatment = []
    if (treatment && treatment.length > 0) {
        const removeDuplicatesBySpecializationId = (array) => {
            const seen = new Set();
            return array.filter(item => {
                const id = item.specialization.id;
                return seen.has(id) ? false : seen.add(id);
            });
        };

        removeDuplicatesBySpecializationId(treatment)?.filter(value => value.status === 1).forEach(element => {
            arrayTreatment.push({
                label: ((element && element.specialization.profession) ? element.specialization.profession.description : '') + " " + (element.specialization.description ? element.specialization.description : ''),
                value: element.id
            })
        });
    }

    useEffect(() => {
        getTreatment()
    }, []);

    const onSubmit = request => {

        if(request.id){
            updateQueueWait(request,request.id)
        }else{
            console.log(request)
            storeQueueWait(request)
        }
    }

    const { control, handleSubmit,  formState: { errors } } = useForm({
        defaultValues:{...data}
    });

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className={"col-lg-3"} style={{marginTop: 10}} >
                    <label className="fw-bold text-muted">Tratamento*:</label>
                    <Controller
                        name="specialization_id"
                        control={control}
                        rules={{ required: true }}
                        render={({ field : { onChange,  value }}) => <Field options={arrayTreatment}  type="select" value={value} onChange={onChange} />}
                    />
                    {errors.specialization_id && <p style={{color:'red',marginTop:10}}>Necessário selecionar o tratamento.</p>}
                </div>

                <div className={"col-lg-3"} style={{marginTop: 10}} >
                    <label className="fw-bold text-muted">Dia da semana:</label>
                    <Controller
                        name="day_the_week"
                        control={control}
                        render={({ field : { onChange,  value }}) =>
                            <Field
                                type="select"
                                options={[
                                    { label: 'Domingo', value: 0 },
                                    { label: 'Segunda-feira', value: 1 },
                                    { label: 'Terça-feira', value: 2 },
                                    { label: 'Quarta-feira', value: 3 },
                                    { label: 'Quinta-feira', value: 4 },
                                    { label: 'Sexta-feira', value: 5 },
                                    { label: 'Sábado', value: 6 }
                                ]}
                                value={value}
                                onChange={onChange}
                            />
                        }
                    />
                </div>
                <div className={"col-lg-3"} style={{marginTop: 10}} >
                    <label className="fw-bold text-muted">Turno:</label>
                    <Controller
                        name="to_turn"
                        control={control}
                        render={({ field : { onChange,  value }}) =>
                            <Field
                                type="select"
                                options={[
                                    {
                                        label: 'Manhã',
                                        value: 1
                                    },
                                    {
                                        label: 'Tarde',
                                        value: 2
                                    },
                                    {
                                        label: 'Noite',
                                        value: 3
                                    }
                                ]}
                                value={value}
                                onChange={onChange}
                            />
                        }
                    />
                </div>
                <div className={"col-lg-12"} style={{marginTop: 10}} >
                    <label className="fw-bold text-muted">Observações:</label>
                    <Controller
                        name="observation"
                        control={control}
                        render={({ field : { onChange,  value }}) => <Field type="textArea" value={value} onChange={onChange} />}
                    />
                </div>
            </div>
            <div className="row" style={{textAlign: 'right', marginTop: 10}}>
                <div className="col-md-9">

                </div>
                <div className="col-md-3">
                    <Button text="Salvar" type="submit"/>
                </div>
            </div>
        </form>
    );
}

export default QueueWaitModal;
