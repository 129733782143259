export const getProcedures = () => ({type: 'GET_PROCEDURES'});
export const proceduresStart = () => ({type: 'PROCEDURE_START'});
export const proceduresFail = () => ({type: 'PROCEDURE_FAIL'});
export const proceduresSuccess = (itens) => ({type: 'PROCEDURE_SUCCESS',itens});
export const proceduresStore = (data) => ({type: 'STORE_PROCEDURES',data});
export const proceduresLoading = (status) => ({type: 'PROCEDURE_LOADING',status});
export const createProcedures = (data) => ({type: 'PROCEDURE_CREATE',data});
export const proceduresUpdate = (data,id) => ({type: 'UPDATE_PROCEDURES',data,id});
export const updateProcedures = (data,id) => ({type: 'PROCEDURE_UPDATE',data,id});
export const destroyProcedures = (id) => ({type: 'DESTROY_PROCEDURES',id});
export const proceduresDestroy = (id) => ({type: 'PROCEDURE_DESTROY',id});