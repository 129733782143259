import React, {useEffect, useState} from "react";
import {DateTime} from "luxon";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import DocumentsController from "../../containers/document/DocumentController";
import blank from "../../assets/img/blank.png";
import {
    calcularMoney,
    canSign,
    checkPermission,
    formatDate,
    formatDate3,
    formatDateTOYYYYMMDD,
    getName
} from "../../utils";
import Group from "../../containers/group";
import api from "../../api";
import {hasInterest, hasUnchecked} from "../Helper";
import ContentLoader from "react-content-loader";
import Noticie from "../Noticie";

const Service = ({data,user}) => {

    if (!checkPermission(user.permissions, 'service.show') || !data.service?.id) {
        return null;
    }

    return(
        <div className="row">
            <div className="col-lg-12" style={{paddingTop: 15}}>
                <div className="card">
                    <div className="card-header cursor-pointer" >
                        <div className="card-title m-0" style={{flexDirection: 'row', flex: 1}}>
                            <h3 className="fw-bolder m-0" style={{flex: 1, fontSize: 15}}>Serviço</h3>
                        </div>
                    </div>
                    <div className="card-body" style={{paddingTop: 0, paddingBottom: 0}}>
                        <div className="row">
                            <div className="col-lg-3">
                                <label className="fw-bold text-muted"
                                       style={{marginTop: 10}}>Nome:</label>
                                <p style={{marginTop: 10}}>{data.service.name}</p>
                            </div>
                            <div className="col-lg-3">
                                <label className="fw-bold text-muted"
                                       style={{marginTop: 10}}>Valor:</label>
                                <p style={{marginTop: 10}}>{calcularMoney(data.service.total).toLocaleString('pt-br', {
                                    style: 'currency',
                                    currency: 'BRL'
                                })}</p>
                            </div>
                            <div className="col-lg-3">
                                <label className="fw-bold text-muted"
                                       style={{marginTop: 10}}>Comissão:</label>
                                <p style={{marginTop: 10}}>
                                    {
                                        data.service.type_commission === 1 ?
                                            calcularMoney(data.service.commisission_fixed).toLocaleString('pt-br', {
                                                style: 'currency',
                                                currency: 'BRL'
                                            })
                                            :
                                            calcularMoney((data.service.total * data.service.commisission_percentage) / 100).toLocaleString('pt-br', {
                                                style: 'currency',
                                                currency: 'BRL'
                                            }) + " (" + data.service.commisission_percentage + "%)"
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const WhyDidYouUnmarkIt = ({data}) => {

    if(![5,6,7,8,13,14,15].includes(data.status.id)){
        return null;
    }

    return (
        <div className="row">
            <div className="col-lg-12" style={{paddingTop: 15}}>
                <div className="card">
                    <div className="card-header cursor-pointer" >
                        <div className="card-title m-0" style={{flexDirection: 'row', flex: 1}}>
                            <h3 className="fw-bolder m-0" style={{flex: 1, fontSize: 15}}>
                                Por que desmarcou?
                            </h3>
                        </div>
                    </div>
                    <div className="card-body">
                        {data.obs_desmarcar}
                    </div>
                </div>
            </div>
        </div>
    )
}

const SignatureDocument = ({data,openViewPDF,user}) => {

    if (!checkPermission(user.permissions, 'signed.calls.show')) {
        return null;
    }

    if(!data?.signed_document){
        return null;
    }

    return (
        <div className="row">
            <div className="col-lg-12" style={{paddingTop: 15}}>
                <div className="card">
                    <div className="card-header cursor-pointer" >
                        <div className="card-title m-0" style={{flexDirection: 'row', flex: 1}}>
                            <h3 className="fw-bolder m-0" style={{flex: 1, fontSize: 15}}>
                                Documento assinado
                            </h3>
                        </div>
                    </div>
                    <div className="card-body container-fluid d-flex align-items-center ">
                        <div className="d-flex flex-column  flex-grow-1 ">
                            <p><b>Código de referência:</b> {data.signed_document.uuid}</p>
                            <p><b>Data da assinatura:</b> {formatDate(DateTime.fromSQL(data.signed_document.created_at))}</p>
                        </div>
                        <div className="d-flex">
                            <button className="btn btn-primary btn-sm" onClick={() => openViewPDF(true,{url:data.signed_document.url})}>
                                Visualizar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Notification = ({data}) => {

    return (
        <>
            {
                data.call_old || data.call_new ?
                    <div className="row">
                        <div className="col-lg-12" style={{paddingTop: 15}}>
                            <div className="card">
                                <div className="card-header cursor-pointer" style={{minHeight: 35}}>
                                    <div className="card-title m-0" style={{flexDirection: 'row', flex: 1}}>
                                        <h3 className="fw-bolder m-0" style={{flex: 1, fontSize: 15}}>
                                            Avisos
                                        </h3>
                                    </div>
                                </div>
                                <div className="card-body" style={{paddingTop:10,paddingBottom:10}}>
                                    {data.call_old ? <Noticie description={data.call_old} type="warning" title="Remarcado de:" /> : <></>}
                                    {data.call_new ? <Noticie description={data.call_new} type="warning" title="Remarcado para:" /> : <></>}
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <></>
            }
        </>
    )
}

const Info = ({data,openPatient,statusConfirm,user,openViewPDF}) => {

    return(
        <>
            <div style={{borderTopRightRadius: 0, borderTopLeftRadius: 0}} className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-lg-3">
                            <label className="fw-bold text-muted" style={{marginTop: 10}}>Paciente:</label><br/>
                            <p
                                className="btn btn-link"
                                style={{textAlign: 'left'}}
                                title={data.patient.name}
                                onClick={() => openPatient()}
                            >
                                <img
                                    alt={data.patient.name}
                                    height="20px"
                                    width="20px"
                                    src={data.patient.img ? data.patient.img : blank}
                                    style={{
                                        marginRight: 10,
                                        marginTop: -6,
                                        borderRadius: 10
                                    }}
                                />
                                {data.patient ? getName(data.patient.name) : ""}
                            </p>
                        </div>
                        <div className="col-lg-3">
                            <label className="fw-bold text-muted" style={{marginTop: 10}}>Profissional:</label>
                            <p
                                style={{marginTop: 10}}
                            >
                                <img
                                    alt={data.professional.name}
                                    height="20px"
                                    width="20px"
                                    src={data.professional.img ? data.professional.img : blank}
                                    style={{
                                        marginRight: 10,
                                        marginTop: -6,
                                        borderRadius: 10
                                    }}
                                />
                                {data.professional ? getName(data.professional.name) : ""}
                            </p>
                        </div>
                        <div className="col-lg-3">
                            <label className="fw-bold text-muted" style={{marginTop: 10}}>Tipo de
                                agendamento:</label>
                            <p style={{marginTop: 10}}>{data.type_schedule.description}</p>
                        </div>
                        {
                            data.type_address >0 ? <div className="col-lg-2">
                                <label className="fw-bold text-muted" style={{marginTop: 10}}>Local:</label>
                                <p style={{marginTop: 10}}>{data.type_address === 1 ? "Clínica" : data.type_address === 2 ? "Domicílio" : data.type_address === 3 ? "Escola" : data.type_address === 4 ? "Teleatendimento" : ""}</p>
                            </div> :<></>
                        }
                        {
                            parseInt(data.type_address) === 1 ?
                                <div className="col-lg-2">
                                    <label className="fw-bold text-muted" style={{marginTop: 10}}>Sala:</label>
                                    <p style={{marginTop: 10}}>{data.room.description}</p>
                                </div>
                                :
                                <></>
                        }
                        {
                            parseInt(data.type_address) === 2 ?
                                <div className="col-lg-3">
                                    <label className="fw-bold text-muted"
                                           style={{marginTop: 10}}>Endereço:</label>
                                    <p style={{marginTop: 10}}>{data.address ? <span
                                        className="fw-bolder fs-6 text-gray-800">{data.address.street}, {data.address.number} - {data.address.neighborhood}, {data.address.city} - {data.address.state}, {data.address.zipcode}</span> : null}</p>
                                </div>
                                :
                                <></>
                        }
                        <div className="col-lg-5">
                            <label className="fw-bold text-muted" style={{marginTop: 10}}>Tratamento:</label>
                            <p style={{marginTop: 10}}>{data.treatment ? data.treatment.specialization.profession.description + " " + (data.treatment.specialization.description ? data.treatment.specialization.description : "") : ""}</p>
                        </div>
                        <div className="col-lg-5">
                            <label className="fw-bold text-muted" style={{marginTop: 10}}>Agendamento:</label>
                            <p style={{marginTop: 10}}>{formatDate3(DateTime.fromSQL(data.start_date), DateTime.fromSQL(data.end_date))}</p>
                        </div>
                        <div className="col-lg-6">
                            <label className="fw-bold text-muted" style={{marginTop: 10}}>Status:</label><br/>
                            {
                                data.status ?
                                    <div
                                        className="badge"
                                        style={{background: data.status.color}}
                                    >
                                        {data.status.description + " "}
                                    </div>
                                    :
                                    <></>
                            }
                            <br/>
                            {
                                statusConfirm && statusConfirm[0] && [2].includes(statusConfirm[0].status.id) ?
                                    <div
                                        className="badge"
                                        style={{background: statusConfirm[0].status.color, marginTop: 5}}
                                    >
                                        Foi confirmado no dia {formatDate(DateTime.fromISO(statusConfirm[0].updated_at))}
                                    </div>
                                    :
                                    <></>
                            }
                            {
                                statusConfirm && statusConfirm[0] && [5].includes(statusConfirm[0].status.id) ?
                                    <div
                                        className="badge"
                                        style={{background: statusConfirm[0].status.color, marginTop: 5}}
                                    >
                                        Foi desmarcado no dia {formatDate(DateTime.fromISO(statusConfirm[0].updated_at))}
                                    </div>
                                    :
                                    <></>
                            }
                            {
                                statusConfirm && statusConfirm[0] && [1].includes(statusConfirm[0].status.id) ?
                                    <div className="badge"
                                         style={{background: statusConfirm[0].status.color, marginTop: 5}}
                                    >
                                        O link de confirmação foi criado no  dia {formatDate(DateTime.fromISO(statusConfirm[0].updated_at))}
                                    </div>
                                :
                                <></>
                            }
                        </div>
                        {
                            data.replacement ?
                                <div className="col-lg-4">
                                    <label className="fw-bold text-muted" style={{marginTop: 10}}></label><br/>
                                    <div className="badge badge-light-warning" style={{marginTop: 10}}>Reposição</div>
                                </div>
                            :
                                <></>
                        }
                        {data.rescheduled ? <div className="col-lg-4">
                            <label className="fw-bold text-muted" style={{marginTop: 10}}></label><br/>
                            <div className="badge badge-primary" style={{backgroundColor: '#ffc107',marginTop: 10}}>Remarcado</div>
                        </div>: <></>}
                        {
                            data?.signed_document ?
                                <div className="col-lg-4">
                                    <label className="fw-bold text-muted" style={{marginTop: 10}}></label><br/>
                                    <div className="badge badge-success" style={{marginTop: 10}}>Atendimento assinado</div>
                                </div>
                            :
                            <></>
                        }
                            {
                                data.observation ?
                                    <div className="col-lg-12">
                                    <label className="fw-bold text-muted" style={{marginTop: 10}}>Observações:</label>
                                    <p style={{marginTop: 10}}>{data.observation}</p>
                                </div>
                                :
                                <></>
                        }

                    </div>
                </div>
            </div>
            <Service data={data} user={user} />
            <Notification data={data} />
            <WhyDidYouUnmarkIt data={data} />
            <SignatureDocument data={data} openViewPDF={openViewPDF} user={user} />
        </>
    )
}

function SessionModal({modalPayload, user, groupsCopy, closeModal, isMobile,openViewPDF}) {

    const dispatch = useDispatch();
    const history = useHistory();
    const [tab, setTab] = useState(0);
    const [disabledEvolution, setDisabledEvolution] = useState('');
    const [novo, setNovo] = useState(false);
    const [statusConfirm, setStatusConfirm] = useState([]);
    let countCharacters = 16
    const closeModalTertiary = () => dispatch({type: 'UI_CLOSE_MODAL_TERTIARY'});
    const closeModalSecondary = () => dispatch({type: 'UI_CLOSE_MODAL_SECONDARY'});
    const openCall = (modal,item,typeModal) => dispatch({type:'OPEN_CALL',modal,item,typeModal});
    const data = useSelector(state => state.calendar.item)[modalPayload.uuid];
    const loadingCreate = useSelector(state => state.calendar.loadingCreate);
    const token = useSelector(state => state.auth.token);
    const handlerUpdateStatus = (data) => dispatch({type: 'NEW_UPDATE_STATUS', data});

    if([5].includes(data.type_schedule.id)){
        countCharacters = 23
    }

    const getStatusConfirm = (call) => {
        api.get('/callsConfirm/' + call, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }).then((response) => {
            setStatusConfirm(response.data)
        })
    }

    const openPatient = () => {
        closeModal();
        closeModalTertiary()
        closeModalSecondary();
        history.push('/patient/' + data.patient.id)
    }

    useEffect(() => {
        if(data.uuid !== modalPayload.uuid){
            modalPayload.uuid = data.uuid;
        }
    }, [data]);

    useEffect(() => {
        if (data.id) {
            getStatusConfirm(data.id)
        }

    }, []);

    const companiesNot = [
        3, 84, 72, 43, 23
    ]

    const not = companiesNot.filter((value) => value === user.company.id);

    const buttonLeft = () => {
        switch (data.status.id) {
            case 1:
            case 2:
                return checkPermission(user.permissions, 'status.arrived') &&
                    <button onClick={() => handlerUpdateStatus({calls: data, status: 3, id: data.id})} style={{
                        backgroundColor: '#c0e310',
                        color: '#fff',
                        flex: 1,
                        width: '100%',
                        marginTop: 0,
                        marginBottom: 5
                    }} className="btn align-self-center btn-sm">Paciente chegou</button>
            case 3:
                return checkPermission(user.permissions, 'status.tomeet') &&
                    <button onClick={() => handlerUpdateStatus({calls: data, status: 4, id: data.id})} style={{
                        backgroundColor: '#10e017',
                        color: '#fff',
                        flex: 1,
                        width: '100%',
                        marginTop: 0,
                        marginBottom: 5
                    }} className="btn align-self-center btn-sm">Atender</button>
            case 4:
            case 17:
            case 18:
                return checkPermission(user.permissions, 'status.finish') && <button
                    disabled={!not.length > 0 && [2, 1, 4,5].includes(data.type_schedule.id) && disabledEvolution.length <= countCharacters}
                    onClick={() => handlerUpdateStatus({calls: data, status: 9, id: data.id})} style={{
                    backgroundColor: '#0bdba1',
                    color: '#fff',
                    flex: 1,
                    width: "100%",
                    marginTop: 0,
                    marginBottom: 5
                }} className="btn align-self-center btn-sm">Finalizar</button>
            default:
                return;
        }
    }

    let d1 = DateTime.fromSQL(data?.start_date)
    let d2 = DateTime.now();

    const permissions = [
        {
            'type': 1,
            'permission': 'avaliation.modify'
        },
        {
            'type': 2,
            'permission': 'evolution.modify'
        },
        {
            'type': 3,
            'permission': 'anamnese.modify'
        },
        {
            'type': 4,
            'permission': 'return.modify'
        },
        {
            'type':5,
            'permission':'plan.list'
        },
        {
            'type':6,
            'permission':'teste.modify'
        },
        {
            'type':7,
            'permission':'teste.modify'
        },
    ]

    const permission = permissions.filter((p) => p.type === data?.type_schedule.id);

    return (
        <>
            <div style={{display: 'flex'}}>
                <ul className="nav nav-tabs flex-nowrap text-nowrap"  style={{flex: 1, width: 300, overflowX: 'auto', overflowY: 'hidden'}}>
                    <li className="nav-item">
                        <a style={{cursor: 'pointer'}}
                           className={"nav-link btn btn-color-gray-600 btn-active-color-primary rounded-bottom-0" + (tab === 0 ? '  show active' : '')}
                           onClick={() => setTab(0)}>
                            Informações
                        </a>
                    </li>
                    {
                        data?.id > 0 && data && !hasUnchecked(data.status.id) && checkPermission(user.permissions, permission[0].permission) ?
                            <li className="nav-item">
                                <a style={{cursor: 'pointer'}}
                                   className={"nav-link btn btn-color-gray-600 btn-active-color-primary rounded-bottom-0" + (tab === 3 ? '  show active' : '')}
                                   onClick={() => setTab(3)}
                                >
                                    {data.type_schedule.description}
                                </a>
                            </li>
                            :
                            <></>
                    }
                    {
                        data?.id > 0  && data && !hasUnchecked(data.status.id) ?
                            <li className="nav-item">
                                <a
                                    style={{cursor: 'pointer'}}
                                    className={"nav-link btn btn-color-gray-600 btn-active-color-primary rounded-bottom-0" + (tab === 1 ? '  show active' : '')}
                                    onClick={() => setTab(1)}> Anexos </a> </li>
                            :
                            <></>
                    }
                </ul>
                {! data?.signed_document && <ul className="nav nav-tabs">
                    {
                        data?.id && (checkPermission(user.permissions, "calls.update") || checkPermission(user.permissions, "calls.destroy")) ?
                            <li className="nav-item">
                                <a style={{cursor: 'pointer'}}
                                   className={"nav-link btn btn-color-gray-600 btn-active-color-primary rounded-bottom-0 dropdown-toggle"}
                                   onClick={() => setNovo(!novo)}
                                >
                                    Opções
                                </a>
                                <div
                                    className={"dropdown-menu " + (novo ? "show" : '')}
                                    style={{marginTop: 1, right: 24}} id="session">
                                    {
                                        data?.rescheduled !== 1 && !hasInterest(data.type_schedule.id) && checkPermission(user.permissions, "calls.update") &&
                                        <button
                                            className="dropdown-item"
                                            style={{cursor: 'pointer'}}
                                            onClick={() => {
                                                openCall('atendimento',{...data,edit:true,type:modalPayload.type},"primary");
                                                setNovo(false);
                                            }}
                                        >
                                            Editar
                                        </button>
                                    }
                                    {
                                        checkPermission(user.permissions, "calls.destroy") &&
                                        <button
                                            className="dropdown-item"
                                            style={{cursor: 'pointer'}}
                                            onClick={() => {
                                                openCall("confirmDestroyModal", {...data,type:modalPayload.type},"primary");
                                                setNovo(false);
                                            }}
                                        >
                                            Excluir
                                        </button>
                                    }
                                    {
                                        !hasInterest(data.type_schedule.id) &&  data?.rescheduled !== 1 && (hasUnchecked(data.status.id)) && checkPermission(user.permissions, "status.reschedule") &&
                                        <button
                                            className="dropdown-item"
                                            style={{cursor: 'pointer'}}
                                            onClick={() => {
                                                openCall("remarcar", data,"primary");
                                                setNovo(false)
                                            }}
                                        >
                                            Remarcar
                                        </button>
                                    }
                                    {
                                        !hasInterest(data.type_schedule.id) &&  data.status.id === 1 && checkPermission(user.permissions, "calls.update") &&
                                        <a
                                            className="dropdown-item position-relative"
                                            style={{cursor: "pointer"}}
                                            onClick={() => {
                                                openCall("confirmCalls", data,"primary");
                                                setNovo(false);
                                            }}
                                        >
                                            Confirmar
                                        </a>
                                    }
                                </div>
                            </li>
                            :
                            <></>
                    }
                </ul>}
            </div>
            <div className="tab-content">
                <div className={"tab-pane fade " + (tab === 0 ? '  show active' : '')}>
                    <Info openViewPDF={openViewPDF} data={data}  openPatient={openPatient} statusConfirm={statusConfirm} user={user} />
                </div>
                <div className={"tab-pane fade " + (tab === 1 ? '  show active' : '')}>
                    {data?.id   && data && !hasUnchecked(data.status.id) ? <DocumentsController disabled={data?.signed_document} typeModel={4} id={data.id} key={2}/> : <></>}
                </div>
                {
                    data?.id && !hasUnchecked(data.status.id) &&
                        <div className={"tab-pane fade " + (tab === 3 ? '  show active' : '')}>
                            {
                                data?.id && <Group
                                    disabled={data?.signed_document}
                                    isMobile={isMobile}
                                    setDisabledEvolution={setDisabledEvolution}
                                    groupsCopy={groupsCopy}
                                    type={data.type_schedule?.id} user={user}
                                    treatment={data.treatment?.id}
                                    value_link={data?.id}
                                    patient={data.patient?.id}
                                    company={user.company.id}
                                    professional={data.professional?.id}
                                />
                            }
                        </div>
                }
            </div>
            {
                data.status?.id <= 4 || (canSign() && checkPermission(user.permissions,'signed.calls.created') && data.status?.id === 9 && ![5,6,7].includes(data.type_schedule?.id)) ||  data.status?.id === 17 ||   data.status?.id === 18 ?
                    !data?.signed_document?.id && <div className="card" style={{marginTop: 15}}>
                        <div className="card-body">
                            {
                                data.status?.id === 9 && ![5,6,7].includes(data.type_schedule?.id) ?
                                    <div className="row">
                                        <div className="col-md-9">
                                            {!user.certificate ? <Noticie description={ "Para <b>assinar o atendimento</b> é necessário configurar o certificado do tipo A1"} type="warning" title="Atenção" /> : <></>}
                                        </div>
                                        <div className="col-md-3">
                                            {data.professional?.id === user.id ? <button
                                                onClick={() => openCall("signatureModal", data,"primary")}
                                                style={{
                                                    backgroundColor: '#0bdba1',
                                                    color: '#fff',
                                                    flex: 1,
                                                    width: '100%',
                                                    marginBottom: 10
                                                }}
                                                className="btn align-self-center btn-sm"
                                            >
                                                Visualizar e assinar
                                            </button> : <></>}
                                        </div>
                                    </div>
                                    :
                                    <div className="row">
                                        <div className="col-md-9">
                                            {
                                                !not.length > 0 && [2, 1, 4,5].includes(data.type_schedule.id) && disabledEvolution.length <= countCharacters ?
                                                    <Noticie description={ "Para <b>finalizar</b> é necessário inserir pelo menos 16 caracteres na opção <b><u>" +data.type_schedule.description + "</u></b>."} type="warning" title="Atenção" />
                                                    :
                                                    <></>
                                            }
                                        </div>
                                        <div className="col-md-3">
                                            {
                                                loadingCreate ?
                                                    <ContentLoader backgroundColor="#c7c6c6" viewBox="0 0 380 50">
                                                        <rect x="0" y="0" rx="4" ry="4" width="380" height="30"/>
                                                    </ContentLoader>
                                                    :
                                                    ((formatDateTOYYYYMMDD(d1) <= formatDateTOYYYYMMDD(d2)) && data && data.id || data.status.id === 17 )  && buttonLeft()
                                            }
                                            {
                                                loadingCreate ?
                                                    <ContentLoader backgroundColor="#c7c6c6" viewBox="0 0 380 50">
                                                        <rect x="0" y="0" rx="4" ry="4" width="380" height="30"/>
                                                    </ContentLoader>
                                                    :
                                                    data && data.id && data.status ?
                                                        data.status.id === 1 || data.status.id === 2 || data.status.id === 3 ?
                                                            checkPermission(user.permissions, 'status.markoff') &&
                                                            <button
                                                                onClick={() => openCall("markOffCallsModal", data,"primary")}
                                                                style={{
                                                                    backgroundColor: '#db0f24',
                                                                    color: '#fff',
                                                                    flex: 1,
                                                                    width: '100%',
                                                                    marginTop: 5,
                                                                    marginBottom: 10
                                                                }}
                                                                className="btn align-self-center btn-sm"
                                                            >
                                                                Desmarcar
                                                            </button>
                                                            :
                                                            <></>
                                                        :
                                                        <></>
                                            }
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                    :
                    <></>
            }
        </>
    );
}

export default SessionModal;