import React, {useState, useEffect} from "react";
import ContentLoader from "react-content-loader";

function List({value, openModal, destroyUserProfession}) {
    const [novo, setNovo] = useState(false);

    useEffect(() => {

        function click(ev) {

            if (ev.srcElement.closest('#userProfession') == null) {
                setNovo(false);
            }
        }

        if (novo) {
            window.addEventListener('click', click, false);
            return () => {
                window.removeEventListener('click', click, false);
            };
        }

    }, [novo]);

    return (
        <tr key={value.id} style={{cursor: 'pointer'}}>
            <td style={{textAlign: 'left'}}>{value.profession.description}</td>

            <td style={{textAlign: 'left'}}>{value.document} </td>

            <td style={{textAlign: 'right'}}>
                <button className="btn btn-sm btn-icon  btn-active-color-primary" style={{float: 'right'}}
                        onClick={() => setNovo(true)}>
                    <span className="svg-icon svg-icon-muted svg-icon-2hx">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="4" fill="currentColor"/>
                            <rect x="11" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor"/>
                            <rect x="15" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor"/>
                            <rect x="7" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor"/>
                        </svg>
                    </span>
                </button>
                <div className={"dropdown-menu " + (novo ? "show" : '')} style={{marginTop: 40, right: 33}}
                     id="userProfession">
                    <a className="dropdown-item" style={{cursor: 'pointer'}} onClick={() => {
                        openModal('userProfession', {...value,profession:value.profession.id});
                        setNovo(false)
                    }}>Editar</a>
                    <a className="dropdown-item" style={{cursor: 'pointer'}} onClick={() => {
                        destroyUserProfession(value);
                        setNovo(false)
                    }}>Excluir</a>
                </div>
            </td>
        </tr>
    )
}


function Expedients({loading, openModal, items, destroyUserProfession,user}) {


    return (
        <>
            <div className="card mb-xl-5 mb-5" >
                <div className="card-header cursor-pointer">
                    <div className="card-title align-items-start flex-column">
                        <span className="fw-bolder m-0">Profissões</span>
                    </div>
                    <div className="card-toolbar">
                        <button onClick={() => openModal('userProfession', {user: user})}
                                className="btn btn-sm btn-light-primary">
                            Adicionar
                        </button>
                    </div>
                </div>
                <div className="card-body py-3">


                    <div className='table-responsive'>
                        <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                            <thead>
                                <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                                    <th style={{textAlign: 'left'}}>Profissão</th>
                                    <th style={{textAlign: 'left'}}>Registro</th>
                                    <th style={{textAlign: 'right'}}></th>
                                    <th></th>
                                </tr>
                            </thead>
                            {
                                loading === true ?
                                    <tbody>
                                    {
                                        [1, 2, 3].map((value, index) => {
                                            return (
                                                <tr key={index}>

                                                    <td style={{textAlign: 'left'}}>
                                                        <ContentLoader viewBox="0 0 380 35">
                                                            <rect x="0" y="17" rx="4" ry="4" width="200" height="40"/>
                                                        </ContentLoader>
                                                    </td>

                                                    <td style={{textAlign: 'left'}}>
                                                        <ContentLoader viewBox="0 0 380 50">
                                                            <rect x="0" y="17" rx="4" ry="4" width="200" height="40"/>
                                                        </ContentLoader>
                                                    </td>

                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                    :
                                    <tbody>
                                    {
                                        items && items.length > 0 ?
                                            items.map((value) => {

                                                return <List destroyUserProfession={destroyUserProfession} openModal={openModal}
                                                             value={value} key={value.id}/>
                                            })
                                            :
                                            (<tr>
                                                <td colSpan="4">
                                                    <div style={{textAlign: 'center', paddingTop: 7}}>Não foi encontrado
                                                        nenhuma profissão.
                                                    </div>
                                                </td>
                                            </tr>)
                                    }
                                    </tbody>
                            }
                        </table>
                    </div>

                </div>
            </div>
        </>
    );
}

export default Expedients;
