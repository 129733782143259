
export const companyStart = () => ({type: 'COMPANY_START'});
export const companySuccess = (itens) => ({type: 'COMPANY_SUCCESS',itens});
export const companyFail = () => ({type: 'COMPANY_FAIL'});
export const setSpecializations = (specializations) => ({type: 'SET_SPECIALIZATIONS',specializations});
export const getSpecializations = (professional) => ({type: 'GET_SPECIALIZATIONS',professional});
export const setSchedules = (schedules) => ({type: 'SET_SCHEDULES',schedules});
export const setTypeSchedules = (typeschedules) => ({type: 'SET_TYPESCHEDULES',typeschedules});
export const getTypeSchedules = () => ({type: 'GET_TYPESCHEDULES'});
export const setRooms = (rooms) => ({type: 'SET_ROOMS',rooms});
export const getRooms = () => ({type: 'GET_ROOMS'});
export const setTools = (tools) => ({type: 'SET_TOOLS',tools});
export const getTools = (patient) => ({type: 'GET_TOOLS',patient});
export const setStatus = (status) => ({type: 'SET_STATUS',status});
export const getStatus = () => ({type: 'GET_STATUS'});
export const getDiagnostic = () => ({type: 'GET_TYPE_DIAGNOSTIC'});
export const setDiagnostic = (data) => ({type: 'SET_DIAGNOSTIC', data});
export const setLoadingSchedule = (status) => ({type:'SCHEDULES_LOADING',status})
export const loadingTools = (status) => ({type:'TOOLS_LOADING',status});
export const loadingGenerate = (status) => ({type:'TOOLS_DOCUMENT_LOADING',status})
export const documentGenerate = (data) => ({type:'TOOLS_DOCUMENT_GENERATE',data});
