import React, {useCallback, useEffect, useState, createRef} from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from '@fullcalendar/list';
import ptLocale from "@fullcalendar/core/locales/pt";
import {useMediaQuery} from "react-responsive";
import {connect, useDispatch, useSelector} from "react-redux";
import {DateTime, Duration} from "luxon";
import {debounce} from "lodash";
import {checkPermission} from "../../utils";
import {getProfessionals} from "../../store/actions/professionalsActions";
import {openModal} from "../../store/actions/uiActions";
import {getStatus} from "../../store/actions/companiesActions";
import api from "../../api";
import eventContent from "../../components/calendar/EventContent";
import {searchCalls, setEndDate, setPatient, setProfessional, setStartDate} from "../../store/actions/calendarActions";
import Search from "./Search";
import {useHistory, useLocation} from "react-router-dom";

function CalendarView({
                          openModal,
                          statusArray,
                          getStatus,
                          user,
                          searchCalls,
                          loading,
                          events,
                          myScheduler,
                          setStartDate,
                          setEndDate,
                          startDate,
                          endDate,
                          professional,
                          setProfessional,
                          patient,
                          setPatient
                      }) {
    const [expedients, setExpedients] = useState([]);
    const isBigScreen = useMediaQuery({query: "(min-width: 1200px)"});
    const [status, setStatus] = useState(null);
    const [roomLocal, setRoomLocal] = useState();
    const calendarRef = createRef();
    let location = useLocation();
    const [patientLocal, setPatientLocal] = useState();
    const [professionalLocal, setProfessionalLocal] = useState();
    const [aniversariantes, setAniversariantes] = useState([]);
    const dispatch = useDispatch();
    const openCall = (modal, item, typeModal) => dispatch({type: 'OPEN_CALL', modal, item, typeModal});

    const history = useHistory();

    const duration = Duration.fromObject({minutes: user.company.slotDuration ? user.company.slotDuration : 30});
    const dateTime = DateTime.fromObject({hour: 0, minute: 0}).plus(duration);

    const calendarOptions = {
        slotMinTime: "07:00:00",
        slotMaxTime: "22:00:00",
        customButtons: {
            miButton: {
                text: "Legendas",
                click: () => {
                    openModal("legendas");
                },
            }
        },
        headerToolbar: {
            left: "prev,next miButton today",
            center: "title",
            right: isBigScreen ? user.company.id === 195 ? "dayGridMonth,listWeek,listDay" : "dayGridMonth,timeGridWeek,listDay" : "",
        },
        nowIndicator: true,
        scrollTime: "07:00:00",
        eventLimit: "true",
        dayMaxEventRows: 5,
        plugins: [dayGridPlugin, timeGridPlugin, listPlugin],
        selectConstraint: "businessHours",
        contentHeight: isBigScreen ? 677 : 550,
        selectable: true,
        initialView: isBigScreen ? user.company.id === 195 ? "listWeek" : "timeGridWeek" : "listDay",
        allDayText: "24 horas",
        buttonText: {
            week: "Semana",
            day: "Dia",
            month: "Mês",
            today: "Hoje",
        },
        titleFormat: {month: "long", day: "2-digit", year: "numeric"},
        locale: ptLocale,
        slotDuration: dateTime.toFormat('HH:mm:ss'),
        slotLabelFormat: [
            {
                hour: 'numeric',
                minute: '2-digit'
            }, // top level of text
            {weekday: 'short'} // lower level of text
        ]
    };

    useEffect(() => {

        if (calendarRef.current) {
            calendarRef.current.getApi().removeAllEvents();
            calendarRef.current.getApi().addEventSource([...events, ...aniversariantes]);
        }
    }, [events, aniversariantes]);

    const handleDateChange = useCallback(debounce((arg) => {

        const {startStr, endStr} = arg;

        setStartDate(startStr);
        setEndDate(endStr);

        searchCalls({
            dateStart: startStr,
            dateEnd: endStr,
            professional: location.pathname === "/schedule/my" ? [user.id] : professionalLocal,
            patient: patientLocal,
            status: status,
            room: roomLocal,
        });

        const getBirthday = async (startStr, endStr) => {

            const response = await api.post('/user/birthday', {
                dateStart: startStr,
                dateEnd: endStr
            }, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            });

            let array = [];
            (response.data || []).forEach((value) => {
                array.push({
                    patient: value.id,
                    title: value.title,
                    start: value.birthday_date,
                    type: value.type,
                    allDay: true,
                    status: {
                        id: 0
                    },
                    type_schedule: {
                        id: 0
                    },
                    birthday: true
                })
            })

            setAniversariantes(array);
        }

        getBirthday(startStr, endStr);

    }, 100), [professional, patientLocal]);

    const onSubmit = useCallback(async (data) => {
        const room = data.room?.map(value => value.value);

        setProfessional(data.professional.length === 0 ? [] : data.professional);
        setProfessionalLocal(data.professional.length === 0 ? [] : data.professional);
        setStatus(data.status === 0 ? [] : [data.status]);
        setPatient(data.patient.length === 0 ? [] : data.patient);
        setPatientLocal(data.patient.length === 0 ? [] : data.patient);
        setRoomLocal(room?.length === 0 ? [] : room);

        try {
            searchCalls({
                dateStart: startDate,
                dateEnd: endDate,
                professional: myScheduler ? [user.id] : data.professional.length === 0 ? [] : data.professional,
                patient: data.patient.length === 0 ? [] : data.patient,
                status: data.status === 0 ? [] : [data.status],
                room: room?.length === 0 ? [] : room,
            })

            if (data.professional.length === 1) {
                const response = await api.get('/user/' + (data.professional[0]) + "/expedients?format=true", {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                    }
                });

                if (response.status === 200) {
                    setExpedients(response.data);
                }
            } else {
                setExpedients([]);
            }
        } catch (err) {

        }
    }, [startDate, endDate])

    let token = useSelector(state => state.auth.token);

    useEffect(() => {
        getStatus();

        const getExpedient = async () => {
            const response = await api.get('/user/' + (user.id) + "/expedients?format=true", {
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            });
            if (response.status === 200) {
                setExpedients(response.data);
            }
        }

        getExpedient();

        return () => {
        }
    }, []);

    return (
        <>

            <div className="card" style={{zIndex: 3}}>
                <div className="card-header" style={{minHeight: 15}}>
                    <div className="card-title" style={{width: '100%'}}>
                        <div style={{display: "flex", flexDirection: "row", width: '100%'}}>
                            <div style={{flex: 1}}>
                                Filtro
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <Search user={user} statusArray={statusArray} onSubmit={onSubmit} myScheduler={myScheduler}/>
                </div>
            </div>

            <div className="card" style={{marginTop: 20}}>
                <div className="card-body">
                    {loading ? <div style={{
                        position: "absolute",
                        zIndex: 999,
                        width: isBigScreen ? "95.4%" : "85%",
                        background: "#c7c7c71c",
                        alignContent: "center",
                        display: "flex",
                        justifyContent: "space-around",
                        height: "93.3%",
                        borderRadius: 5,
                        textAlign: "center",
                        flexDirection: "column",
                    }}>Carregando...</div> : <></>}
                    <FullCalendar
                        ref={calendarRef}

                        businessHours={expedients && expedients.length > 0 ? expedients : false}

                        selectConstraint="businessHours"

                        {...calendarOptions}

                        eventClick={(at) => {
                            if (at.event.extendedProps.birthday) {

                                if (at.event.extendedProps.type === 3) {
                                    history.push('/patient/' + at.event.extendedProps.patient)
                                }

                                return;
                            }
                            if (at.event.display === "background") return;

                            checkPermission(user.permissions, "calls.show") && openCall("session", {
                                ...at.event.extendedProps,
                                id: at.event.id,
                                edit: true,
                                start_date: at.event.extendedProps.start_date,
                                type: 1
                            },"secondary");


                        }}
                        eventContent={(event) => eventContent(event, true)}
                        selectOverlap={(event) => {
                            return event.rendering === "background";
                        }}
                        events={myScheduler ? [...events.filter(value => value.professional.id === user.id), ...aniversariantes] : [...events, ...aniversariantes]}
                        datesSet={handleDateChange}
                    />
                </div>
            </div>
        </>
    );
}

const mapStateToprops = state => ({
    user: state.auth,
    statusArray: state.companies.status,
    events: state.calendar.itens,
    loading: state.calendar.loading,
    startDate: state.calendar.startDate,
    endDate: state.calendar.endDate,
    professional: state.calendar.professional,
    patient: state.calendar.patient
});

const mapDispatchToprops = dispatch => ({
    getProfessionals: (typeUser, page, perPage) => dispatch(getProfessionals(typeUser, page, perPage)),
    openModal: (modal, payload) => dispatch(openModal(modal, payload)),
    getStatus: () => dispatch(getStatus()),
    searchCalls: (data) => dispatch(searchCalls(data)),
    setStartDate: (startDate) => dispatch(setStartDate(startDate)),
    setEndDate: (endDate) => dispatch(setEndDate(endDate)),
    setProfessional: (data) => dispatch(setProfessional(data)),
    setPatient: (data) => dispatch(setPatient(data))
});

export default connect(mapStateToprops, mapDispatchToprops)(React.memo(CalendarView));
