import {connect} from 'react-redux';
import React, { useMemo, useState} from 'react';
import {Field} from "../../components/Field";
import {DateTime} from "luxon";
import {Controller, useForm} from "react-hook-form";
import {formatDateNotHour} from "../../utils";
import {closeAny, openAny} from "../../store/actions/uiActions";
import PieChartWithLoader from "../../components/dashboard/PieChartWithLoader";
import CardStatus from "./charts/CardStatus";
import ListWithLoader from "../../components/dashboard/ListWithLoader";
import RankingWithLoader from "../../components/dashboard/RankingWithLoader";

const Filter = ({closeAny,handleSubmit,control,isMobile,getValues,openAny,setRequestData}) => {

    const onSubmit = (data) => {
        closeAny();
        setRequestData({...data,professional: data.professional === 0 ? null : data.professional})
    }

    const Form = useMemo(()=>{

        return (
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col-md-4">
                        <div className='row'>
                            <div className='col-md-6' style={{marginTop: 10}}>
                                <label className="fw-bold text-muted">Filtro por período:</label>
                                <Controller
                                    name="startDate"
                                    control={control}
                                    render={({field: {onChange, value}}) =>
                                        <Field type="date" value={value} onChange={onChange}/>
                                    }
                                />
                            </div>
                            <div className='col-md-6' style={{marginTop: 10}}>
                                <label></label>
                                <Controller
                                    name="endDate"
                                    control={control}
                                    render={({field: {onChange, value}}) =>
                                        <Field type="date" value={value} onChange={onChange}/>
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-1" style={{marginTop: 30}}>
                        <button className='btn btn-success btn-sm btn-block' style={{width: '100%'}}>
                            <div style={{width: 20, margin: 'auto'}}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path
                                        d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352c79.5 0 144-64.5 144-144s-64.5-144-144-144S64 128.5 64 208s64.5 144 144 144z"
                                        fill="#fff"/>
                                </svg>
                            </div>
                        </button>
                    </div>
                    <div className="col-md-5"></div>
                </div>
            </form>
        )
    },[]);


    if(isMobile){
        return(
            <div
                style={{padding: "10px 10px",display: 'flex', flexDirection: 'row'}}
            >
                <div style={{
                    flex: 1,
                    textAlign: 'left'
                }}>
                    {formatDateNotHour(DateTime.fromSQL(getValues('startDate')))} - {formatDateNotHour(DateTime.fromSQL(getValues('endDate')))}
                </div>
                <div >
                    <span
                        className="svg-icon svg-icon-black "
                        onClick={() =>
                            openAny(Form,'Filtro','lg')
                        }
                    >
                        <svg width="20" height="24" viewBox="0 0 20 20" fill="#000" xmlns="http://www.w3.org/2000/svg">
                            <rect x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="#000"/>
                            <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="#000"/>
                        </svg>
                    </span>
                </div>
            </div>
        )
    }

    return Form;
}

function Index({isMobile,openAny,closeAny}) {
    const [requestData,setRequestData] = useState({
        startDate:DateTime.local().startOf('month').toFormat('yyyy-MM-dd'),
        endDate:DateTime.local().endOf("month").toFormat('yyyy-MM-dd'),
    });

    const {control, handleSubmit,getValues} = useForm({
        defaultValues:{
            startDate:DateTime.local().startOf('month').toFormat('yyyy-MM-dd'),
            endDate:DateTime.local().endOf("month").toFormat('yyyy-MM-dd'),
        }
    });

    return (
        <>
            <div className="row" style={{marginTop:10}}>
                <div className="col-md-2" style={{marginBottom: 10}}>
                    <CardStatus title="Pacientes ativos" status={1} />
                </div>
                <div className="col-md-2" style={{marginBottom: 10}}>
                    <CardStatus title="Pacientes inativos" status={0} />
                </div>
                <div className="col-md-12">
                    <Filter
                        setRequestData={setRequestData}
                        isMobile={isMobile}
                        closeAny={closeAny}
                        getValues={getValues}
                        control={control}
                        handleSubmit={handleSubmit}
                        openAny={openAny}
                    />
                </div>
                <div className="col-md-4">
                    <PieChartWithLoader
                        endpoint="/user/dashboard/waitingCallAndInCall"
                        requestData={requestData}
                        title="Em atendimento ou aguardando"
                        labelField="description"
                        valueField="total"
                        information="Este gráfico é baseado nos pacientes cadastrados no período especificado, indicando se possuem ou não uma equipe de tratamento definida."
                    />
                </div>
                <div className="col-md-4">
                    <PieChartWithLoader
                        endpoint="/user/dashboard/diagnostic"
                        requestData={requestData}
                        title="Paciente por diagnóstico"
                        labelField="diagnostic"
                        valueField="total"
                        information="Este gráfico é baseado nos pacientes com diagnóstico definido."
                    />
                </div>
                <div className="col-md-4">
                    <PieChartWithLoader
                        endpoint="/user/dashboard/status"
                        requestData={requestData}
                        title="Por status do tratamento"
                        labelField="status"
                        valueField="total"
                        colorField="color"
                        information="Este gráfico é baseado nas atualizações do status do tratamento do paciente dentro do período especificado."
                    />
                </div>
                <div className="col-md-4">
                    <PieChartWithLoader
                        endpoint="/user/dashboard/treatmentInCall"
                        requestData={requestData}
                        title="Em atendimento por tratamento"
                        labelField="specialization_id"
                        valueField="total"
                        information="Este gráfico é baseado nos pacientes que têm uma equipe de tratamento definida."
                    />
                </div>
                <div className="col-md-4">
                    <ListWithLoader
                        endpoint="/user/dashboard/inCall"
                        requestData={requestData}
                        title="Pacientes em atendimento"
                        labelField="name"
                        information="Esta lista é baseada nos pacientes que têm uma equipe de tratamento definida."
                    />
                </div>
                <div className="col-md-4">
                    <RankingWithLoader
                        endpoint="/user/dashboard/patientLack"
                        requestData={requestData}
                        title="Faltas por paciente"
                        labelField="patient"
                        valueField="total"
                        information="Esta lista é baseada nos atendimentos que o paciente desmarcou durante o período pesquisado."
                    />
                </div>
                <div className="col-md-4">
                    <PieChartWithLoader
                        endpoint="/user/dashboard/treatmentWaiting"
                        requestData={requestData}
                        title="Aguardando por tratamento"
                        labelField="specialization_id"
                        valueField="total"
                        information="Este gráfico é baseado nos tratamentos que estão aguardando na fila de espera."
                    />
                </div>
                <div className="col-md-4">
                    <ListWithLoader
                        endpoint="/user/dashboard/waitingCall"
                        requestData={requestData}
                        title="Pacientes aguardando atendimento"
                        labelField="name"
                        information="Esta lista é baseada nos pacientes que não têm uma equipe de tratamento definida."
                    />
                </div>
                <div className="col-md-4">
                    <RankingWithLoader
                        endpoint="/user/dashboard/professionalLack"
                        requestData={requestData}
                        title="Faltas por profissional"
                        labelField="professional"
                        valueField="total"
                        information="Esta lista é baseada nos atendimentos que o profissional desmarcou durante o período pesquisado."
                    />
                </div>
            </div>
        </>
    );
}

const mapDispatchToProps = dispatch => ({
    openAny: (children,title,size) => dispatch(openAny(children,title,size)),
    closeAny:() => dispatch(closeAny())
})

export default connect(null, mapDispatchToProps)(Index)