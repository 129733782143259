import {  put, takeEvery,select} from 'redux-saga/effects';
import api from '../../api';
import { reportsFail, reportsStart, reportsSuccess } from '../actions/reportsActions';

export const getAuthToken = (state) => state.auth.token;
export const getAuthCompany = (state) => state.auth.company.id

function* get(action){

    try{
        yield put(reportsStart());

        const response = yield  api.get('/reports/'+action.page,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield put(reportsSuccess(response.data))
        }else{
            yield put(reportsFail())
        }
    } catch (e) {
        console.error(e);
        yield put(reportsFail())
    }
}


export function* reportsSaga() {
    yield takeEvery('GET_REPORTS', get);
}
