import {connect, useSelector} from "react-redux";
import blank from "../../assets/img/blank.png";
import React, {useCallback, useEffect, useState} from "react";
import api from "../../api";
import {useParams} from "react-router-dom";

function Company({isMobile}) {
    const [companies, setCompanies] = useState({})
    const {id} = useParams();


    const token = useSelector(state => state.auth.token);

    const fetchCompanies = useCallback(async () => {

        const response = await api.get(
            "/company/" + id,
            {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json'
                }
            });
        setCompanies(response.data)

    }, [token, id]);

    useEffect(() => {
        fetchCompanies();
    }, [token]);

    return (
        <div className="card" style={{marginBottom: 20, borderRadius: isMobile ? -30 : 10}}>
            <div className="card-body">
                <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                    {companies.logo && <div className="me-7 mb-4" style={{marginRight: 30}}>
                        <div className="image-input image-input-outline">
                            <div className="image-input-wrapper" style={{
                                backgroundSize: 'contain',
                                backgroundImage: `url(${companies.logo ? companies.logo : blank})`
                            }}></div>

                            <>
                                <label
                                    className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                    data-kt-image-input-action="change" data-bs-toggle="tooltip"
                                    aria-label="Change avatar">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13"
                                         fill="currentColor" className="bi bi-pencil-fill" viewBox="0 0 16 16">
                                        <path
                                            d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"
                                            fill='#a1a5b7'/>
                                    </svg>
                                    <input type="file" name="avatar" accept=".png, .jpg, .jpeg"
                                           onChange={(e) => console.log('')}/>
                                    <input type="hidden" name="avatar_remove"/>
                                </label>

                            </>

                        </div>
                    </div>}

                    <div className="flex-grow-1">
                        <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                            <div className="d-flex flex-column">
                                <div className="d-flex align-items-center mb-2">
                                    <div className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">
                                        {companies.description}
                                    </div>
                                </div>
                                <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                                    {
                                        companies.id ?
                                            <span
                                                className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                                                <b style={{marginRight: 10}}>Identificador: </b> {companies.id}
                                            </span>
                                            :
                                            <></>
                                    }
                                    {
                                        companies.cpf ?
                                            <span
                                                className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                                                <b style={{marginRight: 10}}>CPF: </b> {companies.cpf}
                                            </span>
                                            :
                                            <></>
                                    }
                                    {
                                        companies.cnpj ?
                                            <span
                                                className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                                                <b style={{marginRight: 10}}>CNPJ: </b> {companies.cnpj}
                                            </span>
                                            :
                                            <></>
                                    }
                                    {
                                        companies.email ?
                                            <span
                                                className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                                                <b style={{marginRight: 10}}>Email: </b> {companies.email}
                                            </span>
                                            :
                                            <></>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    auth: state.auth,

});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Company);