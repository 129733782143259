import React, { useEffect } from "react";
import isMobile from "is-mobile";
import {useSelector} from "react-redux";

function Modal({color,title,size,children,closeModal,type,zIndex,modalRef,fullscreen}) {
    const  isMobileV =  isMobile({featureDetect:true,tablet:true});

    const {modalOpen,modalOpenSecondary,modalOpenTertiary} = useSelector(state => state.ui)

    useEffect(() =>{
        document.body.style.overflow = "hidden";

        return () =>{
            if(!modalOpen || !modalOpenSecondary || !modalOpenTertiary){
                document.body.style.overflow = 'auto';
            }
        }
    },[closeModal,isMobileV,modalOpen,modalOpenSecondary,modalOpenTertiary]);

   const zIndexr = zIndex ? 199999+zIndex : 199999

    let style = {
        display: 'block',
        zIndex: zIndexr+1,
        backgroundColor: color ? color : '#fff'
    }

    if(isMobileV){
        style = {
            display: 'block',
            zIndex: zIndexr+1,
            width:"100%",
            backgroundColor: color ? color : '#fff'
        }
    }

    return (
        <>
            {
                type === 'drawers' ||  isMobileV  ?
                    <div
                        className="bg-body drawer drawer-end drawer-on"
                        style={style}
                    >
                        <div className={"card shadow-none border-0 rounded-0"} style={{backgroundColor: color ? color : '#fff',width:'100%'}}>
                            {
                                isMobileV ?
                                    <div
                                        className={'card-header'}
                                         style={{ paddingTop: 5, paddingBottom: 5,minHeight:60}}
                                    >
                                        <h4
                                            className={'card-title'}
                                            style={{marginLeft: '-10px'}}
                                        >
                                            <button
                                                style={{marginRight: 20}}
                                                onClick={() => {
                                                    closeModal()
                                                }}
                                                className="btn btn-sm btn-icon btn-active-color-primary"
                                            >
                                                <span className="svg-icon svg-icon-muted  svg-icon-2hx">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M11.2657 11.4343L15.45 7.25C15.8642 6.83579 15.8642 6.16421 15.45 5.75C15.0358 5.33579 14.3642 5.33579 13.95 5.75L8.40712 11.2929C8.01659 11.6834 8.01659 12.3166 8.40712 12.7071L13.95 18.25C14.3642 18.6642 15.0358 18.6642 15.45 18.25C15.8642 17.8358 15.8642 17.1642 15.45 16.75L11.2657 12.5657C10.9533 12.2533 10.9533 11.7467 11.2657 11.4343Z"
                                                            fill="#000"/>
                                                    </svg>
                                                </span>
                                            </button>
                                            {title}
                                        </h4>
                                    </div>
                                    :
                                    <div className="card-header">
                                        <h3 className="card-title fw-bold text-dark"
                                            style={{paddingLeft: 0}}>{title}</h3>
                                        <div className="card-toolbar">
                                            <button
                                                onClick={() => {
                                                    closeModal()
                                                }}
                                                className="btn btn-sm btn-icon btn-active-color-primary"
                                            >
                                                <span className="svg-icon svg-icon-1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                         viewBox="0 0 24 24" fill="none">
                                                        <rect opacity="0.5" x="6" y="17.3137" width="16" height="2"
                                                              rx="1" transform="rotate(-45 6 17.3137)"
                                                              fill="black"></rect>
                                                        <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                                              transform="rotate(45 7.41422 6)" fill="black"></rect>
                                                    </svg>
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                            }
                            <div className="card-body" ref={modalRef} style={{overflowY: "auto", overflowX: 'hidden'}}>
                                {children}
                            </div>
                        </div>
                    </div>
                    :

                    <div className="modal fade show" style={{display: 'block', zIndex: zIndexr + 1}}>
                        <div className={(fullscreen ? "modal-fullscreen " :"")+"modal-dialog modal-dialog-centered  modal-" + size}>
                            <div className="modal-content" style={{backgroundColor: color ? color : '#fff'}}>
                                <div className="modal-header">
                                    <h2 className="card-label fw-bolder fs-3 " style={{paddingLeft: 0}}>{title}</h2>
                                    <button onClick={() => {
                                        closeModal()
                                    }} className="btn btn-sm btn-icon btn-active-color-primary">
                                                <span className="svg-icon svg-icon-1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                         viewBox="0 0 24 24" fill="none">
                                                        <rect opacity="0.5" x="6" y="17.3137" width="16" height="2"
                                                              rx="1" transform="rotate(-45 6 17.3137)"
                                                              fill="black"></rect>
                                                        <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                                              transform="rotate(45 7.41422 6)" fill="black"></rect>
                                                    </svg>
                                                </span>
                                        </button>
                                    </div>
                                    <div className="modal-body" style={{paddingTop:5}}>
                                        {children}
                                    </div>
                                </div>
                            </div>
                        </div>
            }
            <div
                data-test={zIndexr}
                style={{
                    position: 'fixed',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: '#69696970',
                    zIndex: parseInt(zIndexr)
                }}
            >
            </div>
        </>
    );
}



export default Modal
