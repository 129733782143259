import React, { useEffect, useState } from 'react';
import api from '../../api';
import {connect, useSelector} from 'react-redux';
import ListChartLoader from "../loading/ListChartLoader";
import {openAny} from "../../store/actions/uiActions";

const RankingWithLoader = ({ endpoint, requestData, title, labelField, valueField,openAny,information }) => {
    const token = useSelector(state => state.auth.token);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setData([])
                setLoading(true);
                const response = await api.post(endpoint, requestData, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (response.status === 200) {
                    setData(response.data);
                }
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [token, endpoint, requestData]);

    return (
        <div className="card" style={{ marginTop: 10 }}>
            <div className="card-header" style={{ padding: '5px 20px', minHeight: 30,fontSize:11 , display:"flex"}}>
                <div className="card-title" style={{fontSize:15}}>{title}</div>
                {information ? <div className="card-toolbar">
                    <span style={{cursor:"pointer"}} title={information} className="svg-icon svg-icon-black  svg-icon-2" onClick={() => openAny(<div>{information}</div>,'Informações')}>
                         <svg width="24" height="24" viewBox="0 0 24 24" fill="none"  xmlns="http://www.w3.org/2000/svg">
                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
                            <path d="M11.276 13.654C11.276 13.2713 11.3367 12.9447 11.458 12.674C11.5887 12.394 11.738 12.1653 11.906 11.988C12.0833 11.8107 12.3167 11.61 12.606 11.386C12.942 11.1247 13.1893 10.896 13.348 10.7C13.5067 10.4947 13.586 10.2427 13.586 9.944C13.586 9.636 13.4833 9.356 13.278 9.104C13.082 8.84267 12.69 8.712 12.102 8.712C11.486 8.712 11.066 8.866 10.842 9.174C10.6273 9.482 10.52 9.82267 10.52 10.196L10.534 10.574H8.826C8.78867 10.3967 8.77 10.2333 8.77 10.084C8.77 9.552 8.90067 9.07133 9.162 8.642C9.42333 8.20333 9.81067 7.858 10.324 7.606C10.8467 7.354 11.4813 7.228 12.228 7.228C13.1987 7.228 13.9687 7.44733 14.538 7.886C15.1073 8.31533 15.392 8.92667 15.392 9.72C15.392 10.168 15.322 10.5507 15.182 10.868C15.042 11.1853 14.874 11.442 14.678 11.638C14.482 11.834 14.2253 12.0533 13.908 12.296C13.544 12.576 13.2733 12.8233 13.096 13.038C12.928 13.2527 12.844 13.528 12.844 13.864V14.326H11.276V13.654ZM11.192 15.222H12.928V17H11.192V15.222Z"  fill="currentColor"/>
                        </svg>
                    </span>
                </div> : <></>}
            </div>
            <div className="card-body" style={{height:313,overflowY:'auto'}}>
                {
                    loading ? (
                        <ListChartLoader />
                    ) :
                    data.length > 0 ?
                        data?.map((value,index) => {
                            return(
                                <div key={index}>
                                    <div className="d-flex flex-stack">
                                        <div className="text-gray-700 fw-bold fs-6 me-2">{index+1}º  - {value[labelField]}</div>
                                        <div className="d-flex align-items-senter">
                                            <span className="text-gray-900 fw-boldest fs-6">{value[valueField]}</span>
                                        </div>
                                    </div>
                                    <div className="separator separator-dashed my-3"></div>
                                </div>
                            )
                        })
                        :
                        <div
                            style={{
                                display:"grid",
                                alignItems:"center",
                                height:260,
                                textAlign:"center"
                            }}
                        >
                            Não encontramos dados correspondentes. Tente ajustar seus critérios de busca.
                        </div>
                }
            </div>
        </div>
    );
};


const mapDispatchToProps = dispatch => ({
    openAny:(children,title,size) => dispatch(openAny(children,title,size)),
});

export default connect(null,mapDispatchToProps)(RankingWithLoader);

