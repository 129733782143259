
export const getBankAccount = () => ({type: 'GET_BANK_ACCOUNT'});
export const getBank = () => ({type: 'GET_BANK'});
export const bankAccountSuccess = (data) => ({type: 'BANK_ACCOUNT_SUCCESS',data});
export const bankSuccess = (data) => ({type: 'BANK_SUCCESS',data});
export const bankAccountLoading = (status) => ({type: 'BANK_ACCOUNT_LOADING',status});
export const updateBankAccount = (data,id) => ({type: 'UPDATE_BANK_ACCOUNT',data,id});
export const storeBankAccount = (data) => ({type: 'STORE_BANK_ACCOUNT',data});
export const destroyBankAccount = (id) => ({type: 'DESTROY_BANK_ACCOUNT',id});









