import {connect, useDispatch} from 'react-redux';
import React, {useEffect, useState} from 'react';
import { Field } from "../../components/Field";
import ListAtendimentos from "../../components/dashboard/listAtendimentos";
import DoughnutStatus from '../../components/dashboard/DoughnutStatus';
import {
    getCallsStartEnd, getCountCallsNotSigned,
    getCountPendencies, getCountPlanTherapeutic,
    setEndDate,
    setStartDate
} from "../../store/actions/calendarActions";
import { getNotification } from '../../store/actions/authActions';
import { getProfessionals } from '../../store/actions/professionalsActions';
import ListPendencies from '../../components/dashboard/ListPendencies';
import CallsToday from '../../components/dashboard/CallsToday';
import {DateTime} from "luxon";
import {closeAny, openAny, openModalTertiary} from "../../store/actions/uiActions";
import {formatDateNotHour} from "../../utils";
import { Controller, useForm } from "react-hook-form";

const Filter = ({getCountCallsNotSigned,professionalSearchHome,getCountPlanTherapeutic,setProfessional,professionals,getCallsStartEnd,getCountPendencies,closeAny,user,handleSubmit,control}) => {

    const professionalsArray = professionals?.map(value => ({label:value.name,value:value.id}));
    professionalsArray.unshift({value:0,label:'Todos'})

    const onSubmit = (data) => {
        closeAny();
        professionalSearchHome(data.professional === 0 ?  null : data.professional);
        setProfessional(data.professional === 0 ?  null : data.professional)
        getCallsStartEnd({ noLock:true, professional: data.professional === 0 ?  [] : [data.professional], patient: [], status: [], dateStart: data.dateStart+" 00:00:00", dateEnd: data.dateEnd+" 23:59:00" });
        getCountPendencies({ professional: data.professional === 0 ?  null : data.professional })
        getCountPlanTherapeutic({ professional: data.professional === 0 ?  null : data.professional })
        getCountCallsNotSigned({ professional: data.professional === 0 ?  null : data.professional })
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                {
                    user.admin === true || user.subadmin === true ?
                        <div className="col-md-2" style={{marginTop: 10}}>
                            <label className="fw-bold text-muted">Profissional:</label>
                            <Controller
                                name="professional"
                                control={control}
                                render={({field: {onChange, value}}) =>
                                    <Field type="select" value={value} onChange={onChange}
                                           options={professionalsArray}/>
                                }
                            />
                        </div>
                        :
                        <></>
                }
                <div className="col-md-4">
                    <div className='row'>
                        <div className='col-md-6' style={{marginTop: 10}}>
                            <label className="fw-bold text-muted">Filtro por período:</label>
                            <Controller
                                name="dateStart"
                                control={control}
                                render={({field: {onChange, value}}) =>
                                    <Field type="date" value={value} onChange={onChange}/>
                                }
                            />
                        </div>
                        <div className='col-md-6' style={{marginTop: 10}}>
                            <label></label>
                            <Controller
                                name="dateEnd"
                                control={control}
                                render={({field: {onChange, value}}) =>
                                    <Field type="date" value={value} onChange={onChange}/>
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-1" style={{marginTop: 32}}>
                    <button className='btn btn-success btn-sm btn-block' style={{width: '100%'}}>
                        <div style={{width: 20, margin: 'auto'}}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path
                                    d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352c79.5 0 144-64.5 144-144s-64.5-144-144-144S64 128.5 64 208s64.5 144 144 144z"
                                    fill="#fff"/>
                            </svg>
                        </div>
                    </button>
                </div>
                <div className="col-md-5"></div>
            </div>
        </form>
    )
}

function Overview({ getCountCallsNotSigned, getCountPlanTherapeutic, getCountPendencies, openModalTertiary, closeAny, isMobile, professionals, getProfessionals,user,getNotification,getCallsStartEnd,items,loading,setStartDate,setEndDate,openAny}) {
    const dispatch = useDispatch();
    const [dateStart,setDateStart] = useState(DateTime.local().toFormat('yyyy-MM-dd'));
    const [dateEnd,setDateEnd] = useState(DateTime.local().toFormat('yyyy-MM-dd'));
    const [professional,setProfessional] = useState(null);

    const professionalSearchHome = (professional) => dispatch({type:'PROFESSIONAL_SEARCH_HOME',professional});

    const openCall = (modal,item,typeModal) => dispatch({type:'OPEN_CALL',modal,item,typeModal});

    const {control, handleSubmit,getValues} = useForm({
        defaultValues:{
            professional: (user.admin === true || user.subadmin === true) ? 0 : user.id,
            dateStart:DateTime.local().toFormat('yyyy-MM-dd'),
            dateEnd:DateTime.local().toFormat('yyyy-MM-dd'),
        }
    });

    useEffect(()=>{
        getNotification();
        setStartDate('');
        setEndDate('');

        if(user.admin === true || user.subadmin === true ) {
            getProfessionals(1,1,100);
            getCallsStartEnd({ noLock:true, professional: [], patient: [], status: [], dateStart: dateStart+" 00:00:00", dateEnd: dateEnd+" 23:59:00" });
            getCountPendencies();
            getCountPlanTherapeutic();
            professionalSearchHome(null);
            getCountCallsNotSigned();
        }else{
            professionalSearchHome(user.id)
            setProfessional(user.id);
            getCallsStartEnd({ noLock:true, professional: [user.id], patient: [], status: [], dateStart: dateStart+" 00:00:00", dateEnd: dateEnd+" 23:59:00" });
            getCountPendencies({ professional: user.id});
            getCountPlanTherapeutic({ professional: user.id});
            getCountCallsNotSigned({ professional: user.id});
        }

        return () => {
            professionalSearchHome(null);
        }
    },[]);

    return (
        <>
            {
                isMobile ?
                    <>
                        <div style={{padding: "10px 20px", backgroundColor: '#fff',display: 'flex', flexDirection: 'row'}}>
                            <div style={{
                                flex: 1,
                                textAlign: 'left'
                            }}>
                                {formatDateNotHour(DateTime.fromSQL(getValues('dateStart')))} - {formatDateNotHour(DateTime.fromSQL(getValues('dateEnd')))}
                            </div>
                            <div >
                                <span className="svg-icon svg-icon-black " onClick={() => openAny(<Filter professionalSearchHome={professionalSearchHome} setProfessional={setProfessional} professionals={professionals} control={control} closeAny={closeAny} handleSubmit={handleSubmit} getCallsStartEnd={getCallsStartEnd} getCountPendencies={getCountPendencies} user={user} getCountPlanTherapeutic={getCountPlanTherapeutic} getCountCallsNotSigned={getCountCallsNotSigned} />,'Filtro','lg')}>
                                    <svg width="20" height="24" viewBox="0 0 20 20" fill="#000" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="#000"/>
                                        <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="#000"/>
                                    </svg>
                                </span>
                            </div>
                        </div>
                        <DoughnutStatus data={items} loading={loading} isMobile={isMobile}  />
                        <div style={{padding: 20}}>
                            <CallsToday
                                openAny={openAny}
                                isMobile={isMobile}
                                data={items}
                                openCall={openCall}
                                dateStart={dateStart}
                                dateEnd={dateEnd}
                                loading={loading}

                                auth={user}
                            />
                            <div style={{marginTop: 20}}/>
                            <ListPendencies
                                openAny={openAny}
                                isMobile={isMobile}
                                openCall={openCall}
                                loading={loading}
                                openModalTertiary={openModalTertiary}
                                auth={user}
                                professional={professional}
                            />
                            <div style={{marginTop: 20}}/>
                        </div>
                    </>
                    :
                    <>
                        <Filter
                            professionals={professionals}
                            closeAny={closeAny}
                            getCallsStartEnd={getCallsStartEnd}
                            getCountPendencies={getCountPendencies}
                            getCountPlanTherapeutic={getCountPlanTherapeutic}
                            user={user}
                            handleSubmit={handleSubmit}
                            control={control}
                            setProfessional={setProfessional}
                            professionalSearchHome={professionalSearchHome}
                            getCountCallsNotSigned={getCountCallsNotSigned}
                        />
                        <div className="row">
                            <div className="col-md-4" style={{marginTop: 20}}>
                                <ListAtendimentos data={items} loading={loading}/>
                            </div>
                            <div className="col-md-4" style={{marginTop: 20}}>
                                <DoughnutStatus data={items} loading={loading}/>
                            </div>
                            <div className="col-sm-4" style={{marginTop: 20}}>
                                <ListPendencies
                                    openModalTertiary={openModalTertiary}
                                    openAny={openAny}
                                    loading={loading}
                                    auth={user}
                                    professional={professional}
                                />
                            </div>
                            <div className="col-md-12" style={{marginTop: 10}}>
                                <CallsToday
                                    openAny={openAny}
                                    data={items}
                                    openCall={openCall}
                                    dateStart={dateStart}
                                    dateEnd={dateEnd}
                                    loading={loading}
                                    auth={user}
                                />
                            </div>
                        </div>
                    </>
            }
        </>
    );
}

const mapStateToProps = state => {
    return {
        listAtendimentos: state.calendar.dashboard.listAtendimentos,
        status: state.calendar.dashboard.status,
        professionals: state.professionals.itens,
        user: state.auth,
        items: state.calendar.dashboard.callsDay ? state.calendar.dashboard.callsDay.itens : [],
        loading: state.calendar.dashboard.callsDay ? state.calendar.dashboard.callsDay.loading : false,
    };
};

const mapDispatchToProps = dispatch => ({
    getProfessionals: (typeUser, page, perPage) => dispatch(getProfessionals(typeUser, page, perPage)),
    getNotification: () => dispatch(getNotification()),
    getCallsStartEnd: (data) => dispatch(getCallsStartEnd(data)),
    openAny: (children,title,size) => dispatch(openAny(children,title,size)),
    setStartDate: (startDate) => dispatch(setStartDate(startDate)),
    setEndDate: (endDate) => dispatch(setEndDate(endDate)),
    closeAny:() =>dispatch(closeAny()),
    openModalTertiary:(modal,payload) =>dispatch(openModalTertiary(modal,payload)),
    getCountPendencies:(data) => dispatch(getCountPendencies(data)),
    getCountPlanTherapeutic:(data) => dispatch(getCountPlanTherapeutic(data)),
    getCountCallsNotSigned:(data) => dispatch(getCountCallsNotSigned(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Overview);