import {checkPermission} from "../../utils";
import React from "react";
import Address from "../patients/address";
import ExpedientsController from "./ExpedientsController";
import UserProfessionController from "./UserProfessionController";
const Info = ({item,user}) => {

    return (
        <div className="row">
            <div className="col-md-6">
                <div className="row">
                    {checkPermission(user.permissions, "address.list") && <div className="col-md-12">
                        {item?.id && <Address patient={item.id} />}
                    </div>}
                </div>
            </div>
            {checkPermission(user.permissions, "address.list") && <div className="col-md-6">
                {item?.id && <UserProfessionController user={item.id} />}
            </div>}
            {checkPermission(user.permissions, "address.list") && <div className="col-md-12">
                {item?.id &&  <ExpedientsController id={item.id} />}
            </div>}
        </div>
    )
}

export default Info