import blank from "../../assets/img/blank.png";
import React, { useEffect, useState } from "react";
import {canSign} from "../../utils";

export default function Profile({ auth, dispatch }) {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {

    function click(ev) {
      if (ev.srcElement.closest("#profile") == null) {
        setOpen(false);
      }
    }

    if (isOpen) {
      window.addEventListener("click", click, false);
      return () => {
        window.removeEventListener("click", click, false);
      };
    }

  }, [isOpen]);


  return (
      <>
        <div className="d-flex mt-1">
          <div className="cursor-pointer symbol symbol-35px" style={{borderRadius:50}} title={auth.name}  onClick={() => setOpen(true)}>
            <img src={auth.img ? auth.img : blank} alt="foto do profissional" />
          </div>
        </div>
        <div id="profile" className={"menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px " + (isOpen ? "show" : "")} style={{ zIndex: 99999999, position: "fixed", right: 15, top: 60, transform: "translate(0px, 0px)" }}>
          <div className="menu-item px-3">
            <div className="menu-content d-flex align-items-center px-3">
              <div className="symbol symbol-50px me-5">
                <img src={auth.img ? auth.img : blank} alt="foto do profissional" />
              </div>
              <div className="d-flex flex-column">
                <div className="fw-bolder d-flex align-items-center fs-5">
                  <div style={{ textOverflow: "ellipsis", overflow: "hidden", width: 180 }}>{auth.name} </div>
                </div>
                <a href={"mailto:" + auth.email} className="fw-bold text-muted text-hover-primary fs-7">{auth.email}</a>
                <a href={"tel:" + auth.cellphone} className="fw-bold text-muted text-hover-primary fs-7">+{auth.cellphone && auth.ddi +" "+ auth.cellphone.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3")}</a>
              </div>
            </div>
          </div>
          <div className="separator my-2"></div>
          <div className="menu-item px-5">
            <a style={{cursor:'pointer'}} className="menu-link px-5" onClick={() => {dispatch({ type: "UI_OPEN_MODAL", modal: "user", payload:{...auth,"myData":true}});setOpen(false);}}>Meus dados</a>
          </div>
          <div className="menu-item px-5">
            <a style={{cursor:'pointer'}} className="menu-link px-5" onClick={() => {dispatch({ type: "UI_OPEN_MODAL", modal: "alterPassword" });setOpen(false);}}>Alterar senha</a>
          </div>
          {canSign() ? <div className="menu-item px-5">
            <a style={{cursor:'pointer'}} className="menu-link px-5" onClick={() => {dispatch({ type: "UI_OPEN_MODAL", modal: "certificateModal" });setOpen(false);}}>Meu certificado</a>
          </div>: <></>}
          <div className="separator my-2"></div>
          <div className="menu-item px-5">
            <a target="_black" href={"https://api.whatsapp.com/send?phone=5521990452839&text=Olá, me chamo *" + auth.name + "*, estou precisando de ajuda."} className="menu-link px-5">Suporte no Whatsapp</a>
          </div>
          <div className="separator my-2"></div>
          <div className="menu-item px-5">
            <a style={{cursor:'pointer'}} onClick={() => dispatch({ type: "AUTH_LOGOUT" })} className="menu-link px-5">Sair</a>
          </div>
        </div>

      </>
  );
}
