import {updateObject} from '../../utils';

const initialState = {
    loading:true,
    itens:[]
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'REPORTS_START':
            return updateObject(state, {loading: true});
        case 'REPORTS_SUCCESS':
            return updateObject(state, {loading: false,itens:action.data});
        case 'REPORTS_FAIL':
            return updateObject(state, {loading: false,itens:[]});
        default:
            return state;        
    }
}

export default reducer;
  