import  React from 'react';
import DataTable from 'react-data-table-component';

function Table(props) {
    
    return (
        <DataTable
            {...props}
        />
    );
}


export default Table