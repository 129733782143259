import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import logo1 from '../../assets/img/usemed.png'
import { useForm,Controller } from "react-hook-form";
import { Field } from '../../components/Field';
import { Button } from '../../components/Button';
import { getToken, start } from '../../store/actions/accountActions';
import { useParams } from 'react-router-dom';
import Lottie from 'react-lottie';
import { getProfession } from '../../store/actions/professonActions';

function Active({loading,getToken,tokens,getProfession,profession,start,startLoading}) {

    const {setValue,getValues, control, handleSubmit, formState: { errors }} = useForm({
        defaultValues:{

        }
    });

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: require('../../assets/lottie/loading_blue.json'),
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const { token } = useParams();

    useEffect(()=>{
        getToken(token);


        getProfession();



    },[token]);


    if( tokens && tokens.length > 0){
        setValue('email',tokens[0].email);
        setValue('idToken',tokens[0].id);
    }
    const onSubmit = (request)=>{

        start(request);
    }

    return (
        <div className="d-flex flex-column flex-column-fluid" >
            {
                startLoading ?
                    <div className="d-flex flex-row-fluid flex-column flex-column-fluid text-center p-30 py-lg-20"  >
                        <a className="pt-lg-20 mb-12">
                            <img alt="logo" src={logo1} className="h-70px" />
                        </a>
                        <div className="text-center"><h1 className="fw-bold fs-2qx text-gray-800 mb-7" style={{paddingTop:200}}>Estamos preparando o ambiente.</h1></div>
                        <div className="fs-3 fw-semibold text-muted mb-10">
                            Isso pode levar alguns minutos.
                        </div>
                        <Lottie
                            options={defaultOptions}
                            height={200}
                            width={200}
                        />
                    </div>
                :

                    loading ?
                        <div style={{textAlign:'center',height: 550,paddingTop:260}}>
                            <Lottie
                                options={defaultOptions}
                                height={200}
                                width={200}
                            />
                        </div>
                    :
                        tokens && tokens.length > 0 && getValues('email') ?
                            <div className="d-flex flex-row-fluid flex-column flex-column-fluid text-center p-10 py-lg-20" >
                                <a className="pt-lg-20 mb-12">
                                    <img alt="logo" src={logo1} className="h-70px" />
                                </a>
                                <h1 className="fw-bold fs-2qx text-gray-800 mb-7">Preparando o ambiente</h1>
                                <div className="fs-3 fw-semibold text-muted mb-10">Estamos muito felizes com sua presença,
                                    <br />para continuarmos precisamos de algumas informações.
                                </div>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className='row justify-content-md-center' style={{textAlign:'left'}}>
                                        <div className="col-lg-2">
                                            <label className="fw-bold text-muted" style={{marginTop:10}}>Nome:</label>
                                            <Controller
                                                name="name"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field : { onChange, value }}) => <Field type="text"  value={value} onChange={onChange} />}
                                            />
                                            {errors.name && <p style={{color:'red',marginTop:10}}>Necessário preencher o nome.</p>}
                                        </div>
                                        <div className="col-lg-2">
                                            <label className="fw-bold text-muted" style={{marginTop:10}}>Celular:</label>
                                            <Controller
                                                name="cellphone"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field : { onChange, value }}) => <Field type="cellphone"  value={value} onChange={onChange} />}
                                            />
                                            {errors.cellphone && <p style={{color:'red',marginTop:10}}>Necessário selecionar o profissional.</p>}
                                        </div>
                                        <div className="col-lg-2">
                                            <label className="fw-bold text-muted" style={{marginTop:10}}>Área:</label>
                                            <Controller
                                                name="profession"
                                                control={control}
                                                render={({ field : { onChange, value }}) => <Field type="select" options={profession}  value={value} onChange={onChange} />}
                                            />
                                        </div>
                                        <div className="col-lg-12">
                                            <p className="text-muted text-center p-10 ">Se sua área não foi encontrada, não tem problema, você pode entrar em <a className='link-primary fw-bold'  target="_black" href="https://api.whatsapp.com/send?phone=5521990452839&amp;text=Olá, não encontrei minha área." >contato conosco</a> <br/> e teremos o prazer de adicioná-la.</p>
                                        </div>
                                    </div>
                                    <div className='row justify-content-md-center' style={{textAlign:'left'}}>
                                        <div className="col-lg-2">
                                            <label className="fw-bold text-muted" style={{marginTop:10}}>Senha:</label>
                                            <Controller
                                                name="password"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field : { onChange, value }}) => <Field type="password"  value={value} onChange={onChange} />}
                                            />
                                            {errors.password && <p style={{color:'red',marginTop:10}}>Necessário preencher a senha.</p>}
                                        </div>
                                        <div className="col-lg-2">
                                            <label className="fw-bold text-muted" style={{marginTop:10}}>Confirmar senha:</label>
                                            <Controller
                                                name="password_confirmation"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field : { onChange, value }}) => <Field type="password"  value={value} onChange={onChange} />}
                                            />
                                            {errors.password_confirmation && <p style={{color:'red',marginTop:10}}>Necessário preencher a confirmação de senha.</p>}
                                        </div>
                                    </div>
                                    <div className='row justify-content-md-center' style={{marginTop:40}}>
                                        <div className="col-lg-3">
                                            <Button loading={loading} text="Enviar" type="submit" className="btn-lg btn-block" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        :
                            <div className="d-flex flex-row-fluid flex-column flex-column-fluid text-center p-30 py-lg-20"  >
                                <div className="text-center"><h1 className="fw-bold fs-2qx text-gray-800 mb-7" style={{paddingTop:200}}>Convite expirado</h1></div>
                            </div>
            }
        </div>
    );
}

const mapStateToprops = state => {
    return {
        tokens:state.account.tokens,
        loading:state.account.templatesLoading,
        profession: state.profession.itens,
        startLoading:state.account.actionLoading
    };
}

const mapDispatchToprops = dispatch => ({
    getToken:(token) => dispatch(getToken(token)),
    getProfession: () => dispatch(getProfession()),
    start:(data) => dispatch(start(data))
})

export default connect(mapStateToprops,mapDispatchToprops)(Active)
