import React, { useEffect } from "react";
import { useForm,Controller } from "react-hook-form";
import { Field } from "../Field";
import { Button } from "../Button";


function PlanModal({loading,data,storePlan,auth,updatePlan}) {    

    const {control, handleSubmit, formState: { errors } } = useForm({
        defaultValues:{
            patient:data ? data.patient : null,
            professional: auth ? auth.id : null,
            company: auth ? auth.company.id : null,
            description: data ? data.description : null
        }
    });

    useEffect(()=>{
   
    },[])   

    const onSubmit = (request) =>{
        if(data && data.id){
            updatePlan(request,data.id);
        }else{
            storePlan(request);
        }        
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-lg-12" >
                    <Controller
                        name={"description"}
                        control={control}
                        rules={{ required: true }}
                        render={({ field : { onChange, value }}) => <Field type="textAreaEditor" value={value} onChange={(value) => {onChange(value);} } />}
                    />
                    {errors.description && <p style={{color:'red',marginTop:10}}>Necessário preencher o Objetivo.</p>}
                </div>           
            </div>
            <div className="row" style={{textAlign:'right',marginTop:20}}>
                <div className="col-md-9"></div>
                <div className="col-md-3">
                    <Button loading={loading} text="Salvar" type="submit" />   
                </div>   
            </div>
        </form>
    );
}

export default PlanModal