import React from "react";


export const BadgeTypeScheduler = ({data}) => {
    switch (data.id) {
        case 1:
            return <div className="badge badge-primary"  style={{ background:'#a9fca9',color:'#000',height:22}}> {data.description}</div>
        case 2:
            return <div className="badge badge-primary"  style={{ background:'#c6eaff',color:'#000',height:22}}> {data.description}</div>
        case 3:
            return <div className="badge badge-primary"  style={{ background:'#ededed',color:'#000',height:22}}> {data.description}</div>
        case 4:
            return <div className="badge badge-primary"  style={{ background:'#e2c7bc',color:'#000',height:22}}> {data.description}</div>
        default:
            return <></>;
    }
}
