import {updateObject} from '../../utils';

const initialState = {
    loading:true,
    itens:[],
    loadingRequest:false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'PLAN_START':
            return updateObject(state, {loading: true});
        case 'PLAN_SUCCESS':
            return updateObject(state, {loading: false,itens:action.data});
        case 'PLAN_FAIL':
            return updateObject(state, {loading: false,itens:[]});
        case 'PLAN_LOADING_REQUEST':
            return updateObject(state, {loadingRequest: action.status});
        case 'PLAN_INSERT':{
            let newDocument = [...state.itens];
            newDocument.push(action.data);    
            return updateObject(state, {itens:newDocument,loadingRequest: false});
        }
        case 'PLAN_REMOVE':{            
            let index = state.itens.findIndex(function(item) { 
                return item.id === action.id; 
            });

            let itensRemove = [...state.itens];

            itensRemove.splice(index, 1);
            return updateObject(state, {itens: itensRemove});
        }
        default:
            return state;        
    }
}

export default reducer;
