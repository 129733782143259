import React, {useEffect} from "react";
import {Controller, useForm} from "react-hook-form";
import {Field} from "../Field";
import {Button} from "../Button";
import api from "../../api";
import toast from "react-hot-toast";
import {connect, useSelector} from "react-redux";
import {DateTime} from "luxon";
import {getProfessionals} from "../../store/actions/professionalsActions";

function ReportsView({
    data,
    professionals,
    getProfessionals,
    auth
}) {

    const {control, handleSubmit, formState: { errors } } = useForm({
        defaultValues:{
            start:DateTime.local().minus({ months: 1 }).startOf('month').toFormat('yyyy-MM-dd'),
            end:DateTime.local().minus({ months: 1 }).endOf('month').toFormat('yyyy-MM-dd'),
            professional:auth.admin === true || auth.subadmin === true ?  0 : auth.id
        }
    });

    useEffect(() => {
        getProfessionals(1,1,100);
    }, []);

    const token = useSelector(state => state.auth.token);
    const onSubmit = (request) => {

        let url =  data.url.replace("##start##", `${request.start} 00:00:00`)
        url =  url.replace("##end##", `${request.end} 23:59:59`)

        let response = api.post(url,request, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        });

        toast.promise(response,{
            loading: 'Gerando relatório.',
            success: 'O relatório foi gerado com sucesso.',
            error: 'O relatório não foi gerado.',
        }).then((response) => {
            window.open(response.data.url,'_top' );
        })
    }

    let professionalsArray = [];

    if(professionals){
        professionalsArray.push({value:0,label:'Todos'})
        professionals && professionals.forEach(value => {
            professionalsArray.push({label:value.name,value:value.id})
        });
    }


    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className={"col-lg-3"} style={{marginTop: 10}} >
                    <label className="fw-bold text-muted">Início:</label>
                    <Controller
                        name="start"
                        control={control}
                        rules={{ required: true }}
                        render={({ field : { onChange,  value }}) => <Field type="dateNative" value={value} onChange={onChange} />}
                    />
                    {errors.name && <p style={{color:'red',marginTop:10}}>Necessário preencher o nome.</p>}
                </div>
                <div className={"col-lg-3"} style={{marginTop: 10}}>
                    <label className="fw-bold text-muted">Fim:</label>
                    <Controller
                        name="end"
                        control={control}
                        rules={{ required: true }}
                        render={({ field : { onChange,  value }}) => <Field type="dateNative" value={value} onChange={onChange} />}
                    />
                    {errors.total && <p style={{color:'red',marginTop:10}}>Necessário preencher o valor.</p>}
                </div>
                {
                    auth.admin === true || auth.subadmin === true ? <div className={"col-lg-3"} style={{marginTop: 10}}>
                        <label className="fw-bold text-muted">Profissionais:</label>
                        <Controller
                            name="professional"
                            control={control}

                            render={({ field : { onChange,  value }}) => <Field type="select" options={professionalsArray} value={value} onChange={onChange} />}
                        />
                    </div> : <></>
                }
            </div>
            <div className="row" style={{textAlign: 'right', marginTop: 10}}>
                <div className="col-md-9">

                </div>
                <div className="col-md-3">
                    {<Button text="Pesquisar" type="submit"/>}
                </div>
            </div>
        </form>
    );
}

const mapStateToprops = state => {
    return {
        professionals:state.professionals.itens,
        auth:state.auth,
    };
}

const mapDispatchToprops = dispatch => ({
    getProfessionals:(typeUser,page,perPage) => dispatch(getProfessionals(typeUser,page,perPage)),
})

export default connect(mapStateToprops,mapDispatchToprops)(ReportsView)

