import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import persistState from 'redux-localstorage';
import createRootReducer from './store/reducers';
import { authSaga } from './store/sagas/authSaga';
import { updateObject } from './utils';
import { companiesSaga } from './store/sagas/companiesSaga';
import { calendarSaga } from './store/sagas/calendarSaga';
import { patientsSaga } from './store/sagas/patientsSaga';
import { proceduresSaga } from './store/sagas/proceduresSaga';
import { groupsSaga } from './store/sagas/groupsSaga';
import { reportsSaga } from './store/sagas/reportsSaga';
import { documentsSaga } from './store/sagas/documentsSaga';
import { planSaga } from './store/sagas/planSaga';
import { professionSaga } from './store/sagas/professionSaga'
import { ProfessionalsSaga } from './store/sagas/professionalsSaga';
import { orientationSaga } from './store/sagas/orientationSaga';
import { expedientsSaga } from './store/sagas/expedientsSaga';
import { profilesSaga } from './store/sagas/profilesSaga';
import { TimeBlockSaga } from './store/sagas/TimeBlockSaga';
import { AccountSaga } from './store/sagas/AccountSaga';
import { treatmentSaga } from './store/sagas/treatmentSaga';
import { FinanceSaga } from './store/sagas/FinanceSaga';
import { serviceSaga } from "./store/sagas/serviceSaga";
import { diagnosticSaga } from "./store/sagas/diagnosticSaga";
import { onWSConnect } from "./store/sagas/wsSaga";
import {operatorSaga} from "./store/sagas/operatorsSaga";
import {healthInsuranceSaga} from "./store/sagas/healthInsuranceSaga";
import {userProfessionSaga} from "./store/sagas/userProfessionSaga";
import {signatureSaga} from "./store/sagas/SignatureSaga";
import {QueueWaitSaga} from "./store/sagas/QueueWaitSaga";
import {companyBankAccountsSaga} from "./store/sagas/companyBankAccountsSaga";

export const history = createBrowserHistory();

export let store = null;

export default function configureStore(preloadedState) {
  const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  const sagaMiddleware = createSagaMiddleware()
  store = createStore(
    createRootReducer(history),
    preloadedState,
    composeEnhancer(
      applyMiddleware(
        routerMiddleware(history),
        sagaMiddleware
      ),
      persistState('wevalue', {
        slicer: () => {
          return (state) => {

            let subset = [];

            for (const stateKey in state) {
              subset[stateKey] = state[stateKey];
            }

            return updateObject(
              subset,
              {
                companies: updateObject(subset.companies,{schedules: [],specializations:[],professionals:[],patients:[],typeschedules:[],rooms:[]}),
                patients:updateObject(subset.patients,{itens: []}),
                ui: updateObject(subset.ui, {modal: null, modalOpen: false,modalSecondary: null,modalOpenSecondary: false,modalOpenTertiary: false,viewPDFOpen:false,payloadViewPDF:null}),
                account: updateObject(subset.account, {templates:[],templatesLoading:false,tokens:[],actionLoading:false}),
              }
            )
          }
        }
      })
    )
  );

  sagaMiddleware.run(authSaga);
  sagaMiddleware.run(companiesSaga);
  sagaMiddleware.run(calendarSaga);
  sagaMiddleware.run(patientsSaga);
  sagaMiddleware.run(proceduresSaga);
  sagaMiddleware.run(groupsSaga);
  sagaMiddleware.run(reportsSaga);
  sagaMiddleware.run(documentsSaga);
  sagaMiddleware.run(planSaga);
  sagaMiddleware.run(professionSaga);
  sagaMiddleware.run(ProfessionalsSaga);
  sagaMiddleware.run(orientationSaga);
  sagaMiddleware.run(expedientsSaga);
  sagaMiddleware.run(profilesSaga);
  sagaMiddleware.run(TimeBlockSaga);
  sagaMiddleware.run(AccountSaga);
  sagaMiddleware.run(treatmentSaga);
  sagaMiddleware.run(FinanceSaga);
  sagaMiddleware.run(serviceSaga);
  sagaMiddleware.run(diagnosticSaga);
  sagaMiddleware.run(onWSConnect);
  sagaMiddleware.run(operatorSaga);
  sagaMiddleware.run(healthInsuranceSaga);
  sagaMiddleware.run(userProfessionSaga);
  sagaMiddleware.run(signatureSaga);
  sagaMiddleware.run(QueueWaitSaga);
  sagaMiddleware.run(companyBankAccountsSaga);


  if (module.hot) {
    module.hot.accept('./store/reducers', () => {
      store.replaceReducer(createRootReducer(history));
    });
  }

  return store
}