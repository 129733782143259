import React, { useEffect,useState } from "react";
import {formatDateStartAndEnd} from "../../utils";
import {DateTime} from "luxon";
import blank from '../../assets/img/blank.png';
import api from "../../api";
import toast from "react-hot-toast";
import {useSelector} from "react-redux";
import ContentLoader from "react-content-loader";

function ConfirmCallsModal({data,getResponsible,responsibles}) {

    const loadingCreate = useSelector(state => state.calendar.loadingCreate);
    const [loading,setLoading] = useState(loadingCreate);
    const [link,setLink] = useState('')
    const call = useSelector(state => state.calendar.item)[data.uuid];
    let token = useSelector(state => state.auth.token);

    const createUrl = (id) => {
        setLoading(true);
        let response = api.post('/callsConfirm', {call:id}, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        });

        toast.promise(response,{
            loading: 'Criando link de confirmação.',
            success: 'O link de confirmação foi criado.'
        }).then((response) => {
            setLink(response.data.url);
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() =>{
        if(call.patient.id) getResponsible(call.patient.id)

        if(call.id){
            createUrl(call.id);
        }
    },[call]);

    const whatsapp = (number) =>{

        let text = 'Gostaríamos de confirmar o agendamento para a *'+(data.replacement ? "reposição" : (data.type_schedule?.id === 2 ? "sessão" : data.type_schedule?.description))+'* '+(data.type_address === 4 ? "*online*" : "")+' de *'+(data.treatment.specialization.profession && data.treatment.specialization.profession.description)+(data.treatment.specialization.description ? " "+data.treatment.specialization.description : '')+'* com o(a) terapeuta *'+data.professional.name+'*, marcada para o paciente *'+data.patient.name+'*, agendada para *'+formatDateStartAndEnd(DateTime.fromSQL(data.start_date),DateTime.fromSQL(data.end_date))+'*. ' +
            '\n' +
            '\n' +
            'Para confirmar o agendamento, pedimos que clique no link a seguir e confirme o atendimento: [inserir link]. ' +
            '\n' +
            '\n' +
            'Agradecemos antecipadamente pela sua atenção e estamos à disposição para esclarecer qualquer dúvida.';

        text = text.replace("[inserir link]",link);
        let url = "https://api.whatsapp.com/send?phone="+number+'&text=' +
            window.encodeURIComponent(text);

        window.open(url, '_blank')
    }

    if(loading){
        return(
            <div style={{display:"flex",flexDirection:"column"}}>
                <h4>Texto que será enviado:</h4>
                <div style={{display:"flex",flexDirection:"row-reverse"}}>
                    <svg viewBox="0 0 8 13" height="13" width="8" preserveAspectRatio="xMidYMid meet" className="" version="1.1"
                         x="0px" y="0px" enableBackground="new 0 0 8 13" xmlSpace="preserve"><path opacity="0.13" d="M5.188,1H0v11.193l6.467-8.625 C7.526,2.156,6.958,1,5.188,1z"></path>
                        <path fill="#d9fdd3" d="M5.188,0H0v11.193l6.467-8.625C7.526,1.156,6.958,0,5.188,0z"></path>
                    </svg>
                    <div style={{padding:20,backgroundColor:'#d9fdd3',width: '100%',borderRadius: 10,borderTopRightRadius:0}}>
                        <ContentLoader viewBox="0 0 400 130" height={130} width={400}>
                            <rect x="0" y="0" rx="3" ry="3" width="250" height="10" />
                            <rect x="20" y="20" rx="3" ry="3" width="220" height="10" />
                            <rect x="20" y="40" rx="3" ry="3" width="170" height="10" />
                            <rect x="0" y="60" rx="3" ry="3" width="250" height="10" />
                            <rect x="20" y="80" rx="3" ry="3" width="200" height="10" />
                            <rect x="20" y="100" rx="3" ry="3" width="80" height="10" />
                        </ContentLoader>
                    </div>
                </div>
                <br/>
                <h4>Escolha o destinatário:</h4>
                <hr/>
                <ContentLoader viewBox="0 0 400 130" height={130} width={400}>
                    <rect x="0" y="0" rx="3" ry="3" width="250" height="10" />
                    <rect x="20" y="20" rx="3" ry="3" width="220" height="10" />
                    <rect x="20" y="40" rx="3" ry="3" width="170" height="10" />
                    <rect x="0" y="60" rx="3" ry="3" width="250" height="10" />
                    <rect x="20" y="80" rx="3" ry="3" width="200" height="10" />
                    <rect x="20" y="100" rx="3" ry="3" width="80" height="10" />
                </ContentLoader>
            </div>
        )
    }

    return (
        <div style={{display:"flex",flexDirection:"column"}}>
            <h4>Texto que será enviado:</h4>
            <div style={{display:"flex",flexDirection:"row-reverse"}}>

                <svg viewBox="0 0 8 13" height="13" width="8" preserveAspectRatio="xMidYMid meet" className="" version="1.1"
                     x="0px" y="0px" enableBackground="new 0 0 8 13" xmlSpace="preserve"><path opacity="0.13" d="M5.188,1H0v11.193l6.467-8.625 C7.526,2.156,6.958,1,5.188,1z"></path>
                    <path fill="#d9fdd3" d="M5.188,0H0v11.193l6.467-8.625C7.526,1.156,6.958,0,5.188,0z"></path></svg>
                <div style={{padding:20,backgroundColor:'#d9fdd3',width: '100%',borderRadius: 10,borderTopRightRadius:0}}>
                    Gostaríamos de confirmar o agendamento para a <b>{data.replacement ? "reposição" : (data.type_schedule?.id === 2 ? "sessão" : data.type_schedule?.description)}</b> {data.type_address === 4 ? <b>online</b> : <></>} de <b>{data.treatment.specialization.profession && data.treatment.specialization.profession.description} {data.treatment.specialization.description}</b>  com o(a) terapeuta <b>{data.professional.name}</b>, marcada para o paciente <b>{data.patient.name}</b>, agendada para <b>{formatDateStartAndEnd(DateTime.fromSQL(data.start_date),DateTime.fromSQL(data.end_date))}</b>.
<br/>
<br/>
Para confirmar o agendamento, pedimos que clique no link a seguir e confirme o atendimento: {link}.
<br/>
<br/>
Agradecemos antecipadamente pela sua atenção e estamos à disposição para esclarecer qualquer dúvida.
                </div>
            </div>
            <br/>
            <h4>Escolha o destinatário:</h4>
            <hr/>
            {
                call.patient.cellphone && call.patient.cellphone !== '55' ?
            <div style={{paddingBottom:10,border:"1px dashed #dbc9c9",paddingTop: 10,borderRadius: 10}}>

                        <a onClick={() => {whatsapp("+"+call.patient.ddi+call.patient.cellphone)}} style={{display:'flex',cursor:'pointer'}}>
                            <div>
                                <img alt={call.patient.name} height="58px" width="58px" src={call.patient.img ? call.patient.img : blank} style={{marginLeft: 10,marginRight: 10,borderRadius: 10}} />
                            </div>
                            <div  style={{paddingTop:3,display:'flex',flexDirection:'column'}}>
                                <h6>{call.patient.name}</h6>
                                <small>+{call.patient.ddi} {call.patient.cellphone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')}</small>
                                <small>Paciente</small>
                            </div>
                        </a>

            </div>
                    :
                    <></>
            }
            {
                responsibles.map((value,index) =>{

                    return(
                        <div style={{paddingBottom:10,border:"1px dashed #dbc9c9",paddingTop: 10,borderRadius: 10,marginTop:10}} key={index}>
                            {
                                value.responsible.cellphone && value.responsible.cellphone !== '55' ?
                                    <a onClick={() => {whatsapp("+"+value.responsible.ddi+value.responsible.cellphone)}} style={{display:'flex',cursor:'pointer'}}>
                                        <div>
                                            <img alt={value.responsible.name} height="58px" width="58px" src={value.responsible.img ? value.responsible.img : blank} style={{marginLeft: 10,marginRight: 10,borderRadius: 10}} />
                                        </div>
                                        <div  style={{paddingTop:3,display:'flex',flexDirection:'column'}}>
                                            <h6>{value.responsible.name}</h6>
                                            <small>+{value.responsible.ddi} {value.responsible.cellphone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')}</small>
                                            <small>{value.type.description}</small>
                                        </div>
                                    </a>
                                    :
                                    <></>
                            }
                        </div>
                    )
                })
            }
            <div style={{paddingBottom:10,border:"1px dashed #dbc9c9",paddingTop: 10,borderRadius: 10,paddingLeft:10,marginTop:10}}>
                {
                    <a onClick={() => {whatsapp('')}} style={{display:'flex',cursor:'pointer'}}>
                        <div  style={{paddingTop:3,display:'flex',flexDirection:'column'}}>
                            <h6>Outros</h6>
                            <small>Usuários da sua lista de amigos no WhatsApp</small>
                        </div>
                    </a>
                }
            </div>
        </div>
    );
}

export default ConfirmCallsModal
