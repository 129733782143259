import {updateObject} from '../../utils';

const initialState = {
    itens:[],
    loading:false,
    all:[],
    loadingAction:false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'TREATMENT_SUCCESS':
            return updateObject(state, {itens: action.data});
        case 'TREATMENT_ALL_SUCCESS':
            return updateObject(state, {all: action.data});
        case 'TREATMENT_LOADING':
            return updateObject(state, {loading: action.status});
        case 'TREATMENT_LOADING_ACTION':
            return updateObject(state, {loadingAction: action.status});
        default:
            return state;
    }
}

export default reducer;
  