import { put, select, takeEvery} from 'redux-saga/effects';
import {sendWS} from "../../websocket";
import {wsAuthenticated} from "../actions/wsActions";
import {
    calendarAlterHome,
    calendarAlterSchedule, calendarDestroyHome, calendarDestroySchedule,
    calendarStoreHome,
    calendarStoreSchedule, destroyItem, setItem
} from "../actions/calendarActions";
import {
    patientAlterAnamnese,
    patientAlterEvolution,
    patientAlterTherapeutic,
    patientDestroyAnamnese,
    patientDestroyEvolution,
    patientDestroyTherapeutic,
    patientStoreAnamnese,
    patientStoreEvolution,
    patientStoreTherapeutic
} from "../actions/patientsActions";
export const getAuth = (state) => state.auth;

function* authenticate() {
    const auth = yield select(getAuth);
    sendWS({"type": "authenticate", "payload": auth.token});
}

function* handleMessage(action) {
    const ws = action.payload;
    console.log(ws)
    switch (ws.type) {
        case 'AUTH_FAILED':
            yield authenticate();
            //TODO: Try to refresh token, if fail, logout
            break ;
        case 'AUTH_SUCCESS':
            yield put(wsAuthenticated());
            break;
        case 'UPDATE_CALL':
            yield put(calendarAlterHome(ws.payload));
            yield put(calendarAlterSchedule(ws.payload));
            yield put(patientAlterEvolution(ws.payload));
            yield put(patientAlterAnamnese(ws.payload));
            yield put(patientAlterTherapeutic(ws.payload));
            yield put(setItem(ws.payload,ws.payload.uuid));
            break;
        case 'STORE_CALL':
            yield put(calendarStoreSchedule(ws.payload));
            yield put(calendarStoreHome(ws.payload));
            yield put(patientStoreEvolution(ws.payload));
            yield put(patientStoreAnamnese(ws.payload));
            yield put(patientStoreTherapeutic(ws.payload));
            break;
        case 'DESTROY_CALL':
            yield put(destroyItem(ws.payload.uuid));
            yield put(calendarDestroySchedule(ws.payload));
            yield put(calendarDestroyHome(ws.payload));
            yield put(patientDestroyEvolution(ws.payload));
            yield put(patientDestroyAnamnese(ws.payload));
            yield put(patientDestroyTherapeutic(ws.payload));
            break;
        default:
            console.warn(`Mensagem não mapeada. Type: ${ws.type}`, ws);
    }
}

export function* onWSConnect() {
    yield takeEvery('WS_CONNECTED', authenticate);
    yield takeEvery('WS_MESSAGE', handleMessage);
}