import {updateObject} from '../../utils';

const initialState = {
    loading:false,
    items:[],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'USER_PROFESSION_LOADING':
            return updateObject(state, {loading: action.status});
        case 'USER_PROFESSION_SUCCESS':
            return updateObject(state, {items:action.data});
        default:
            return state;        
    }
}

export default reducer;
