import {getUuid, updateObject} from '../../utils';
import {DateTime} from "luxon";

const initialState = {
    itens:[],
    patientSearch:null,
    loading:true,
    total:0,
    item:{},
    autocomplete:[],
    patientPrivate:[],
    patientPrivateStatus:[],
    patientPrivateLoading:true,
    patientPrivateLoadingAction:false,
    patientTasks:[],
    patientTasksLoading:true,
    patientTasksLoadingAction:false,
    address:[],
    addressLoading:false,
    addressLoadingSearch:false,
    addressRequestLoading:false,
    loadingRequest:false,
    typeAddress:[],
    professionals:{
        itens:[],
        loading:false,
        total:0,
        item:{},
        autocomplete:[]
    },
    responsible:{
        itens:[],
        loading:false
    },
    calls:{
        itens:[],
        loading:false
    },
    reports:{
        itens:[],
        loading:true,
    },
    avaliation:{
        itens:[],
        loading:true
    },
    evolution:{
        itens:[],
        loading:true
    },
    return:{
        itens:[],
        loading:true
    },
    anamnese:{
        itens:[],
        loading:false
    },
    therapeutic:{
        itens:[],
        loading:false
    },
    typeResponsible:[]
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SEARCH_PATIENT':{
            return updateObject(state,{patientSearch:action.name})
        }
        case 'ADDRESS_START':
            return updateObject(state, {addressLoading: true});
        case 'ADDRESS_SUCCESS':
            return updateObject(state, {addressLoading: false,address:action.itens});
        case 'ADDRESS_FAIL':
            return updateObject(state, {addressLoading: false,address:[]});
        case 'ADDRESS_DESTROY_START':
            return updateObject(state, {addressDestroyLoading: true});
        case 'ADDRESS_LOADING_SEARCH':
            return updateObject(state, {addressLoadingSearch: action.status});
        case 'ADDRESS_DESTROY_FAIL':
            return updateObject(state, {addressDestroyLoading: false});
        case 'ADDRESS_START_REQUEST':
            return updateObject(state, {addressRequestLoading: true});
        case 'ADDRESS_FAIL_REQUEST':
            return updateObject(state, {addressRequestLoading: false});
        case 'SET_TYPE_ADDRESS':
            return updateObject(state, {typeAddress: action.data});
        case 'ADDRESS_INSERT':
            let newAddress = [...state.address];

            newAddress.push(action.data)

            return updateObject(state, {address: newAddress,addressRequestLoading:false});
        case 'ADDRESS_REMOVE':{

            let index = state.address.findIndex(function(item) {
                return item.id === action.id;
            });

            let newAddressRemove = [...state.address];

            newAddressRemove.splice(index, 1);
            return updateObject(state, {address: newAddressRemove});
        }
        case 'ADDRESS_ALTER':{
            let index = state.address.findIndex(function(item) {
                return item.id === action.id;
            });

            let newAddressUpdate = [...state.address];

            newAddressUpdate[index] = action.data
            return updateObject(state, {address: newAddressUpdate,addressRequestLoading:false});
        }

        case 'PATIENTS_CLEAR':
            return initialState;

        case 'AVALIATION_SUCCESS':{
            let newArray = [...state.avaliation.itens];

            action.data.forEach(element => {
                newArray.push(element);

            })

            return updateObject(state, {avaliation:{loading:false,itens:newArray,total:action.total,count:state.avaliation.count}});
        }

        case 'AVALIATION_LOADING':
            return updateObject(state, {avaliation:{loading:action.status,itens:state.avaliation.itens,count:state.avaliation.count}});

        case 'AVALIATION_COUNT':
            return updateObject(state, {avaliation:{count:action.count,itens:state.avaliation.itens}});

        case 'RETURN_SUCCESS':{
            let newArray = [...state.return.itens];

            action.data.forEach(element => {
                newArray.push(element);
            });

            return updateObject(state, {return:{loading:false,itens:newArray,total:action.total,count:state.return.count}});
        }

        case 'RETURN_LOADING':
            return updateObject(state, {return:{loading:action.status,itens:state.return.itens,count:state.return.count}});

        case 'RETURN_COUNT':
            return updateObject(state, {return:{count:action.count,itens:state.return.itens}});

        case 'ANAMNESE_SUCCESS': {
            return updateObject(state, {anamnese:{loading:false,itens:action.data}});
        }


        case 'THERAPEUTIC_SUCCESS': {
            return updateObject(state, {therapeutic:{loading:false,itens:action.data}});
        }

        case 'ANAMNESE_ALTER': {
            let array = [...state.anamnese.itens];

            const index = array.findIndex(
                (o1) => o1.id === action.data.value_link
            );

            if (index !== -1) {
                const groups = array[index]['groups'];
                const newGroup = groups.map(group => ({...group,fields:group.fields.map(field => field.id === action.data.id ? ({...field,value: action.data.value}) : ({...field}))}));
                array[index] = {...array[index],groups:[...newGroup]}
            }

            return updateObject(state, {anamnese:{loading:false,itens:array}});
        }



        case 'ANAMNESE_DESTROY_MULT': {
            let newArray = [...state.anamnese.itens];

            action.calls.forEach(value => {
                let index = newArray.findIndex((ar) => {
                    return value.treatment === ar.treatment.id && value.professional === ar.professional.id && value.patient === ar.patient.id && value.start === ar.start
                });
                if (index > -1) {
                    newArray.splice(index, 1);
                }
            });

            return updateObject(state, { anamnese: {itens: newArray} });
        }

        case 'ANAMNESE_CLEAR':
            return updateObject(state, {anamnese:{loading:false,itens:[],count:0}});

        case 'ANAMNESE_LOADING':
            return updateObject(state, {anamnese:{loading:action.status,itens:state.anamnese.itens,count:state.anamnese.count}});

        case 'THERAPEUTIC_LOADING':
            return updateObject(state, {therapeutic:{loading:action.status,itens:state.therapeutic.itens,count:state.therapeutic.count}});


        case 'ANAMNESE_COUNT':
            return updateObject(state, {anamnese:{count:action.count,itens:state.anamnese.itens}});

        case 'EVOLUTION_SUCCESS':{
            return updateObject(state, {evolution:{loading:false,itens:action.data}});
        }

        case 'EVOLUTION_DESTROY_MULT': {
            let newArray = [...state.evolution.itens];

            action.calls.forEach(value => {
                let index = newArray.findIndex((ar) => {
                    return value.treatment === ar.treatment.id && value.professional === ar.professional.id && value.patient === ar.patient.id && value.start === ar.start
                });
                if (index > -1) {
                    newArray.splice(index, 1);
                }
            });

            return updateObject(state, { evolution: {itens: newArray} });
        }



        case 'EVOLUTION_COUNT':
            return updateObject(state, {evolution:{count:action.count,itens:state.evolution.itens}});

        case 'EVOLUTION_CLEAR':
            return updateObject(state, {evolution:{loading:false,itens:[],count:0}});

        case 'EVOLUTION_LOADING':

            return updateObject(state, {evolution:{loading:action.status,itens:state.evolution.itens,count:state.evolution.count}});

        case 'RESPONSIBLE_SUCCESS':
            return updateObject(state, {responsible:{loading:false,itens:action.data}});
        case 'RESPONSIBLE_LOADING':
            return updateObject(state, {responsible:{loading:action.status,itens:state.responsible.itens}});
        case 'SET_TYPE_RESPONSIBLE':
            return updateObject(state, {typeResponsible:action.data});

        case 'PATIENTS_SUCCESS':
            return updateObject(state, {loading:false,itens:action.itens,total:action.count});
        case 'PATIENTS_LOADING':
            return updateObject(state, {loading:action.status,itens:state.patients ? state.itens : []});
        case 'PATIENTS_LOADING_AUTOCOMPLETE':
            return updateObject(state, {loadingAutocomplete:action.status});
        case 'PATIENTS_SUCCESS_AUTOCOMPLETE':
            return updateObject(state, {loadingAutocomplete:false,autocomplete:action.data});
        case 'PATIENT_SET':
            return updateObject(state, {loading:false,item:action.data});

        case 'PATIENTS_FAIL_REQUEST':
            return updateObject(state, {loadingRequest:false});
        case 'PATIENTS_START_REQUEST':
            return updateObject(state, {loadingRequest:true});
        case 'PATIENT_ALTER':{
            let newObj = {...state.item};
            Object.getOwnPropertyNames(action.data).sort().forEach(function(val, idx, array) {
                newObj[val] = action.data[val]
            });

            return updateObject(state, {item:newObj});
        }

        case 'SET_PATIENT_PRIVATE':{
            return updateObject(state, {patientPrivate:action.data});
        }
        case 'PATIENT_PRIVATE_STATUS':
            return updateObject(state, {patientPrivateLoading:action.status});

        case 'PATIENT_PRIVATE_STATUS_ACTION':
            return updateObject(state, {patientPrivateLoadingAction:action.status});

        case 'PATIENT_PRIVATE_STATUS_SUCCESS':{
            return updateObject(state, {patientPrivateStatus:action.data});
        }

        case 'SET_PATIENT_TASKS':{
            return updateObject(state, {patientTasks:action.data});
        }

        case 'PATIENT_TASKS_STATUS':
            return updateObject(state, {patientTasksLoading:action.status});

        case 'PATIENT_TASKS_STATUS_ACTION':
            return updateObject(state, {patientTasksLoadingAction:action.status});

        case 'PATIENT_TASKS_STATUS_SUCCESS':{
            return updateObject(state, {patientTasksStatus:action.data});
        }

        case 'PATIENT_ALTER_ITEM_ARRAY_EVOLUTION': {
            let array = [...state.evolution.itens];

            let index = array.findIndex((ar) => {
                return action.data.id === ar.id;
            });

            if (index > -1) {
                array[index] = {...array[index],status:action.data.status}
            }

            return updateObject(state, {evolution:{itens:array}});
        }

        case 'PATIENT_ALTER_ID_IN_ARRAY_EVOLUTION':{
            let array = [...state.evolution.itens];

            let index = array.findIndex((ar) => {
                return action.tid === ar.tid;
            });

            if (index > -1) {
                array[index]['aid'] = action.id
                array[index]['id'] = action.id
            }

            return updateObject(state, {evolution:{itens:array}});
        }

        case 'PATIENT_ALTER_ITEM_ARRAY_ANAMNESE': {
            let array = [...state.anamnese.itens];

            let index = array.findIndex((ar) => {
                return action.data.id === ar.id;
            });

            if (index > -1) {
                array[index] = {...array[index],status:action.data.status}
            }

            return updateObject(state, {anamnese:{itens:array}});
        }

        case 'PATIENT_ALTER_ID_IN_ARRAY_ANAMNESE':{
            let array = [...state.anamnese.itens];

            let index = array.findIndex((ar) => {
                return action.tid === ar.tid;
            });

            if (index > -1) {
                array[index]['aid'] = action.id
                array[index]['id'] = action.id
            }

            return updateObject(state, {anamnese:{itens:array}});
        }

        case 'EVOLUTION_ALTER': {
            let array = [...state.evolution.itens];

            const index = array.findIndex(
                (o1) => o1.id === action.data.value_link
            );

            if (index !== -1) {
                const groups = array[index]['groups'];
                const newGroup = groups.map(group => ({...group,fields:group.fields.map(field => field.id === action.data.id ? ({...field,value: action.data.value}) : ({...field}))}));
                array[index] = {...array[index],groups:[...newGroup]}
            }

            return updateObject(state, {evolution:{loading:false,itens:array}});
        }

        case 'PATIENT_ALTER_EVOLUTION': {
            let array = [...state.evolution.itens];

            let index = array.findIndex((ar) => {
                return ar.uuid === action.data.uuid;
            });

            if (index > -1) {
                const groups = array[index]['groups'];
                array[index] = {...action.data,groups,"uuid":getUuid(action.data)}
            }

            return updateObject(state, {evolution:{itens:array}});
        }

        case 'PATIENT_ALTER_ANAMNESE':{
            let array = [...state.anamnese.itens];

            let index = array.findIndex((ar) => {
                return ar.uuid === action.data.uuid;
            });

            if (index > -1) {
                const groups = array[index]['groups'];
                array[index] = {...action.data,groups,"uuid":getUuid(action.data)}
            }

            return updateObject(state, {anamnese:{itens:array}});
        }

        case 'PATIENT_ALTER_THERAPEUTIC':{
            let array = [...state.therapeutic.itens];

            let index = array.findIndex((ar) => {
                return ar.uuid === action.data.uuid;
            });

            console.log(action.data)

            if (index > -1) {
                const groups = array[index]['groups'];
                array[index] = {...action.data,groups,"uuid":getUuid(action.data)}
            }

            return updateObject(state, {therapeutic:{itens:array}});
        }

        case 'PATIENT_STORE_ANAMNESE':{
            if(DateTime.fromSQL(action.data.start_date) > DateTime.now()){
                return;
            }

            if(action.data.type_schedule !== 3){
                return;
            }

            const items = [...state.anamnese.itens];
            items.push(action.data);
            return updateObject(state, {anamnese:{itens:items}});
        }

        case 'PATIENT_STORE_EVOLUTION': {

            if(DateTime.fromSQL(action.data.start_date) > DateTime.now()){
                return;
            }

            if(action.data.type_schedule === 3){
                return;
            }

            const items = [...state.evolution.itens];
            items.push(action.data);
            return updateObject(state, {evolution:{itens:items}});
        }

        case 'PATIENT_STORE_THERAPEUTIC': {

            if(action.data.type_schedule === 5){
                return;
            }

            const items = [...state.therapeutic.itens];
            items.push(action.data);
            return updateObject(state, {therapeutic:{itens:items}});
        }

        case 'PATIENT_DESTROY_EVOLUTION': {
            let items = [...state.evolution.itens].filter(value => value.uuid !== action.data.uuid);
            return updateObject(state, {evolution:{itens:items}});
        }

        case 'PATIENT_DESTROY_ANAMNESE': {
            let items = [...state.anamnese.itens].filter(value => value.uuid !== action.data.uuid);
            return updateObject(state, {anamnese:{itens:items}});
        }

        case 'PATIENT_DESTROY_THERAPEUTIC': {
            let items = [...state.therapeutic.itens].filter(value => value.uuid !== action.data.uuid);
            return updateObject(state, {therapeutic:{itens:items}});
        }

        case 'THERAPEUTIC_ALTER': {
            let array = [...state.therapeutic.itens];

            const index = array.findIndex(
                (o1) => o1.id === action.data.value_link
            );

            if (index !== -1) {
                const groups = array[index]['groups'];

                const newGroup = groups.map(group => ({...group,fields:group.fields.map(field => field.id === action.data.id ? ({...field,value: action.data.value}) : ({...field}))}));

                array[index] = {...array[index],groups:[...newGroup]}
            }

            console.log(index,array)

            return updateObject(state, {therapeutic:{loading:false,itens:array}});
        }

        default:
            return state;
    }
}

export default reducer;
