import {updateObject} from '../../utils';

const initialState = {
    items:[],
    loading: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'HEALTH_INSURANCE_SUCCESS':
            return updateObject(state, {items: action.data});
        case 'HEALTH_INSURANCE_LOADING':
            return updateObject(state, {loading: action.status});
        default:
            return state;
    }
}

export default reducer;
