import Lottie from 'react-lottie';
import React,{ useState,useEffect } from "react";

function List({value,openModal,destroyExpedients}){
    const [novo,setNovo] = useState();

    useEffect(() => {

        function click(ev) {

            if(ev.srcElement.closest('#novoPlan') == null){
                setNovo(false);
            }
        }

        if(novo){
            window.addEventListener('click',click,false);
            return () => {
                window.removeEventListener('click',click,false);
            };
        }

    }, [novo]);

    const week = [
        "Domingo",
        "Segunda-feira",
        "Terça-feira",
        "Quarta-feira",
        "Quinta-feira",
        "Sexta-feira",
        "Sábado"
    ]

    return(
        <tr key={value.id} style={{cursor:'pointer'}}>
            <td style={{textAlign:'left'}}>{week[value.day_week]}</td>

            <td style={{textAlign:'right'}}>{value.hour_open} </td>
            <td style={{textAlign:'right'}}>{value.hour_close} </td>
            <td style={{textAlign:'right'}}>
                <button  className="btn btn-sm btn-icon  btn-active-color-primary" style={{float:'right'}} onClick={()=>setNovo(true)}>
                    <span className="svg-icon svg-icon-muted svg-icon-2hx">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="4" fill="currentColor"/>
                            <rect x="11" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor"/>
                            <rect x="15" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor"/>
                            <rect x="7" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor"/>
                        </svg>
                    </span>
				</button>
                <div className={"dropdown-menu "+(novo ? "show" : '')} style={{marginTop:40,right:33}} id="novoPlan" >
					<a className="dropdown-item" style={{cursor:'pointer'}} onClick={()=>{openModal('expedients',value);setNovo(false)}}>Editar</a>
					<a className="dropdown-item" style={{cursor:'pointer'}} onClick={()=>{destroyExpedients(value.id,value.user);setNovo(false)}}>Excluir</a>
				</div>
            </td>
        </tr>
    )
}


function Expedients({loading,openModal,itens,id,destroyExpedients}) {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: require('../../assets/lottie/loading_blue.json'),
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <>
            <div className="card  mb-5 mb-xl-10">
                <div className="card-header cursor-pointer">
                    <div className="card-title align-items-start flex-column">
                        <span className="fw-bolder m-0">Expedientes</span>
                    </div>
                    <div className="card-toolbar">
                        <button onClick={() => openModal('expedients',{user:id})} className="btn btn-sm btn-light-primary" >
                            Adicionar
                        </button>
                    </div>
                </div>
                <div className="card-body py-3">
                    {
                        loading === true ?
                            <div style={{textAlign:'center'}}>
                                <Lottie
                                    options={defaultOptions}
                                    height={200}
                                    width={200}
                                />
                            </div>
                        :
                            <div className='table-responsive'>
                                <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                    <thead>
                                        <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                                            <th style={{textAlign:'left'}}>Dia</th>
                                            <th style={{textAlign:'right'}}>Início</th>
                                            <th style={{textAlign:'right'}}>Fim</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            itens && itens.length > 0 ?

                                                itens.map((value) =>{

                                                    return <List destroyExpedients={destroyExpedients} openModal={openModal} value={value} key={value.id} />
                                                })
                                            :
                                                (<tr><td colSpan="4"><div style={{textAlign:'center',paddingTop:7}}>Não foi encontrado nenhum horário.</div></td></tr>)
                                        }
                                    </tbody>
                                </table>
                            </div>
                    }
                </div>
            </div>
        </>
    );
}

export default Expedients;
