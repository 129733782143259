import { put, takeEvery, select } from 'redux-saga/effects';
import api from '../../api';
import { closeModal } from '../actions/uiActions';
import {diagnosticLoading, diagnosticSuccess} from "../actions/diagnosticActions";
import toast from "react-hot-toast";
export const getAuthToken = (state) => state.auth.token;
export const getAuthCompany = (state) => state.auth.company.id;
function* get(action){
    try{
        yield put(diagnosticSuccess([]));
        yield put(diagnosticLoading(true))
        const response = yield  api.get('/diagnosticPatient/'+action.patient,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield put(diagnosticSuccess(response.data));
            yield put(diagnosticLoading(false))
        }
    } catch (e) {
        yield put(diagnosticLoading(false))
        console.error(e);
    }
}

function* store(action){
    try{
        let response = api.post('/diagnosticPatient',action.data,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        toast.promise(response,
            {
                loading: 'Cadastrando diagnóstico ...',
                success: 'O diagnóstico foi cadastrado com sucesso.',
                error: 'O diagnóstico não foi cadastrado.'
            }
        );

        response = yield response;

        if(response.status === 201){
            yield put(closeModal());
            yield get({patient:action.data.patient});
        }
    } catch (e) {
        console.error(e);
    }
}

function* update(action){

    try{
        let response = api.put('/diagnosticPatient/'+action.id,action.data,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        toast.promise(response,
            {
                loading: 'Atualizando diagnóstico ...',
                success: 'O diagnóstico foi atualizado com sucesso.',
                error: 'O diagnóstico não foi atualizando.'
            }
        );
        response = yield response;

        if(response.status === 200){
            yield get({patient:action.data.patient})
        }
    } catch (e) {
        console.error(e);  

    }
}

function* destroy(action){
    try{

        let response =   api.delete('/diagnosticPatient/'+action.data.id,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        toast.promise(response,
            {
                loading: 'Excluindo diagnóstico ...',
                success: 'O diagnóstico foi excluído com sucesso.',
                error: 'O diagnóstico não foi excluído.'
            }
        );
        response = yield response
        if(response.status === 200){
            yield get({patient:action.data.patient})
        }
    } catch (e) {
        console.error(e);       
    }
}

export function* diagnosticSaga() {
    yield takeEvery('GET_DIAGNOSTIC', get);
    yield takeEvery('STORE_DIAGNOSTIC', store);
    yield takeEvery('UPDATE_DIAGNOSTIC', update);
    yield takeEvery('DESTROY_DIAGNOSTIC', destroy);
}