import {  put, takeEvery,select} from 'redux-saga/effects';
import api from '../../api';
import toast from 'react-hot-toast';
import {
    documentLoadingRequest,
    documentRemove, documentRemoveFinance,
    documentsFail, documentsFinanceSuccess,
    documentsStart,
    documentsSuccess, documentUpdate, documentUpdateFinance, openModalDocument,
    typeDocumentsSuccess
} from '../actions/documentsActions';

export const getAuthToken = (state) => state.auth.token;

function* get(action){
    try{
        yield put(documentsStart());

        const response = yield  api.get("/documents/typeModel/"+ action.typeModel+"/id/"+action.id,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            switch (action.typeModel){
                case 2 :
                    yield put( documentsFinanceSuccess(response.data))
                    break;
                default:
                    yield put(documentsSuccess(response.data))
                    break;
            }
        }else{
            yield put(documentsFail())
        }
    } catch (e) {
        console.error(e);
        yield put(documentsFail())
    }
}

function* getTypesDocuments(action){
    try{

        const response = yield  api.get("/documents/typeDocument/typeModel/"+ action.typeModel,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield put(typeDocumentsSuccess(response.data))
        }
    } catch (e) {
        console.error(e);

    }
}

function* upload(action){
    try{
        let data = new FormData();
        data.append('description', action.data.description);
        data.append('typeModel', action.data.type_model);
        data.append('typeDocument', action.data.type_document);
        data.append('id', action.data.id);

        for (let i = 0; i < action.data.file.length; i++) {
            data.append('files[]', action.data.file[i]);
        }

        let response =   api.post("/documents/upload",data,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
                'Content-Type': 'multipart/form-data'
            }
        });

        toast.promise(response,{
                loading: action.data.file.length === 1 ? 'Enviando o arquivo.' : "Enviados os arquivos.",
                success: action.data.file.length === 1 ? 'O arquivo foi enviado com sucesso.' : "Os arquivos foram enviados com sucesso.",
                error: (error) => {
                    return error.response.data.message
                }
            }
        );

        response = yield response;
        console.log(response)
        if(response.status === 200){
            yield put(openModalDocument(false));
            yield get({typeModel:action.data.type_model,id:action.data.id})
        }else{
            yield put(documentLoadingRequest(false));
        }
    } catch (e) {
        yield put(documentLoadingRequest(false));
    }
}

function* update(action){
    try{

        let response = api.put("/documents/"+action.data.idDocument, action.data,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),

            }
        });

        toast.promise(response,{
                loading: 'Atualizando informações do anexo.',
                success: (success) => {
                    return success.data.message
                },
                error: (error) => {
                    return error.response.data.message
                }
            }
        );

        response = yield response;

        if(response.status === 200){
            switch (action.data.type_model){
                case 2 :
                    yield put(documentUpdateFinance(action.data.idDocument,response.data.document));
                    break;
                default:
                    yield put(documentUpdate(action.data.idDocument,response.data.document));
                    break;
            }
        }
    } catch (e) {
        console.error(e)
    }
}

function* destroy(action){
    try{

        let response = api.delete("/documents/"+action.id,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken))
            }
        });

        toast.promise(response,{
                loading: 'Excluindo o anexo.',
                success: (success) => {
                    return success.data.message
                },
                error: (error) => {
                    return error.response.data.message
                }
            }
        );
        response = yield response;
        if(response.status === 200){

            yield put(documentRemove(action.id));
            yield put(documentRemoveFinance(action.id));
        }
    } catch (e) {

        console.error(e)
    }
}

export function* documentsSaga() {
    yield takeEvery('GET_DOCUMENTS', get);
    yield takeEvery('GET_TYPES_DOCUMENTS', getTypesDocuments);
    yield takeEvery('UPLOAD_DOCUMENTS', upload);
    yield takeEvery('DESTROY_DOCUMENTS', destroy);
    yield takeEvery('UPDATE_DOCUMENTS', update);
}
