import { put, takeEvery, select} from 'redux-saga/effects';
import api from '../../api';
import toast from "react-hot-toast";
import {closeModal} from "../actions/uiActions";
import {operatorLoading, operatorSuccess, setOperators} from "../actions/operatorsActions";

export const getAuthToken = (state) => state.auth.token;

function* get(){
    try{
        yield put(operatorLoading(true))
        const response = yield  api.get('/companyHealthInsurance',{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield put(operatorLoading(false));
            yield put(operatorSuccess(response.data));
        }
    } catch (e) {
        yield put(operatorLoading(false));
        console.error(e);
    }
}

function* getOperators(){
    try{

        const response = yield  api.get('/operators',{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield put(setOperators(response.data))
        }
    } catch (e) {
        console.error(e);
    }
}

function* update(action){
    console.log(action)
    let response = api.put('/companyHealthInsurance/'+action.id, action.data, {
        headers: {
            'Authorization': 'Bearer ' + (yield select(getAuthToken)),
        }
    });

    toast.promise(response,
        {
            loading: 'Atualizando o operadora...',
            success: (success) => {
                return 'Operadora atualizada com sucesso.'
            },
            error: (error) => {
                return 'Não foi possível atualizar a operadora.'
            }
        }
    );

    response = yield response;
    if (response.status === 200) {
        yield get();
    }
}

function* store(action){

    let response = api.post('/companyHealthInsurance', action.data, {
        headers: {
            'Authorization': 'Bearer ' + (yield select(getAuthToken)),
        }
    });

    toast.promise(response,
        {
            loading: 'Cadastrando a operadora...',
            success: (success) => {
                return 'Operadora cadastrada com sucesso.'
            },
            error: (error) => {
                return "Não foi possível cadastrar a operadora."
            }
        }
    );

    response = yield response;

    if (response.status === 201) {
        yield put(closeModal());
        yield get();
    }
}




export function* operatorSaga() {
    yield takeEvery('GET_OPERATOR', get);
    yield takeEvery('GET_OPERATORS', getOperators);
    yield takeEvery('UPDATE_OPERATOR', update);
    yield takeEvery('STORE_OPERATOR', store);
}
