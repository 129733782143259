import {DateTime} from "luxon";
import {Avatar} from "../Avatar";
import {checkPermission, formatDateNotHour, formatHourStartAndEnd, getName} from "../../utils";
import React from "react";

const Title = ({title,fontSize,fontWeight}) => {
    return(
        <div
            style={{
                width:170,
                fontWeight:fontWeight ? fontWeight : '400',
                display: 'inline-bloc',
                whiteSpace: 'nowrap',
                fontSize:fontSize ? fontSize : 12,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                direction: 'ltr'
            }}
        >{title}</div>
    )
}

const Call = ({value,openCall,auth}) => {
    return (
        <div
            onClick={() => checkPermission(auth.permissions, "calls.show") && openCall("session", {
                ...value,
                type: 1,
                id: value.id,
                start: DateTime.fromSQL(value.start_date, {zone: "America/Sao_Paulo"}).toFormat("yyyy-MM-dd HH:mm:ss"),
                end: DateTime.fromSQL(value.end_date, {zone: "America/Sao_Paulo"}).toFormat("yyyy-MM-dd HH:mm:ss"),
                edit: true,
            },"secondary")}

            className="card"
            style={{
                marginRight: 10,
                backgroundColor: '#fff',
                borderRadius: 10,
                minWidth: 275,
                height: 'auto',
                justifyContent: 'start'
            }}
        >
            <div style={{padding: "10px 10px 0 10px", flexDirection: 'row', display: 'flex'}}>
                <div style={{marginRight: 10}}>
                    <Avatar row={value.patient} w={"w-60px"} h={"h-60px"}/>
                </div>
                <div style={{flexDirection: 'column', display: 'flex', width: '100%'}}>
                    <Title fontSize={15} fontWeight={"bold"}
                           title={<>{value.patient?.name}</>}/>
                    <Title
                        title={<>{value.treatment?.specialization?.profession?.abbreviation ?? value.treatment?.specialization?.profession?.description} {value.treatment?.specialization && value.treatment?.specialization?.description}</>}/>
                    <Title title={<div style={{flexDirection: 'row', display: 'flex'}}>
                        <Avatar row={value.professional} w={"w-20px"} h={"h-20px"}/>
                        <div style={{marginLeft: 5}}>{getName(value.professional?.name)}</div>
                    </div>}/>
                </div>
            </div>
            <hr style={{backgroundColor: '#c7c7c7', marginTop: 5, marginBottom: 0}}/>
            <div style={{padding: "10px ", display: 'flex', flexDirection: 'column'}}>
                <div>{formatDateNotHour(DateTime.fromSQL(value.start_date))} {formatHourStartAndEnd(DateTime.fromSQL(value.start_date), DateTime.fromSQL(value.end_date))}</div>
                <div>{value.room?.description ? "Sala " + value.room?.description : ""} - {value.type_schedule.description}</div>
                <div
                    className="badge badge-light-primary"
                    style={{
                        marginTop: 10,
                        background: value.status.color,
                        color: "#fff",

                        width: 'fit-content'
                    }}
                >
                    {value.status.description}
                </div>

                {value.replacement ? <div className="badge badge-light-warning"
                                          style={{
                                              width: 'fit-content',

                                              marginTop: 10
                                          }}>Reposição</div> : <></>}
                {value.rescheduled ? <div className="badge badge-primary" style={{
                    marginTop: 10,
                    width: 'fit-content',
                    backgroundColor: '#ffc107'
                }}>Remarcado</div> : <></>}
            </div>
        </div>
    )
}

export default Call;