import ContentLoader from "react-content-loader";
import React from "react";
import {useSelector} from "react-redux";
import trophy from "../../../assets/img/trofeu.png";

function Pendencies({openModalTertiary,professional}){

    const countPendencies = useSelector(state => state.calendar.dashboard.countPendencies);

    const total = countPendencies?.items?.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.count;
    }, 0);

    const typeSchedules = [
        {
            id: 1,
            color: "#a9fca9",
            description: "Avaliações"
        },
        {
            id: 2,
            color: "#c6eaff",
            description: "Evoluções"
        },
        {
            id: 3,
            color: "#ededed",
            description: "Anamneses"
        },
        {
            id: 4,
            color: "#e2c7bc",
            description: "Devolutivas"
        }
    ]

    return (
        <div className="card mb-5">
            <div className="card-body  px-0 pb-0" style={{paddingTop: 10}}>
                <div className="mb-12 px-4" style={{height: 140}}>
                    {
                        countPendencies?.loading === true ?
                            <div className="d-flex align-items-center mb-3">
                                <ContentLoader
                                    speed={2}
                                    width={200}
                                    height={30}
                                    viewBox="0 0 70% 150"
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#ecebeb"
                                >
                                    <rect x="0" y="0" rx="3" ry="3" width="100" height="20"/>
                                </ContentLoader>
                            </div>
                            :
                            <div className="d-flex align-items-center mb-3">
                                <span
                                    className="fs-1hx fw-bold text-gray-800 me-2 lh-1"
                                    style={{fontSize: 20, flex: 1}}
                                >
                                    {total}
                                </span>
                                {
                                    total > 0 ?
                                        <button
                                            onClick={() => openModalTertiary('pendenciesModal', {
                                                professional: professional
                                            })}
                                            className="btn btn-sm btn-light"
                                            style={{height: 30, width: 78, fontSize: 10}}
                                        >
                                            Ver tudo
                                        </button>
                                        :
                                        <></>
                                }
                            </div>
                    }
                    <span
                        className="fs-8 fw-semibold text-gray-600"
                    >
                        {total <= 1 ? "Atendimento pendente" : "Atendimentos pendentes"}
                    </span>
                    <div style={{
                        display: "flex",
                        gap: 5,
                        flexDirection: "column",
                        flex: 1,
                        marginTop: 10
                    }}>
                        {
                            countPendencies?.loading === true ?
                                <div className="d-flex fs-6 fw-semibold align-items-center">
                                    <ContentLoader
                                        speed={2}
                                        width={200}
                                        height={100}
                                        viewBox="0 0 70% 150"
                                        backgroundColor="#f3f3f3"
                                        foregroundColor="#ecebeb"
                                    >
                                        <rect x="0" y="0" rx="3" ry="3" width="130" height="10"/>
                                        <rect x="0" y="25" rx="3" ry="3" width="120" height="10"/>
                                        <rect x="0" y="50" rx="3" ry="3" width="110" height="10"/>
                                        <rect x="0" y="75" rx="3" ry="3" width="130" height="10"/>
                                    </ContentLoader>
                                </div>
                                :
                                countPendencies?.items?.length > 0 ?
                                    countPendencies?.items?.map((value, index) => {
                                        return (
                                            <div key={index}
                                                 className="d-flex fs-6 fw-semibold align-items-center">
                                                <div
                                                    className="bullet w-8px h-6px rounded-2 me-3"
                                                    style={{background: typeSchedules.filter(type => type.id === value.type_schedule)[0].color}}
                                                >
                                                </div>
                                                <div
                                                    className="fs-8 fw-semibold text-gray-500 flex-shrink-0">{typeSchedules.filter(type => type.id === value.type_schedule)[0].description}
                                                </div>
                                                <div
                                                    className="separator separator-dashed min-w-10px flex-grow-1 mx-2"></div>
                                                <div
                                                    className="ms-auto fw-bolder text-gray-700 text-end">{value.count}
                                                </div>
                                            </div>
                                        )
                                    })
                                    :
                                    <div style={{textAlign: "center"}}>
                                        <img
                                            src={trophy}
                                            width={140}/>
                                    </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Pendencies;