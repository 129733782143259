
export const getOperator = () => ({type: 'GET_OPERATOR'});
export const getOperators = () => ({type: 'GET_OPERATORS'});
export const operatorSuccess = (data) => ({type: 'OPERATOR_SUCCESS',data});
export const setOperators = (data) => ({type: 'SET_OPERATORS',data});
export const operatorLoading = (status) => ({type: 'OPERATOR_LOADING',status});
export const updateOperator = (data,id) => ({type: 'UPDATE_OPERATOR',data,id});
export const storeOperator = (data) => ({type: 'STORE_OPERATOR',data});









