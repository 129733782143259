import React, {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import {DateTime} from "luxon";
import ContentLoader from "react-content-loader";
import api from "../../api";

function RankingReportsController({}) {
    const [newRanking, setNewRanking] = useState([]);
    const [items,setItems] = useState([]);
    const [loading,setLoading] = useState(false);
    const {token,company} = useSelector(state => state.auth)

    const getPendencies = async () => {
        try{
            setLoading(true)

            const response = await api.post(
                '/call/list/pendencies',
                {
                    professional: [],
                    patient: [],
                    dateStart: DateTime.fromISO(company.created_at).toFormat('yyyy-MM-dd HH:mm:ss'),
                    dateEnd: DateTime.local().toFormat('yyyy-MM-dd HH:mm:ss'),
                },
                {
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            });

            if(response.status === 200){
                setItems(response.data);
            }
        }catch (e){

        }finally {
            setLoading(false)
        }

    }

    useEffect(() => {
        getPendencies().then(r => console.log);
    }, [])


    useEffect(() => {
        let ranking = [];
        if (items && items.length > 0) {
            ranking = items.reduce((res, at) => {
                if (typeof res[at.professional.id] === "undefined") res[at.professional.id] = [];
                res[at.professional.id].push(at);
                return res;
            }, {});

            ranking = Object.values(ranking).sort(function (a, b) {
                if (a.length > b.length) {
                    return -1;
                }
                if (a.length < b.length) {
                    return 1;
                }

                return 0;
            });

            setNewRanking(ranking)
        }
    }, [items])

    return (
        <>
            <div className="row">
                <div className="col-md-7" style={{marginTop: 10}}>
                    <h3>Classificação de pendências por terapeutas</h3>
                    <br/>
                </div>

            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                    <thead>
                                        <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                                            <th>Posição</th>
                                            <th>Nome</th>
                                            <th>Quantidade</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        loading ?
                                            [1, 2, 3].map((value, index) => {
                                                return (
                                                    <tr key={index}>

                                                        <td style={{textAlign: 'left'}}>
                                                            <ContentLoader viewBox="0 0 380 35">
                                                                <rect x="0" y="17" rx="4" ry="4" width="200"
                                                                      height="40"/>
                                                            </ContentLoader>
                                                        </td>

                                                        <td style={{textAlign: 'left'}}>
                                                            <ContentLoader viewBox="0 0 380 50">
                                                                <rect x="0" y="17" rx="4" ry="4" width="200"
                                                                      height="40"/>
                                                            </ContentLoader>
                                                        </td>
                                                        <td style={{textAlign: 'left', width: 100}}>
                                                            <ContentLoader viewBox="0 0 380 50">
                                                                <rect x="0" y="17" rx="4" ry="4" width="200"
                                                                      height="40"/>
                                                            </ContentLoader>
                                                        </td>

                                                    </tr>
                                                )
                                            })
                                            :

                                            Object.values(newRanking).map((value, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td style={{textAlign: 'left'}}>{index + 1}º</td>
                                                        <td style={{textAlign: 'left'}}>{value[0].professional.name}</td>
                                                        <td style={{textAlign: 'left'}}>{value.length}</td>
                                                    </tr>
                                                )
                                            })
                                    }
                                    </tbody>
                                </table>
                                <p>
                                    Os profissionais que não constam nesta lista não possuem pendências ou atendimentos
                                    cadastrados.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


export default RankingReportsController;
