import React from "react";
import styled, {createGlobalStyle} from 'styled-components';
import {useSelector} from "react-redux";
import {formatDate3} from "../../utils";
import {DateTime} from "luxon";
import {calculateAge} from "../Helper";

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
`;

const Page = styled.div`
  font-family: 'Montserrat', sans-serif;
 
  padding: 0px 10px ;
  border-radius: 10px;
  height: 75vh;
  overflow: auto;
`;

const Header = styled.div`
  left: 0;
  right: 0;
  height: 50px;
  text-align: center;
  margin-bottom: 2rem;
`;


const Left = styled.div`
  float: left;
  text-align: left;

  h2 {
    color: #0d6efd;
    font-weight: 700;
    padding-left: 0px;
  }

  p {
    margin: -5px 0 0 0;
    font-size: 12px;
  }
`;

const Right = styled.div`
  float: right;
  margin: -20px 0 0 0;

  img {
    width: 130px;
  }
`;

const Content = styled.div`
  background-color: #f8f9fa;
  border-radius: 1rem;
  padding: 20px;
  margin-bottom: 30px;
  
  
  display: flex;
  flex-direction: column;
  &::after {
    content: "";
    display: table;
    clear: both;
  }

  div {
    float: left;

    &.right {
      float: right;
      text-align: right;
    }

    h5 {
      font-size: 1.20rem;
      margin-top: 0;
      margin-bottom: .5rem;
      font-weight: 500;
      line-height: 1.2;
      color: #000;
    }

    p {
      color: #0d6efd;
      font-weight: 700;
      margin: -5px 0 0 0;
    }

    ul {
      margin: -5px 0 0 0;
      padding-left: 0;
      list-style: none;
    }
  }
`;

const Text = styled.p`
  font-family: 'Montserrat', sans-serif;
`

const Section = styled.div`
  page-break-inside: avoid;
  font-family: 'Montserrat', sans-serif;
  h5 {
    font-size: 1.20rem;
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
    color: #000;
  }

  p {
    text-align: justify;
  }
`;

function SignatureModal({isMobile,auth,modalPayload,closeModal,signature}) {
    const form = useSelector(state => state.groups.itens);

    const data = useSelector(state => state.calendar.item)[modalPayload.uuid];

    const profession = data.professional?.user_profession?.filter(value => value.profession.id === data.treatment.specialization.profession.id)

    const header = {
        typeScheduleName: data.type_schedule.description,
        logo: auth.company.logo
    };

    const patient = {
        name:  data.patient.name,
        dateOfBirth: data.patient.birth_date ? DateTime.fromISO(data.patient.birth_date).toFormat('dd/MM/yyyy'): '',
        age: data.patient.birth_date? calculateAge(data.patient.birth_date):'',
        cpf: data.patient.cpf
    };

    const call = {
        treatmentName: data.treatment ? data.treatment.specialization.profession.description + " " + (data.treatment.specialization.description ? data.treatment.specialization.description : "") : "",
        date: formatDate3(DateTime.fromSQL(data.start_date), DateTime.fromSQL(data.end_date)),
        form:form
    };

    const professional = {
        name: data.professional.name,
        cpf: data.professional.cpf,
        specializationName: profession?.length > 0 ? profession[0]?.profession.professional_name : '',
        specializationCode: profession?.length > 0 ?  profession[0]?.document : ''
    };

    console.log(patient)

    return (
        <div style={{width:isMobile ? "100%" : 500}}>
            <Page>
                <GlobalStyle />
                <Header>
                    <Left>
                        <h2 className="h2">{header.typeScheduleName}</h2>
                    </Left>
                    <Right>
                        {header.logo && <img src={header.logo} alt="Logo" />}
                    </Right>
                </Header>
                <Content>
                    <div>
                        <p>Paciente</p>
                        <h5>{patient.name}</h5>
                        <ul>
                            { data.patient.cpf ? <li><b>CPF:</b> {patient.cpf}</li> : <></>}
                            { data.patient.birth_date ? <li><b>Data de nascimento:</b> {patient.dateOfBirth}</li> : <></>}
                            { data.patient.birth_date ? <li><b>Idade:</b> {patient.age}</li> : <></>}
                        </ul>
                    </div>
                    <div style={{ marginTop: 20 }}>
                        <p>Profissional</p>
                        <h5>{professional.name}</h5>
                        <ul>
                            { data.professional.cpf ? <li><b>CPF:</b> {professional.cpf}</li> : <></>}
                            { profession?.length > 0 && profession[0]?.profession?.professional_name ? <li><b>Profissão:</b> {professional.specializationName}</li> : <></>}
                            { profession?.length > 0 && profession[0]?.document? <li><b>Registro:</b> {profession[0]?.document}</li> : <></>}
                        </ul>
                    </div>
                    <div style={{ marginTop: 20 }}>
                        <p>Atendimento</p>
                        <h5>{call.treatmentName}</h5>
                        <ul>
                            <li><b>Data:</b> {call.date}</li>
                        </ul>
                    </div>
                </Content>
                {
                     call?.form?.map((item) => {
                         return item.fields.map((value,index2) => {
                             if(!value.value_alternative && !value.value) return null;

                             return(
                                <Section key={index2}>
                                    <h5>{value.title}</h5>
                                    <Text>{value.value_alternative ?? value.value}</Text>
                                </Section>
                            )
                        })
                     })
                }
            </Page>
            <div className="row justify-content-center" style={{marginTop:20}}>
                <div className="col-md-4" style={{marginTop:10}}>
                    <button className="btn btn-sm btn-danger" style={{width:"100%"}} onClick={() => closeModal()}>Cancelar</button>
                </div>
                <div className="col-md-4" style={{marginTop:10}}>
                    <button
                        disabled={!auth.certificate}
                        className={"btn btn-sm btn-success "+(!auth.certificate ? " disabled" : "")}
                        style={{width:"100%"}}
                        onClick={() => signature({
                            link_id:data.id,
                            type:1
                        })}
                    >
                        Assinar
                    </button>
                </div>
            </div>
        </div>
    );
}

export default SignatureModal;
