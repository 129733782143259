
export const getQueueWait = (patient) => ({type: 'GET_QUEUE_WAIT',patient});
export const listQueueWait = (data) => ({type: 'GET_QUEUE_LIST_WAIT',data});
export const queueWaitListSuccess = (data) => ({type: 'QUEUE_WAIT_LIST_SUCCESS',data});
export const queueWaitSuccess = (data) => ({type: 'QUEUE_WAIT_SUCCESS',data});
export const queueWaitLoading = (status) => ({type: 'QUEUE_WAIT_LOADING',status});
export const updateQueueWait = (data,id) => ({type: 'UPDATE_QUEUE_WAIT',data,id});
export const storeQueueWait = (data) => ({type: 'STORE_QUEUE_WAIT',data});
export const destroyQueueWait = (id) => ({type: 'DESTROY_QUEUE_WAIT',id});









