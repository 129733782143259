import React, {useEffect, useMemo, useState} from 'react';
import { useSelector } from 'react-redux';
import api from "../../../api";
import ChartStyled from "./ChartStyled";
import {formatNumber} from "../../../utils";

function Receive({year,setReceive,type}) {
    const token = useSelector(state => state.auth.token);
    const [loading,setLoading] = useState(false)
    const [data, setData] = useState([]);

    useEffect(() => {
        setData([])
        setLoading(true)
        const getChartData = async () => {
            const response = await api.post(`/finance/money/type/1`,{year:year,type:type}, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                },
            });

            if (response.status === 200) {
                setData(response.data);
            }

            setLoading(false)
        };

        getChartData();
    }, [ token, year,type ]);

    const chartData = useMemo(() => {
        setReceive([]);

        const value = data.map(value => value.total);

        setReceive(value);

        let weeks =  [
            'Janeiro',
            'Fevereiro',
            'Março',
            'Abril',
            'Maio',
            'Junho',
            'Julho',
            'Agosto',
            'Setembro',
            'Outubro',
            'Novembro',
            'Dezembro'
        ];

        const label = data.map((value) => value.year_month);

        let soma = 0;
        data.forEach(value => soma += (value.total / 100));

        return {
            series: [{
                name: 'Valor',
                data: value
            }],
            options: {
                chart: {

                    type: 'area',
                    height: 140,
                    sparkline: {
                        enabled: true
                    },
                },
                stroke: {
                    curve: 'straight'
                },
                fill: {
                    opacity: 1,
                },
                labels: label.map((value) => {
                    let data = value.split('-');
                    return weeks[parseInt(data[1]-1)]+" de "+ data[0]
                }),
                yaxis: {
                    labels: {
                        formatter: function (value) {
                            // Definir a formatação do número aqui
                            return "R$ "+formatNumber(value);
                        },
                    },
                },
                colors: ["rgb(154,182,252)"],
                title: {
                    text: soma.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}),
                    offsetY: 20,
                    offsetX: 20,
                    style: {
                        fontSize: '24px',
                        cssClass: 'apexcharts-yaxis-title'
                    }
                },
                subtitle: {
                    text: "Receitas",
                    offsetY: 50,
                    offsetX: 20,
                    style: {
                        fontSize: '14px',
                        cssClass: 'apexcharts-yaxis-title'
                    }
                }
            },
        };
    }, [data,setReceive]);

    return (
        <ChartStyled data={chartData} loading={loading} />
    );
}

export default Receive;