import { takeEvery, select,put } from 'redux-saga/effects';
import api from '../../api';
import {alterAuthSuccess} from "../actions/authActions";
import toast from "react-hot-toast";
import {
    alterPendenciesCallsNotSigned,
    calendarAlterHome,
    calendarAlterSchedule,
    setItem
} from "../actions/calendarActions";
import {patientAlterAnamnese, patientAlterEvolution} from "../actions/patientsActions";
import {closeModal} from "../actions/uiActions";
export const getAuthToken = (state) => state.auth.token;

function* certificate(action){
    try{

        let data = new FormData();
        data.append('password_certificate', action.data.password_certificate);
        data.append('certificate', action.data.certificate);
        data.append('terms', action.data.terms);

        let response =  api.post('/certificate',data,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
                'Content-Type': 'multipart/form-data'
            }
        });

        toast.promise(response, {
            loading: 'Salvando certificado...',
            success: (success) => {
                return success.data.message
            },
            error: (error) => {
                return error.response.data.message
            }
        })

        response = yield response
        if(response.status === 200){
            yield put(alterAuthSuccess(response.data.certificate))
        }
    } catch (e) {
        console.error(e);       
    }
}

function* destroy(action){
    try{

        let response =  api.delete('/certificate/destroy/'+action.id,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken))
            }
        });

        toast.promise(response, {
            loading: 'Excluindo o certificado...',
            success: (success) => {
                return success.data.message
            },
            error: (error) => {
                return error.response.data.message
            }
        })

        response = yield response
        if(response.status === 200){
            yield put(alterAuthSuccess(response.data.certificate))
        }
    } catch (e) {
        console.error(e);
    }
}


function* signature(action){
    try{

        let response =  api.post('/certificate/signature',action.data,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken))
            }
        });

        toast.promise(response, {
            loading: 'Assinando documento...',
            success: (success) => {
                return success.data.message
            },
            error: (error) => {
                return error.response.data.message
            }
        })

        response = yield response
        if(response.status === 200){
            yield put(closeModal());
            switch (action.data.type){
                case 1:
                    yield put(alterPendenciesCallsNotSigned(response.data.data));
                    yield put(calendarAlterHome(response.data.data));
                    yield put(setItem(response.data.data, response.data.data.uuid));
                    yield put(calendarAlterSchedule(response.data.data));
                    yield put(patientAlterEvolution(response.data.data));
                    yield put(patientAlterAnamnese(response.data.data));
                    break;
                default:
                    break;
            }
        }
    } catch (e) {
        console.error(e);
    }
}

export function* signatureSaga() {
    yield takeEvery('SIGNATURE_CERTIFICATE', certificate);
    yield takeEvery('SIGNATURE_CERTIFICATE_DESTROY', destroy);
    yield takeEvery('SIGNATURE_DOCUMENT', signature);
}