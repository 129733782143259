import {updateObject} from '../../utils';

const initialState = {
    items:[],
    loading: false,
    list:[]
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'QUEUE_WAIT_SUCCESS':
            return updateObject(state, {items: action.data});
        case 'QUEUE_WAIT_LOADING':
            return updateObject(state, {loading: action.status});
        case 'QUEUE_WAIT_LIST_SUCCESS':
            return updateObject(state, {list: action.data});
        default:
            return state;
    }
}

export default reducer;
