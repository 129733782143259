import React, {useEffect, useState} from "react";
import api from "../../../api";
import {useSelector} from "react-redux";
import ContentLoader from "react-content-loader";


function CardStatus({title,status}){
    const [count,setCount] = useState(0);
    const [loading, setLoading] = useState(false)

    const token = useSelector( state => state.auth.token);


    useEffect(() => {
        async function getCount() {
            setLoading(true)
            try {
                const response = await api.get('/user/count/'+status, {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Accept':'application/json'
                    }
                });

                if(response.status === 200){
                    setCount(response.data)
                }
            }catch (e){

            }finally {
                setLoading(false)
            }
        }

        getCount();
    }, [token]);

    if(loading){
        return (
            <div className="card" style={{borderWidth:3,borderLeftColor:status === 1? '#47ff44' : '#f44336',borderLeftStyle:'solid'}}>
                <div className="card-body">
                    <ContentLoader viewBox="0 0 380 60">
                        <rect x="10" y="0" rx="4" ry="4" width="150" height="20" />
                        <rect x="10" y="30" rx="4" ry="4" width="80" height="20" />
                    </ContentLoader>
                </div>
            </div>
        )
    }

    return (

        <div className="card" style={{borderWidth:3,borderLeftColor:status === 1? '#47ff44' : '#f44336',borderLeftStyle:'solid'}}>
            <div className="card-body">
                <h5 style={{
                    fontSize: 24,
                    opacity: 1,
                    fontWeight: 900
                }}>{count}</h5>
                <h5 style={{
                    fontSize: 14,
                    opacity: 1,
                    fontWeight: 400,
                    marginTop: -5
                }}>{title}</h5>
            </div>
        </div>
    )
}

export default CardStatus;