import React, {useState, useEffect, useRef, useCallback} from "react";
import {Link} from 'react-router-dom';
import {Avatar} from "../Avatar";
import api from "../../api";
import {useSelector} from "react-redux";
import {Field} from "../Field";
import {Controller, useForm} from "react-hook-form";
import {formatDate} from "../../utils";
import {DateTime} from "luxon";

const Filter = ({diagnostics,type,control,handleSubmit,loadUsers,status,pageRef,lastPageRef,loadingRef,setUsers,diagnostic,name,closeAny}) => {

    const options = diagnostics?.map(value => ({label:value.description,value:value.id}));


    const onSubmit = (data) => {
        pageRef.current = 1
        lastPageRef.current = 1
        loadingRef.current = false
        setUsers([]);
        if(data.name) {
            name.current = data.name;
        }else{
            name.current = ''
        }

        status.current = data.status
        diagnostic.current = data.diagnostics?.map(value => value.value)
        closeAny()
        loadUsers();

    }


    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className='row'>
                <div className='col-md-3' style={{marginTop: 5}}>
                    <label className="fw-bold text-muted">Nome:</label>
                    <Controller
                        name="name"
                        control={control}
                        render={({field: {onChange, value}}) =>
                            <Field type="text" value={value} onChange={onChange}/>
                        }
                    />
                </div>
                <div className='col-md-2' style={{marginTop: 5}}>
                    <label className="fw-bold text-muted">Status:</label>
                    <Controller
                        name="status"
                        control={control}
                        render={({field: {onChange, value}}) =>
                            <Field type="select" options={[{label: 'Ativo', value: 1}, {
                                label: 'Inativo',
                                value: 0
                            }]} value={value} onChange={onChange}/>
                        }
                    />
                </div>
                {type === 'patient' && <div className='col-md-3' style={{marginTop: 5}}>
                    <label className="fw-bold text-muted">Diagnóstico:</label>
                    <Controller
                        name="diagnostics"
                        control={control}
                        render={({field: {onChange, value}}) =>
                            <Field type="select-multi" options={options} value={value} onChange={onChange}/>
                        }
                    />
                </div>}

                <div className="col-md-1" style={{marginTop: 25}}>
                    <button className='btn btn-success btn-sm' style={{width: '100%'}}>
                        <div style={{width: 18, margin: 'auto'}}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path
                                    d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352c79.5 0 144-64.5 144-144s-64.5-144-144-144S64 128.5 64 208s64.5 144 144 144z"
                                    fill="#fff"/>
                            </svg>
                        </div>
                    </button>
                </div>
            </div>
        </form>
    )
}


function User({type, title, isMobile, diagnostics, openAny ,closeAny}) {


    const [users, setUsers] = useState([]);

    const loadingRef = useRef(false);
    const pageRef = useRef(1);
    const lastPageRef = useRef(1);
    const token = useSelector(state => state.auth.token);

    const name = useRef('')
    const status = useRef(1)
    const diagnostic = useRef([])

    const fetchUsers = useCallback(async () => {

        const response = await api.post(
            '/user/search/type/' + (type === 'patient' ? 3 : 1) + "?page=" + pageRef.current + "&pageSize=10",
            {
                name: name.current,
                status: status.current,
                diagnostics: diagnostic.current,
            }, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json'
                }
            });

        return response.data;
    }, [type, token]);

    const loadUsers = useCallback(async () => {

        if (pageRef.current <= lastPageRef.current && !loadingRef.current) {
            loadingRef.current = true;
            const response = await fetchUsers();
            lastPageRef.current = response.last_page
            setUsers((prevUsers) => [...prevUsers, ...response.data]);
            pageRef.current = pageRef.current + 1;
            loadingRef.current = false;
        }
    }, [fetchUsers]);

    const handleScroll = useCallback(() => {
        const scrollHeight = document.documentElement.scrollHeight;
        const scrollTop = window.scrollY;
        const clientHeight = window.innerHeight;

        if (scrollTop + clientHeight >= scrollHeight - 20) {
            loadUsers();
        }
    }, [loadUsers]);


    const {control, handleSubmit} = useForm({
        defaultValues: {
            status: 1
        }
    });


    useEffect(() => {
        loadUsers();
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll, loadUsers]);


    return (
        <div style={{marginTop: 10}}>
            <div style={{display: 'flex', marginBottom: 10, marginTop: 20}}>
                <h1 style={{flex: 1}}>{title}</h1>
                <div>
                    {
                        isMobile && <div style={{cursor:'pointer',marginRight:10}} onClick={() => openAny(<Filter
                            diagnostics={diagnostics}
                            type={type}
                            control={control}
                            handleSubmit={handleSubmit}
                            loadUsers={loadUsers}
                            status={status}
                            pageRef={pageRef}
                            lastPageRef={lastPageRef}
                            loadingRef={loadingRef}
                            setUsers={setUsers}
                            diagnostic={diagnostic}
                            name={name}
                            closeAny={closeAny}
                        />,"Filtro", "lg")}>
                            <span className="svg-icon svg-icon-black ">
                                 <svg width="20" height="24" viewBox="0 0 20 20" fill="#000"
                                      xmlns="http://www.w3.org/2000/svg">
                                     <rect x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                                           transform="rotate(45 17.0365 15.1223)" fill="#000"/>
                                     <path
                                         d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                         fill="#000"/>
                                 </svg>
                            </span>
                        </div>
                    }
                </div>
            </div>
            {   !isMobile && <div style={{textAlign: 'left'}}>
                <div className="card" style={{marginBottom: 20}}>
                    <div className="card-header" style={{padding: "5px 20px", minHeight: 30}}>
                        <div className="card-title">
                            <div>
                                <div style={{flex: 1}}>Filtro</div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body" style={{paddingTop: 10}}>
                        <Filter
                            diagnostics={diagnostics}
                            type={type}
                            control={control}
                            handleSubmit={handleSubmit}
                            loadUsers={loadUsers}
                            status={status}
                            pageRef={pageRef}
                            lastPageRef={lastPageRef}
                            loadingRef={loadingRef}
                            setUsers={setUsers}
                            diagnostic={diagnostic}
                            name={name}
                            closeAny={closeAny}
                        />
                    </div>
                </div>
            </div>}

            {
                users.map((value, index) => {
                    return (
                        <Link to={"/"+(type === 'user' ? "user" : "patient")+"/"+value.id} className="card  mb-1 mb-xl-2" style={{cursor: 'pointer'}} key={index} >
                            <div className="card-body" style={{padding: 10}}>
                                <div style={{flexDirection: 'row', display: 'flex'}}>
                                    <div style={{flexDirection: 'column', display: 'flex'}}>
                                        <Avatar row={value}/>
                                        {value.status === 1 ? <div className="badge badge-success" style={{
                                            marginTop: 5,
                                            width: 50,
                                            fontSize: 9
                                        }}>Ativo</div> : <div className="badge badge-danger" style={{
                                            marginTop: 5,
                                            width: 50,
                                            fontSize: 9
                                        }}>Inativo</div>
                                        }
                                    </div>
                                    <div style={{marginLeft: 10}}>
                                        <h6>{value.name}</h6>
                                        {value.email && <div><b>Email</b>: {value.email}</div>}
                                        {value.cellphone && <div><b>Celular</b>: {"+" + value.ddi + " " + value.cellphone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')}</div>}
                                        {value.diagnostic_patient?.length > 0 && <div><b>Diagnósticos</b>: {value.diagnostic_patient.map(value => value.diagnostic.description).join(", ")}</div>}
                                        {value.last_action_at?.length > 0 && <div><b>Última ação no sistema</b>: {formatDate(DateTime.fromSQL(value.last_action_at))}</div>}
                                    </div>
                                </div>
                            </div>
                        </Link>
                    )
                })
            }
            {loadingRef.current && pageRef.current < lastPageRef.current &&
                <p style={{textAlign: 'center', padding: 10}}>Carregando...</p>}
        </div>
    );
}

export default User;
