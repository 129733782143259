import {updateObject} from '../../utils';

const initialState = {
    item:{},
    itens:[],
    loading:false,
    loadingRequest:false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'TIMEBLOCK_LOADING':
            return updateObject(state, {loading: action.status});
        case 'TIMEBLOCK_SUCCESS':
            return updateObject(state, {loading: false,itens:action.itens});        
        case 'TIMEBLOCK_LOADING_REQUEST':
            return updateObject(state, {loadingRequest: action.status});
        default:
            return state;
    }
}

export default reducer;
  