import {useForm, Controller} from "react-hook-form";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Field} from "../Field";
import {Button} from "../Button";
import Confirm from "../Confirm";
import {calcularMoney} from "../../utils";
import ContentLoader from "react-content-loader";
import Noticie from "../Noticie";
import api from "../../api";

const options = [
    {'label': 'Clínica', 'value': 1},
    {'label': 'Domicílio', 'value': 2},
    {'label': 'Escola', 'value': 3},
    {'label': 'Teleatendimento', 'value': 4}
]

const optionsRepeat = [
    {label: 'Não se repete', value: 0},
    {label: 'Repetir todo dia', value: 1},
    {label: 'Repetir toda semana', value: 2},
    {label: 'Repetir a cada 14 dias', value: 3}
]

const checkboxes = [
    {label: 'SEG', value: 1},
    {label: 'SEX', value: 5},
    {label: 'TER', value: 2},
    {label: 'SAB', value: 6},
    {label: 'QUA', value: 3},
    {label: 'DOM', value: 0},
    {label: 'QUI', value: 4}
]


function AtendimentoModal({
    getService,
    services,
    getAutocompleteProfessional,
    professionalsAutocomplete,
    professionalsLoadingAutocomplete,
    patientsLoadingAutocomplete,
    patientsAutocomplete,
    getAutocompletePatient,
    updateCall,
    data,
    auth,
    storeCall,
    specializations,
    getSpecializations,
    typeschedules,
    getTypeSchedules,
    getAddress,
    address,
    rooms,
    getRooms,
    calendarRef
}) {
    const status = useSelector(state => state.companies.status);
    const [professional, setProfessional] = useState(data && data.professional ? data.professional.id : 0);
    const [typeAddress, setTypeAddress] = useState(data && data.type_address ? data.type_address : 0);
    const [typeSchedule, setTypeSchedule] = useState(data && data.type_schedule ? data.type_schedule : 0);
    const [repeat, setRepeat] = useState(0);
    const [patient, setPatient] = useState(data && data.patient ? data.patient.id : 0);
    const [confirmTask, setConfirmTask] = useState(false);
    const [company, setCompany] = useState({});
    const [replacement, setReplacement] = useState(!!(data && data.replacement));

    const loadingCreate = useSelector(state => state.calendar.loadingCreate);
    const stateCall = useSelector(state => state.calendar.item)
    const token = useSelector(state => state.auth.token);


    useEffect(() => {
        api.get('/company/'+auth.company.id,{
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }).then(data => setCompany(data.data))
    }, [token]);

    let call = {};

    if(data.edit){
        typeschedules = typeschedules.filter(value => ![5,6,7].includes(value.value));
        call = stateCall[data.uuid];
    }else{
        call = data;
    }

    const [statusSelect, setStatusSelect] = useState(data && data.status ? data.status.id : 0);
    const dispatch = useDispatch();

    const {control, handleSubmit, formState: {errors}, setError, register} = useForm({
        defaultValues: {
            company: auth.company.id,
            patient: call && call.patient ? call.patient.id : null,
            professional: call && call.professional ? call.professional.id : null,
            treatment: call && call.treatment ? call.treatment.id : null,
            type_schedule: call && call.type_schedule ? call.type_schedule.id : null,
            type_address: call && call.type_address ? call.type_address : null,
            room: call && call.room ? call.room.id : null,
            address: call && call.address ? call.address.id : null,
            observation: call ? call.observation : '',
            data: call ? data.edit ? call.start_date : data.start_date : null,
            start: call ? call.start_date : null,
            repetir: 0,
            status: call && call.status ? call.status.id : null,
            repetirCount: 5,
            replacement: call && call.replacement ? call.replacement : false,
            obs_desmarcar: call ? call.obs_desmarcar : null,
            id: call ? call.id : null,
            task: call ? call.task : null,
            week: [],
            tid: call ? call.tid : null,
            call_old: call ? call.call_old : null,
            typeAction: call ? call.type : null,
            service: call && call.service ? call.service.id : null,
            form: '#newForm123'
        }
    });

    useEffect(() => {
        getTypeSchedules();
        getService();
        dispatch({  type: 'GET_STATUS' });
    }, []);

    useEffect(() => {
        if(call.uuid !== data.uuid){
            data.uuid = call.uuid;
        }
    }, [call]);

    useEffect(() => {

        if (call && call.professional && call.professional.id) {
            getSpecializations(call.professional.id)
        }

        if (call) {
            if (call.type_address === 1) {
                getRooms();
            }
            if (call.type_address === 2) {
                getAddress(call.patient.id);
            }
        }
    }, [getTypeSchedules, getSpecializations, getRooms, getAddress, call]);

    const onSubmit = (request, event) => {

        if (request.repetir && request.repetir === 2) {
            if (request.week.length === 0) {
                setError('week',{type:'custom',message:'Necessário selecionar um ou mais dia da semana'});
                return;
            }
        }

        request['type'] = data.type;
        if (data?.edit) {
            request['id'] = call?.id

            if (event.nativeEvent.submitter.name === 'all') {
                request['all'] = true;
            }
            updateCall(request, calendarRef)

        } else {
            storeCall(request, calendarRef)
        }
    }

    const onSelectProfessional = (value) => {
        if (value) {
            getSpecializations(value);
            setProfessional(value);
        }
    }

    const onSelectTypeAddress = (value) => {
        setTypeAddress(value)

        if (value === 1) {
            getRooms();
        }

        if (value === 2) {
            getAddress(patient)
        }
    }

    const items = status.filter((element) => element.lack < 3);

    let arrayStatus = []

    items.forEach((value) => {
        arrayStatus.push({'label': value.description, 'value': value.id})
    });

    const arrayAddress = address.map(value => ({
        value: value.id,
        label: value.street + ", " + value.number + " - " + value.neighborhood + ", " + value.city + " - " + value.state + ", " + value.zipcode
    }));

    let aServices = services?.filter((value) => value.status === 1).map((value) => ({
        'label': value.name + " (" + (calcularMoney(value.total).toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL'
        })) + ") (" + (value.type_commission === 1 ? calcularMoney(value.commisission_fixed).toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL'
        }) : value.type_commission === 2 ? value.commisission_percentage + "%" : "") + ")", 'value': value.id
    }));

    const handleInputChangePatient = (value) => {
        getAutocompletePatient(3, 1, 10, value);
    };

    const handleInputChangeProfessional = (value) => {
        getAutocompleteProfessional(1, 1, 10, value);
    };

    let arrayPatients = [];
    if (patientsAutocomplete && patientsAutocomplete.length > 0) {
        patientsAutocomplete.forEach(value => {
            arrayPatients.push({value: value.id, label: value.name})
        });
    }

    let arrayProfessionals = [];
    if (professionalsAutocomplete && professionalsAutocomplete.length > 0) {
        professionalsAutocomplete.forEach(value => {
            arrayProfessionals.push({value: value.id, label: value.name})
        });
    }

    const arrayTreatment = []
    if (specializations && specializations.length > 0) {

        if(!call?.id){
            specializations = specializations.filter(spe => spe.status === 1)
        }

        specializations.forEach(element => {
            arrayTreatment.push({
                label: ((element && element.specialization.profession) ? element.specialization.profession.description : '') + " " + (element.specialization.description ? element.specialization.description : '') + " (" + element.time + " minutos)",
                value: element.id
            })
        });
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-lg-6">
                    <label className="fw-bold text-muted" style={{marginTop: 10}}>Paciente:</label>
                    {
                        call && call.patient && call.patient.id && call.patient.name ?
                            <p style={{marginTop: 10}}>{call.patient.name}</p>
                            :
                            <>
                                <Controller
                                    name="patient"
                                    control={control}
                                    rules={{required: true}}
                                    render={({field: {onChange, value}}) =>
                                        <Field
                                            type="autocomplete"
                                            loading={patientsLoadingAutocomplete}
                                            options={arrayPatients}
                                            onInputChange={handleInputChangePatient}
                                            value={value}
                                            onChange={(value) => {
                                                onChange(value);
                                                setPatient(value)
                                            }}
                                        />
                                    }
                                />
                                {errors.patient && <p style={{color: 'red', marginTop: 10}}>Necessário selecionar o paciente.</p>}
                            </>
                    }
                </div>
                <div className="col-lg-6">
                    <label className="fw-bold text-muted" style={{marginTop: 10}}>Profissional:</label>
                    {
                        call && call.professional && call.professional.id && call.professional.name ?
                            <p style={{marginTop: 10}}>{call.professional.name}</p>
                            :
                            <>
                                <Controller
                                    name="professional"
                                    control={control}
                                    rules={{required: true}}
                                    render={({field: {onChange, value}}) =>
                                        <Field
                                            type="autocomplete"
                                            loading={professionalsLoadingAutocomplete}
                                            onInputChange={handleInputChangeProfessional}
                                            value={value}
                                            options={arrayProfessionals}
                                            onChange={(v) => {
                                                onChange(v);
                                                onSelectProfessional(v)
                                            }}
                                        />
                                    }
                                />
                                {errors.professional &&  <p style={{color: 'red', marginTop: 10}}>Necessário selecionar o profissional.</p>}
                            </>
                    }
                </div>
                <div className="col-lg-6">
                    <label className="fw-bold text-muted" style={{marginTop: 10}}>Tratamento:</label>
                    <Controller
                        name="treatment"
                        control={control}
                        rules={{required: true}}
                        render={({field: {onChange, value}}) =>
                            <Field
                                type="select"
                                options={arrayTreatment}
                                disabled={professional === 0}
                                value={value}
                                onChange={onChange}
                            />
                        }
                    />
                    {errors.treatment && <p style={{color: 'red', marginTop: 10}}>Necessário selecionar o tratamento.</p>}
                </div>
                <div className="col-lg-6">
                    <label className="fw-bold text-muted" style={{marginTop: 10}}>Tipo de agendamento:</label>
                    <Controller
                        name="type_schedule"
                        control={control}
                        rules={{required: true}}
                        render={({field: {onChange, value}}) =>
                            <Field
                                type="select"
                                options={typeschedules}
                                value={value}
                                onChange={(value) => {
                                    onChange(value);
                                    setTypeSchedule(value)
                                }}
                            />
                        }
                    />
                    {errors.type_schedule && <p style={{color: 'red', marginTop: 10}}>Necessário selecionar o tipo de agendamento.</p>}
                </div>
                {
                    ![5,6,7].includes(typeSchedule) && aServices && aServices.length > 0 ?
                        <div className="col-lg-6">
                            <label className="fw-bold text-muted" style={{marginTop: 10}}>Serviço:</label>
                            <Controller
                                name="service"
                                control={control}
                                render={({field: {onChange, value}}) =>
                                    <Field
                                        type="select"
                                        options={aServices}
                                        value={value}
                                        onChange={(value) => {
                                            onChange(value)
                                        }}
                                    />
                                }
                            />
                        </div>
                        :
                        <></>
                }
                {
                    ![5,6,7].includes(typeSchedule) ?
                        <div className="col-lg-6">
                            <label className="fw-bold text-muted" style={{marginTop: 10}}>Local:</label>
                            <Controller
                                name="type_address"
                                control={control}
                                rules={{required: true}}
                                render={({field: {onChange, value}}) =>
                                    <Field
                                        type="select"
                                        options={options}
                                        value={value}
                                        disabled={parseInt(patient) <= 0}
                                        onChange={(e) => {
                                            onChange(e);
                                            onSelectTypeAddress(e)
                                        }}
                                    />
                                }
                            />
                            {errors.type_address && <p style={{color: 'red', marginTop: 10}}>Necessário selecionar o local</p>}
                        </div>
                        :
                        <></>
                }
                {
                    ![5,6,7].includes(typeSchedule) && parseInt(typeAddress) === 1 ?
                        <div className="col-lg-6">
                            <label className="fw-bold text-muted" style={{marginTop: 10}}>Sala:</label>
                            <Controller
                                name="room"
                                control={control}
                                rules={{required: parseInt(typeAddress) === 1}}
                                render={({field: {onChange, value}}) => <Field type="select" options={rooms}
                                                                               value={value} onChange={onChange}/>}
                            />
                            {errors.room && <p style={{color: 'red', marginTop: 10}}>Necessário selecionar a sala.</p>}
                        </div>
                        :
                        <></>
                }
                {
                    ![5,6,7].includes(typeSchedule) && parseInt(typeAddress) === 2 ?
                        <div className="col-lg-6">
                            <label className="fw-bold text-muted" style={{marginTop: 10}}>Endereço:</label>
                            <Controller
                                name="address"
                                control={control}
                                rules={{required: parseInt(typeAddress) === 2}}
                                render={({field: {onChange, value}}) => <Field type="select" options={arrayAddress}
                                                                               value={value} onChange={onChange}/>}
                            />
                            {errors.address &&
                                <p style={{color: 'red', marginTop: 10}}>Necessário selecionar o endereço.</p>}
                        </div>
                        :
                        <></>
                }
                {
                    (data && data?.edit) && <div className="col-lg-6">
                        <label className="fw-bold text-muted" style={{marginTop: 10}}>Status:</label>
                        <Controller
                            name="status"
                            control={control}
                            rules={{required: parseInt(typeAddress) === 2}}
                            render={({field: {onChange, value}}) =>
                                <Field
                                    type="select"
                                    options={arrayStatus}
                                    value={value}
                                    onChange={(e) => {
                                       onChange(e);
                                       setStatusSelect(e)
                                    }}
                                />
                            }
                        />
                        {errors.address && <p style={{color: 'red', marginTop: 10}}>Necessário selecionar o endereço.</p>}
                    </div>
                }
                <div className="col-lg-6">
                    <label className="fw-bold text-muted" style={{marginTop: 10}}>Data do atendimento:</label>
                    <Controller
                        name="data"
                        control={control}
                        rules={{required: true}}
                        render={({field: {onChange, value}}) => <Field type="datetime" value={value}
                                                                       onChange={onChange}/>}
                    />
                    {errors.data && <p style={{color: 'red', marginTop: 10}}>Necessário selecionar a data.</p>}
                </div>
                {
                    ![5,6,7].includes(typeSchedule) ?
                        <div className="col-lg-6" style={{paddingTop: 15, display: 'flex'}}>
                            <Controller
                                name="replacement"
                                control={control}
                                render={({field: {onChange, value}}) =>
                                    <Field
                                        type="checkbox"
                                        title="Reposição?"
                                        value={value}
                                        onChange={(value) => {
                                            onChange(value);
                                            setReplacement(value);
                                        }}
                                    />
                                }
                            />
                            <span
                                style={{paddingTop: 14}}
                                className="svg-icon svg-icon-muted svg-icon-1hx"
                                title="Este campo ignora o expediente do profissional escolhido."
                            >
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
                                    <path
                                        d="M11.276 13.654C11.276 13.2713 11.3367 12.9447 11.458 12.674C11.5887 12.394 11.738 12.1653 11.906 11.988C12.0833 11.8107 12.3167 11.61 12.606 11.386C12.942 11.1247 13.1893 10.896 13.348 10.7C13.5067 10.4947 13.586 10.2427 13.586 9.944C13.586 9.636 13.4833 9.356 13.278 9.104C13.082 8.84267 12.69 8.712 12.102 8.712C11.486 8.712 11.066 8.866 10.842 9.174C10.6273 9.482 10.52 9.82267 10.52 10.196L10.534 10.574H8.826C8.78867 10.3967 8.77 10.2333 8.77 10.084C8.77 9.552 8.90067 9.07133 9.162 8.642C9.42333 8.20333 9.81067 7.858 10.324 7.606C10.8467 7.354 11.4813 7.228 12.228 7.228C13.1987 7.228 13.9687 7.44733 14.538 7.886C15.1073 8.31533 15.392 8.92667 15.392 9.72C15.392 10.168 15.322 10.5507 15.182 10.868C15.042 11.1853 14.874 11.442 14.678 11.638C14.482 11.834 14.2253 12.0533 13.908 12.296C13.544 12.576 13.2733 12.8233 13.096 13.038C12.928 13.2527 12.844 13.528 12.844 13.864V14.326H11.276V13.654ZM11.192 15.222H12.928V17H11.192V15.222Z"
                                        fill="currentColor"/>
                                </svg>
                            </span>
                        </div>
                        :
                        <></>
                }
                {
                    replacement === false ?
                        !data.edit && ![3, 4, 5, 6, 7].includes(typeSchedule) ?
                            <div className="col-lg-6">
                                <label className="fw-bold text-muted" style={{marginTop: 15}}></label>
                                <Controller
                                    name="repetir"
                                    control={control}
                                    render={({field: {onChange, value}}) =>
                                        <Field
                                            type="select"
                                            options={optionsRepeat}
                                            value={value}
                                            onChange={(e) => {
                                                onChange(e);
                                                setRepeat(e);
                                            }}
                                        />
                                    }
                                />
                            </div>
                            :
                            <></>
                        :
                        <></>
                }
            </div>

            <div className="row">
                {
                    data && data?.edit === true ?
                        <></>
                        :
                        repeat === 2 ?
                            <div className="col-lg-12">
                                <label className="fw-bold text-muted" style={{marginTop: 20}}>Dia da semana</label>
                                <hr style={{marginBottom: -10}}/>
                                <div className="row">
                                    {
                                        checkboxes.map((value, index) => {
                                            return (
                                                <div className="col-lg-6" key={index} style={{paddingTop: 20}}>
                                                    <div className="form-check form-check-custom form-check-solid">
                                                        <input className="form-check-input"
                                                               type="checkbox" {...register('week')} value={value.value}
                                                               id={"checkbox" + value.label}/>
                                                        <label className="form-check-label"
                                                               htmlFor={"checkbox" + value.label}>{value.label}</label>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                {errors.week && <p style={{color: 'red', marginTop: 10}}>Necessário selecionar um dia da
                                    semana.</p>}
                            </div>
                            :
                            <></>
                }
                {[5, 6, 7, 8, 13].includes(statusSelect) && <div className="col-lg-12">
                    <label className="fw-bold text-muted" style={{marginTop: 10}}>Por que desmarcou?</label>
                    <Controller
                        name="obs_desmarcar"
                        control={control}
                        rules={{required: true}}
                        render={({field: {onChange, value}}) => <Field type="textArea" value={value}
                                                                       onChange={onChange}/>}
                    />
                    {errors.obs_desmarcar &&
                        <p style={{color: 'red', marginTop: 10}}>Necessário preencher o motivo do desmarque.</p>}
                </div>}
                {
                    ![5,6,7].includes(typeSchedule) ?
                        <div className="col-lg-12">
                            <label className="fw-bold text-muted" style={{marginTop: 10}}>Observações:</label>
                            <Controller
                                name="observation"
                                control={control}
                                render={({field: {onChange, value}}) =>
                                    <Field
                                        type="textArea"
                                        value={value}
                                        onChange={onChange}
                                    />
                                }
                            />
                        </div>
                        :
                        <></>
                }

                {
                    [5,6,7].includes(typeSchedule) ?
                        <div className="col-md-12" style={{marginTop:15}}>
                            {company?.therapeutic_plan_months ? <Noticie title="Atenção" type="warning" description={"O tipo de agendamento escolhido não precisa ser registrado repetidamente entre o profissional e o paciente. Após o primeiro cadastro, o sistema atualizará automaticamente a data do atendimento a cada "+(company.therapeutic_plan_months === 1 ? "1 mês" : company.therapeutic_plan_months+" meses")+", permitindo que o profissional ajuste as informações conforme necessário. "} /> : <></>}
                        </div>
                        :
                        <></>
                }
            </div>
            <div className="row" style={{textAlign: 'left', marginTop: 10}}>
                <div className="col-md-9">
                    <Confirm
                        typeNotification="warning"
                        textNotification={"Este atendimento faz parte de um cadastro recorrente, isto é, que se repete com alguma frequência previamente determinada. Se você optar por 'Editar também os futuros', todos os atendimentos recorrentes desse horário e terapeuta serão modificados."}
                        title={"Como deseja editar o atendimento?"}
                        open={confirmTask}
                        buttons={[{
                            name: 'Editar somente esse', type: 'submit', onClick: () => {
                            }, class: 'btn-primary', target: 'one'
                        }, {
                            name: 'Editar também os futuros', type: 'submit', onClick: () => {
                            }, class: 'btn-secondary', target: 'all'
                        }]}
                        closeModal={() => setConfirmTask(false)}
                        onClick={() => {
                        }}
                    />
                </div>
                {
                    <div className="col-md-3" style={{textAlign: 'right'}}>
                        {
                            loadingCreate ?
                                <ContentLoader backgroundColor="#c7c6c6" viewBox="0 0 400 70">
                                    <rect x="0" y="0" rx="4" ry="4" width="400" height="40"/>
                                </ContentLoader>
                                :
                                data?.edit ?
                                    call?.id ?
                                        call.task > 0 ?
                                            call.status.id > 1 ?
                                                <Button text="Salvar" type="submit"/>
                                                :
                                                <button className="btn btn-primary btn-sm" type="button" onClick={() => {
                                                    setConfirmTask(true)
                                                }}>Salvar</button>
                                            :
                                            <Button text="Salvar" type="submit"/>
                                        :
                                        <></>
                                    :
                                    <Button text="Salvar" type="submit"/>
                        }
                    </div>
                }
            </div>
        </form>
    );
}

export default AtendimentoModal
