import {updateObject} from '../../utils';

const initialState = {
    itens: [],
    loading:true,
    loadingAction:[],
    loadingPdf:false,
    urlPdf:''
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GROUP_SUCCESS':
            return updateObject(state, {itens: action.itens,loading:false});
        case 'GROUP_START':
            return updateObject(state, {loading: true});
        case 'GROUP_FAIL':
            return updateObject(state, {loading: false,itens:[]});
        case 'GROUP_ACTION':{

            const loading = [...state.loadingAction];
            loading[action.table] = action.status 

            return updateObject(state, {loadingAction:loading});
        }
        case 'GROUP_PDF_LOADING':
            return updateObject(state, {loadingPdf: action.status});
        case 'GROUP_PDF_SUCCESS':
            return updateObject(state, {urlPdf: action.url});
        case 'ALTER_FIELDS_GROUPS':{
            const itens = [...state.itens];            
    
            const index = itens.findIndex(function(element, index, array){
                    return element.id === action.group
            });

            if(index !== -1){
                const indexFields = itens[index].fields.findIndex(function(element, index, array){
                    return element.id === action.field
                });

                itens[index].fields[indexFields].value = action.text
            }

            return updateObject(state, {itens:itens});
        }
        default:
            return state;
    }
}

export default reducer;
  