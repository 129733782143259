import {  put, takeEvery,select} from 'redux-saga/effects';
import api from '../../api';
import { toast } from 'react-toastify';
import { proceduresDestroy,createProcedures, proceduresFail, proceduresLoading, proceduresStart, proceduresSuccess, updateProcedures } from '../actions/procedureActions';
import { closeModal } from '../actions/uiActions';

export const getAuthToken = (state) => state.auth.token;
export const getAuthID = (state) => state.auth.id;

function* getProcedures(){
    try{
        yield put(proceduresStart());

        const response = yield  api.get("/user/"+ (yield select(getAuthID))+"/procedures",{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });
      
        if(response.status === 200){          
            yield put(proceduresSuccess(response.data))
        }else{
            yield put(proceduresFail())   
        }
    } catch (e) {
        console.error(e);
    }
}

function* store(action){
    try{
        yield put(proceduresLoading(true))
        const response = yield  api.post("/procedures",action.data,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){   
            yield put(createProcedures(response.data.procedures))       
            yield put(closeModal())
            toast.success(response.data.message);
        }

        yield put(proceduresLoading(false))
    } catch (e) {
        yield put(proceduresLoading(false))
    }
}


function* update(action){
    try{
        yield put(proceduresLoading(true));

        const response = yield  api.put("/procedures/"+action.id,action.data,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });
       
        if(response.status === 200){  
            yield put(updateProcedures(response.data.procedures,action.id))    
            toast.success(response.data.message);
        }

        yield put(proceduresLoading(false))
    } catch (e) {

      

        yield put(proceduresLoading(false))
    }
}

function* destroy(action){
    try{
       
        const response = yield  api.delete("/procedures/"+action.id,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        
        if(response.status === 200){  
            yield put(proceduresDestroy(action.id))    
            toast.success(response.data.message);
        }       
    } catch (e) {
    
    }
}

export function* proceduresSaga() {
    yield takeEvery('GET_PROCEDURES', getProcedures);
    yield takeEvery('STORE_PROCEDURES', store);
    yield takeEvery('UPDATE_PROCEDURES', update);
    yield takeEvery('DESTROY_PROCEDURES', destroy);
}