import React from 'react';
import {connect} from 'react-redux';
import {closeViewPDF} from "../store/actions/uiActions";
import ViewPDF from "../components/ViewPDF";

function ViewPDFController({modalOpen,modalPayload,closeModal}) {

    if(!modalOpen) return null;

    return (<ViewPDF modalPayload={modalPayload} closeModal={closeModal} />);
}

const mapStateToProps = state => ({
    modalOpen: state.ui.viewPDFOpen,
    modalPayload: state.ui.payloadViewPDF,
})

const mapDispatchToProps = dispatch => ({
    closeModal: () => dispatch(closeViewPDF()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ViewPDFController)
