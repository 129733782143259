import React, {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import Call from "./pendencies/Call";

function PendenciesModal({data,modalRef}) {

    const dispatch = useDispatch();
    const openCall = (modal,item,typeModal) => dispatch({type:'OPEN_CALL',modal,item,typeModal});
    const getCountPendencies = (data) => dispatch({type:'GET_COUNT_PENDENCIES',data});
    const getPendencies = (professional) => dispatch({type:'GET_PENDENCIES',professional});
    const clearPendencies = () => dispatch({type:'CLEAR_PENDENCIES'});
    const {loading,page,lastPage,items} = useSelector(state => state.calendar.dashboard.listPendencies);

    useEffect(() => {
        clearPendencies()
        loadCalls()

        return () => {
            getCountPendencies({ professional: data.professional})
            clearPendencies()
        }
    }, [])


    const loadCalls = useCallback(async () => {
        if (page <= lastPage && !loading) {
            getPendencies(data.professional);
        }
    }, [getPendencies]);

    const handleScroll = useCallback(() => {
        const { scrollHeight, scrollTop, clientHeight } = modalRef.current;
        if (scrollTop + clientHeight >= scrollHeight-5) {
            loadCalls();
        }
    }, [loadCalls]);

    useEffect(() => {
        modalRef.current.addEventListener('scroll', handleScroll);

        return () => {
            modalRef && modalRef.current && modalRef.current.removeEventListener('scroll', handleScroll);
        };
    },[handleScroll]);

    return (
        <>
            {items?.filter(value => [1,2,3,4].includes(value.status.id)).map((value,index) => <Call openCall={openCall} key={index} value={value} />)}
            <div  style={{textAlign: 'center'}}>{loading === true ? "Carregando..." : <></>}</div>
        </>
    );
}

export default PendenciesModal;
