import {updateObject} from '../../utils';

const initialState = {
    modal: null,
    modalOpen: false,
    modalPayload: null,
    modalSecondary: null,
    modalOpenSecondary: false,
    modalPayloadSecondary: null,
    modalTertiary: null,
    modalOpenTertiary: false,
    modalPayloadTertiary: null,
    anyOpen:false,
    title:'',
    size:'',
    children: null,
    viewPDFOpen:false,
    payloadViewPDF: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UI_OPEN_MODAL':
            return updateObject(state, {modal: action.modal, modalOpen: true, modalPayload:action.payload});
        case 'UI_CLOSE_MODAL':
            return updateObject(state, {modalOpen: false});
        case 'UI_OPEN_MODAL_SECONDARY':
            return updateObject(state, {modalSecondary: action.modal, modalOpenSecondary: true, modalPayloadSecondary:action.payload});
        case 'UI_CLOSE_MODAL_SECONDARY':
            return updateObject(state, {modalOpenSecondary: false});
        case 'UI_OPEN_MODAL_TERTIARY':
            return updateObject(state, {modalTertiary: action.modal, modalOpenTertiary: true, modalPayloadTertiary:action.payload});
        case 'UI_CLOSE_MODAL_TERTIARY':
            return updateObject(state, {modalOpenTertiary: false});
        case 'UI_OPEN_ANY':
            return updateObject(state, {title: action.title, children:action.children,size:action.size,anyOpen:true});
        case 'UI_CLOSE_ANY':
            return updateObject(state, {anyOpen: false,children:null});
        case 'UI_OPEN_VIEWPDF':

            return updateObject(state, {viewPDFOpen: action.viewPDFOpen, payloadViewPDF:action.payloadViewPDF});
        case 'UI_CLOSE_VIEWPDF':
            return updateObject(state, {viewPDFOpen: false,payloadViewPDF:null});
        default:
            return state;
    }
}

export default reducer;
  