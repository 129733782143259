import { useForm,Controller } from "react-hook-form";
import { Field } from "../Field";
import React, {  } from "react";
import { Button } from "../Button";
import {formatDateNotHour} from "../../utils";
import {DateTime} from "luxon";

function CertificateModal({certificate,auth,certificateDestroy,openAny}) {

    const {control, handleSubmit, formState: { errors },setValue } = useForm({
        defaultValues:{}
    });

    const changeHandler = (event) => {
        setValue('certificate',event.target.files[0]);
    };

    const onSubmit = (request)=>{
        certificate(request)
    }

    if(auth.certificate){
        let certificateValidityDate = DateTime.fromISO(auth.certificate_validity);

        let now = DateTime.now();

        return <div style={{display:"flex",width:"100%",borderRadius:10,border:"1px solid #f1f1f1",padding:20,paddingBottom:10,marginTop:10}}>
            <div style={{flex:1}}>
                <h4>certificado.pfx</h4>
                <p style={{color:"#b3b3b3"}}>
                    {
                        certificateValidityDate < now ?
                            <b>Seu certifcado venceu no dia: </b>
                            :
                            <b>Seu certifcado vence no dia: </b>
                    }
                    {formatDateNotHour(DateTime.fromISO(auth.certificate_validity))}
                </p>
            </div>
            <div style={{paddingTop:10}}>
                <button className="btn btn-danger btn-sm" onClick={() => certificateDestroy(auth.id)}>Excluir</button>
            </div>
        </div>
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-lg-12">
                    <label className="fw-bold text-muted" style={{marginTop:10}}>Certificado:</label>
                    <Controller
                        name="certificate"
                        control={control}
                        rules={{ required: true }}
                        render={({ field : { onChange, onBlur, value }}) => <input   type="file" name="certificate" onChange={changeHandler} className='form-control' style={{height:38}}/>}
                    />
                    {errors.certificate && <p style={{color:'red',marginTop:10}}>Necessário selecionar o certificado.</p>}
                </div>
                <div className="col-lg-12">
                    <label className="fw-bold text-muted" style={{marginTop:10}}>Senha:</label>
                    <Controller
                        name="password_certificate"
                        control={control}
                        rules={{ required: true }}
                        render={({ field : { onChange, onBlur, value }}) => <Field  type="password" value={value}  onChange={(value) => {onChange(value);} }  />}
                    />
                    {errors.password_certificate && <p style={{color:'red',marginTop:10}}>Necessário preencher a senha do certificado.</p>}
                </div>
                <div className="col-lg-12">
                    <div style={{display:"flex",marginTop:10}}>
                        <Controller
                            name="terms"
                            control={control}
                            rules={{ required: true }}
                            render={({ field : { onChange, onBlur, value }}) => <Field type="checkbox" value={value}  onChange={(value) => {onChange(value);} }  />}
                        />
                        <div
                            style={{marginTop:12}}
                        >
                            Concordo com os
                            <span
                                style={{cursor:"pointer",marginLeft:3,marginRight:3,fontWeight:"bold",color:"blue"}}
                                onClick={() => openAny(
                                    <div style={{marginTop:30,textAlign:"justify"}}>
                                        <h3>Termo de Aceite para Armazenamento de Certificado A1 para Assinatura Eletrônica</h3>
                                        <br/>
                                        <h4>1. Introdução</h4>

                                        <p>Este Termo de Aceite ("Termo") estabelece os termos e condições para o armazenamento seguro do Certificado Digital A1 utilizado para assinatura eletrônica. Ao aceitar este Termo, você concorda com o armazenamento do Certificado Digital A1 nos servidores da Amazon Web Services (AWS) S3 utilizando o AWS Key Management Service (KMS) para criptografia, administrados pela <b>Wayfly Tecnologia & Informacao LTDA</b>.</p>
                                        <br/>
                                        <h4>2. Definições</h4>
                                        <ul>
                                            <li><b>Certificado Digital A1</b>: Certificado emitido por uma autoridade certificadora, utilizado para assinatura eletrônica.</li>
                                            <li><b>AWS S3</b>: Serviço de armazenamento de objetos na nuvem fornecido pela Amazon Web Services.</li>
                                            <li><b>AWS KMS</b>: Serviço de gerenciamento de chaves fornecido pela Amazon Web Services, utilizado para criptografar dados.</li>
                                            <li><b>Wayfly Tecnologia & Informacao LTDA</b>: Empresa responsável pela administração e gerenciamento dos serviços AWS S3 e AWS KMS.</li>
                                        </ul>
                                        <br/>
                                        <h4>3. Armazenamento Seguro</h4>

                                        <p>3.1. O Certificado Digital A1 será armazenado nos servidores AWS S3, administrados pela <b>Wayfly Tecnologia & Informacao LTDA</b>, com criptografia gerenciada pelo AWS KMS.</p>

                                        <p>3.2. Apenas o sistema de assinatura da <b>Wayfly Tecnologia & Informacao LTDA</b> terá acesso ao Certificado Digital A1. Nenhum usuário da <b>Wayfly Tecnologia & Informacao LTDA</b> terá acesso ao certificado.</p>
                                        <br/>
                                        <h4>4. Responsabilidade e Segurança</h4>

                                        <p>4.1. A <b>Wayfly Tecnologia & Informacao LTDA</b> se compromete a implementar todas as medidas de segurança necessárias para proteger o Certificado Digital A1 contra acessos não autorizados, perdas e roubos.</p>

                                        <p>4.2. Você é responsável por manter a confidencialidade das suas credenciais de acesso e por todas as atividades realizadas com seu certificado digital.</p>
                                        <br/>
                                        <h4>5. Consentimento</h4>

                                        <p>Ao aceitar este Termo, você consente expressamente com o armazenamento do Certificado Digital A1 nos servidores da AWS S3, administrados pela <b>Wayfly Tecnologia & Informacao LTDA</b>, utilizando o AWS KMS para criptografia, conforme descrito acima.</p>
                                        <br/>
                                        <h4>6. Alterações no Termo</h4>

                                        <p>A <b>Wayfly Tecnologia & Informacao LTDA</b> se reserva o direito de alterar este Termo a qualquer momento, mediante notificação prévia. A continuidade do uso do serviço após a alteração constitui aceitação das novas condições.</p>

                                        <h4>7. Contato</h4>

                                        <p>Para quaisquer dúvidas ou questões relacionadas a este Termo, entre em contato com nosso suporte técnico pelo e-mail contato@wayfly.com.br.</p>
                                    </div>
                                    ,'Termo de Aceite para Armazenamento'
                                    ,'lg'
                                )}
                            >
                                termos para salvar
                            </span>
                            o certificado.
                        </div>
                    </div>
                    {errors.terms && <p style={{color:'red',marginTop:10}}>Necessário concordar com os termos.</p>}
                </div>
            </div>
            <div className="row" style={{textAlign:'right',marginTop:20}}>
                <div className="col-md-10">

                </div>
                <div className="col-md-2">
                    <Button text="Salvar" type="submit" />
                </div>   
            </div>
        </form>
    );
}

export default CertificateModal