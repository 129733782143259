import {DateTime} from "luxon";
import {toast} from "react-toastify";
import ContentLoader from "react-content-loader";
import {Controller, useForm} from "react-hook-form";
import {checkPermission, formatDate, getName, humanFileSize} from '../../utils';
import React, {useEffect, useState} from "react";
import Modal from "../modal/Modal";
import {Field} from "../Field";
import {Button} from "../Button";
import Img5 from '../../assets/img/5.png';
import {Avatar} from "../Avatar";

function DocumentForm({typeDocument,upload,typeModel,id,loading,data,updateDocuments}){

    const {control, handleSubmit, formState: { errors },setValue } = useForm({
        defaultValues:{
            type_model:typeModel,
            id:id,
            type_document:data &&  data.type_document ? data.type_document.id : null,
            description:data ? data.description : null,
            idDocument: data ? data.id : null
        }
    });

    const changeHandler = (event) => {
        setValue('file',event.target.files);
    };

    const onSubmit = (request)=>{

        if(data && data.id){
            updateDocuments(request);
        }else{
            if(request.file.size > 8000000){
                toast.warning("O arquivo não pode ser maior que 8MB");
                return;
            }

            upload(request);
        }

    };

    let typeDocumentOptions = []
    if(typeDocument && typeDocument.length > 0){
        typeDocument.forEach((value) =>{
            typeDocumentOptions.push({label:value.description,value:value.id})
        })
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-md-12">
                    <label style={{marginTop:10}}>Descrição:</label>
                    <Controller
                        name={"description"}
                        control={control}
                        rules={{ required: true }}
                        render={({ field : { onChange, value }}) => <Field type="text" value={value} onChange={onChange} />}
                    />
                    {errors.description && <p style={{color:'red',marginTop:10}}>Necessário preencher a descrição.</p>}
                </div>

                {
                    data && data.id > 0 ?
                        <></>
                        :
                        <div className="col-md-8">
                            <label style={{marginTop:10}}>Arquivo:</label>

                            <Controller
                                name={"file"}
                                control={control}
                                rules={{ required: true }}
                                render={({ field : { onChange, value }}) => <input multiple={true}  type="file"  onChange={changeHandler} className='form-control' style={{height:38}}/>}
                            />
                            {errors.file && <p style={{color:'red',marginTop:10}}>Necessário escolher o arquivo.</p>}
                        </div>
                }

                <div className="col-lg-4" >
                    <label style={{marginTop:10}}>Tipo de documento</label>
                    <Controller
                        name={"type_document"}
                        control={control}
                        rules={{ required: true }}
                        render={({ field : { onChange, value }}) => <Field type="select" value={value} options={typeDocumentOptions} onChange={onChange} />}
                    />
                    {errors.type_document && <p style={{color:'red',marginTop:10}}>Necessário preencher o tipo de documento.</p>}
                </div>

            </div>
            <div className="row" style={{textAlign:'right',marginTop:20}}>
                <div className="col-md-9"></div>
                <div className="col-md-3">
                    <Button loading={loading} text="Salvar" type="submit" />
                </div>
            </div>
        </form>
    )
}

function DocumentList({openViewPDF,value,auth,destroyDocuments,disabled,openModalDocument,setData}){

    const [novo,setNovo] = useState();

    useEffect(() => {

        function click(ev) {

            if(ev.srcElement.closest('#novoPlan') === null){
                setNovo(false);
            }
        }

        if(novo){
            window.addEventListener('click',click,false);
            return () => {
                window.removeEventListener('click',click,false);
            };
        }

    }, [novo]);

    const preview = () =>{
        switch (value.type){
            case 'video/mp4':
            case 'video/ogg':
            case 'video/quicktime':
                return(
                    <video width="200" height="200" controls style={{borderRadius:10, backgroundColor:'#00afef'}}>
                        <source src={value.url} type="video/mp4" />
                        <source src={value.url} type="video/ogg" />
                        <source src={value.url} type="video/quicktime" />
                        Your browser does not support the video tag.
                    </video>
                )
            case 'image/jpeg':
            case 'image/png':
                return(
                    <img alt="png" src={value.url} width="200" height="200" style={{borderRadius:10, backgroundColor:'#00afef'}}  />
                )
            case 'application/pdf':
                if(!checkPermission(auth.permissions,'documents.show')){
                    return (
                        <>
                            <b>{value ? value.description : '--'}</b>  <br/>
                            <small>{value.name}</small>
                        </>
                    )
                }
                return (
                    <>
                        <button onClick={() => openViewPDF(true,value)} className="btn btn-link" style={{textAlign:"left"}}>
                            <b>{value ? value.description : '--'}</b>  <br/>
                            <small>{value.name}</small>
                        </button>
                    </>
                )
            default:
                return (
                    <>
                        <b>{value ? value.description : '--'}</b>  <br/>
                        <small>{value.name}</small>
                    </>
                )
        }
    }

    return (
        <tr >
            <td style={{textAlign:'left'}}>
                {preview()}
            </td>
            <td style={{textAlign:'left'}}>
                {value && value.type_document ? value.type_document.description : '--'}
            </td>
            <td style={{textAlign:'left'}}>{value ? humanFileSize(value.size,true,0) : '--'}</td>
            <td style={{textAlign:'left'}}>{value ? formatDate(DateTime.fromISO(value.created_at)) : '--'}</td>
            <td style={{textAlign:'left'}}>{value.audit ? <div style={{display:"flex", flexDirection:"row"}}><Avatar row={value.audit.user} w="w-25px" h="h-25px"/> <div style={{marginLeft:10}}>{getName(value?.audit?.user?.name)}</div></div> : '--'}</td>
            <td style={{textAlign:'right'}}>
                <button  className="btn btn-sm btn-icon  btn-active-color-primary" style={{float:'right'}} onClick={()=> setNovo(true)}>
                    <span className="svg-icon svg-icon-muted svg-icon-2hx">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="4" fill="currentColor"/>
                            <rect x="11" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor"/>
                            <rect x="15" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor"/>
                            <rect x="7" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor"/>
                        </svg>
                    </span>
                </button>
                <div className={"dropdown-menu "+(novo ? "show" : '')} style={{marginTop:40,right:33}} id="novoPlan" >
                    { !disabled &&  checkPermission(auth.permissions,'documents.show') && value ?
                        <a

                            className="dropdown-item"
                            style={{cursor:'pointer'}}
                            onClick={() => {
                                openModalDocument(true);
                                setData(value)
                            }}
                        >
                            Editar
                        </a>
                        :
                        <></>
                    }
                    { checkPermission(auth.permissions,'documents.show') && value ? <a  className="dropdown-item" style={{cursor:'pointer'}} onClick={() => {  window.open(value.url, '_blank');setNovo(false) }}>Download</a> : <></> }
                    { !disabled && checkPermission(auth.permissions,'documents.destroy') && value ? <a className="dropdown-item" style={{cursor:'pointer'}} onClick={() => {destroyDocuments(value.id);setNovo(false) }}>Excluir</a> : <></> }
                </div>
            </td>
        </tr>
    )
}

function Documents({openViewPDF,disabled,loading,upload,typeDocument,openModal,openModalDocument,typeModel,id,loadingRequest,documents,auth,destroyDocuments,title,documentsFinance,updateDocuments}) {
    const [data,setData] = useState({});

    return (
        <>
            {openModal &&
                <Modal
                    title={data.id ? "Editar documento" : "Adicionar documento"}
                    closeModal={() => openModalDocument(false)}
                    size="lg"
                    children={
                        <DocumentForm
                            loading={loadingRequest}
                            typeDocument={typeDocument}
                            upload={upload}
                            typeModel={typeModel}
                            id={id}
                            data={data}
                            updateDocuments={updateDocuments}
                        />
                    }
                />
            }
            <div className="card ">
                <div className="card-header cursor-pointer">
                    <div className="card-title align-items-start flex-column">
                        <span className="fw-bolder m-0">{title} </span>
                    </div>
                    <div className="card-toolbar">
                        {!disabled && <button className="btn btn-sm btn-light-primary" onClick={() => {openModalDocument(true);setData({})}}>
                            Adicionar
                        </button> }
                    </div>
                </div>

                <div className="card-body py-3">
                    <div className='table-responsive'>
                        <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                            <thead>
                               <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                                   <th style={{textAlign: 'left'}}>Descrição</th>
                                   <th style={{textAlign: 'left'}}>Tipo de Documento</th>
                                   <th style={{textAlign: 'left'}}>Tamanho</th>
                                   <th style={{textAlign: 'left'}}>Enviado em</th>
                                   <th style={{textAlign: 'left'}}>Enviado por</th>
                                   <th></th>
                               </tr>
                            </thead>
                            {
                                loading === true ?
                                    <tbody>
                                        {
                                           [1, 2, 3, 4, 5, 6].map((value, index) => {
                                               return (
                                                   <tr key={index}>
                                                       <td style={{textAlign: 'left'}}>
                                                           <ContentLoader viewBox="0 0 380 70">
                                                               <rect x="0" y="17" rx="4" ry="4" width="200"
                                                                     height="40"/>
                                                           </ContentLoader>
                                                       </td>
                                                       <td style={{textAlign: 'left'}}>
                                                           <ContentLoader viewBox="0 0 380 35">
                                                               <rect x="0" y="17" rx="4" ry="4" width="200"
                                                                     height="40"/>
                                                           </ContentLoader>
                                                       </td>
                                                       <td style={{textAlign: 'left'}}>
                                                           <ContentLoader viewBox="0 0 380 35">
                                                               <rect x="0" y="17" rx="4" ry="4" width="200"
                                                                     height="40"/>
                                                           </ContentLoader>
                                                       </td>
                                                       <td style={{textAlign: 'left'}}>
                                                           <ContentLoader viewBox="0 0 380 50">
                                                               <rect x="0" y="17" rx="4" ry="4" width="200"
                                                                     height="40"/>
                                                           </ContentLoader>
                                                       </td>
                                                       <td style={{textAlign: 'left'}}>
                                                           <ContentLoader viewBox="0 0 380 50">
                                                               <rect x="0" y="17" rx="4" ry="4" width="200"
                                                                     height="40"/>
                                                           </ContentLoader>
                                                       </td>
                                                   </tr>
                                               )
                                           })
                                       }
                                    </tbody>
                                    :
                                       <tbody className="fw-semibold text-gray-600">
                                       {
                                           typeModel === 2 ?
                                               documentsFinance && documentsFinance.length > 0 ?
                                                   documentsFinance.map((value, index) => {
                                                       return (
                                                           <DocumentList
                                                               openViewPDF={openViewPDF}
                                                               openModalDocument={openModalDocument}
                                                               setData={setData}
                                                               updateDocuments={updateDocuments}
                                                               typeDocument={typeDocument}
                                                               value={value}
                                                               auth={auth}
                                                               key={index}
                                                               destroyDocuments={destroyDocuments}
                                                               upload={upload}
                                                               typeModel={typeModel}
                                                               id={id}
                                                           />
                                                       )
                                                   })
                                                   :
                                                   <tr className="odd">
                                                       <td valign="top" colSpan="5" className="dataTables_empty">
                                                           <div className="d-flex flex-column flex-center">
                                                               <img alt="alt" src={Img5} style={{maxWidth: 290}}/>
                                                               <div className=" fw-bolder text-dark mb-4"
                                                                    style={{fontSize: 16}}>Nenhum documento encontrado.
                                                               </div>
                                                           </div>
                                                       </td>
                                                   </tr>
                                               :
                                               documents && documents.length > 0 ?
                                                   documents.map((value, index) => {
                                                       return (
                                                           <DocumentList
                                                               openViewPDF={openViewPDF}
                                                               disabled={disabled}
                                                               openModalDocument={openModalDocument}
                                                               setData={setData}
                                                               updateDocuments={updateDocuments}
                                                               typeDocument={typeDocument}
                                                               value={value}
                                                               auth={auth}
                                                               key={index}
                                                               destroyDocuments={destroyDocuments}
                                                               upload={upload}
                                                               typeModel={typeModel}
                                                               id={id}
                                                           />
                                                       )
                                                   })
                                                   :
                                                   <tr className="odd">
                                                       <td valign="top" colSpan="5" className="dataTables_empty">
                                                           <div className="d-flex flex-column flex-center">
                                                               <img src={Img5} alt="alt" style={{maxWidth: 290}}/>
                                                               <div className=" fw-bolder text-dark mb-4"
                                                                    style={{fontSize: 16}}>Nenhum documento encontrado.
                                                               </div>
                                                           </div>
                                                       </td>
                                                   </tr>
                                       }
                                       </tbody>
                               }
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Documents;
