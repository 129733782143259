import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {openModal} from "../../store/actions/uiActions";
import ContentLoader from "react-content-loader";
import {checkPermission} from "../../utils";
import {getOperator, updateOperator} from "../../store/actions/operatorsActions";

function OperatorsList({value,openModal,updateOperator,auth}){
    const [novo,setNovo] = useState(false);

    useEffect(() => {
        function click(ev) {
            if(ev.srcElement.closest('#service') == null){
                setNovo(false);
            }
        }

        if(novo){
            window.addEventListener('click',click,false);
            return () => {
                window.removeEventListener('click',click,false);
            };
        }

    }, [novo]);

    return(
        <tr>
            <td style={{textAlign:'left'}}>{value.operator?.ANS_record}</td>
            <td style={{textAlign:'left'}}>{value.operator?.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5')}</td>
            <td style={{textAlign:'left'}}>{value.operator?.fantasy_name}</td>
            <td style={{textAlign:'left'}}>{value.operator?.corporate_reason}</td>
            <td style={{textAlign:'left'}}>{value.status === 1 ? "Ativo" : "Inativo"}</td>
            <td>
                <button  className="btn btn-sm btn-icon  btn-active-color-primary" style={{float:'right'}} onClick={()=>setNovo(true)}>
                    <span className="svg-icon svg-icon-muted svg-icon-2hx">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="4" fill="currentColor"/>
                            <rect x="11" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor"/>
                            <rect x="15" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor"/>
                            <rect x="7" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor"/>
                        </svg>
                    </span>
                </button>
                <div className={"dropdown-menu "+(novo ? "show" : '')} style={{marginTop:40,right:33}} id="service" >
                    {checkPermission(auth.permissions,'operators.update') ? <a className="dropdown-item" style={{cursor:'pointer'}} onClick={()=>{ openModal('operatorsModal', {operator:value.operator.id,id:value.id}); setNovo(false) }}>Editar</a> : <></>}
                    {checkPermission(auth.permissions,'operators.status') ? <a className="dropdown-item" style={{cursor:'pointer'}} onClick={()=>{  updateOperator({status:value.status === 1 ? 2 : 1  },value.id); setNovo(false) }}>{value.status === 1 ? "Desativar" : "Ativar"}</a>: <></>}
                </div>
            </td>
        </tr>
    )
}

function OperatorsController({openModal,getOperator,items,loading,updateOperator,auth}) {

    useEffect(() =>{
        getOperator();
    },[getOperator])

    return(
        <div className="card  mb-5 mb-xl-10">
            <div className="card-header cursor-pointer">
                <div className="card-title align-items-start flex-column">
                    <span className="fw-bolder m-0">Operadoras</span>
                </div>
                <div className="card-toolbar">
                    {checkPermission(auth.permissions,'operators.store') ?  <button className="btn btn-sm btn-light-info" onClick={() => openModal('operatorsModal',{}) }>Adicionar</button> : <></>}
                </div>
            </div>
            <div className="card-body">
                <div className='table-responsive'>
                    <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                        <thead>
                            <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                                <th style={{textAlign:'left'}}>Registro ANS</th>
                                <th style={{textAlign:'left'}}>CNPJ</th>
                                <th style={{textAlign:'left'}}>Nome fantasia</th>
                                <th style={{textAlign:'left'}}>Razão social</th>
                                <th style={{textAlign:'left'}}>Status</th>

                                <th></th>
                            </tr>
                        </thead>
                        {
                            loading === true ?
                                <tbody>
                                {
                                    [1,2,3].map((value,index) =>{
                                        return(
                                            <tr key={index}>
                                                <td style={{textAlign: 'left'}}>
                                                    <ContentLoader viewBox="0 0 200 10">
                                                        <rect x="0" y="0" rx="4" ry="4" width="100" height="10"/>
                                                    </ContentLoader>
                                                </td>
                                                <td style={{textAlign: 'left'}}>
                                                    <ContentLoader viewBox="0 0 200 10">
                                                        <rect x="0" y="0" rx="4" ry="4" width="100" height="10"/>
                                                    </ContentLoader>
                                                </td>
                                                <td style={{textAlign: 'left'}}>
                                                    <ContentLoader viewBox="0 0 200 10">
                                                        <rect x="0" y="0" rx="4" ry="4" width="100" height="10"/>
                                                    </ContentLoader>
                                                </td>
                                                <td style={{textAlign: 'left'}}>
                                                    <ContentLoader viewBox="0 0 200 10">
                                                        <rect x="0" y="0" rx="4" ry="4" width="100" height="10"/>
                                                    </ContentLoader>
                                                </td>
                                                <td style={{textAlign: 'left'}}>
                                                    <ContentLoader viewBox="0 0 200 10">
                                                        <rect x="0" y="0" rx="4" ry="4" width="100" height="10"/>
                                                    </ContentLoader>
                                                </td>
                                                <td style={{textAlign: 'left'}}>
                                                    <ContentLoader viewBox="0 0 200 10">
                                                        <rect x="0" y="0" rx="4" ry="4" width="100" height="10"/>
                                                    </ContentLoader>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                                :
                                <tbody>
                                {
                                    items && items.length > 0 ?
                                        items.map((value,index) =>{
                                            return <OperatorsList auth={auth} value={value} key={index} openModal={openModal} updateOperator={updateOperator} />
                                        })
                                        :
                                        <tr>
                                            <td colSpan="6"><div style={{textAlign:"center",paddingTop:10}}>Não foi encontrado nenhuma operadora.</div></td>
                                        </tr>
                                    }
                                </tbody>
                        }
                    </table>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    items: state.operators.items,
    loading: state.operators.loading,
    auth: state.auth
})

const mapDispatchToProps = dispatch => ({
    openModal:(modal,data) => dispatch(openModal(modal,data)),
    getOperator:() => dispatch(getOperator()),
    updateOperator:(data,id) => dispatch(updateOperator(data,id))
})

export default connect(mapStateToProps,mapDispatchToProps)(OperatorsController)
