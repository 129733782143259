import blank from '../../assets/img/blank.png';
import { checkPermission, formatDateNotHour } from "../../utils";
import { DateTime } from "luxon";
import React, { useState} from "react";
import Confirm from '../../components/Confirm';
import { Link } from 'react-router-dom';
import {useMediaQuery} from "react-responsive";
import ContentLoader from "react-content-loader";

export default function PatientTop({item,menu,openModal,edit,type,onUpload,onDelete,auth,permission,destroyPatient,loading,isMobile}) {
    const [confirm,setConfirm] = useState(false);

    let age = 0;
    const isBigScreen = useMediaQuery({query: '(min-width: 1200px)'});

    if(item.birth_date){
        const date1 = DateTime.fromISO(DateTime.now().toISO())
        const date2 = DateTime.fromSQL(item.birth_date )
        const diff = date1.diff(date2, ["years", "months", "days", "hours"])

        let ageValue = diff.toObject();

        if(ageValue.years > 0 && ageValue.months > 0){
            age = (ageValue.years === 1 ? "1 ano" :  ageValue.years+" anos") + " e "+(ageValue.months === 1 ? " 1 mês" : ageValue.months+" meses");
        }else if(ageValue.years > 0){
            age = (ageValue.years === 1 ? "1 ano" :  ageValue.years+" anos") ;
        }else if(ageValue.years === 0){
            age = (ageValue.months === 1 ? " 1 mês" : ageValue.months+" meses");
        }else if(ageValue.years === 0 && ageValue.months === 0){
            age = ageValue.days  === 1 ? " 1 dia" : ageValue.days + " dias";
        }
    }

    const maritalStatus = [
        "Solteiro(a)",
        "Casado(a)",
        "Viúvo(a)",
        "Divorciado(a)",
        "Separado(a)"
    ];

    if(loading){
        return(
            <div className="card" style={{marginBottom:20,borderRadius:isMobile?-30:10}}>
                <div className="card-body ">
                    <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                        <div className="me-7 mb-4" style={{flex:isBigScreen ? 0 : 1}}>
                            <div className="image-input image-input-outline"  >
                                <div className="image-input-wrapper w-125px h-125px" style={{backgroundImage: `url(${item.img ? item.img : blank})`}}></div>
                            </div>
                        </div>
                        <div className="flex-grow-1">
                            <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                <div className="d-flex flex-column">
                                    <div className="d-flex align-items-center mb-2">
                                        <div style={{width:300}}>
                                            <ContentLoader viewBox="0 0 380 40">
                                                <rect x="0" y="17" rx="4" ry="4" width="300" height="60" />
                                            </ContentLoader>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                                        <span className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                                            <div style={{width:150}}>
                                                <ContentLoader viewBox="0 0 380 40">
                                                    <rect x="0" y="17" rx="4" ry="4" width="300" height="60" />
                                                </ContentLoader>
                                            </div>
                                        </span>
                                        <span className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                                            <div style={{width:150}}>
                                                <ContentLoader viewBox="0 0 380 40">
                                                    <rect x="0" y="17" rx="4" ry="4" width="300" height="60" />
                                                </ContentLoader>
                                            </div>
                                        </span>
                                        <span className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                                            <div style={{width:150}}>
                                                <ContentLoader viewBox="0 0 380 40">
                                                    <rect x="0" y="17" rx="4" ry="4" width="300" height="60" />
                                                </ContentLoader>
                                            </div>
                                        </span>
                                        <span className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                                            <div style={{width:150}}>
                                                <ContentLoader viewBox="0 0 380 40">
                                                    <rect x="0" y="17" rx="4" ry="4" width="300" height="60" />
                                                </ContentLoader>
                                            </div>
                                        </span>
                                        <span className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                                            <div style={{width:150}}>
                                                <ContentLoader viewBox="0 0 380 40">
                                                    <rect x="0" y="17" rx="4" ry="4" width="300" height="60" />
                                                </ContentLoader>
                                            </div>
                                        </span>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="card" style={{marginBottom:20,borderRadius:isMobile?-30:10}}>
            <div className="card-body">
                <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                    <div className="me-7 mb-4" style={{flex:isBigScreen ? 0 : 1}}>
                        <div className="image-input image-input-outline"  >
                            <div className="image-input-wrapper w-125px h-125px" style={{backgroundImage: `url(${item.img ? item.img : blank})`}}></div>
                            {
                                edit && checkPermission(auth.permissions,permission+".update") ?
                                    <>
                                        <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" aria-label="Change avatar" >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-pencil-fill" viewBox="0 0 16 16">
                                                <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" fill='#a1a5b7'/>
                                            </svg>
                                            <input type="file" name="avatar" accept=".png, .jpg, .jpeg" onChange={(e) => onUpload(e.target.files[0])}/>
                                            <input type="hidden" name="avatar_remove" />
                                        </label>
                                        <button onClick={() => onDelete()} className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="cancel" data-bs-toggle="tooltip" aria-label="Cancel avatar" >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" fill='#a1a5b7'/>
                                            </svg>
                                        </button>
                                    </>
                                :
                                    <></>
                            }
                        </div>
                    </div>

                    <div className="flex-grow-1">
                        <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                            <div className="d-flex flex-column">
                                <div className="d-flex align-items-center mb-2">
                                    <Link to={(type === 'user' ? "/user/" : "/patient/")+item.id} className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">
                                        {item.name}
                                    </Link>

                                </div>
                                <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                                    {
                                        item.use_image === 1 ?
                                            <div className="badge badge-success  fs-8 py-1 px-3" style={{marginRight:10}}><b>Autorizado o uso de imagem</b></div>
                                            :
                                            item.use_image === 2 ?
                                                <div className="badge badge-danger fs-8 py-1 px-3" style={{marginRight:10}}><b>Não Autorizado o uso de imagem</b></div>
                                                :
                                                <></>
                                    }
                                    {
                                        item.status === 1 ?
                                            <div className="badge badge-success fs-8 py-1 px-3" style={{marginRight:10}}>
                                                <b>Ativo</b>
                                            </div>
                                            :
                                            <div className="badge badge-danger fs-8 py-1 px-3" style={{marginRight:10}}><b>Inativo</b></div>
                                    }
                                    {
                                        item.deceased === 1  ?
                                            <div className="badge badge-danger fs-8 py-1 px-3">
                                                <b>Falecido</b>
                                            </div>
                                            :
                                            <></>
                                    }
                                </div>
                                <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">

                                    {
                                        item.sex ?
                                            <span className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                                                <b style={{marginRight:10}}>Sexo: </b> {item.sex === 1 ? "Masculino" : "Feminino"}
                                            </span>
                                        :
                                            <></>
                                    }
                                    {
                                        item.marital_status ?
                                            <span className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                                                <b style={{marginRight:10}}>Estado Civil: </b> {maritalStatus[item.marital_status-1]}
                                            </span>
                                        :
                                            <></>
                                    }
                                    {
                                        item.birth_date ?
                                            <span className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                                                <b style={{marginRight:10}}>Data de nascimento: </b> {formatDateNotHour(DateTime.fromSQL(item.birth_date))}
                                            </span>
                                        :
                                            <></>
                                    }
                                     {
                                        item.birth_date ?
                                            <span className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                                                <b style={{marginRight:10}}>Idade: </b>{age}
                                            </span>
                                        :
                                            <></>
                                    }
                                    {
                                        item.cellphone ?
                                            <span className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                                                <b style={{marginRight:10}}>Celular: </b>{item.ddi} {item.cellphone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')}
                                            </span>
                                        :
                                            <></>
                                    }
                                    {
                                        item.profession ?
                                            <span className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                                                <b style={{marginRight:10}}>Profissão: </b> {item.profession}
                                            </span>
                                        :
                                            <></>
                                    }
                                    {
                                        item.email ?
                                            <span className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                                                <b style={{marginRight:10}}>Email: </b> {item.email}
                                            </span>
                                        :
                                            <></>
                                    }
                                    {
                                        item.cpf ?
                                            <span className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                                                <b style={{marginRight:10}}>CPF: </b> {item.cpf}
                                            </span>
                                        :
                                            <></>
                                    }
                                    {
                                        item.rg ?
                                            <span className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                                                <b style={{marginRight:10}}>RG: </b> {item.rg}
                                            </span>
                                        :
                                            <></>
                                    }
                                    {
                                        item.school ?
                                            <span className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                                                <b style={{marginRight:10}}>Escola: </b> {item.school}
                                            </span>
                                        :
                                            <></>
                                    }
                                    {
                                        item.school_observation ?
                                            <span className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                                                <b style={{marginRight:10}}>Observações Escola: </b> {item.school_observation}
                                            </span>
                                        :
                                            <></>
                                        }
                                    {
                                        item.bf ?
                                            <span className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                                                <b style={{marginRight:10}}>Bolsa família: </b> Sim
                                            </span>
                                            :
                                            <></>
                                    }
                                    {
                                        item.bpc ?
                                            <span className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                                                <b style={{marginRight:10}}>Benefício de Prestação Continuada (BPC): </b> Sim
                                            </span>
                                            :
                                            <></>
                                    }
                                    {
                                        item.origem ?
                                            <span className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                                                <b style={{marginRight:10}}>Origem: </b> {item.origem}
                                            </span>
                                            :
                                            <></>
                                    }
                                    {
                                        item.emprega_apae ?
                                            <span className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                                                <b style={{marginRight:10}}>Entrega APAE: </b> Sim
                                            </span>
                                            :
                                            <></>
                                    }
                                    {
                                        item.number_sus ?
                                            <span className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                                                <b style={{marginRight:10}}>Número do SUS: </b> {item.number_sus}
                                            </span>
                                            :
                                            <></>
                                    }

                                    {
                                        item.recommendation ?
                                            <span className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                                                <b style={{marginRight:10}}>Indicação: </b> {item.recommendation}
                                            </span>
                                            :
                                            <></>
                                    }
                                    {
                                        item.transfer_apae ?
                                            <span className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                                                <b style={{marginRight:10}}>Transferido: </b> Sim
                                            </span>
                                            :
                                            <></>
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                        { edit && checkPermission(auth.permissions,permission+".update") && <div className="my-2" style={{marginRight:10}}>
                            <button onClick={() => openModal(type,item)} className="btn btn-primary btn-sm">Editar</button>
                        </div> }
                        <Confirm
                            title="Deseja excluir o paciente ? "
                            open={confirm}
                            closeModal={ () => setConfirm(false)}
                            onClick={() => destroyPatient(item.id,0,'patient')}
                        />
                        { type !== 'user' && edit && checkPermission(auth.permissions,permission+'.destroy') && <div className="my-2">    <button className='btn btn-danger btn-sm' onClick={() =>setConfirm(true)}>Excluir</button></div> }
                    </div>
                    { isMobile !== true && menu}
                </div>
        </div>
    );
}
