import React from "react";
import { useForm,Controller } from "react-hook-form";
import { Field } from "../Field";
import { Button } from "../Button";

function ReportsModal({loading,data,download}) {

    const {control, handleSubmit, formState: { errors } } = useForm({
        defaultValues:{
           patient: data.item ? data.item.id : null,
           company: data.item ? data.item.company  : null
        }
    });

    const onSubmit = (response) =>{
        var queryString = Object.keys(response).map(key =>  key + '=' + response[key]).join('&');
        download(data.report,queryString);
    }
    let  params =  []

    if(data.params) params = JSON.parse(data.params)

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                {params.map((p,index) => {

                    return (
                        <div className="col-lg-3" key={index}>
                            <label className="fw-bold text-muted" style={{marginTop:10}}>{p.title}:</label>
                            <Controller
                                name={p.name}
                                control={control}
                                rules={{ required: p.required }}
                                render={({ field : { onChange, onBlur, value }}) => <Field type={p.type} value={value} options={p.options}  onChange={(value) => {onChange(value);} } />}
                            />
                            {errors[p.name] && <p style={{color:'red',marginTop:10}}>Necessário preencher o {p.title}.</p>}
                        </div>
                    )
                })}
            </div>
            <div className="row" style={{textAlign:'right',marginTop:20}}>
                <div className="col-md-9"></div>
                <div className="col-md-3">
                    <Button loading={loading} text="Salvar" type="submit" />
                </div>
            </div>
        </form>
    );
}

export default ReportsModal
