import {updateObject} from '../../utils';

const initialState = {
    loading:false,
    itens:[],
    specializations:[],
    schedules:[],
    typeschedules:[],
    rooms:[],
    status:[],
    loadingSchedule:false,
    tools:[],
    loadingTools:true,
    toolsDocumentLoading:false,
    diagnostics: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'COMPANY_START':
            return updateObject(state, {loading: true});
        case 'COMPANY_SUCCESS':
            return updateObject(state, {loading: false,itens:action.itens});
        case 'COMPANY_FAIL':
            return updateObject(state, {loading: false,itens:[]});
        case 'SET_SPECIALIZATIONS':
            return updateObject(state, {specializations:action.specializations});
        case 'SCHEDULES_LOADING':            
            return updateObject(state, {loadingSchedule: action.status});
        case 'SET_SCHEDULES':
            return updateObject(state, {schedules:action.schedules});      
        case 'SET_TYPESCHEDULES':
            return updateObject(state, {typeschedules:action.typeschedules});
        case 'SET_ROOMS':
            return updateObject(state, {rooms:action.rooms});
        case 'SET_TOOLS':
            return updateObject(state, {tools:action.tools,loadingTools:false});
        case 'SET_STATUS':
            return updateObject(state, {status:action.status});
        case 'DELETE_VALUES':
            return updateObject(state, {schedules: [],specializations:[],professionals:[],patients:[],typeschedules:[],rooms:[]});
        case 'TOOLS_DOCUMENT_LOADING':
            return updateObject(state, {toolsDocumentLoading:action.status});
        case 'TOOLS_LOADING':
            return updateObject(state, {loadingTools:action.status});
            case 'SET_DIAGNOSTIC':
            return updateObject(state, {diagnostics:action.data});
        default:
            return state;
    }
}

export default reducer;
  