import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import Routes from './routes';
import {Toaster} from 'react-hot-toast';
import isMobile from "is-mobile";
import AnyController from "./containers/AnyController";
import PrimaryController from "./containers/PrimaryController";
import SecondaryController from "./containers/SecondaryController";
import TertiaryController from "./containers/TertiaryController";
import ViewPDFController from "./containers/ViewPDFController";

const App = ({ history}) => {
    const calendarRef = React.createRef(null);

    return (
        <ConnectedRouter history={history}>
            <div style={{zIndex:299999}}><Toaster position="top-right"/>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            </div>
            <AnyController
                calendarRef={calendarRef}
                isMobile={isMobile({featureDetect:true,tablet:true})}
            />
            <ViewPDFController
                calendarRef={calendarRef}
                isMobile={isMobile({featureDetect:true,tablet:true})}
            />
            <PrimaryController
                calendarRef={calendarRef}
                isMobile={isMobile({featureDetect:true,tablet:true})}
            />
            <SecondaryController
                calendarRef={calendarRef}
                isMobile={isMobile({featureDetect:true,tablet:true})}
            />
            <TertiaryController
                calendarRef={calendarRef}
                isMobile={isMobile({featureDetect:true,tablet:true})}
            />

            <Routes
                calendarRef={calendarRef}
                isMobile={isMobile({featureDetect:true,tablet:true})}
            />
        </ConnectedRouter>
    )
}

export default App;