import  React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Expedients from '../../components/professionals/Expedients';
import { destroyExpedients, getExpedients } from '../../store/actions/expedientsActions';
import { openModal } from '../../store/actions/uiActions';

function ExpedientsController({openModal,getExpedients,id,expedients,loading,destroyExpedients}) {

    useEffect(() =>{
        getExpedients(id)
    },[getExpedients,id])

    return (
        <Expedients itens={expedients} id={id} openModal={openModal} loading={loading} destroyExpedients={destroyExpedients} />
    );
}

const mapStateToprops = state => ({
   expedients:state.expedients.itens,
   loading:state.expedients.loading
});

const mapDispatchToprops = dispatch => ({
    openModal:(modal,payload) => dispatch(openModal(modal,payload)),
    getExpedients:(id) => dispatch(getExpedients(id)),
    destroyExpedients:(id,user) => dispatch(destroyExpedients(id,user))
});

export default connect(mapStateToprops,mapDispatchToprops)(ExpedientsController);