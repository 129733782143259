import {updateObject} from '../../utils';

const initialState = {
    token: "",
    admin:0,
    id: null,
    name: null,
    email: null,
    cellphone: null,
    company:{},
    error: null,
    loading: false,
    authRedirectPath: '/',
    notificationToken: null,
    code:0,
    isSmsSend:false,
    logomarca:null,
    img:'',
    permissions:[],
    subadmin:false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'AUTH_START':
            return updateObject(state, {error: null, loading: true});
        case 'AUTH_SEND_SMS':
            return updateObject(state, {error: null, loading: false,isSmsSend:true,cellphone:action.cellPhone});
        case 'AUTH_SUCCESS':
            return updateObject(state, {
                ...action.data,
                error: null,
                loading: false,
                isSmsSend:false,
            });
            case 'ALTER_AUTH':
            return updateObject(state, {
                ...state,
                ...action.data,
                "company": state.company
            });
        case 'AUTH_FAIL':
            return updateObject(state, {error: null, loading: false});
        case 'AUTH_VERIFY_CANCEL':
            return updateObject(state, {error: null, loading: false,isSmsSend:false});
        case 'AUTH_SET_COMPANY':
            return updateObject(state, {company:action.data});
        case 'AUTH_SET_ADMIN':
            return updateObject(state, {admin:action.data});
        case 'AUTH_LOGOUT':
            return updateObject(state, {token:null});
        case 'AUTH_PERMISSION':
            return updateObject(state, {permissions: action.data});
        default:
            return state;
    }
}

export default reducer;
