import { put, takeEvery, select } from 'redux-saga/effects';
import api from '../../api';
import {
    loadingTools,
    companyFail,
    companyStart,
    companySuccess,
    setLoadingSchedule,
    setRooms,
    setSchedules,
    setSpecializations,
    setStatus,
    setTools,
    setTypeSchedules,
    loadingGenerate,
    setDiagnostic
} from '../actions/companiesActions';
import { documentInsert} from '../actions/documentsActions';
import { closeModal } from '../actions/uiActions';
import { toast } from 'react-toastify';

export const getAuthToken = (state) => state.auth.token;
export const getAuthCompany = (state) => state.auth.company.id;

function* getCompanies(){

    try{
        yield put(companyStart())
       
        const response = yield  api.get('/user/companies',{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield put(companySuccess(response.data));
        }else{
            yield put(companyFail());
        }   
    } catch (e) {
        console.error(e);      
        yield put(companyFail()); 
    }
}

function* getSpecializations(action){
  
    try{
        const response = yield  api.get('/companies/'+ (yield select(getAuthCompany))+'/specializations',{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield put(setSpecializations(response.data));
        }
    } catch (e) {
        console.error(e);      
    }
}

 
function* getSchedules(action){
    try{
        yield put(setLoadingSchedule(true))
        const response = yield  api.get('/user/'+action.professional+'/specialization/'+action.specialization+'/patient/'+action.patient+'/schedule',{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield put(setSchedules(response.data));
        }

        yield put(setLoadingSchedule(false))
    } catch (e) {
        console.error(e);    
        yield put(setLoadingSchedule(false))
    }
}

function* getTypeSchedules(){
    try{
        const response = yield  api.get('/companies/'+ (yield select(getAuthCompany))+'/typeschedules',{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield put(setTypeSchedules(response.data));
        }
    } catch (e) {
        console.error(e);      
    }
}

function* getRooms(){
    try{
        const response = yield  api.get('/companies/'+ (yield select(getAuthCompany))+'/rooms',{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield put(setRooms(response.data));
        }
    } catch (e) {
        console.error(e);      
    }
}

function* getTools(action){

    try{
        yield put(loadingTools(true));

        const response = yield  api.get('/companies/'+ (yield select(getAuthCompany))+'/tools?patient='+action.patient,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });


        if(response.status === 200){
           yield put(setTools(response.data));
        }
    } catch (e) {
        yield put(loadingTools(false));
        console.error(e);      
    }
}

function* getStatus(){
    try{
        const response = yield  api.get('/status/list',{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield put(setStatus(response.data));
        }
    } catch (e) {
        console.error(e);      
    }
}


function* getDiagnostic(){
    try{
        const response = yield  api.get('/diagnostics',{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield put(setDiagnostic(response.data));
        }
    } catch (e) {
        console.error(e);
    }
}


function* generateDocument(action){

    try{
        yield put(loadingGenerate(true));
    
        const response = yield  api.post('/generate',action.data,{
            baseURL: "https://wevalue.com.br/reports",
            //baseURL: "http://localhost:3002",
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield put(documentInsert(response.data.result));
            toast.success(response.data.message);    
            yield put(closeModal());    
        }

        yield put(loadingGenerate(false));
    } catch (e) {
        yield put(loadingGenerate(false));
        console.error(e);      
    }
}



export function* companiesSaga() {
    yield takeEvery('GET_COMPANIES', getCompanies);
    yield takeEvery('GET_SPECIALIZATIONS', getSpecializations);
    yield takeEvery('GET_SCHEDULES', getSchedules);
    yield takeEvery('GET_TYPESCHEDULES', getTypeSchedules);
    yield takeEvery('GET_ROOMS', getRooms);
    yield takeEvery('GET_STATUS', getStatus);
    yield takeEvery('GET_TOOLS', getTools);
    yield takeEvery('TOOLS_DOCUMENT_GENERATE', generateDocument);
    yield takeEvery('GET_TYPE_DIAGNOSTIC', getDiagnostic);
}