import { toast } from 'react-toastify';

export function messenge(data,type){
    
    if(typeof data.errors == 'object'){
       
        Object.values(data.errors).forEach(element => {
            toast[type](element[0])
        });

        return;
    }

    toast[type](data.message)
}