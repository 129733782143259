import React, {useEffect, useMemo, useState} from 'react';
import { useSelector } from 'react-redux';
import api from "../../../api";
import {formatNumber} from "../../../utils";
import Chart from "react-apexcharts";

function PayRadar({year,type}) {
    const token = useSelector(state => state.auth.token);
    const [data, setData] = useState([]);

    useEffect(() => {
        setData([]);

        const getChartData = async () => {
            const response = await api.post(`/finance/typePayment/type/2`,{year:year, type:type}, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                },
            });

            if (response.status === 200) {
                setData(response.data);
            }
        };

        getChartData();
    }, [ token, year, type]);

    const chartData = useMemo(() => {
        const value = data.map(value => value.total);

        const label = data.map((value) => value.type_payment.description);

        return {

            series: value,
            options: {
                chart: {
                    width: 380,
                    type: 'pie',
                },
                yaxis: {
                    labels: {
                        formatter: function (value) {
                            // Definir a formatação do número aqui
                            return "R$ "+formatNumber(value);
                        },
                    },
                },
                labels: label,

            }
        };

    }, [data]);

    return (
        <Chart
            options={chartData.options}
            series={chartData.series}
            type="pie"
            key="2"
        />
    );
}

export default PayRadar;