import ContentLoader from "react-content-loader";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import checklist from "../../../assets/img/checklist.png";
import api from "../../../api";

function PlanTherapeutic({openModalTertiary,professional,auth,openAny}){
    const token = useSelector(state => state.auth.token);
    const [company, setCompany] = useState({});

    useEffect(() => {
        api.get('/company/' + auth.company.id, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }).then(data => setCompany(data.data))
    }, [token]);

    const countPlanTherapeutic = useSelector(state => state.calendar.dashboard.countPlanTherapeutic);

    const totalTherapeutic = parseInt(countPlanTherapeutic.items?.outdated);

    return (
        <div className="card">
            <div className="card-body px-0 pb-0" style={{paddingTop: 10}}>
                <div
                    className="mb-12 px-4"
                    style={{height: 140, display: "flex", flexDirection: "column"}}
                >
                    {
                        countPlanTherapeutic?.loading === true ?
                            <div className="d-flex align-items-center mb-3">
                                <ContentLoader
                                    speed={2}
                                    width={200}
                                    height={30}
                                    viewBox="0 0 70% 150"
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#ecebeb"
                                >
                                    <rect x="0" y="0" rx="3" ry="3" width="100" height="20"/>
                                </ContentLoader>
                            </div>
                            :
                            <div className="d-flex align-items-center mb-3">
                                <span
                                    className="fs-1hx fw-bold text-gray-800 me-2 lh-1"
                                    style={{fontSize: 20, flex: 1}}
                                >
                                    {totalTherapeutic}
                                </span>
                                <div
                                    onClick={() =>
                                        openAny(
                                            <div style={{marginTop: 10}}>
                                                {/*<h4 style={{color: '#1097e0'}}>Pendente:</h4>
                                                                            <p style={{textAlign: 'justify'}}>Cada terapeuta que faz parte da equipe de tratamento de um paciente com status <b
                                                                                    style={{color: '#1045e3'}}>Em
                                                                                    tratamento</b> deve preencher o plano terapêutico. Caso o mesmo terapeuta atenda o paciente em diferentes tratamentos, será necessário preencher um plano terapêutico para cada um deles.</p>

                                                                            */}
                                                <h4 style={{color: '#dddd5b'}}>Desatualizado:</h4>
                                                <p style={{textAlign: 'justify'}}>Para garantir que os planos terapêuticos estejam sempre atualizados, todo plano terapêutico de um tratamento com status <b style={{color: '#1045e3'}}>Em
                                                    tratamento</b> que não tenha sido atualizado nos últimos {company?.therapeutic_plan_months} meses será marcado como desatualizado.</p>
                                            </div>,
                                            "Informações"
                                        )
                                    }
                                    className="badge badge-light-primary"
                                    style={{cursor: 'pointer',width:90}}
                                >
                                    Informações
                                </div>
                            </div>
                    }
                    <span className="fs-8 fw-semibold text-gray-600">Plano Terapêutico</span>
                    <div style={{
                        display: "flex",
                        gap: 5,
                        flexDirection: "column",
                        flex: 1,
                        marginTop: 10
                    }}>
                        {
                            countPlanTherapeutic?.loading === true ?
                                <div className="d-flex fs-6 fw-semibold align-items-center">
                                    <ContentLoader
                                        speed={2}
                                        width={200}
                                        height={100}
                                        viewBox="0 0 70% 150"
                                        backgroundColor="#f3f3f3"
                                        foregroundColor="#ecebeb"
                                    >
                                        <rect x="0" y="0" rx="3" ry="3" width="130" height="10"/>
                                        <rect x="0" y="25" rx="3" ry="3" width="120" height="10"/>
                                        <rect x="0" y="50" rx="3" ry="3" width="110" height="10"/>

                                    </ContentLoader>
                                </div>
                                :
                                countPlanTherapeutic?.items?.pendencies > 0 || countPlanTherapeutic?.items?.outdated > 0 ?
                                    <>
                                        {/*countPlanTherapeutic?.items?.pendencies > 0 &&
                                                            <div className="d-flex fs-6 fw-semibold align-items-center">
                                                                <div
                                                                    className="bullet w-8px h-6px rounded-2 me-3"
                                                                    style={{background: '#1097e0'}}
                                                                >
                                                                </div>
                                                                <div
                                                                    className="fs-8 fw-semibold text-gray-500 flex-shrink-0">Pendentes
                                                                </div>
                                                                <div
                                                                    className="separator separator-dashed min-w-10px flex-grow-1 mx-2"></div>
                                                                <div
                                                                    className="ms-auto fw-bolder text-gray-700 text-end">{countPlanTherapeutic.items?.pendencies}
                                                                </div>
                                                            </div>
                                                        */}
                                        {
                                            countPlanTherapeutic.items?.outdated > 0 &&
                                            <div className="d-flex fs-6 fw-semibold align-items-center">
                                                <div className="bullet w-8px h-6px rounded-2 me-3"
                                                     style={{background: '#dddd5b'}}></div>
                                                <div
                                                    className="fs-8 fw-semibold text-gray-500 flex-shrink-0">Desatualizados
                                                </div>
                                                <div
                                                    className="separator separator-dashed min-w-10px flex-grow-1 mx-2"></div>
                                                <button
                                                    onClick={() => openModalTertiary('pendenciesTherapeuticModal', {
                                                        professional: professional
                                                    })}
                                                    className="btn btn-sm btn-light"
                                                    style={{height: 30, width: 78, fontSize: 10}}
                                                >
                                                    {countPlanTherapeutic.items?.outdated}
                                                </button>
                                            </div>
                                        }
                                        <div style={{
                                            textAlign: 'center',
                                            marginBottom: 10,
                                            marginTop:50,
                                            justifyContent: 'space-between',
                                        }}>

                                        </div>
                                    </>
                                    :
                                    <div style={{textAlign: "center"}}>
                                        <img
                                            src={checklist}
                                            width={140}/>
                                    </div>
                        }

                    </div>
                </div>
            </div>
        </div>
    );
}

export default PlanTherapeutic;