import React, {useEffect} from "react";
import {useForm, Controller} from "react-hook-form";
import {Field} from "../Field";
import {Button} from "../Button";
import {useSelector} from "react-redux";

function BankAccountsModal({data,storeBankAccount,updateBankAccount}) {

    const {control, handleSubmit, formState: {errors}} = useForm({
        defaultValues: {
            ...data
        }
    });

    const banks = useSelector(state => state.companyBankAccount.banks)

    const options = banks?.map(value => ({value:value.id,label:value.bank}));

    const onSubmit = (request) => {
        if(data.id){
            updateBankAccount(request,data.id);
        }else{
            request['status'] = 1;
            storeBankAccount(request);
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-lg-3">
                    <label>Banco:</label>
                    <Controller
                        name="bank_id"
                        control={control}
                        rules={{required: true}}
                        render={({field: {onChange, value}}) =>
                            <Field
                                type="select"
                                options={options}
                                value={value}
                                onChange={onChange}
                            />
                        }
                    />
                    {errors.bank_id &&
                        <p style={{color: 'red', marginTop: 10}}>Necessário selecionar o banco.</p>}
                </div>
                <div className="col-lg-3">
                    <label>Agencia:</label>
                    <Controller
                        name="agency"
                        control={control}
                        render={({field: {onChange, value}}) => <Field type="text" value={value} onChange={onChange}/>}
                    />
                </div>
                <div className="col-lg-3">
                    <label>Conta:</label>
                    <Controller
                        name="account"
                        control={control}
                        render={({field: {onChange, value}}) => <Field type="text" value={value} onChange={onChange}/>}
                    />
                </div>
            </div>
            <div className="row" style={{textAlign: 'right', marginTop: 20}}>
                <div className="col-md-9"></div>
                <div className="col-md-3">
                    <Button  text="Salvar" type="submit"/>
                </div>
            </div>
        </form>
    );
}

export default BankAccountsModal
