import {updateObject} from '../../utils';

const initialState = {
    itens: [],
    loading:true,
    loadingAction:false,
    itensFormat:[]
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'EXPEDIENTS_SUCCESS':
            return updateObject(state, {itens: action.itens});
        case 'EXPEDIENTS_FORMAT_SUCCESS':
            return updateObject(state, {itensFormat: action.data});
        case 'EXPEDIENTS_LOADING_ACTION':
            return updateObject(state, {loadingAction: action.status});
        case 'EXPEDIENTS_LOADING':
            return updateObject(state, {loading: action.status,itens:state.itens});
        default:
            return state;
    }
}

export default reducer;
  