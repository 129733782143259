import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import logo1 from '../../assets/img/usemed.png'
import { useForm,Controller } from "react-hook-form";
import { Field } from '../../components/Field';
import { Button } from '../../components/Button';
import { getToken, start } from '../../store/actions/accountActions';
import { useParams } from 'react-router-dom';
import Lottie from 'react-lottie';
import { getProfession } from '../../store/actions/professonActions';
import { useMediaQuery } from "react-responsive";

function Create({loading,tokens,getProfession,profession,start,startLoading}) {

    const {setValue, control, handleSubmit, formState: { errors }} = useForm({
        defaultValues:{

        }
    });
    const isBigScreen = useMediaQuery({query: '(min-width: 1200px)'});
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: require('../../assets/lottie/loading_blue.json'),
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const { token } = useParams();

    useEffect(()=>{
        getProfession();
    },[token]);


    if( tokens && tokens.length > 0){
        setValue('email',tokens[0].email);
        setValue('idToken',tokens[0].id);
    }
    const onSubmit = (request)=>{

        start(request);
    }

    return (
        <div className="d-flex flex-column flex-column-fluid" >
            {
                startLoading ?
                    <div className="d-flex flex-row-fluid flex-column flex-column-fluid text-center p-30 py-lg-20"  >
                        <a className="pt-lg-20 mb-12">
                            <img alt="logo" src={logo1} className="h-70px" />
                        </a>
                        <div className="text-center"><h1 className="fw-bold fs-2qx text-gray-800 mb-7" style={{paddingTop:200}}>Estamos preparando o ambiente.</h1></div>
                        <div className="fs-3 fw-semibold text-muted mb-10">
                            Isso pode levar alguns minutos.
                        </div>
                        <Lottie
                            options={defaultOptions}
                            height={200}
                            width={200}
                        />
                    </div>
                :

                    <div className="d-flex flex-row-fluid flex-column flex-column-fluid text-center " >
                        {isBigScreen && <svg className="triangle-side-back position-absolute" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 753.92 973.3" style={{height: '80%',top:50}}>
                            <path d="M.24,837.5C28,840,54.55,841.82,79.39,829.16c15.55-7.91,27.77-21,39.3-34.06C175.21,731,225.14,658.24,297.58,612.84c72.68-45.55,160.51-58.77,245.75-68.31,27.35-3.06,54.86-5.87,81.47-12.87a265,265,0,0,0,75.23-33c15.33-9.81,29.94-21.54,39.52-37,17.94-29,15.52-65.71,11.55-99.56-3-25.22-6.7-51.17-19.78-72.94-16.56-27.56-45.83-44.74-74.89-58.52-58.1-27.57-122.54-47.82-166.46-94.8C468.58,113,452.83,84.4,427.39,66.14a104.59,104.59,0,0,0-66.46-19.28C334.59,48.39,310,59.8,284.93,68,190.85,98.67,82.64,82.18,2,24.85Z" style={{fill: '#4794fe',opacity: 0.1,isolation: 'isolate'}}></path>
                            <path d="M0,971.5c27.76,2.51,54.31,4.32,79.15-8.34,15.54-7.91,27.77-21,39.3-34.06C175,865,224.9,792.24,297.34,746.84c72.68-45.55,160.51-58.77,245.75-68.31,27.35-3.06,54.86-5.87,81.47-12.87a265,265,0,0,0,75.23-33c15.33-9.81,29.94-21.54,39.52-37,17.94-29,15.52-65.71,11.55-99.56-3-25.22-6.7-51.17-19.78-72.94-16.56-27.56-45.83-44.74-74.89-58.52-58.1-27.57-122.54-47.82-166.46-94.8-21.39-22.88-37.14-51.44-62.58-69.7a104.6,104.6,0,0,0-66.41-19.29c-26.39,1.53-51,12.94-76,21.15-94.13,30.67-202.34,14.18-283-43.15Z" style={{fill: '#4794fe',opacity: 0.1}}></path>
                            <circle cx="385.74" cy="6" r="6" style={{fill: '#0088fe'}}></circle>
                            <circle cx="195.74" cy="645" r="16" style={{fill: '#fee28a'}}></circle>
                        </svg>}
                        <div className='container'>
                            <div className='card' style={{marginTop:100}}>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className='col-md-5'>
                                            <a className="pt-lg-20 mb-12">
                                                <img alt="logo" src={logo1} className="h-70px" />
                                            </a>

                                            <div className="fs-3 fw-semibold text-muted mb-10">Estamos muito felizes com sua presença,
                                                <br />para continuarmos precisamos de algumas informações.
                                            </div>
                                            <form onSubmit={handleSubmit(onSubmit)}>
                                                <div className='row' style={{textAlign:'left'}}>
                                                    <div className="col-lg-6">
                                                        <label className="fw-bold text-muted" style={{marginTop:10}}>Nome:</label>
                                                        <Controller
                                                            name="name"
                                                            control={control}
                                                            rules={{ required: true }}
                                                            render={({ field : { onChange, value }}) => <Field type="text"  value={value} onChange={onChange} />}
                                                        />
                                                        {errors.name && <p style={{color:'red',marginTop:10}}>Necessário preencher o nome.</p>}
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <label className="fw-bold text-muted" style={{marginTop:10}}>Celular:</label>
                                                        <Controller
                                                            name="cellphone"
                                                            control={control}
                                                            rules={{ required: true }}
                                                            render={({ field : { onChange, value }}) => <Field type="cellphone"  value={value} onChange={onChange} />}
                                                        />
                                                        {errors.cellphone && <p style={{color:'red',marginTop:10}}>Necessário selecionar o profissional.</p>}
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <label className="fw-bold text-muted" style={{marginTop:10}}>Área:</label>
                                                        <Controller
                                                            name="profession"
                                                            control={control}
                                                            render={({ field : { onChange, value }}) => <Field type="select" options={profession}  value={value} onChange={onChange} />}
                                                        />
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <label className="fw-bold text-muted" style={{marginTop:10}}>Email:</label>
                                                        <Controller
                                                            name="email"
                                                            control={control}
                                                            rules={{ required: true }}
                                                            render={({ field : { onChange, value }}) => <Field type="email"  value={value} onChange={onChange} />}
                                                        />
                                                        {errors.password && <p style={{color:'red',marginTop:10}}>Necessário preencher o emial.</p>}
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <label className="fw-bold text-muted" style={{marginTop:10}}>Senha:</label>
                                                        <Controller
                                                            name="password"
                                                            control={control}
                                                            rules={{ required: true }}
                                                            render={({ field : { onChange, value }}) => <Field type="password"  value={value} onChange={onChange} />}
                                                        />
                                                        {errors.password && <p style={{color:'red',marginTop:10}}>Necessário preencher a senha.</p>}
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <label className="fw-bold text-muted" style={{marginTop:10}}>Confirmar senha:</label>
                                                        <Controller
                                                            name="password_confirmation"
                                                            control={control}
                                                            rules={{ required: true }}
                                                            render={({ field : { onChange, value }}) => <Field type="password"  value={value} onChange={onChange} />}
                                                        />
                                                        {errors.password_confirmation && <p style={{color:'red',marginTop:10}}>Necessário preencher a confirmação de senha.</p>}
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <p className="text-muted text-center p-10 ">Se sua área não foi encontrada, não tem problema, você pode entrar em <a className='link-primary fw-bold'  target="_black" href="https://api.whatsapp.com/send?phone=5521990452839&amp;text=Olá, não encontrei minha área." >contato conosco</a> <br/> e teremos o prazer de adicioná-la.</p>
                                                    </div>
                                                </div>
                                                <div className='row justify-content-md-center' style={{marginTop:0}}>
                                                    <div className="col-lg-5">
                                                        <Button loading={loading} text="Enviar" type="submit" className="btn btn-lg btn-block" />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        {isBigScreen &&<div className='col-md-7'>
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" className="triangle-side-back position-absolute inverse" viewBox="0 0 764.94 1006.05" style={{height:'100%',zIndex:10,top:0,right:0}}>
                                                <defs>
                                                    <linearGradient id="triangle-gradient" x1="1404.62" y1="542.5" x2="1920" y2="542.5" gradientUnits="userSpaceOnUse">
                                                        <stop offset="0" stopColor="#fee28a" stopOpacity="0.18"></stop>
                                                        <stop offset="0.5" stopColor="#fff5d8" stopOpacity="0.2"></stop>
                                                        <stop offset="1" stopColor="#fee28a" stopOpacity="0.05"></stop>
                                                    </linearGradient>
                                                </defs>
                                                <path d="M1919.5,148.59c-88.72-48-227.44-102-394-84.08-105.41,11.33-220.77,23.73-298,134.29-61.94,88.67-100.45,242.05-49,348,76.46,157.44,293,89.42,370,269.74,18,42.07,24.89,89.6,69,146,15.64,20,56.29,70.59,125,93.42,83.58,27.77,154.16-3.66,177-15.18Z" transform="translate(-1154.56 -61)" style={{fill:'#4794fe',opacity:0.1}}></path>
                                            </svg>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

            }
        </div>
    );
}

const mapStateToprops = state => {
    return {
        tokens:state.account.tokens,
        loading:state.account.templatesLoading,
        profession: state.profession.itens,
        startLoading:state.account.actionLoading
    };
}

const mapDispatchToprops = dispatch => ({
    getToken:(token) => dispatch(getToken(token)),
    getProfession: () => dispatch(getProfession()),
    start:(data) => dispatch(start(data))
})

export default connect(mapStateToprops,mapDispatchToprops)(Create)
