import {updateObject} from '../../utils';

const initialState = {
    loading:true,
    itens:[],
    loadingRequest:false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'PROFESSION_START':
            return updateObject(state, {loading: true});
        case 'PROFESSION_SUCCESS':
            return updateObject(state, {loading: false,itens:action.data});
        case 'PROFESSION_FAIL':
            return updateObject(state, {loading: false,itens:[]});
        case 'PROFESSION_LOADING_REQUEST':
            return updateObject(state, {loadingRequest: action.status});
        default:
            return state;        
    }
}

export default reducer;
