export const getDocuments = (typeModel,id) => ({type: 'GET_DOCUMENTS',typeModel,id});
export const getTypesDocuments = (typeModel) => ({type: 'GET_TYPES_DOCUMENTS',typeModel});
export const documentsStart = () => ({type: 'DOCUMENTS_START'});
export const documentsFail = () => ({type: 'DOCUMENTS_FAIL'});
export const documentsSuccess = (data) => ({type: 'DOCUMENTS_SUCCESS',data});
export const documentsFinanceSuccess = (data) => ({type: 'DOCUMENTS_FINANCE_SUCCESS',data});
export const typeDocumentsSuccess = (data) => ({type: 'TYPE_DOCUMENTS_SUCCESS',data});
export const download = (report,query) => ({type: 'DOWNLOAD_DOCUMENTS',report,query});
export const upload = (data) => ({type: 'UPLOAD_DOCUMENTS',data});
export const documentLoadingRequest = (status) => ({type: 'DOCUMENTS_LOADING_REQUEST',status});
export const documentClear = () => ({type: 'DOCUMENTS_CLEAR'});
export const documentInsert = (data) => ({type: 'DOCUMENTS_INSERT',data});
export const documentInsertFinance = (data) => ({type: 'DOCUMENTS_INSERT_FINANCE',data});

export const documentRemove = (id) => ({type:'DOCUMENTS_REMOVE',id});
export const documentRemoveFinance = (id) => ({type:'DOCUMENTS_REMOVE_FINANCE',id});
export const documentUpdate = (id,data) => ({type:'DOCUMENTS_UPDATE',id,data})

export const documentUpdateFinance = (id,data) => ({type:'DOCUMENTS_UPDATE_FINANCE',id,data})
export const destroyDocuments = (id) => ({type:'DESTROY_DOCUMENTS',id});
export const documentLoadinDestroy  = (id,status) => ({type:'DOCUMENTS_LOADING_DESTROY',id,status});
export const  openModalDocument = (status) => ({type:'OPEN_MODAL_DOCUMENT',status});
export const  updateDocuments = (data) => ({type:'UPDATE_DOCUMENTS',data});
