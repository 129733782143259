import React, { useEffect } from "react";
import { useForm,Controller } from "react-hook-form";
import { Field } from "../Field";
import { Button } from "../Button";


function ToolsModal({loading,documentGenerate,data}) {    

    const {control, handleSubmit } = useForm({
        defaultValues:{
            value:data ? data.value : '',
            company:data ? data.company : null,
            footer:data ? data.footer : '',
            footer_height: data ? data.footer_height : '',
            header: data ? data.header : '',
            header_height: data ? data.header_height : '',
            file_name: data ? data.file_name : '',
            patient: data ? data.patient : null
        }
    });

    useEffect(()=>{
   
    },[])   

    const onSubmit = (response) =>{   
        documentGenerate(response)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className={"col-lg-12"} >            
                    <Controller
                        name="value"
                        control={control}
                        render={({ field : { onChange, onBlur, value }}) => <Field type="textAreaEditor" value={value} mentions={data.mentions ? data.mentions :  []}  onChange={(v) => {onChange(v);} } />}
                    />                    
                </div>         
            </div>
            <div className="row" style={{textAlign:'right',marginTop:20}}>
                <div className="col-md-9"></div>
                <div className="col-md-3">
                    <Button loading={loading} text="Gerar documento" type="submit" />  
                </div>   
            </div>
        </form>
    );
}

export default ToolsModal