import React, {useEffect, useState} from "react";
import { useForm,Controller } from "react-hook-form";
import { Field } from "../Field";
import { Button } from "../Button";


function ParentalModal({loading,data,store,update}) {
    const [preview,setPreview] = useState({ type :''});

    const {control, handleSubmit, formState: { errors },setValue } = useForm({
        defaultValues:{
            patient:data ? data.patient : null,
            description: data ? data.description : null,
            type:data.type
        }
    });

    const changeHandler = (event) => {
        setValue('file',event.target.files[0]);
        event.target.files[0]['url'] = URL.createObjectURL(event.target.files[0]);
        setPreview(event.target.files[0]);
    };

    useEffect(()=>{
   
    },[]);

    const onSubmit = (request) =>{
        if(data && data.id){
            update(request,data.id);
        }else{
            store(request);
        }        
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {
                data.type === 1 ?
                    <div className="row">
                        <div className="col-lg-12" >
                            <Controller
                                name="description"
                                control={control}
                                rules={{ required: true }}
                                render={({ field : { onChange, value }}) => <Field type="textAreaEditor" value={value} onChange={(value) => {onChange(value);} } />}
                            />
                            {errors.description && <p style={{color:'red',marginTop:10}}>Necessário preencher a orientação.</p>}
                        </div>
                    </div>
                    :

                    <div className="row">
                        <div className="col-md-12" style={{textAlign:'center'}}>
                            {
                                preview.type ?
                                    (preview.type === 'video/mp4' || preview.type === 'video/ogg') ?
                                    <video width="100%" height="300" controls>
                                        <source src={preview.url} type="video/mp4" />
                                        <source src={preview.url} type="video/ogg" />
                                        <source src={preview.url} type="video/quicktime" />
                                        Your browser does not support the video tag.
                                    </video>
                                    :
                                     <img src={preview.url}  height="300" alt={"preview"} style={{maxWidth:'100%'}}/>

                                    :
                                    <></>
                            }
                        </div>
                        <div className="col-md-12">
                            <label style={{marginTop:10}}>Arquivo:</label>
                            <Controller
                                name="file"
                                control={control}
                                rules={{ required: true }}
                                render={() => <input   type="file" name="file" onChange={changeHandler} accept="video/*,image/*"  className='form-control' style={{height:38}}/>}
                            />
                            {errors.type_document && <p style={{color:'red',marginTop:10}}>Necessário escolher o arquivo.</p>}
                        </div>
                    </div>
            }
            <div className="row" style={{textAlign:'right',marginTop:20}}>
                <div className="col-md-9"></div>
                <div className="col-md-3">
                    <Button loading={loading} text="Salvar" type="submit" />   
                </div>   
            </div>
        </form>
    );
}

export default ParentalModal