'use client';
import React, {useEffect, useMemo, useState} from 'react';
import { Document, Page,pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import isMobile from "is-mobile";
import {useSelector} from "react-redux";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function ViewPDF({modalPayload,closeModal}) {
    const [numPages, setNumPages] = useState(null);
    const  isMobileV =  isMobile({featureDetect:true,tablet:true});
    const [scale, setScale] = useState(isMobileV ? 0.66 : 1.0); // Estado para a escala

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const {modalOpen,modalOpenSecondary,modalOpenTertiary} = useSelector(state => state.ui)

    useEffect(() =>{
        document.body.style.overflow = "hidden";

        return () =>{
            if(!modalOpen || !modalOpenSecondary || !modalOpenTertiary){
                document.body.style.overflow = 'auto';
            }
        }
    },[modalOpen,modalOpenSecondary,modalOpenTertiary]);

    const zIndexr = 199999+400000
    const file = useMemo(() => (modalPayload.url), [modalPayload]);

    let style = {
        display: 'block',
        zIndex: zIndexr+1,
        backgroundColor: '#fff'
    }

    if(isMobileV){
        style = {
            display: 'block',
            zIndex: zIndexr+1,
            width:"100%",
            backgroundColor:'#fff'
        }
    }

    return (
        <>
            <div
                className="g-body drawer drawer-end drawer-on bg-white"
                style={style}

            >
                <div className={"card shadow-none border-0 rounded-0"}>
                    <div
                        className={'card-header'}
                        style={{ paddingTop: 5, paddingBottom: 5,minHeight:60}}
                    >
                        <h4
                            className={'card-title'}
                            style={{marginLeft: '-10px'}}
                        >
                            <button
                                style={{marginRight: 20}}
                                onClick={() => {
                                    closeModal()
                                }}
                                className="btn btn-sm btn-icon btn-active-color-primary"
                            >
                                {
                                    isMobileV ?
                                        <span className="svg-icon svg-icon-muted  svg-icon-2hx">
                                            <svg
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M11.2657 11.4343L15.45 7.25C15.8642 6.83579 15.8642 6.16421 15.45 5.75C15.0358 5.33579 14.3642 5.33579 13.95 5.75L8.40712 11.2929C8.01659 11.6834 8.01659 12.3166 8.40712 12.7071L13.95 18.25C14.3642 18.6642 15.0358 18.6642 15.45 18.25C15.8642 17.8358 15.8642 17.1642 15.45 16.75L11.2657 12.5657C10.9533 12.2533 10.9533 11.7467 11.2657 11.4343Z"
                                                    fill="#000"
                                                />
                                            </svg>
                                        </span>
                                        :
                                        <span className="svg-icon svg-icon-muted svg-icon-1">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                            >
                                                <rect
                                                    opacity="0.5" x="6" y="17.3137" width="16" height="2"
                                                      rx="1" transform="rotate(-45 6 17.3137)"
                                                      fill="black"></rect>
                                                <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                                      transform="rotate(45 7.41422 6)" fill="black"></rect>
                                            </svg>
                                        </span>
                                }
                            </button>
                           Visualizar documento
                        </h4>
                        <button className="btn btn-sm btn-link" onClick={() => {  window.open(file, '_blank')}}>
                            <span className="svg-icon svg-icon-muted svg-icon-2hx">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3" d="M19 15C20.7 15 22 13.7 22 12C22 10.3 20.7 9 19 9C18.9 9 18.9 9 18.8 9C18.9 8.7 19 8.3 19 8C19 6.3 17.7 5 16 5C15.4 5 14.8 5.2 14.3 5.5C13.4 4 11.8 3 10 3C7.2 3 5 5.2 5 8C5 8.3 5 8.7 5.1 9H5C3.3 9 2 10.3 2 12C2 13.7 3.3 15 5 15H19Z" fill="currentColor"/>
                                    <path d="M13 17.4V12C13 11.4 12.6 11 12 11C11.4 11 11 11.4 11 12V17.4H13Z" fill="currentColor"/>
                                    <path opacity="0.3" d="M8 17.4H16L12.7 20.7C12.3 21.1 11.7 21.1 11.3 20.7L8 17.4Z" fill="currentColor"/>
                                </svg>
                            </span>
                        </button>
                    </div>
                    <div className="card-body"  style={{overflowY: "auto", overflowX: 'hidden',padding:0}}>
                        <Document
                            onLoadError={console.error}
                            options={{ workerSrc: `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js` }}
                            file={file}
                            onLoadSuccess={onDocumentLoadSuccess}
                        >
                            {Array.from(new Array(numPages), (el, index) => (

                                <Page
                                        key={`page_${index + 1}`}
                                        pageNumber={index + 1}
                                        scale={scale}
                                />

                            ))}
                        </Document>
                    </div>
                </div>
            </div>
            <div

                style={{
                    position: 'fixed',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: '#69696970',
                    zIndex: parseInt(zIndexr)
                }}
            >
            </div>
        </>
    );
}


export default ViewPDF;