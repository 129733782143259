import React, { useEffect } from "react";
import { useForm,Controller } from "react-hook-form";
import { Field } from "../Field";
import { Button } from "../Button";

function DesmarcarAtendimentoModal({loading,status,getStatus,data,markoffCall}) {

    const { control, handleSubmit,  formState: { errors } } = useForm({
        defaultValues:{
            calls: data
        }
    });


    let array = [];

    useEffect(()=>{
        getStatus();
    },[]) ;

    let itenss = []

    itenss = status.filter(function(element){
        return element.lack === 1
    });

    itenss.forEach((data) => {
        array.push({'label':data.description,'value':data.id})
    });

    const onSubmit = request => {

        markoffCall(request)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className={"col-lg-6"} >
                    <label className="fw-bold text-muted">Motivo:</label>
                    <Controller
                        name="status"
                        control={control}
                        rules={{ required: true }}
                        render={({ field : { onChange, onBlur, value }}) => <Field type="select" value={value} onChange={onChange} options={array}  />}
                    />
                    {errors.status && <p style={{color:'red',marginTop:10}}>Necessário selecionar o motivo.</p>}
                </div>
                <div className={"col-lg-12"} style={{marginTop:15}}>
                <label className="fw-bold text-muted">Motivo:</label>
                    <Controller
                        name="obs_desmarcar"
                        control={control}
                        rules={{ required: true }}
                        render={({ field : { onChange, onBlur, value }}) => <Field type="textArea" value={value} onChange={onChange} options={array}  />}
                    />
                    {errors.obs_desmarcar && <p style={{color:'red',marginTop:10}}>Necessário preencher a observação.</p>}
                </div>
            </div>
            <div className="row" style={{textAlign:'right',marginTop:20}}>
                <div className="col-md-9"></div>
                <div className="col-md-3">
                    <Button loading={loading} text="Salvar" type="submit" />
                </div>
            </div>
        </form>
    );
}

export default DesmarcarAtendimentoModal
