import React, {useEffect} from "react";
import {useForm, Controller} from "react-hook-form";
import {Field} from "../Field";
import {Button} from "../Button";
import {useSelector} from "react-redux";

function UserProfessionModal({loading, data, profession, getProfession, updateUserProfession, storeUserProfession}) {
    const professionsItems = useSelector(state => state.userProfession.items);

    const options = profession.filter(value => !data.id ? !professionsItems.map(op => op.profession.id).includes(value.value) : {} )

    const {control, handleSubmit, formState: {errors}} = useForm({
        defaultValues: {
            ...data
        }
    });

    useEffect(() => {
        getProfession()
    }, [])

    const onSubmit = (request) => {
        if (data && data.id) {
            updateUserProfession(request, data.id)
        } else {
            storeUserProfession(request)
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-lg-3">
                    <label>Profissão:</label>
                    <Controller
                        name="profession"
                        control={control}
                        rules={{required: true}}
                        render={({field: {onChange, value}}) => <Field type="select" options={options} value={value}
                                                                       onChange={onChange}/>}
                    />
                    {errors.profession &&
                        <p style={{color: 'red', marginTop: 10}}>Necessário selecionar a profissão.</p>}
                </div>
                <div className="col-lg-3">
                    <label>Registro:</label>
                    <Controller
                        name="document"
                        control={control}
                        render={({field: {onChange, value}}) => <Field type="text" value={value} onChange={onChange}/>}
                    />
                </div>
            </div>
            <div className="row" style={{textAlign: 'right', marginTop: 20}}>
                <div className="col-md-9"></div>
                <div className="col-md-3">
                    <Button loading={loading} text="Salvar" type="submit"/>
                </div>
            </div>
        </form>
    );
}

export default UserProfessionModal
