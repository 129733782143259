import React, { useEffect, useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import PieChartLoader from '../loading/PieChartLoader';
import api from '../../api';
import {connect, useSelector} from 'react-redux';
import {openAny, openModal} from "../../store/actions/uiActions";

const PieChartWithLoader = ({openModal, endpoint, requestData, title, labelField, valueField, colorField,information,openAny}) => {
    const token = useSelector(state => state.auth.token);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setData([])
                setLoading(true);
                const response = await api.post(endpoint, requestData, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (response.status === 200) {
                    setData(response.data);
                }
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [token, endpoint, requestData]);

    const chartData = useMemo(() => {
        const series = data.map(item => item[valueField]);
        const labels = data.map(item => item[labelField]);
        const colors = colorField ? data.map(item => item[colorField]) : undefined;

        return {
            series: series,
            options: {
                chart: {
                    type: 'pie',
                    width: 700,
                },
                labels: labels,
                colors: colors,
                legend: {
                    position: 'bottom',
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 310,
                        },
                        legend: {
                            position: 'bottom',
                            fontSize: '12px',
                            itemMargin: {
                                horizontal: 10,
                                vertical: 5,
                            },
                        },
                    },
                }],
            },
        };
    }, [data]);

    return (
        <div className="card" style={{ marginTop: 10 }}>
            <div className="card-header" style={{ padding: '5px 20px', minHeight: 30,fontSize:11 , display:"flex"}}>
                <div className="card-title" style={{fontSize:15}}>{title}</div>
                <div className="card-toolbar">
                    {
                        information ?
                            <span style={{cursor:"pointer"}} title={information} className="svg-icon svg-icon-black svg-icon-2" onClick={() => openAny(<div>{information}</div>,'Informações')}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"  xmlns="http://www.w3.org/2000/svg">
                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
                                <path d="M11.276 13.654C11.276 13.2713 11.3367 12.9447 11.458 12.674C11.5887 12.394 11.738 12.1653 11.906 11.988C12.0833 11.8107 12.3167 11.61 12.606 11.386C12.942 11.1247 13.1893 10.896 13.348 10.7C13.5067 10.4947 13.586 10.2427 13.586 9.944C13.586 9.636 13.4833 9.356 13.278 9.104C13.082 8.84267 12.69 8.712 12.102 8.712C11.486 8.712 11.066 8.866 10.842 9.174C10.6273 9.482 10.52 9.82267 10.52 10.196L10.534 10.574H8.826C8.78867 10.3967 8.77 10.2333 8.77 10.084C8.77 9.552 8.90067 9.07133 9.162 8.642C9.42333 8.20333 9.81067 7.858 10.324 7.606C10.8467 7.354 11.4813 7.228 12.228 7.228C13.1987 7.228 13.9687 7.44733 14.538 7.886C15.1073 8.31533 15.392 8.92667 15.392 9.72C15.392 10.168 15.322 10.5507 15.182 10.868C15.042 11.1853 14.874 11.442 14.678 11.638C14.482 11.834 14.2253 12.0533 13.908 12.296C13.544 12.576 13.2733 12.8233 13.096 13.038C12.928 13.2527 12.844 13.528 12.844 13.864V14.326H11.276V13.654ZM11.192 15.222H12.928V17H11.192V15.222Z"  fill="currentColor"/>
                                </svg>
                            </span>
                            :
                            <></>
                    }
                    {
                        data.length > 0 ?
                            <span onClick={() => openModal('PieChartModal',{data,title,labelField,valueField,colorField})} style={{cursor:"pointer",marginLeft:10}} class="svg-icon svg-icon-muted  svg-icon-2">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3" d="M4.7 17.3V7.7C4.7 6.59543 5.59543 5.7 6.7 5.7H9.8C10.2694 5.7 10.65 5.31944 10.65 4.85C10.65 4.38056 10.2694 4 9.8 4H5C3.89543 4 3 4.89543 3 6V19C3 20.1046 3.89543 21 5 21H18C19.1046 21 20 20.1046 20 19V14.2C20 13.7306 19.6194 13.35 19.15 13.35C18.6806 13.35 18.3 13.7306 18.3 14.2V17.3C18.3 18.4046 17.4046 19.3 16.3 19.3H6.7C5.59543 19.3 4.7 18.4046 4.7 17.3Z" fill="currentColor"/>
                                    <rect x="21.9497" y="3.46448" width="13" height="2" rx="1" transform="rotate(135 21.9497 3.46448)" fill="currentColor"/>
                                    <path d="M19.8284 4.97161L19.8284 9.93937C19.8284 10.5252 20.3033 11 20.8891 11C21.4749 11 21.9497 10.5252 21.9497 9.93937L21.9497 3.05029C21.9497 2.498 21.502 2.05028 20.9497 2.05028L14.0607 2.05027C13.4749 2.05027 13 2.52514 13 3.11094C13 3.69673 13.4749 4.17161 14.0607 4.17161L19.0284 4.17161C19.4702 4.17161 19.8284 4.52978 19.8284 4.97161Z" fill="currentColor"/>
                                </svg>
                            </span>
                            :
                            <></>
                    }
                </div>
            </div>
            <div className="card-body">
                {
                    loading ? (
                        <PieChartLoader />
                    ) :
                    data.length > 0 ?
                         <Chart
                             options={chartData.options}
                             series={chartData.series}
                             type="pie"

                         />
                        :
                        <div
                            style={{
                                display:"grid",
                                alignItems:"center",
                                height:260,
                                textAlign:"center"
                            }}
                        >
                            Não encontramos dados correspondentes. Tente ajustar seus critérios de busca.
                        </div>
                }
            </div>
        </div>
    );
};


const mapDispatchToProps = dispatch => ({
    openAny:(children,title,size) => dispatch(openAny(children,title,size)),
    openModal:(modal,payload) => dispatch(openModal(modal,payload))
});

export default connect(null,mapDispatchToProps)(PieChartWithLoader);
