import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import User from '../../components/patients/User';
import {getDiagnostic} from "../../store/actions/companiesActions";
import {closeAny, openAny} from "../../store/actions/uiActions";

function PatientController({auth,isMobile,getDiagnostic,diagnostics,openAny,closeAny}) {

    useEffect(() => {
        getDiagnostic()
    }, []);

    return (
        <User
            diagnostics={diagnostics}
            auth={auth}
            isMobile={isMobile}
            permission="patients"
            type="patient"
            title="Pacientes"
            openAny={openAny}
            closeAny={closeAny}
        />
    );
}

const mapStateToProps = state => ({
    auth: state.auth,
    diagnostics: state.companies.diagnostics
});

const mapDispatchToProps = dispatch => ({
    getDiagnostic:() => dispatch(getDiagnostic()),
    openAny:(children,title,size) => dispatch(openAny(children,title,size)),
    closeAny:() => dispatch(closeAny())
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientController);