import Lottie from 'react-lottie';
import React from "react";

export default function ListAtendimentos({data,loading}) {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: require('../../assets/lottie/loading_blue.json'),
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };
    let itens = [];
    let groupsCalls = []

    if(data && data.length > 0) {
        data = data?.filter(at => ![5,6,7].includes(at.type_schedule.id));
        itens = data?.filter((at) => [1,2].includes(at.status.id));

        groupsCalls = itens.reduce((acc, item) => {
            const specializationId = item.specialization_id;

            // Verifica se já existe um grupo para o specialization_id atual
            const existingGroup = acc.find(group => group[0]?.specialization_id === specializationId);

            if (existingGroup) {
                // Se o grupo já existe, verifica se o item já está no grupo
                if (!existingGroup.some(existingItem => existingItem.tid === item.tid)) {
                    existingGroup.push(item);
                }
            } else {
                // Se não existe, cria um novo grupo com o item atual
                acc.push([item]);
            }

            return acc;
        }, []);

// Ordena os grupos pelo número de itens (opcional)
        groupsCalls.sort((a, b) => b.length - a.length);


    }



    return (
        <div className="card " style={{height:380}}>
            <div className="card-header pt-5">
                {
                    loading ?
                        <div style={{textAlign:'left',marginLeft:-75,marginTop:5}}>
                            <Lottie
                                options={defaultOptions}
                                height={30}
                                width={200}
                            />
                        </div>
                    :
                        <div className="card-title d-flex flex-column">
                            <span className="fs-2hx fw-bolder text-matued me-2 lh-1 ls-n2">{itens.length}</span>
                            <span className="text-matued opacity-50 pt-1 fw-bold fs-6">{itens.length === 1 ? 'Atendimento restante' : "Atendimentos restantes"}</span>
                        </div>
                }
            </div>
            <div className="card-body pt-5" style={{height:290,overflow:'auto'}}>
                {
                    loading ?
                        <div style={{textAlign:'center',marginTop:90}}>
                            <Lottie
                                options={defaultOptions}
                                height={60}
                                width={300}
                            />
                        </div>
                    :
                        groupsCalls && Object.values(groupsCalls).length > 0 ?
                            Object.values(groupsCalls).map((value,index) => {

                                return(
                                    <div key={index}>
                                        <div className="d-flex flex-stack">
                                        <div className="text-gray-700 fw-bold fs-6 me-2">{value[0].treatment.specialization.profession && value[0].treatment.specialization.profession.description}  {value[0].treatment.specialization.description}</div>
                                            <div className="d-flex align-items-senter">
                                                <span className="text-gray-900 fw-boldest fs-6">{value.length}</span>
                                            </div>
                                        </div>
                                        <div className="separator separator-dashed my-3"></div>
                                    </div>
                                )
                            })
                        :
                            <div style={{textAlign:'center',marginTop:100}}> Não encontramos nenhum atendimento.. </div>
                }
            </div>
        </div>
    );
}
