import  React from 'react';
import { connect } from 'react-redux';
import User from '../../components/patients/User';
import {closeAny, openAny} from "../../store/actions/uiActions";

function UserController({isMobile,auth,openAny,closeAny}) {

    return (
        <User
            auth={auth}
            isMobile={isMobile}
            type="user" 
            title="Profissionais"
            permission="professionals"
            openAny={openAny}
            closeAny={closeAny}
        />
    );
}

const mapStateToProps = state => ({
    auth:state.auth
});

const mapDispatchToProps = dispatch => ({
    openAny:(children,title,size) => dispatch(openAny(children,title,size)),
    closeAny:() => dispatch(closeAny())
});

export default connect(mapStateToProps,mapDispatchToProps)(UserController);