import React, {lazy, Suspense, useEffect, useState} from "react";
import {connect, useDispatch} from 'react-redux';
import {useHistory,useParams } from "react-router-dom";
import PatientTop from "../patients/patientTop";
import { openModal } from "../../store/actions/uiActions";
import { deletePhotoProfessional, getProfessional, uploadPhotoProfessional } from "../../store/actions/professionalsActions";
import { Header, MenuMobile} from "../../components/Tab";
import Img5 from "../../assets/img/5.png";
import Info from "./Info";
const DocumentController = lazy(() => import('../document/DocumentController'));

function User({item,openModal,getProfessional,loading,uploadPhotoProfessional,deletePhotoProfessional,user, isMobile, layoutRef,setHeaderRight}) {
    const [novo, setNovo] = useState(false);
    const [tab,setTab] = useState(1);
    const dispatch = useDispatch();

    let history = useHistory();
    const { id } = useParams();

    useEffect(()=>{
        getProfessional(id);
    },[id,getProfessional]);

    const components = {
        1: <Info item={item} user={user} />,
        2:<DocumentController id={id} typeModel={3} title="Anexos" key={1} />
    }

    const ActiveTabComponent = components[tab];

    useEffect(() => {

        const loadData = async () => {
            getProfessional(id);
            await new Promise((r) => setTimeout(r, 1000));
        };

        loadData();

        return () => {
            dispatch({type:'PROFESSIONALS_SET','patient':''});
        };
    }, [dispatch,getProfessional,id]);

    useEffect(() => {
        function click(ev) {
            if (ev.srcElement.closest("#menupatient") == null) {
                setNovo(false);
            }
        }

        if (novo) {
            window.addEventListener("click", click, false);
            return () => {
                window.removeEventListener("click", click, false);
            };
        }
    }, [novo]);

    const shared = [
        {
            'id':'info',
            'description': 'Informações',
            'contents': 1,
            'notViewCompany':[],
            'order':1
        },
        {
            "id": "document",
            "description": "Anexos",
            "contents": 2,
            "notViewCompany": [],
            "order": 6,
            "permission": "documents.list",
            "comingSoon": false,
            "comingSoonNotCompany": [],
        }
    ]

    const filterNotViewCompany = shared.filter(comp =>{return !comp.notViewCompany.includes(user.company.id)});

    const menu = [...filterNotViewCompany];


    useEffect(() => {
        if(isMobile) {
            layoutRef.current.classList.remove('container-xxl');

            setHeaderRight(
                <>
                    <button className="btn btn-icon btn-active-icon-primary" style={{justifyItems: "flex-end", justifyContent: "flex-end"}} onClick={() => setNovo(!novo)}>
                      <span className="svg-icon svg-icon-2x">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="black" />
                          <path d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z" fill="#000" />
                        </svg>
                      </span>
                    </button>
                    <div className={"dropdown-menu " + (novo ? "show" : "")} style={{marginTop: 10, right: 0}} id="menupatient">
                        <MenuMobile tab={tab} menu={menu} setTab={setTab} auth={user}/>
                    </div>
                </>
            )
        }

    }, [isMobile, layoutRef, novo]);

    if (item?.length === 0) {
        return (
            <div style={{ textAlign: "center" }}>
                <br /><br />
                <br /><br />
                <h1>O usuário não foi encontrado!</h1>
                <br /><br />
                <br /><br />
                <img src={Img5} style={{ width: 400 }} alt="img" />
            </div>
        );
    }

    return (
        <>
            { !isMobile &&<div className="page-title d-flex flex-rpw align-items-start  flex-wrap me-lg-2 pb-lg-0" style={{marginBottom:5}}>
                <h3 style={{ flex: 1 }}><button onClick={() => history.goBack()} className="btn btn-icon btn-secondary" style={{ marginRight: 20 }}>
                    <span className="svg-icon svg-icon-muted svg-icon-2hx">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M9.60001 11H21C21.6 11 22 11.4 22 12C22 12.6 21.6 13 21 13H9.60001V11Z" fill="black"/>
                            <path opacity="0.3" d="M9.6 20V4L2.3 11.3C1.9 11.7 1.9 12.3 2.3 12.7L9.6 20Z" fill="black"/>
                        </svg>
                    </span>
                </button> Ficha do usuário</h3>
            </div> }
            <PatientTop
                openModal={openModal}
                item={item ?? {}}
                loading={loading}
                edit={true}
                menu={<Header tab={tab} menu={menu} setTab={setTab} />}
                type="user"
                onUpload={(file) => uploadPhotoProfessional(file,item.id)}
                onDelete={() => {deletePhotoProfessional(item.id)}}
                auth={user}
                permission="professionals"
            />
            <Suspense fallback={<div>Loading...</div>}>
                {ActiveTabComponent}
            </Suspense>
        </>
    );
}


const mapStateToProps = state => ({
    item:state.professionals.item,
    user:state.auth,
    loading: state.professionals.loading,
})

const mapDispatchToProps = dispatch => ({
    openModal:(modal,payload) => dispatch(openModal(modal,payload)),
    getProfessional:(id) => dispatch(getProfessional(id)),
    uploadPhotoProfessional:(file,id) => dispatch(uploadPhotoProfessional(file,id)),
    deletePhotoProfessional:(id) => dispatch(deletePhotoProfessional(id))
 })

export default connect(mapStateToProps,mapDispatchToProps)(User)
