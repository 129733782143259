import axios from 'axios';
import { toast } from 'react-toastify';

var api = axios.create({
 baseURL: 'https://api.wayfly.com.br/api/'
  //baseURL: 'http://localhost/api/'
});

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {

    if(error.response && error.response.status === 401){
      localStorage.clear();
      window.location = '/login'
    }

    if(error.response && error.response.status === 500){
      toast('Não foi possível realizar conexão com o servidor.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    return Promise.reject(error);
  }
);

export default api;
