
export const getHealthInsurances = (patient) => ({type: 'GET_HEALTH_INSURANCE',patient});
export const healthInsuranceSuccess = (data) => ({type: 'HEALTH_INSURANCE_SUCCESS',data});
export const healthInsuranceLoading = (status) => ({type: 'HEALTH_INSURANCE_LOADING',status});
export const updateHealthInsurance = (data,id) => ({type: 'UPDATE_HEALTH_INSURANCE',data,id});
export const storeHealthInsurance = (data) => ({type: 'STORE_HEALTH_INSURANCE',data});
export const destroyHealthInsurance = (id) => ({type: 'DESTROY_HEALTH_INSURANCE',id});









