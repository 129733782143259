import  React,{ useState,useEffect} from 'react';
import { connect } from 'react-redux';
import Finance from '../../components/finance/Finance';
import {destroyFinance, destroyFinanceTask, getFinance, getTypePayment} from '../../store/actions/financeActions';
import {closeAny, openAny, openModal} from '../../store/actions/uiActions';
import {DateTime} from "luxon";

function ReceiveController({auth, get,items,loading,openModal,destroyFinance,patient,name,title,destroyFinanceTask,getTypePayment,typePayments,typeModel,isMobile,layoutRef, setHeaderNavigation, openAny, closeAny}) {
	const [dateStart,setDateStart] = useState(patient?.id ? DateTime.fromISO(patient.created_at).minus({years: 1}).toFormat("yyyy-MM-dd") : DateTime.local().startOf('month').toFormat('yyyy-MM-dd'));
    const [dateEnd,setDateEnd] = useState(DateTime.local().endOf('month').toFormat('yyyy-MM-dd'));
	const [typeDate,setTypeDate] = useState(1);
	const [typePayment, setTypePayment] = useState(0);
	const [bankAccountId, setBankAccountId] = useState(0);
	const [nota, setNota] = useState(0);

	useEffect(() =>{
        get(1,dateStart,dateEnd,patient?.id,typeDate,typePayment,typeModel,nota);
		getTypePayment(1);
    },[]);

	const start = (data) => {
		closeAny()
        get(1,data.dateStart,data.dateEnd,patient?.id,data.typeDate,data.typePayment,typeModel,data.nota,data.bankAccountId);
    }

	const download = () => {
		get(1,dateStart,dateEnd,patient?.id,typeDate,typePayment,typeModel,nota);
	}

	return (
		<Finance
			setHeaderNavigation={setHeaderNavigation}
			layoutRef={layoutRef}
			isMobile={isMobile}
			download={download}
			nota={nota}
			setNota={setNota}
			typePayment={typePayment}
			setTypePayment={setTypePayment}
			typePayments={typePayments}
			typeDate={typeDate}
			setTypeDate={setTypeDate}
			start={start} 
			dateStart={dateStart}  
			setDateStart={setDateStart} 
			dateEnd={dateEnd} 
			setDateEnd={setDateEnd}  
			patient={patient?.id}
			name={name}  
			destroy={destroyFinance} 
			items={items} 
			loading={loading} 
			auth={auth} 
			title={title ? title : "Contas a receber"} 
			openModal={openModal} 
			permission="receive"
			destroyFinanceTask={destroyFinanceTask}
			typeModel={typeModel}
			openAny={openAny}
			setBankAccountId={setBankAccountId}
			bankAccountId={bankAccountId}

		/>
	);
}

const mapStateToProps = state => ({
    auth: state.auth,
    items: state.finance.receive,
    loading: state.finance.loading,
	typePayments:state.finance.typePayments
}); 

const mapDispatchToProps = dispatch => ({
    get:(type,dateStart,dateEnd,patient,typeDate,typePayment,typeModel,nota,bankAccountId) => dispatch(getFinance(type,dateStart,dateEnd,patient,typeDate,typePayment,typeModel,nota,bankAccountId)),
    openModal:(modal,payload) => dispatch(openModal(modal,payload)),
    destroyFinance:(data) => dispatch(destroyFinance(data)),
	destroyFinanceTask:(data) => dispatch(destroyFinanceTask(data)),
	getTypePayment:(type) => dispatch(getTypePayment(type)),
	openAny:(children,title,size) => dispatch(openAny(children,title,size)),
	closeAny:() => dispatch(closeAny())
});

export default connect(mapStateToProps,mapDispatchToProps)(ReceiveController);