import { push } from 'connected-react-router';
import { put, takeEvery,select} from 'redux-saga/effects';
import api from '../../api';
import { permissionsSuccess, profilesLoading, profilesLoadingAction, profilesSuccess } from '../actions/profilesActions';
import { closeModal } from '../actions/uiActions';
import { messenge } from './utils';


export const getAuthToken = (state) => state.auth.token;
export const getAuthID = (state) => state.auth.id;
export const getAuthCompany = (state) => state.auth.company.id

function* getProfiles(action){

    try{
       
        yield put(profilesLoading(true))
        const response = yield  api.get("/roles",{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });
        
        if(response.status === 200){       
            yield put(profilesSuccess(response.data));
            yield put(profilesLoading(false))
            yield getPermissions();
        }else{
           
        }
    } catch (e) {
        console.error(e);    
        yield put(profilesLoading(false))   
    }
}


function* getPermissions(){
   
    try{
        const response = yield  api.get("/permissions",{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });
      
        if(response.status === 200){       
          yield put(permissionsSuccess(response.data))
        }else{
           
        }
    } catch (e) {
        console.error(e);    
       
    }
}

function* update(action){
    try{
        yield put(profilesLoadingAction(true))
        const response = yield  api.put("/roles/"+action.id,action.data,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });
     
        if(response.status === 200){       
            yield messenge(response.data,'success');
            yield put(profilesLoadingAction(false))
            yield getProfiles();           
        }
    } catch (e) {
        console.error(e);    
        yield put(profilesLoadingAction(false))
        yield messenge(e.data,'warning');
    }
}

function* store(action){
    try{
        yield put(profilesLoadingAction(true))
        const response = yield  api.post("/roles",action.data,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });
    
        if(response.status === 200){     
            yield put(closeModal());
            yield messenge(response.data,'success');
            yield put(profilesLoadingAction(false));
            yield getProfiles();
            yield put(push('/company/roles'));
        }
    } catch (e) {
        console.error(e);    
        yield put(profilesLoadingAction(false))
        yield messenge(e.data,'warning');
    }
}


function* destroy(action){
    try{
      
        const response = yield  api.delete("/roles/"+action.id,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });
    
        if(response.status === 200){                
            yield messenge(response.data,'success');
          
            yield getProfiles();       
        }
    } catch (e) {
        console.error(e);    
    
        yield messenge(e.data,'warning');
    }
}


function* usePermission(action){
    try{
  

        const response = yield  api.post("/permissions/"+action.action+"/role/"+action.role+"/permission/"+action.permission,{},{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });
        
        if(response.status === 200){                
            yield messenge(response.data,'success');          
        }

    } catch (e) {
        console.error(e);    
    
        yield messenge(e.data,'warning');
    }
}

export function* profilesSaga() {
    yield takeEvery('GET_PROFILES', getProfiles);
    yield takeEvery('UPDATE_PROFILES', update);
    yield takeEvery('STORE_PROFILES', store);
    yield takeEvery('DESTROY_PROFILES', destroy);
    yield takeEvery('USE_PERMISSION', usePermission);
    yield takeEvery('GET_PERMISSIONS', getPermissions);

 
}