import {updateObject} from '../../utils';

const initialState = {
    itens: [],
    loading:false,
    loadingAction:false,
    permissions:[]
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'PROFILES_SUCCESS':
            return updateObject(state, {itens: action.itens,loading:false});
        case 'PERMISSIONS_SUCCESS':
            return updateObject(state, {permissions: action.itens});
        case 'PROFILES_LOADING':
            return updateObject(state, {loading: action.status});
        case 'PROFILES_LOADING_ACTION':
            return updateObject(state, {loadingAction: action.status});
        default:
            return state;
    }
}

export default reducer;
  