import {Controller, useForm} from "react-hook-form";
import {Field} from "../../components/Field";
import React, {useEffect} from "react";
import api from "../../api";
import {debounce} from "lodash";
import {useDispatch, useSelector} from "react-redux";

export default function Search({onSubmit, statusArray, myScheduler}) {
    const {control, handleSubmit} = useForm({
        defaultValues: {
            professional: [],
            patient: [],
            status: 0,
        },
    });

    const dispatch = useDispatch();
    const rooms = useSelector(state => state.companies.rooms)

    useEffect(() => {
        dispatch({type:'GET_ROOMS'})
    }, []);


    let token = useSelector(state => state.auth.token);

    const handleInputChangeProfessional = debounce(((value, callback) => {
        api.post(`/user/search/type/1?page=1&pageSize=10`, {name: value}, {
            headers: {
                "Authorization": "Bearer " + token,
            },
        }).then((response) => {
            const options = response.data.data.map((result) => ({
                value: result.id,
                label: result.name,
            }));
            callback(options);
        });
    }), 1000);

    const handleInputChangePatient = debounce(((value, callback) => {
        api.post(`/user/search/type/3?page=1&pageSize=10`, {name: value}, {
            headers: {
                "Authorization": "Bearer " + token,
            },
        }).then((response) => {
            const options = response.data.data.map((result) => ({
                value: result.id,
                label: result.name,
            }));
            callback(options);
        });
    }), 1000);

    let items = [];

    if (statusArray && statusArray.length > 0) {
        items = statusArray.filter((element) => {
            return element.lack !== 2;
        });
    }

    let arrayStatus = [];
    arrayStatus.push({"label": "Todos", "value": 0});
    items.forEach((data) => {
        arrayStatus.push({"label": data.description, "value": data.id});
    });

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                {
                    !myScheduler ? <div className="col-md-4" style={{marginTop: 5}}>
                        <label className="fw-bold text-muted">Profissional:</label>
                        <Controller
                            name="professional"
                            control={control}
                            render={({field: {onChange, value}}) => <Field isMulti={true} type="selectAsync" key={2}
                                                                           onInputChange={handleInputChangeProfessional}
                                                                           value={value} onChange={onChange}/>}
                        />
                    </div> : <></>}
                <div className="col-md-4" style={{marginTop: 5}}>
                    <label className="fw-bold text-muted">Paciente:</label>
                    <Controller
                        name="patient"
                        control={control}
                        render={({field: {onChange, value}}) => <Field isMulti={true} type="selectAsync" key={1}
                                                                       onInputChange={handleInputChangePatient}
                                                                       value={value} onChange={onChange}/>}
                    />
                </div>
                <div className="col-md-4" style={{marginTop: 5}}>
                    <label className="fw-bold text-muted">Sala:</label>
                    <Controller
                        name="room"
                        control={control}
                        render={({field: {onChange, value}}) => <Field type="select-multi" options={rooms}
                                                                       value={value}
                                                                       onChange={onChange}/>}
                    />
                </div>
                <div className="col-md-4" style={{marginTop: 5}}>
                    <label className="fw-bold text-muted">Status:</label>
                    <Controller
                        name="status"
                        control={control}
                        render={({field: {onChange, value}}) => <Field type="select" options={arrayStatus} value={value}
                                                                       onChange={onChange}/>}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-10"></div>
                <div className="col-md-2" style={{marginTop: 22}}>
                    <button className="btn btn-success btn-sm btn-block" style={{width: "100%", marginTop: 3}}
                            type="submit">
                        Aplicar
                    </button>
                </div>
            </div>
        </form>
    );
}
