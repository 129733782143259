import React, {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {Field} from "../Field";
import {Button} from "../Button";

function ServiceModal({
    data,
    loading,
    updateService,
                          storeService
}) {
    const [typeCommission,setTypeCommission] = useState(null);
    const onSubmit = request => {
        if(data && data.id){
            updateService(request,data.id);
        }else{
            storeService(request)
        }
    }

    const { control, handleSubmit,  formState: { errors } } = useForm({
        defaultValues:{
            name: data ? data.name : null,
            total: data ? data.total : null,
            type_commission: data ? data.type_commission : null,
            commisission_fixed: data ? data.commisission_fixed : null,
            commisission_percentage: data ? data.commisission_percentage : null,
            integration: data ? data.integration : null
        }
    });

    useEffect(() => {
        if(data.type_commission) setTypeCommission(data.type_commission)
    })

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className={"col-lg-3"} style={{marginTop: 10}} >
                    <label className="fw-bold text-muted">Nome:</label>
                    <Controller
                        name="name"
                        control={control}
                        rules={{ required: true }}
                        render={({ field : { onChange,  value }}) => <Field type="text" value={value} onChange={onChange} />}
                    />
                    {errors.name && <p style={{color:'red',marginTop:10}}>Necessário preencher o nome.</p>}
                </div>
                <div className={"col-lg-3"} style={{marginTop: 10}}>
                    <label className="fw-bold text-muted">Valor:</label>
                    <Controller
                        name="total"
                        control={control}
                        rules={{ required: true }}
                        render={({ field : { onChange,  value }}) => <Field type="money" value={value} onChange={onChange} />}
                    />
                    {errors.total && <p style={{color:'red',marginTop:10}}>Necessário preencher o valor.</p>}
                </div>
                <div className={"col-lg-3"} style={{marginTop: 10}} >
                    <label className="fw-bold text-muted">Tipo da comissão:</label>
                    <Controller
                        name="type_commission"
                        control={control}
                        rules={{ required: true }}
                        render={({ field : { onChange,  value }}) => <Field type="select" value={value} onChange={(item) => {onChange(item);setTypeCommission(item)}} options={[{value:1,label:'Valor fixo'},{value:2,label:'Porcentagem'}]} />}
                    />
                    {errors.type_commission && <p style={{color:'red',marginTop:10}}>Necessário selecionar o tipo da comissão.</p>}
                </div>
                {
                    typeCommission === 1 ?
                        <div className={"col-lg-3"} style={{marginTop: 10}} >
                            <label className="fw-bold text-muted">Comissão (R$):</label>
                            <Controller
                                name="commisission_fixed"
                                control={control}
                                rules={{ required: true }}
                                render={({ field : { onChange,  value }}) => <Field type="money" value={value} onChange={onChange} />}
                            />
                            {errors.commisission_fixed && <p style={{color:'red',marginTop:10}}>Necessário preencher a comissão.</p>}
                        </div>
                        :
                        typeCommission === 2 ?
                            <div className={"col-lg-3"} style={{marginTop: 10}} >
                                <label className="fw-bold text-muted">Comissão (%):</label>
                                <Controller
                                    name="commisission_percentage"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field : { onChange,  value }}) => <Field type="text" value={value} onChange={onChange} />}
                                />
                                {errors.commisission_percentage && <p style={{color:'red',marginTop:10}}>Necessário preencher a comissão.</p>}
                            </div>
                            :
                                <></>
                }


            </div>
            <div className="row" style={{textAlign: 'right', marginTop: 10}}>
                <div className="col-md-9">

                </div>
                <div className="col-md-3">
                    {
                        <Button loading={loading} text="Salvar" type="submit"/>
                    }
                </div>
            </div>
        </form>
    );
}

export default ServiceModal;
