import {updateObject} from '../../utils';

const initialState = {
    itens:[],
    loading: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SERVICE_SUCCESS':
            return updateObject(state, {itens: action.data});
        case 'SERVICE_LOADING':
            return updateObject(state, {loading: action.status});
        case 'SERVICE_STORE':{
            const newArray = [...state.itens];
            newArray.push(action.data);
            return updateObject(state, {itens:newArray});
        }
        case 'SERVICE_UPDATE':{
            const newArray = [...state.itens];
            const index = state.itens.findIndex((value) => value.id === action.id);

            newArray[index] = action.data

            return updateObject(state, {itens:newArray});
        }
        default:
            return state;
    }
}

export default reducer;
