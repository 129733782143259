import React, { useEffect } from "react";
import { connect } from 'react-redux';
import { destroyProfiles, getProfiles, permissionUse } from "../../store/actions/profilesActions";
import Lottie from 'react-lottie';
import { useMediaQuery } from "react-responsive";
import { openModal } from "../../store/actions/uiActions";
import { checkPermission } from "../../utils";
import { Field } from "../../components/Field";
function Profile({getProfiles,itens,loading,openModal,auth,permissions,destroyProfiles,permissionUse}){

	const isBigScreen = useMediaQuery({query: '(min-width: 1200px)'});

    useEffect(() => {
		getProfiles()
    }, [getProfiles]);

	const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: require('../../assets/lottie/loading_blue.json'),
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };


	if(loading){
		return <div style={{textAlign:'center',height:isBigScreen ? 770 : 550,paddingTop:isBigScreen ? 280 : 160}}>
			<Lottie
				options={defaultOptions}
				height={200}
				width={200}
			/>
		</div>
	}

	const handle = (action,role,permission) => {
		permissionUse(action,role,permission)
	}

    return (
        <div className="row">
			{
				itens.map((data,index)=>{

					return (
						<div className="col-md-4" key={index}>
							<div className="card card-flush" style={{marginBottom:20}}>
								<div className="card-header">
									<div className="card-title">
										<h2 style={{marginLeft:-30}}>{data.name[0].toUpperCase()+data.name.substring(1)}</h2>
									</div>
								</div>
								<div className="card-body pt-1" >
									<div className="fw-bold text-gray-600 mb-5">Total de usuários: {data.totalUsers}</div>
									<div className="d-flex flex-column text-gray-600" style={{height:300,overflowY: 'auto'}}>
										{
											permissions.map((p,pIndex) => {
												let isPermission = data.permissions.filter((per) => per.id === p.id);
												return(
													<div className="d-flex align-items-center py-2" key={pIndex}>
														{data.admin !== 1 ? <Field type="checkbox" value={isPermission.length > 0} onChange={(state)  => { handle((state ? "add" : "remove"),data.id,p.id)}}  /> : <span className="bullet bg-primary me-3"></span>}{p.description}
													</div>
												)
											})
										}
									</div>
								</div>
								<div className="card-footer">
								{ /*data.admin !== 1 && checkPermission(auth.permissions,"roles.update") && <button type="button" className="btn btn-sm btn-light btn-secondary my-1" style={{marginRight:10}} onClick={() => openModal('profile',data)} >Administrativo?</button>*/}
										{data.admin !== 1 && checkPermission(auth.permissions,"roles.update") && <button type="button" className="btn btn-sm btn-light btn-primary my-1" style={{marginRight:10}} onClick={() => openModal('profile',data)} >Editar</button>}
										{data.admin !== 1 && checkPermission(auth.permissions,"roles.destroy") && <button type="button" className={"btn btn-sm btn-light btn-danger my-1 "+(data.totalUsers > 0 ? "disabled" : "")} onClick={() => destroyProfiles(data.id)} >Excluir</button>}
								</div>
							</div>
						</div>
					)
				})
			}
        </div>
    );
}

const mapStateToprops = state => ({
    itens:state.profiles.itens,
    loading: state.profiles.loading,
	auth:state.auth,
	permissions:state.profiles.permissions
})

const mapDispatchToprops = dispatch => ({
	getProfiles:() => dispatch(getProfiles()),
	openModal:(modal,data) => dispatch(openModal(modal,data)),
	destroyProfiles:(id) => dispatch(destroyProfiles(id)),
	permissionUse:(action,role,permission) => dispatch(permissionUse(action,role,permission))
 })

export default connect(mapStateToprops,mapDispatchToprops)(Profile)
