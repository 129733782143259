import {DateTime} from "luxon";
import React, {useEffect, useState} from "react";
import Event from "./Event";

function Day({day, startTime, endTime, duration, events, eventClick, slotClick, expedients,isMobile}) {
    const [slots, setSlots] = useState([]);

    function isTimeInExpedient(time, expedients) {
        const dateTime = DateTime.fromFormat(time, 'HH:mm');

        for (const expedient of expedients) {
            const startTime = DateTime.fromFormat(expedient.startTime, 'HH:mm');
            const endTime = DateTime.fromFormat(expedient.endTime, 'HH:mm');

            // Verificar se o dia da semana e o horário estão dentro do expediente
            if (
                expedient.daysOfWeek.includes(dateTime.weekday) &&
                dateTime >= startTime &&
                dateTime <= endTime
            ) {
                return true;
            }
        }

        return false;
    }

    function generateTimeSlots(startTime, endTime, duration) {
        let timeSlots = new Set();
        let currentTime = DateTime.fromJSDate(startTime);

        while (currentTime <= endTime) {
            const formattedTime = formatTime(currentTime);
            timeSlots.add(formattedTime)
            currentTime = currentTime.plus({ minutes: duration });
        }

        timeSlots = Array.from(timeSlots)

        timeSlots.sort();

        let result = [];

        timeSlots.forEach((value) => {
            result.push({time: value});
        })

        return result;
    }

    function generateTimeSlotsEvents(startTime, endTime, duration, events,expedients) {
        let timeSlots = new Set();
        let currentTime = DateTime.fromJSDate(startTime);

        const occupiedTimeSlots = new Set();
        const blockedTimeSlots = new Set();
        const expedientTimeSlots = new Set();

        expedients.filter((ex) =>  ex.daysOfWeek.includes(day.weekday)).map((value) => {
            let eventStartTime = DateTime.fromSQL(day.toFormat('yyyy-MM-dd')+" "+value.startTime+":00");
            let eventEndTime = DateTime.fromSQL(day.toFormat('yyyy-MM-dd')+" "+value.endTime+":00");

            while (eventStartTime < eventEndTime) {
                const formattedTime = eventStartTime.toFormat('HH:mm');
                expedientTimeSlots.add(formattedTime);
                eventStartTime = eventStartTime.plus({ minutes: 1 });
            }
        })

        events.filter(ev => ev.status.id !== 10).map((ev) => {

            timeSlots.add(formatTime(DateTime.fromSQL(ev.start_date)));

            let eventStartTime = DateTime.fromSQL(ev.start_date);
            let eventEndTime = DateTime.fromSQL(ev.end_date);

            while (eventStartTime < eventEndTime) {
                const formattedTime = eventStartTime.toFormat('HH:mm');
                occupiedTimeSlots.add(formattedTime);
                eventStartTime = eventStartTime.plus({ minutes: 1 });
            }

            timeSlots.add(formatTime(DateTime.fromSQL(ev.end_date)));
        });

        while (currentTime <= endTime) {
            const formattedTime = formatTime(currentTime);
            timeSlots.add(formattedTime)
            currentTime = currentTime.plus({ minutes: duration });
        }

        events.filter(ev => ev.status.id === 10).map((ev) => {

            let eventStartTime = DateTime.fromSQL(ev.start_date);
            let eventEndTime = DateTime.fromSQL(ev.end_date);

            while (eventStartTime < eventEndTime) {
                const formattedTime = eventStartTime.toFormat('HH:mm');
                blockedTimeSlots.add(formattedTime);
                eventStartTime = eventStartTime.plus({ minutes: duration });
            }
        });

        timeSlots = Array.from(timeSlots)

        timeSlots.sort();

        let result = [];

        timeSlots.forEach((value) => {
            if (occupiedTimeSlots.has(value)) {
                result.push({time: value,occupied:true});
            }else{
                if (blockedTimeSlots.has(value)) {
                    result.push({time: value, blocked: true});
                }else{

                    if(expedients.length > 0){
                        if(!expedientTimeSlots.has(value)){
                            result.push({time: value,outHours:true});
                        }else{
                            result.push({time: value});
                        }
                    }else{
                        result.push({time: value});
                    }
                }
            }
        });

        return result;
    }

    function formatTime(dateTime) {
        return dateTime.toFormat("HH:mm");
    }

    useEffect(() => {
        const timeSlots = generateTimeSlots(startTime, endTime, duration);
        setSlots(timeSlots)
    }, []);


    useEffect(() => {
        const timeSlotsEvents = generateTimeSlotsEvents(startTime, endTime, duration, events,expedients);
        setSlots(timeSlotsEvents)
    }, [events,expedients]);

    return (
        <>
            {
                slots.map((slot,index) => {
                    let eventKey = day.toFormat('yyyy-MM-dd')+ " " + slot.time;

                    let event = events.filter(event =>  event.start_date === eventKey+":00");

                    if(event.filter(ev => ev.status.id !== 10).length > 1){
                        return event.filter(ev => ev.status.id !== 10)
                            .map((value,index2) => <Event isMobile={isMobile} eventClick={eventClick} event={value} key={index2} />);
                    }else if (event.filter(ev => ev.status.id !== 10).length === 1){
                        if(event[0].status.id !== 10) {
                            return <Event isMobile={isMobile} eventClick={eventClick} event={event[0]} key={index} />
                        }
                    }else if(slot.occupied){
                        return ;
                    }

                    return (
                        <a
                            title={slot.blocked ? 'Horário bloqueado' : slot.outHours ? "Profissional sem expediente, disponível para uma possível reposição" : 'Disponível para marcação de atendimentos'}
                            onClick={() => {
                                if(!slot.blocked){
                                    slotClick({
                                        slot: DateTime.fromSQL(eventKey + ":00"),
                                        outHours: slot.outHours
                                    })
                                }
                            }}
                            key={index}
                            className={`list-group-item list-group-item-action `+(slot.blocked ? 'disabled' : '')}
                            style={{height: 38, paddingLeft: 5,cursor:'pointer',backgroundColor:slot.blocked ? '#f36a6ac4' : slot.outHours ? "#e4e1e1" : 'transparent'}}
                        >
                            <div style={{fontSize: 10}}>{slot.time} {slot.blocked ? 'Horário bloqueado' : ''}</div>
                        </a>
                    )
                })
            }
        </>
    )
}

export default Day;
