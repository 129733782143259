import React, {useEffect} from "react";

function LegendasModal({getStatus,status}) {

    useEffect(() => {
        getStatus()
    },[])

    let itenss = []

    itenss = status.filter(function(element){
        return element.lack !== 2
    });

    return (
        <div>
            <h6>Tipo de agendamento:</h6>
            <div style={{flexDirection:'row',display:'flex',marginBottom:10}}>
                <div style={{height:20,width:100,background:'#ededed',marginRight:10}}></div>[A] Anamnese
            </div>
            <div style={{flexDirection:'row',display:'flex',marginBottom:10}}>
                <div style={{height:20,width:100,background:'#c6eaff',marginRight:10}}></div>[E] Evolução
            </div>
            <div style={{flexDirection:'row',display:'flex',marginBottom:10}}>
                <div style={{height:20,width:100,background:'#ffeca7',marginRight:10}}></div>[R] Reposição
            </div>
            <div style={{flexDirection:'row',display:'flex',marginBottom:10}}>
                <div style={{height:20,width:100,background:'#e2c7bc',marginRight:10}}></div>[D] Devolutiva
            </div>
            <div style={{flexDirection:'row',display:'flex',marginBottom:10}}>
                <div style={{height:20,width:100,background:'#a9fca9',marginRight:10}}></div>[AV] Avaliação
            </div>
            <div style={{flexDirection:'row',display:'flex',marginBottom:10}}>
                <div style={{height:20,width:100,background:'#42d5ee',marginRight:10}}></div>[P] Plano Terapêutico
            </div>

            <hr/>
            <h6>Status do atendimento:</h6>
            {
                itenss.map((value,index) => {
                    return (
                        <div style={{flexDirection:'row',display:'flex',marginBottom:10}} key={index}>
                            <div style={{height:20,width:5,background:value.color,marginRight:10}}></div> {value.description}
                        </div>
                    )
                })
            }
        </div>
    );
}

export default LegendasModal
