import {updateObject} from '../../utils';

const initialState = {
    typePayments:[],
    pay:[],
	  receive:[],
    loading:false,
    loadingAction:false,
	  parcelas:[],
    parcelasLoading:false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
		case 'FINANCE_PARCELAS_SUCCESS':
			return updateObject(state, {parcelas: action.data});
		case 'FINANCE_PARCELAS_LOADING':
			return updateObject(state, {parcelasLoading: action.status});
        case 'FINANCE_TYPE_PAYMENT':
            return updateObject(state, {typePayments: action.data});
		case 'FINANCE_LOADING':
            return updateObject(state, {loading: action.status});
        case 'FINANCE_LOADING_ACTION':
            return updateObject(state, {loadingAction: action.status});
        case 'FINANCE_RECEIVE_SUCCESS':
            return updateObject(state, {receive: action.data});
        case 'FINANCE_RECEIVE_SET':{
            let newArray = [...state.receive];

            let filter = newArray.filter((ar) => {
                let now = new Date(ar.due_date);
                let date = new Date(action.data.due_date);
                return now.getMonth()+"/"+now.getFullYear() === date.getMonth()+"/"+date.getFullYear();
            });

            if(filter.length > 0 || newArray.length === 0){
                newArray.push({...action.data,new:true});
            }

            return updateObject(state, {receive: newArray});
        }
        case 'FINANCE_RECEIVE_ALTER':{
            let newArray = [...state.receive];

            let index = newArray.findIndex((ar) => {
                return ar.id === action.id
            });

            newArray[index] = action.data;


            return updateObject(state, {receive: newArray});
        }
        case 'FINANCE_RECEIVE_DESTROY':{
            let newArray = [...state.receive];

            let index = newArray.findIndex((ar) => {
                return ar.id === action.id
            });

            if (index > -1) {
                newArray.splice(index, 1);
            }

            return updateObject(state, {receive: newArray});
        }

        case 'FINANCE_RECEIVE_DESTROY_MULT':{
            let newArray = [...state.receive];

            action.ids.forEach(value => {

                let index = newArray.findIndex((ar) => {
                    return ar.id === value.id
                });

                if (index > -1) {
                    newArray.splice(index, 1);
                }
            });

            return updateObject(state, {receive: newArray});
        }


        case 'FINANCE_PAY_SUCCESS':
            return updateObject(state, {pay: action.data});
        case 'FINANCE_PAY_SET':{
            let newArray = [...state.pay];

            let filter = newArray.filter((ar) => {
                let now = new Date(ar.due_date);
                let date = new Date(action.data.due_date);
                return now.getMonth()+"/"+now.getFullYear() === date.getMonth()+"/"+date.getFullYear();
            });

            if(filter.length > 0 || newArray.length === 0){
                newArray.push({...action.data,new:true});
            }

            return updateObject(state, {pay: newArray});
        }
        case 'FINANCE_PAY_ALTER':{
            let newArray = [...state.pay];

            let index = newArray.findIndex((ar) => {
                return ar.id === action.id
            });

            newArray[index] = action.data;

            return updateObject(state, {pay: newArray});
        }

        case 'FINANCE_PAY_DESTROY':{
            let newArray = [...state.pay];

            let index = newArray.findIndex((ar) => {
                return ar.id === action.id
            });

            if (index > -1) {
                newArray.splice(index, 1);
            }

            return updateObject(state, {pay: newArray});
        }

        case 'FINANCE_PAY_DESTROY_MULT':{
            let newArray = [...state.pay];

            action.ids.forEach(value => {
                let index = newArray.findIndex((ar) => {
                    return ar.id === value.id
                });

                if (index > -1) {
                    newArray.splice(index, 1);
                }
            });

            return updateObject(state, {pay: newArray});
        }
        case 'FINANCE_PAY_ADD_MULT':{
            let newArray = [...state.pay];

            newArray = [...newArray,...action.data.map((value) => ({...value,new:true}))]

            return updateObject(state, {pay: newArray});
        }

        case 'FINANCE_RECEIVE_ADD_MULT':{
            let newArray = [...state.receive];

            newArray = [...newArray,...action.data.map((value) => ({...value,new:true}))]

            return updateObject(state, {receive: newArray});
        }

        default:
            return state;
    }
}

export default reducer;
