import React, {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {Field} from "../Field";
import {Button} from "../Button";

function CompanyModal({
    data,

}) {

    const onSubmit = request => {

    }

    const { control, handleSubmit,  formState: { errors } } = useForm({
        defaultValues:{

        }
    });

    useEffect(() => {

    })

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className={"col-lg-3"} style={{marginTop: 10}}>
                    <label className="fw-bold text-muted">Nome:</label>
                    <Controller
                        name="name"
                        control={control}
                        rules={{required: true}}
                        render={({field: {onChange, value}}) => <Field type="text" value={value} onChange={onChange}/>}
                    />
                    {errors.name && <p style={{color: 'red', marginTop: 10}}>Necessário preencher o nome.</p>}
                </div>
                <div className={"col-lg-3"} style={{marginTop: 10}}>
                    <label className="fw-bold text-muted">CNPJ:</label>
                    <Controller
                        name="cnpj"
                        control={control}

                        render={({field: {onChange, value}}) => <Field type="text" value={value} onChange={onChange}/>}
                    />

                </div>
                <div className={"col-lg-3"} style={{marginTop: 10}}>
                    <label className="fw-bold text-muted">CPF:</label>
                    <Controller
                        name="cpf"
                        control={control}
                        render={({field: {onChange, value}}) => <Field type="cpf" value={value} onChange={onChange}/>}
                    />
                </div>
                <div className={"col-lg-3"} style={{marginTop: 10}}>
                    <label className="fw-bold text-muted">E-mail:</label>
                    <Controller
                        name="email"
                        control={control}
                        render={({field: {onChange, value}}) => <Field type="email" value={value} onChange={onChange}/>}
                    />
                </div>
            </div>
            <div className="row" style={{textAlign: 'right', marginTop: 10}}>
                <div className="col-md-9">

                </div>
                <div className="col-md-3">
                    {
                        <Button text="Salvar" type="submit"/>
                    }
                </div>
            </div>
        </form>
    );
}

export default CompanyModal;
