import React, {Suspense} from 'react';

export function Header({menu,tab,setTab,titleAlert,auth}) {
    return ( 
        <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fw-bolder" style={{fontSize:12,marginBottom: -25}}>
            {
                menu.map((value,index) => {
                    return (
                        <li className="nav-item mt-2" key={index} style={value.comingSoon ? {cursor:'not-allowed'} : {}}>
                            {
                                value.comingSoon &&  !value.comingSoonNotCompany.includes(auth.company.id) ?
                                    <a style={{cursor:'pointer'}}  className={"nav-link text-active-primary ms-0 me-10 py-5  position-relative"+(tab === value.contents ? " active" : "")+" "+(value.comingSoon ? " disabled" : "")} onClick={() => setTab(value.contents )}>
                                        {value.description}
                                        {
                                            value.comingSoon === true && <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-info" title={titleAlert}>
                                            Em breve
                                            </span>
                                        }
                                    </a>
                                :
                                    <a style={{cursor:'pointer'}} className={"nav-link text-active-primary ms-0 me-10 py-5  position-relative"+(tab === value.contents ? " active" : "")} onClick={() => setTab(value.contents )}>
                                        {value.description}
                                        {
                                            value.count > 0 && <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger" title={titleAlert}>
                                                {value.count > 99 ? "+99" : value.count}
                                            </span>
                                        }
                                      
                                        {
                                            value.alert && <span className="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle" title={titleAlert}>
                                                <span className="visually-hidden">New alerts</span>
                                            </span>
                                        }
                                    </a>
                            }
                        </li>
                    )
                })
            }
        </ul>
    )
}


export function MenuMobile({menu,tab,setTab,titleAlert,auth}) {
    return (
        <div>
            {
                menu.map((value,index) =>{
                    if(value.comingSoon &&  !value.comingSoonNotCompany.includes(auth.company.id)){
                        return(
                            <a  className={"dropdown-item disabled position-relative"} key={index}  style={{cursor:'not-allowed',marginTop:5}}>
                                {value.description}
                                <span className="position-absolute top-0 translate-middle badge rounded-pill bg-info" title={titleAlert}>
                                    Em breve
                                </span>
                            </a>
                        )
                    }else{
                        return (
                            <a className={"dropdown-item  position-relative "+(tab === value.contents ? " active" : "")} key={index} onClick={() => setTab(value.contents )} style={{cursor:'pointer',marginTop:5}}>
                                {value.description}
                                {
                                    value.count > 0 && <span className="position-absolute top-0  translate-middle badge rounded-pill bg-danger" title={titleAlert}>
                                        {value.count > 99 ? "+99" : value.count}
                                    </span>
                                }
                                {
                                    value.alert && <span className="position-absolute top-0 translate-middle p-2 bg-danger border border-light rounded-circle" title={titleAlert}>
                                        <span className="visually-hidden">New alerts</span>
                                    </span>
                                }
                            </a>
                        )
                    }
                })
            }
        </div>
    )
}

export function Contents ({components,tab}) {
    const ActiveTabComponent = components[tab];

    return (
        <div className="tab-content" style={{paddingLeft:9,paddingRight:9}}>
            <div className={"tab-pane fade show active"}>
                <Suspense fallback={<div>Loading...</div>}>
                    {ActiveTabComponent}
                </Suspense>
            </div>
        </div>
    )
}