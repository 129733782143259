import { put, takeEvery, select} from 'redux-saga/effects';
import api from '../../api';
import { closeModal } from '../actions/uiActions';
import { messenge } from './utils';
import {
    financeReceiveAlter,
    financeLoading,
    financeLoadingAction,
    financeReceiveSuccess,
    typePaymentSuccess,
    financePaySuccess,
    financePayAlter,
    financeReceiveDestroy,
    financePayDestroy,
    financeParcelasLoading,
    financeParcelasSuccess,
    financeReceiveDestroyMult, financePayDestroyMult, financeReceiveAddMult, financePayAddMult
} from '../actions/financeActions';
import toast from "react-hot-toast";

export const getAuthToken = (state) => state.auth.token;

function* getTypePayment(action){
    try{
        const response = yield  api.get('/finance/typePayment/type/'+action.typeFinance,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
           yield put(typePaymentSuccess(response.data))
        }
    } catch (e) {
        console.error(e);
    }
}

function* get(action){

    try{
        yield put(financeLoading(true));
        const response = yield  api.post('/finance/type/'+action.typeFinance+'/'+action.typeModel,{
            patient: action.patient ? action.patient : 0,
            start: action.dateStart,
            end: action.dateEnd,
			typeDate:action.typeDate,
            typePayment:action.typePayment,
            nota:action.nota,
            bankAccountId:action.bankAccountId
        },{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
			if(action.typeFinance === 1){
				yield put(financeReceiveSuccess(response.data));
			}

			if(action.typeFinance === 2){
				yield put(financePaySuccess(response.data));
			}

            yield put(financeLoading(false))
        }
    } catch (e) {
        console.error(e);
        yield put(financeLoading(false))
    }
}

function* store(action){

    try{
        let response =  api.post('/finance',action.data,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        toast.promise(response,{
                loading: 'Cadastrando a '+(action.data.type === 1 ? "receita." : "conta."),
                success: (success) => {
                    return success.data.message
                },
                error: (error) => {
                    return error.response.data.message
                }
            }
        );

        response = yield response;

        if(response.status === 200){
			if(action.data.type === 1){
				yield put(financeReceiveAddMult(response.data.data));
			}

			if(action.data.type === 2){
				yield put(financePayAddMult(response.data.data));
			}

            yield put(closeModal())
        }
    } catch (e) {
        console.error(e);
    }
}

function* update(action){
    try{

        let response = api.put('/finance/'+action.id,action.data,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        toast.promise(response,{
                loading: 'Editando a '+(action.data.type === 1 ? "receita." : "conta."),
                success: (success) => {
                    return success.data.message
                },
                error: (error) => {
                    return error.response.data.message
                }
            }
        );

        response = yield response;

        if(response.status === 200){

            yield put(financeLoadingAction(false))  ;

            if(action.data.all){
                if(action.data.type === 1){
                    yield put(financeReceiveDestroyMult(response.data.finances_remove));
                    yield put(financeReceiveAddMult(response.data.finances_add));
                }

                if(action.data.type === 2){
                    yield put(financePayDestroyMult(response.data.finances_remove));
                    yield put(financePayAddMult(response.data.finances_add));
                }
            }else{
                if(action.data.type === 1){
                    yield put(financeReceiveAlter(response.data.data[0],action.id))
                }

                if(action.data.type === 2){
                    yield put(financePayAlter(response.data.data[0],action.id))
                }
            }

            yield put(closeModal())
        }
    } catch (e) {
        console.error(e);
    }
}

function* destroy(action){
    try{
        let response = api.delete('/finance/'+action.data.id,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        toast.promise(response,{
                loading: 'Excluindo a '+(action.data.type === 1 ? "receita." : "conta."),
                success: (success) => {
                    return success.data.message
                },
                error: (error) => {
                    return error.response.data.message
                }
            }
        );

        response = yield response;

        if(response.status === 200){

            if(action.data.type === 1){
                yield put(financeReceiveDestroy(action.data.id));
            }

            if(action.data.type === 2){
                yield put(financePayDestroy(action.data.id));
            }

        }
    } catch (e) {
        console.error(e);
    }
}


function* destroyTask(action){
    try{
        let response =  api.post('/finance/task/destroy',action.data,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        toast.promise(response,{
                loading: 'Excluindo as '+(action.data.type === 1 ? "receitas." : "contas."),
                success: (success) => {
                    return success.data.message
                },
                error: (error) => {
                    return error.response.data.message
                }
            }
        );

        response = yield response;

        if(response.status === 200){

            if(action.data.type === 1){
                yield put(financeReceiveDestroyMult(response.data.dados));
            }

            if(action.data.type === 2){
                yield put(financePayDestroyMult(response.data.dados));
            }
        }
    } catch (e) {
        console.error(e);
    }
}

function* reportPayment(action){
    try{

        let response = api.post('/finance/report/payment/'+action.id,action.data,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        toast.promise(response,{
                loading: (action.data.type === 1 ? "Informando recebimento." : "Informando pagamento."),
                success: (success) => {
                    return success.data.message
                },
                error: (error) => {
                    return error.response.data.message
                }
            }
        );

        response = yield response;

        if(response.status === 200){

			if(action.typeFinance === 'receive'){
				yield put(financeReceiveAlter(response.data.data[0],action.id))
			}

			if(action.typeFinance === 'pay'){
				yield put(financePayAlter(response.data.data[0],action.id))
			}
			yield put(closeModal())
        }
    } catch (e) {
        console.error(e);
    }
}

function* getParcelas(action){

    try{
		yield put(financeParcelasLoading(true))
        const response = yield  api.get('/finance/value/'+action.value+'/parcelas/'+action.parcelas,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
			yield put(financeParcelasLoading(false));
			yield put(financeParcelasSuccess(response.data))
        }
    } catch (e) {
        console.error(e);
		yield put(financeParcelasLoading(false))
		if(e.response)  yield messenge(e.response.data,'warning');
    }
}

export function* FinanceSaga() {
    yield takeEvery('GET_TYPE_PAYMENT', getTypePayment);
    yield takeEvery('GET_FINANCE', get);
    yield takeEvery('STORE_FINANCE', store);
    yield takeEvery('UPDATE_FINANCE',update);
    yield takeEvery('DESTROY_FINANCE',destroy);
    yield takeEvery('DESTROY_FINANCE_TASK',destroyTask);
    yield takeEvery('REPORTPAYMENT_FINANCE',reportPayment);
	yield takeEvery('GET_FINANCE_PARCELAS', getParcelas);


}
