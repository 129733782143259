import {wsConnected, wsConnecting, wsDisconnected, wsMessage} from "./store/actions/wsActions";
import {store} from "./configureStore";

export let webSocket = null;
let isDisconnect = false;

export function connect(ws_url) {
    if (webSocket !== null) return;

    if (isDisconnect) isDisconnect = false;

    store.dispatch(wsConnecting());
    webSocket = new WebSocket(ws_url);

    webSocket.onopen = function () {

        if(webSocket.readyState !== 1){
            return;
        }
        store.dispatch(wsConnected());
    };

    webSocket.onclose = function () {
        store.dispatch(wsDisconnected());

        setTimeout(() => {
            if(isDisconnect) return;

            webSocket = null;
            connect(ws_url);
        }, 1000);
    };

    webSocket.onmessage = function (m) {
        return store.dispatch(wsMessage(JSON.parse(m.data)));
    };
}

export function disconnect()
{
    if(webSocket === null) return;

    webSocket.close();
    isDisconnect = true;
    webSocket = null;
}

export function sendWS(e) {

    if (webSocket === null) {
        console.error("WebSocket está fechado.");
        return;
    }

    webSocket.send(JSON.stringify(e));
}